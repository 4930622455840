import { useParams } from "react-router";
import CreateIronRodInvoice from "./CreateIronRodInvoice";
import { useQuery, useQueryClient } from "react-query";
import { useAuth } from "../../hooks/useAuth";
import ModalLoader from "../utils/ModalLoader";
import currency from "currency.js";
import { isEmpty } from "lodash";
import { useIsAdmin } from "../../utils/hooks";
import { UnAuthorized } from "../utils/UnAuthorized";

export default function EditPreorderInvoiceWrapper() {
  const { backendUrl } = useAuth();
  const { Trans_ID } = useParams();
  const isAdmin = useIsAdmin();

  const getTransaction = async (Trans_ID) => {
    let response = await fetch(
      `${backendUrl}/api/transaction/pre-order-invoice/${Trans_ID}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    let { data } = await response.json();
    data = cleanUpDataForEditing(data);
    return data;
  };

  const { data, refetch, isSuccess, isFetching } = useQuery(
    ["GET_ALL_TRANSACTION_DATA_PREORDER", Trans_ID],
    () => getTransaction(Trans_ID),
    {
      keepPreviousData: false,
    }
  );

  const cleanUpDataForEditing = ({
    transaction,
    sales,
    customer,
    payments,
    soldBatches,
  }) => {
    let items = [];
    let splitPayments = {};

    // console.log(payments);

    // TODO - Check if invoice has been tampered with

    let bank = "";
    // console.log(deliveryInfo, transaction);
    const cashPayment = payments.filter((el) => el.PaymentType === "Cash");
    //  console.log(cashPayment, payments.length);

    if (
      transaction.Payment_Type === "Split Payment" &&
      payments.length == 2 &&
      !isEmpty(cashPayment) && // No Balance
      Number(transaction.Balance) === 0
    ) {
      // console.log("jd");
      splitPayments.cashAmount = payments
        .filter((el) => el.PaymentType === "Cash")
        .map((el) => el.Credit)
        .reduce((a, b) => currency(a).add(b).value, 0);

      const otherPayment = payments.find((el) => el.PaymentType !== "Cash");

      splitPayments.splitPaymentType =
        otherPayment.PaymentType === "Credit/Debit Card"
          ? "card"
          : otherPayment.PaymentType === "Direct Bank Transfer"
          ? "directTransfer"
          : otherPayment.PaymentType === "Cheque"
          ? "cheque"
          : "";

      bank = otherPayment.BankName;
    } else if (
      transaction.Payment_Type === "Split Payment Multiple" ||
      payments.length >= 2
    ) {
      transaction.Payment_Type = "Split Payment Multiple";
      splitPayments.splitPaymentCardArray = payments
        .filter((el) => el.PaymentType === "Credit/Debit Card")
        .map((el) => ({
          amount: currency(el.Credit).value,
          amountPaid: currency(el.Credit).subtract(el.posCharge).value,
          bank: el.BankName,
          posCharge: el.posCharge,
        }));

      splitPayments.splitDirectBankTransferArray = payments
        .filter((el) => el.PaymentType === "Direct Bank Transfer")
        .map((el) => ({ amount: el.Credit, bank: el.BankName }));

      splitPayments.splitPaymentChequeArray = payments
        .filter((el) => el.PaymentType === "Cheque")
        .map((el) => ({
          amount: el.Credit,
          bank: el.BankName,
          chequeNumber: el.chequeNumber,
        }));

      splitPayments.cashAmount = payments
        .filter((el) => el.PaymentType === "Cash")
        .map((el) => el.Credit)
        .reduce((a, b) => currency(a).add(b).value, 0);

      if (isEmpty(splitPayments.splitPaymentCardArray)) {
        delete splitPayments.splitPaymentCardArray;
      }
      if (isEmpty(splitPayments.splitDirectBankTransferArray)) {
        delete splitPayments.splitDirectBankTransferArray;
      }
      if (isEmpty(splitPayments.splitPaymentChequeArray)) {
        delete splitPayments.splitPaymentChequeArray;
      }
    } else if (payments) {
      bank = payments[0].BankName;
    }

    items = sales.map((el) => ({
      ...el,
      PayType: transaction.Payment_Type,
      shippingCost: el.shippingCost,
      otherCharges: el.otherCharges,
      ShipTo: transaction.ShipTo,
      bank,
      customer,

      // --------------------------------------------
      loadingCharge: currency(el.LoadingCharge).value,
      offloadingCharge: currency(el.OffloadingCharge).value,
      transportCharge: currency(el.TransportCharge).value,
      ...(transaction.Payment_Type === "Split Payment" ||
      transaction.Payment_Type === "Split Payment Multiple"
        ? { posCharge: currency(el.PosCharge).value }
        : {}),
      taxType: transaction.taxType,
      soldBatches: soldBatches.filter((batch) =>
        batch.Bar_Code.startsWith(`${el.Bar_Code}-B`)
      ),
      // ---------------------------------------------
    }));

    return { items, splitPaymentsDataToEdit: splitPayments };
  };

  if (!isAdmin) return <UnAuthorized />;

  return (
    <>
      {isSuccess && !isFetching ? (
        <CreateIronRodInvoice
          mode="edit-preorder"
          oldInvoiceData={data.items}
          splitPaymentsDataToEdit={data.splitPaymentsDataToEdit}
          refreshInvoiceDataItems={refetch}
        />
      ) : null}
      {isFetching && <ModalLoader show={isFetching} />}
    </>
  );
}
