import { format } from "date-fns";
import { truncate } from "lodash";
import { useEffect } from "react";
import { useState } from "react";
import { Modal, Table, Form, Button, Tab } from "react-bootstrap";
import { useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { services } from "../../../config";
import { useAuth } from "../../../hooks/useAuth";
import { requisitionActions } from "../../../utils/reactQueryActions";
import ApprovalBadge from "../../utils/ApprovalBadge";
import Avatar from "../../utils/Avatar";
import "./../../../assets/scss/taskDetail.scss";
import Conversations from "./Conversations";

export default function ConversationsModal({ location }) {
  const { backendUrl } = useAuth();
  const queryClient = useQueryClient();
  let { id } = useParams();
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState("conversations");
  const [currentSubTab, setCurrentSubTab] = useState("todo");

  const getRequisitionDetails = async (id) => {
    // await waitFor(5000);
    let response = await fetch(`${backendUrl}/api/requisition/${id}`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    return data;
  };

  const {
    error,
    data: { requisition } = { requisition: null },
    isSuccess,
    refetch,
    isFetching,
  } = useQuery(
    [requisitionActions.GET_REQUISITION_DETAILS, id],
    () => getRequisitionDetails(id),
    {}
  );

  useEffect(() => {
    console.log(error);
  }, [error]);

  return (
    <>
      <Modal
        show={true}
        onHide={() => navigate(-1)}
        dialogClassName="task-detail-content"
        centered={false}
        animation={false}
        enforceFocus={false}
      >
        <Modal.Header bsPrefix="modal-header" closeButton>
          <div id="info">
            <ApprovalBadge text="Pending" className="approval" bg={"warning"} />
          </div>
        </Modal.Header>
        <Modal.Body bsPrefix="modal-body">
          <div className="nav-tabs d-flex d-md-none">
            <div
              className={`tab ${currentTab === "details" && "active"}`}
              onClick={() => setCurrentTab("details")}
            >
              Details
            </div>
            <div
              className={`tab ${currentTab === "conversations" && "active"}`}
              onClick={() => setCurrentTab("conversations")}
            >
              Conversation
            </div>
          </div>
          <div className="holder d-flex">
            <div
              className={`border-right ${
                currentTab === "details" ? "d-block" : "d-none"
              } d-md-block`}
            >
              <div className="info">
                <div className="task-header">
                  <div className="title">
                    <h1>Material Marketing Request</h1>
                    <p>Due date: June 16 at 02.00 PM</p>
                  </div>
                </div>
                {/* <div className="desc">
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Ratione, a! Omnis laboriosam quidem autem vitae minima non
                    nesciunt fugiat numquam mollitia perspiciatis tenetur saepe
                    laudantium, pariatur incidunt rerum voluptates vero!
                  </p>
                </div> */}

                <div className="d-flex">
                  <Table borderless className="info-table flex-grow-1">
                    <tbody>
                      <tr className="text-dark">
                        <td className="text-light">Type:</td>
                        <td>
                          <span className="fw-5">{requisition?.type}</span>{" "}
                          <br />
                          <span
                            className="text-light d-block mt-1 text-nowrap"
                            title={requisition?.title}
                          >
                            {requisition?.title}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                  <Table borderless className="info-table">
                    <tbody>
                      <tr>
                        <td className="text-light">Reciepient :</td>
                        <td>
                          <div className="d-flex align-items-center gap-2 fw-5">
                            <Avatar name={requisition?.requesttoUser?.Name} />
                            {requisition?.requesttoUser?.Department || "..."}
                          </div>
                        </td>
                      </tr>
                      <tr />
                    </tbody>
                  </Table>
                </div>

                <div className="nav-tabs d-flex w-50 mb-4">
                  <div
                    className={`tab ${currentSubTab === "todo" && "active"}`}
                    onClick={() => setCurrentSubTab("todo")}
                  >
                    Detail Requisition
                  </div>
                  <div
                    className={`tab ${currentSubTab === "attachment" &&
                      "active"}`}
                    onClick={() => setCurrentSubTab("attachment")}
                  >
                    Attachment
                  </div>
                </div>

                <div>
                  <div
                    className={` ${currentSubTab === "attachment" && "d-none"}`}
                  >
                    {/* <TaskTodoList
                      selectedTask={selectedTask}
                      usersList={projectUsersList}
                      user={user}
                      list={list}
                      canMarkTodo={() =>
                        canSeeChat(selectedProject, selectedTask)
                      }
                      moveTaskToList={moveTaskToList}
                    /> */}
                  </div>
                  <div className={` ${currentSubTab === "todo" && "d-none"}`}>
                    {/*<Attachments
                      rootFolderId={rootFolderId}
                      selectedTask={selectedTask}
                      selectedProject={selectedProject}
                      setAsCover={setAsCover}
                      canSeeChat={() =>
                        canSeeChat(selectedProject, selectedTask)
                      }
                      isShared={isShared}
                      // key={componentKey}
                    /> */}
                  </div>
                </div>
              </div>
            </div>

            <Conversations currentTab={currentTab} />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
