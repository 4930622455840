import { useQuery } from "react-query";
import {
  AddCircleIcon,
  CreateInvoiceIcon,
  NoSelectedItemIcon,
  UserSolidIcon,
  CashSelectIcon,
  CreditSelectIcon,
  DirectTransferSelectIcon,
  ChequeSelectIcon,
  CustomerAccountSelectIcon,
  CreditMemoSelectIcon,
  EditIcon,
  DeleteIcon,
} from "./Icons";
import Select from "react-select";
import { Col, Row, Form, Button } from "react-bootstrap";
import { isEmpty } from "lodash";
import { useAuth } from "../hooks/useAuth";
import { queryActions } from "../utils/reactQueryActions";
import CachedIcon from "mdi-react/CachedIcon";
import { useEffect } from "react";
import CurrencyCustomInput from "./utils/CurrencyCustomInput";
import { useIsAdmin, useIsCashier } from "../utils/hooks";

const cashOptions = [
  {
    label: "Cash On Hand",
    value: "Cash on hand",
  },
];

export default function ItemsPayment({
  payment = {
    Payment: "Credit",
    From: "",
    vendorPaymentAmount: 0,
  },
  setPayment,
}) {
  const isAdmin = useIsAdmin();
  const isCashier = useIsCashier();

  const paymentMethod = [
    {
      icon: <CreditSelectIcon />,
      label: "Credit",
      value: "Credit",
    },
    /*   {
      icon: <CreditSelectIcon />,
      label: "Imprest",
      value: "Imprest",
    }, */
    {
      icon: <CashSelectIcon />,
      label: "Cash",
      value: "Cash",
    },

    {
      icon: <ChequeSelectIcon />,
      label: "Cheque",
      value: "Cheque",
    },

    {
      icon: <DirectTransferSelectIcon />,
      label: "Direct Bank Transfer",
      value: "Direct Bank Transfer",
    },
    /*  {
      icon: <CreditSelectIcon />,
      label: "Credit/Debit Card (POS)",
      value: "Credit/Debit Card",
    }, */
  ];

  const { backendUrl } = useAuth();
  const fetchSetUpData = async () => {
    // await waitFor(5000);
    let response = await fetch(`${backendUrl}/api/invoice/set-up`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();

    data.vendors = [
      {
        value: "",
        label: "One-time vendor",
      },
      ...data?.vendors.map((el) => ({
        label: el.CompanyName,
        value: el.Vendor_ID,
      })),
    ];

    data.banks = data?.banks
      .filter((el) => el?.BankName && el.currency === "NGN")
      .map((el) => ({
        ...el,
        label: `${el.BankName} ${el?.currency ? `(${el?.currency})` : `(NGN)`}`,
        value: el.BankName,
      }));

    data.branch = [
      {
        value: "General",
        label: "General",
      },
    ];

    data.imprest = data?.imprest
      .filter((el) => el?.Description)
      .map((el) => ({
        ...el,
        label: el?.Description,
        value: el?.Description,
      }));

    if (!isEmpty(data?.imprest)) {
      //  formik.setFieldValue("From", data.imprest[0].value);
    }
    return data;
  };

  const {
    data = { banks: [], vendors: [], branch: [], imprest: [] },
    isLoading,
    refetch,
  } = useQuery([queryActions.INVOICE_SETUP], () => fetchSetUpData(), {
    keepPreviousData: true,
  });

  const resolveFromOptions = (PaymentType) => {
    // console.log(PaymentType, data.banks);
    return PaymentType === "Imprest"
      ? data.imprest
      : ["Cheque", "Direct Bank Transfer", "Credit/Debit Card"].includes(
          PaymentType
        )
      ? data.banks
      : cashOptions;
  };

  /*   useEffect(() => {
    console.log(payment);
  }, [payment]); */

  return (
    <Row className="form-mb">
      {/*   {JSON.stringify(payment)} */}
      <Form.Group as={Col}>
        <Form.Label className="fw-5">
          Payment{" "}
          <Button
            onClick={() => refetch()}
            size="sm"
            variant=""
            className="p-0 fs-6"
          >
            <CachedIcon />
          </Button>
        </Form.Label>
        <Select
          classNamePrefix="form-select"
          menuPlacement="top"
          placeholder="Choose method"
          isSearchable={false}
          value={paymentMethod.find((el) => el.value === payment?.Payment)}
          options={paymentMethod}
          onChange={(selected) => {
            setPayment({
              ...payment,
              Payment: selected?.value,
              From: "",
            });
          }}
          getOptionLabel={(el) => (
            <div className="label-with-icon d-flex gap-2 align-items-center">
              <span>{el.icon}</span> <span className="fw-5">{el.label}</span>
            </div>
          )}
          isDisabled={!Boolean(isCashier || isAdmin)}
        />
      </Form.Group>

      {payment.Payment === "Cheque" && (
        <Form.Group as={Col}>
          <Form.Label className="fw-5">
            Check Number
            <Button
              onClick={() => refetch()}
              size="sm"
              variant=""
              className="p-0 invisible"
            >
              <CachedIcon />
            </Button>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter Cheque Number"
            name="payment"
            value={payment.chequeNumber}
            onChange={(e) =>
              setPayment({
                ...payment,
                chequeNumber: e.target.value,
              })
            }
          />
        </Form.Group>
      )}

      {payment.Payment !== "Credit" && (
        <Form.Group as={Col}>
          <Form.Label className="fw-5">
            From{" "}
            <Button
              onClick={() => refetch()}
              size="sm"
              variant=""
              className="p-0 invisible"
            >
              <CachedIcon />
            </Button>
          </Form.Label>

          <Select
            classNamePrefix="form-select"
            menuPlacement="top"
            value={resolveFromOptions(payment.Payment).find(
              (el) => el.value === payment.From
            )}
            options={resolveFromOptions(payment.Payment)}
            onChange={(selected) => {
              setPayment({
                ...payment,
                Payment: payment.Payment,
                From: selected?.value,
              });
            }}
            key={payment?.Payment}
          />
        </Form.Group>
      )}

      <Form.Group
        as={Col}
        className={`${payment?.Payment === "Credit" ? "invisible" : ""}`}
      >
        <Form.Label className="fw-5">
          Amount
          <Button
            onClick={() => refetch()}
            size="sm"
            variant=""
            className="p-0 invisible"
          >
            <CachedIcon />
          </Button>
        </Form.Label>

        <CurrencyCustomInput
          name="vendorPaymentAmount"
          value={payment.vendorPaymentAmount}
          onValueChange={(value, name) => {
            setPayment({
              ...payment,
              vendorPaymentAmount: value,
            });
          }}
          placeholder="0.00"
          decimalsLimit={2}
        />
      </Form.Group>
    </Row>
  );
}
