import { FieldArray, useFormik, FormikProvider } from "formik";
import React, { useState, useMemo } from "react";
import Button from "react-bootstrap/Button";
import { Modal, Form, Nav, InputGroup, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import * as yup from "yup";
import EyeOffOutline from "mdi-react/EyeOffOutlineIcon";
import EyeOutline from "mdi-react/EyeOutlineIcon";

import useDebounce, {
  useBackendUrl,
  useEffectOnce,
  useIsAdmin,
  useIsDocumentControl,
} from "../utils/hooks";
import Avatar from "./utils/Avatar";
import { useAuth } from "../hooks/useAuth";
import { useStoreActions, useStoreState } from "easy-peasy";
import { DeleteIcon, GearIcon } from "./Icons";
import { useMutation, useQuery } from "react-query";
//  import { initialGeneralSettings } from "../utils/helpers";
// import Select from "react-select";
// import CurrencyCustomInput from "./utils/CurrencyCustomInput";
import ExpensesSelector from "./utils/ExpensesSelector";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import { Popover } from "react-tiny-popover";
// import eventBus from "../utils/EventBus";
// import { isEmpty, uniqBy } from "lodash";
// import { cacheTimes, queryActions } from "../utils/reactQueryActions";
// import NumberCustomInput from "./utils/NumberCustomInput";
// import { durationOptions } from "../config";

const initialGeneralSettings = {
  outrightAccountID: "",
  outrightAccountDescription: "",
  scrapAccountID: "",
  scrapAccountDescription: "",
};

export default function DamagesAccountModal({
  showGeneralSettingsModal,
  setShowGeneralSettingsModal,
}) {
  const { backendUrl } = useAuth();

  //   const isDocumentControl = useIsDocumentControl();
  //const generalSettings = useStoreState((state) => state.generalSettings);
  const setGeneralSettings = useStoreActions(
    (actions) => actions.setGeneralSettings
  );
  const [showJournalPopover, setShowJournalPopover] = useState(false);
  const [showScrapPopover, setShowScrapPopover] = useState(false);

  const addSettings = async (payload) => {
    let response = await fetch(`${backendUrl}/api/users/company-settings`, {
      method: "POST",
      // credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const addSettingsMutation = useMutation((payload) => addSettings(payload), {
    onError: () => {
      toast.error(`Unable to perform action`);
    },
  });

  const formik = useFormik({
    initialValues: initialGeneralSettings,
    onSubmit: (values) => {
      //  Save  to  server
      const {
        outrightAccountID,
        outrightAccountDescription,
        scrapAccountID,
        scrapAccountDescription,
      } = values;

      const inputs = {
        outrightAccountID,
        outrightAccountDescription,
        scrapAccountID,
        scrapAccountDescription,
      };
      //   console.log(inputs);
      addSettingsMutation.mutate(
        { settings: inputs },
        {
          onSuccess: ({ message, data }) => {
            setGeneralSettings(values);
            toast.success("Settings saved");
            setShowGeneralSettingsModal(false);
          },
        }
      );
    },
  });

  const setUp = async () => {
    // await waitFor(5000);
    let response = await fetch(`${backendUrl}/api/users/company/settings`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    if (data.settings) {
      formik.setValues({ ...initialGeneralSettings, ...data.settings });
    }

    return data;
  };

  const { isFetching } = useQuery(["DAMAGES_ACCOUNTS"], () => setUp(), {
    enabled: true,
  });

  const handleSelectedJornalAccount = (account) => {
    formik.setFieldValue("outrightAccountID", account?.AccountID);
    formik.setFieldValue("outrightAccountDescription", account?.Description);
    setShowJournalPopover(false);
  };

  const handleScrapJornalAccount = (account) => {
    formik.setFieldValue("scrapAccountID", account?.AccountID);
    formik.setFieldValue("scrapAccountDescription", account?.Description);
    setShowScrapPopover(false);
  };

  //   eventBus.useCustomEventListener("JOURNAL_ACCOUNT_CREATED", (account) => {
  //     handleSelectedJornalAccount(account);
  //   });

  return (
    <>
      <Modal
        size="md"
        show={showGeneralSettingsModal}
        onHide={() => setShowGeneralSettingsModal(false)}
        enforceFocus={false}
      >
        <Modal.Header closeButton>
          <Modal.Title className="h5">
            <GearIcon /> Account Settings
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormikProvider value={formik}>
            <Form
              noValidate
              onSubmit={formik.handleSubmit}
              autoComplete="off"
              style={
                isFetching ? { opacity: "0.5", pointerEvents: "none" } : {}
              }
            >
              <div className="my-4">
                <Form.Group className="mb-3">
                  <Form.Label>Outright Damage Account</Form.Label>
                  <InputGroup hasValidation>
                    <Form.Control
                      name="account"
                      placeholder="Select Outright Damage Account"
                      value={`${formik.values?.outrightAccountID ||
                        "..."} / ${formik.values?.outrightAccountDescription ||
                        "..."}`}
                      onChange={() => {}}
                      readOnly
                    />

                    <Popover
                      isOpen={showJournalPopover}
                      onClickOutside={() => setShowJournalPopover(false)}
                      content={() => (
                        <ExpensesSelector
                          handleSelectedExpense={handleSelectedJornalAccount}
                          usage={"chart of accounts"}
                          //   onHide={() => setShowJournalPopover(false)}
                        />
                      )}
                      position="bottom"
                    >
                      <InputGroup.Text
                        onClick={() => setShowJournalPopover(true)}
                      >
                        <MagnifyIcon />
                      </InputGroup.Text>
                    </Popover>
                  </InputGroup>
                </Form.Group>

                <hr />

                <Form.Group className="mb-3">
                  <Form.Label>Scrap Damage Account</Form.Label>
                  <InputGroup hasValidation>
                    <Form.Control
                      name="account"
                      placeholder="Select Outright Damage Account"
                      value={`${formik.values?.scrapAccountID ||
                        "..."} / ${formik.values?.scrapAccountDescription ||
                        "..."}`}
                      onChange={() => {}}
                      readOnly
                    />

                    <Popover
                      isOpen={showScrapPopover}
                      onClickOutside={() => setShowScrapPopover(false)}
                      content={() => (
                        <ExpensesSelector
                          handleSelectedExpense={handleScrapJornalAccount}
                          usage={"chart of accounts"}
                          //   onHide={() => setShowScrapPopover(false)}
                        />
                      )}
                      position="bottom"
                    >
                      <InputGroup.Text
                        onClick={() => setShowScrapPopover(true)}
                      >
                        <MagnifyIcon />
                      </InputGroup.Text>
                    </Popover>
                  </InputGroup>
                </Form.Group>
              </div>
            </Form>{" "}
          </FormikProvider>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => formik.submitForm()}
            variant="primary"
            disabled={addSettingsMutation.isLoading}
            className="px-4"
          >
            {addSettingsMutation.isLoading ? "Please wait..." : "Save"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
