import {
  Dropdown,
  Form,
  Button,
  Table,
  ProgressBar,
  InputGroup,
} from "react-bootstrap";
import Select from "react-select";
import { useEffect, useState } from "react";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import { useQuery, useMutation } from "react-query";
import queryString from "query-string";
// import { toast } from "react-toastify";
import { Link } from "react-router-dom";
// import MultiRangeSlider from "multi-range-slider-react";

import "./../../assets/scss/reports/cashbook.scss";
import useDebounce, {
  useLocalStorage,
  useQueryParams,
} from "../../utils/hooks";
import CachedIcon from "mdi-react/CachedIcon";
// import { queryActions } from "../../utils/reactQueryActions";
import { formatNumberSystem, paginationOptions } from "../../utils/helpers";
import { useAuth } from "../../hooks/useAuth";
import ReactPaginate from "react-paginate";
import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";
import NewVendorModal from "../NewVendorModal";
import EditVendorModal from "./EditVendor";
import { FilterTwoIcon, FilterCollapseIcon, MessageIcon } from "../Icons";
import MultiRangeSlider from "../MultiRangeSlider";
import { fetchActionsUtil } from "../../utils/helpers";
import { toast } from "react-toastify";
import ConfirmDialog from "../ConfirmDialogue";
import {
  ApproveCheckIcon,
  EditIcon,
  ChartPieSliceIcon,
  GearIcon,
} from "../Icons";
import currency from "currency.js";
import VendorSelector from "./VendorSelector";
import { Popover } from "react-tiny-popover";
import { IsPrivileged } from "../DisplayChildElement";

export default function SupplyRate() {
  const { backendUrl, token } = useAuth();
  const [showCreateNewVendorModal, setShowCreateNewVendorModal] = useState(
    false
  );
  const [showEditVendorModal, setShowEditVendorModal] = useState({
    open: false,
    id: "",
  });
  const [showItemsPopover, setShowItemsPopover] = useState(false);

  const [max, setMax] = useState();
  const [min, setMin] = useState();

  const [jobsValue, setJobsValue] = useState([]);
  const [certValue, setCertValue] = useState([]);

  const [initialRanges, setInitialRanges] = useState({ max: 0, min: 0 });

  const [showFilter, setShowFilter] = useLocalStorage("showFilter", false);

  const initialFilterParams = {
    q: "",
    cert: "",
    job: "",
    maxRange: 0,
    minRange: 0,
  };

  const [queryParams, setQueryParams] = useQueryParams({
    page: 1,
    limit: 40,
    ...initialFilterParams,
  });
  const [filterParams, setFilterParams] = useState({
    ...initialFilterParams,
    ...queryParams,
  });
  const debouncedFilterParams = useDebounce(filterParams, 500);

  useEffect(() => {
    setQueryParams({ ...queryParams, ...debouncedFilterParams });
  }, [debouncedFilterParams]);

  const handleSearchQueryChange = (e) => {
    setQueryParams({
      ...queryParams,
      [e.target.name]: e.target.value,
    });
  };

  const handleFilterParamsChange = (e) => {
    setFilterParams({
      ...filterParams,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  const getVendor = async (debouncedqueryParams) => {
    const { data } = await fetchActionsUtil(
      `${backendUrl}/api/vendors/supply-rate?${queryString.stringify(
        debouncedqueryParams
      )}`,
      "GET",
      token
    );

    return data;
  };

  const { data = { vendorsPos: [], vendorData: {} }, refetch } = useQuery(
    ["VENDOR_SUPPLY_RATE", debouncedFilterParams],
    () => getVendor(debouncedFilterParams),
    {
      enabled: true,
      keepPreviousData: true,
    }
  );

  const setSelectedVendor = (e) => {
    if (Object.entries(e).length > 0) {
      refetch();
    }
  };

  const setEditVendor = (e) => {
    if (e) {
      refetch();
    }
  };

  // const onSelected = (userId) => {
  //   const theSelected = [...selectedData];
  //   if (theSelected.length > 0) {
  //     const index = theSelected.findIndex((i) => i === userId);

  //     if (index >= 0) {
  //       theSelected.splice(index, 1);
  //       setSelectedData(theSelected);
  //     } else {
  //       setSelectedData((d) => [...d, userId]);
  //     }
  //   } else {
  //     setSelectedData((d) => [...d, userId]);
  //   }
  // };

  // const sendRFQ = () => {
  //   console.log(selectedData);
  // };

  const search = () => {
    setQueryParams({
      ...queryParams,
      ...filterParams,
    });
  };

  const reset = () => {
    setFilterParams(initialFilterParams);
    setQueryParams({
      ...queryParams,
      ...initialFilterParams,
    });
    setJobsValue([]);
    setCertValue([]);
  };

  useEffect(() => {
    setFilterParams({
      ...filterParams,
      maxRange: max,
      minRange: min,
    });
  }, [min, max]);

  const verifyVendorMutation = useMutation(
    (payload) =>
      fetchActionsUtil(
        `${backendUrl}/api/vendors/verify-user/${payload}`,
        "POST",
        token,
        { maincompany: backendUrl }
      ),
    {
      onSuccess: ({ message }) => {
        toast.success(message);
        refetch();
      },
      onError: ({ error, message }) => {
        console.log(error);
        toast.error(message);
      },
    }
  );

  const verifyVendor = async (id, name, verified) => {
    if (!id) return toast.error("Vendor Id is needed");

    if (
      await ConfirmDialog({
        title:
          verified !== "true" ? "Verify Vendor" : "Resend Registration Email",
        description: `Are you sure you want to ${
          verified !== "true" ? "verify" : "resend registration email to"
        }  ${name}`,
      })
    ) {
      verifyVendorMutation.mutate(id);
    }
  };

  const searchSelect = (name, value) => {
    const el = value.map((el) => el.value);
    if (name === "job") setJobsValue(value);
    if (name === "cert") setCertValue(value);

    setFilterParams({
      ...filterParams,
      [name]: el,
    });
  };

  // console.log("filterParams?.job", filterParams?.job);

  return (
    <IsPrivileged roleName="Suppliers Performance">
      <section className="cashbook-wrapped">
        <main className="cash-book">
          <div className="content">
            <div className="content-main">
              <div className={`filter-area ${showFilter ? "open" : "close"}`}>
                <div className="filter-header">
                  <h2>
                    <FilterTwoIcon /> Filters
                  </h2>

                  <Button
                    variant="white"
                    onClick={() => setShowFilter(!showFilter)}
                  >
                    <FilterCollapseIcon />
                  </Button>
                </div>
                <div className="filter-body">
                  <Form>
                    <div className="body">
                      <Form.Group className="mb-2-5">
                        <Form.Label>Vendor ID</Form.Label>
                        <InputGroup>
                          <Form.Control
                            name="Vendor_ID"
                            placeholder="Enter Vendor ID"
                            value={filterParams?.Vendor_ID}
                            onChange={(e) => handleFilterParamsChange(e)}
                          />
                          <Popover
                            isOpen={showItemsPopover}
                            onClickOutside={() => setShowItemsPopover(false)}
                            content={() => (
                              <VendorSelector
                                handleSelectedCustomer={(vendor) => {
                                  setFilterParams({
                                    ...filterParams,
                                    Vendor_ID: vendor.Vendor_ID,
                                  });
                                  setShowItemsPopover(false);
                                }}
                                setShowCustomerSelector={setShowItemsPopover}
                              />
                            )}
                            position="bottom"
                          >
                            <InputGroup.Text
                              onClick={() =>
                                setShowItemsPopover(!showItemsPopover)
                              }
                            >
                              <MagnifyIcon />
                            </InputGroup.Text>
                          </Popover>
                        </InputGroup>
                      </Form.Group>
                    </div>

                    <div className="buttons rounded">
                      <Button onClick={() => reset()} variant="white">
                        Reset
                      </Button>
                      <Button onClick={() => search()} variant="primary">
                        Search
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>

              <div className="content-body">
                <header className="text-nowrap">
                  <h1>
                    {!showFilter && (
                      <button
                        onClick={() => setShowFilter(!showFilter)}
                        className="btn filter"
                      >
                        <FilterTwoIcon />
                      </button>
                    )}
                    Suppliers Performance
                    <div className="d-flex justify-content-between ms-3">
                      <div className="d-flex flex-wrap gap-3">
                        <Button
                          onClick={() => refetch()}
                          variant=""
                          className="bg-light-blue border-0 text-primary"
                        >
                          <CachedIcon />
                        </Button>
                      </div>
                    </div>
                  </h1>
                  <div className="actions">
                    {/* {selectedData.length > 0 && (
                    <Button
                      disabled={selectedData.length > 0 ? false : true}
                      variant="primary mx-4"
                      onClick={sendRFQ}
                    >
                      Send RFQ
                    </Button>
                  )} */}

                    {/*  <Button
                    variant="primary"
                    onClick={() => setShowCreateNewVendorModal(true)}
                  >
                    + Create New
                  </Button> */}
                  </div>
                </header>

                <div className="px-4 pb-4">
                  <Table
                    borderless
                    striped
                    responsive
                    className="product-table"
                  >
                    <thead className="sticky border-bottom">
                      <tr>
                        <th />
                        <th>Vendor_ID</th>
                        <th>Company Name</th>
                        <th>Total PO</th>
                        <th>Not Fufilled</th>
                        <th>Fufilled</th>
                        <th>Fufillment Rate (%)</th>
                      </tr>
                    </thead>

                    <tbody className="blue-hover">
                      {data.vendorsPos &&
                        data.vendorsPos?.map((el, index) => (
                          <tr key={el.Vendor_ID}>
                            <td>
                              <Dropdown>
                                <Dropdown.Toggle
                                  variant=""
                                  className="bg-white border-0"
                                  bsPrefix="print more"
                                >
                                  <DotsVerticalIcon />
                                </Dropdown.Toggle>
                                <Dropdown.Menu
                                  popperConfig={{
                                    strategy: "fixed",
                                  }}
                                  renderOnMount
                                  className="dropdown-with-icons"
                                >
                                  <Dropdown.Item
                                    onClick={() =>
                                      setShowEditVendorModal({
                                        open: true,
                                        id: el.Vendor_ID,
                                      })
                                    }
                                    as="button"
                                  >
                                    <EditIcon /> Edit
                                  </Dropdown.Item>
                                  <Dropdown.Item>
                                    <Link
                                      to={`/vendor/PO?vendorNumber=${
                                        el.Vendor_ID
                                      }`}
                                    >
                                      <ChartPieSliceIcon /> Vendor Purchase
                                      Order
                                    </Link>
                                  </Dropdown.Item>
                                  <Dropdown.Item>
                                    <Link
                                      to={`/reports/purchase-history?vendorNumber=${
                                        el.Vendor_ID
                                      }&NoDate=${true}&CompName=${
                                        el.CompanyName
                                      }`}
                                    >
                                      <ChartPieSliceIcon /> Vendor Supply
                                      History
                                    </Link>
                                  </Dropdown.Item>
                                  <Dropdown.Item>
                                    <Link
                                      to={`/vendor/vendorsPos-ledger?Vendor_ID=${
                                        el.Vendor_ID
                                      }&NoDate=${true}&customerName=${
                                        el.CompanyName
                                      }`}
                                    >
                                      <GearIcon /> View Vendor Ledger
                                    </Link>
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </td>
                            <td>{el.Vendor_ID}</td>
                            <td>{el.CompanyName}</td>
                            <td>
                              {currency(el.StatusCountsNotComplete, {
                                symbol: "",
                                precision: 0,
                              })
                                .add(el.StatusCountsComplete)
                                .format()}
                            </td>
                            <td>
                              {currency(el.StatusCountsNotComplete, {
                                symbol: "",
                                precision: 0,
                              }).format()}
                            </td>
                            <td>
                              {currency(el.StatusCountsComplete, {
                                symbol: "",
                                precision: 0,
                              })
                                .add()
                                .format()}
                            </td>
                            <td className="text-nowrap">
                              <div className="d-flex gap-3">
                                {" "}
                                <ProgressBar
                                  style={{ width: "50%" }}
                                  now={
                                    currency(el.StatusCountsComplete)
                                      .divide(
                                        Number(el?.StatusCountsComplete) +
                                          Number(el.StatusCountsNotComplete)
                                      )
                                      .multiply(100).value
                                  }
                                />
                                <span>
                                  {currency(Number(el?.StatusCountsComplete))
                                    .divide(
                                      Number(el?.StatusCountsComplete) +
                                        Number(el.StatusCountsNotComplete)
                                    )
                                    .multiply(100).value || 0}
                                  %
                                </span>
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </div>

                <div className="d-flex justify-content-between px-4 align-items-center pagination pb-4 ">
                  <div className="pagination_left d-flex gap-3 align-items-center">
                    <p className="m-0 p-0">Show</p>
                    <select
                      value={queryParams.limit}
                      name="limit"
                      className="form-select "
                      onChange={(e) => handleSearchQueryChange(e)}
                    >
                      <option value="10">10 rows</option>
                      <option value="20">20 rows</option>
                      <option value="30">30 rows</option>
                      <option value="40">40 rows</option>
                    </select>
                  </div>

                  <ReactPaginate
                    {...paginationOptions}
                    pageCount={Math.ceil(data.count / queryParams.limit)}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={0}
                    onPageChange={({ selected }) => {
                      document.body.scrollTop = document.documentElement.scrollTop = 0;
                      setQueryParams({
                        ...queryParams,
                        page: selected + 1,
                      });
                    }}
                    forcePage={queryParams.page - 1}
                  />
                </div>
              </div>
            </div>
          </div>
          {showCreateNewVendorModal && (
            <NewVendorModal
              showCreateNewVendorModal={showCreateNewVendorModal}
              setShowCreateNewVendorModal={setShowCreateNewVendorModal}
              setSelectedVendor={setSelectedVendor}
            />
          )}
          {showEditVendorModal && (
            <EditVendorModal
              setShowCreateNewVendorModal={setShowEditVendorModal}
              showCreateNewVendorModal={showEditVendorModal}
              setSelectedVendor={setEditVendor}
            />
          )}
        </main>
      </section>
    </IsPrivileged>
  );
}
