import { useEffect, useRef, useState } from "react";
import { useAuth } from "./useAuth";
import { toast } from "react-toastify";

// Timeout durations

function useAutoLogout(setShowLogoutWarning) {
  const { logout, user, backendUrl } = useAuth();

  // const INACTIVITY_TIMEOUT = user ? ((user.autoLogoutTimeout - 1) * 60 * 1000 || 59 * 60 * 1000) :  59 * 60 * 1000 // default to 59 minutes (1 mins warning subtracted)
  const INACTIVITY_TIMEOUT = user
    ? user.autoLogoutTimeout
      ? (user.autoLogoutTimeout - 1) * 60 * 1000
      : 59 * 60 * 1000
    : 59 * 60 * 1000; // default to 59 minutes (1 mins warning subtracted)

  const timerRef = useRef(null); // To track the logout timeout globally

  const logoutUser = async options => {
    try {
      const res = await fetch(`${backendUrl}/api/auth/logout`, {
        method: "POST",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        body: JSON.stringify({ Staff_ID: user.Staff_ID, ...options }),
        credentials: "include",
      });

      if (res.ok) {
        logout();
      } else {
        toast.error("Unable to perform action: Please try again");
      }
    } catch (err) {
      console.log(err);
    } finally {
    }
  };

  const handleActivity = e => {
    localStorage.setItem("lastActivity", Date.now().toString());
    resetTimers();
  };

  const resetTimers = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current); // Clear the logout timer
    }

    // Start the logout timer
    timerRef.current = setTimeout(() => {
      if (user) {
        // logoutUser(); // Logs out the user when inactivity exceeds the limit
        setShowLogoutWarning(true); // Trigger the warning 1 minute before logging out
      }
    }, INACTIVITY_TIMEOUT);
  };

  useEffect(() => {
    // Set the initial activity timestamp and start the timers
    handleActivity();

    // Event listeners for user activity
    const events = ["mouseover", "click", "keydown", "scroll", "touchstart"];
    events.forEach(event => {
      window.addEventListener(event, handleActivity);
    });

    // Cleanup event listeners and timers on component unmount or page navigation
    return () => {
      events.forEach(event => {
        window.removeEventListener(event, handleActivity);
      });
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [logoutUser]);

  useEffect(() => {
    // Sync between tabs by listening for localStorage changes
    const syncLogoutAcrossTabs = e => {
      if (e.key === "lastActivity") {
        resetTimers(); // Reset the timers if activity changes in another tab
      }
    };

    window.addEventListener("storage", syncLogoutAcrossTabs);

    return () => {
      window.removeEventListener("storage", syncLogoutAcrossTabs);
    };
  }, []);
}

export default useAutoLogout;
