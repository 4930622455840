import { Modal, Form, Row, Col, Button } from "react-bootstrap";
import { styleHelper } from "../../utils/helpers";
import Select from "react-select";
import CurrencyCustomInput from "../utils/CurrencyCustomInput";
import NumberCustomInput from "../utils/NumberCustomInput";
import { useFormik } from "formik";
import { useAuth } from "../../hooks/useAuth";
import { useQuery, useMutation } from "react-query";
import RsDateRangePicker from "../utils/RsDateRangePicker";
import * as yup from "yup";
import { toast } from "react-toastify";
import moment from "moment";

const rangeOptions = [
  {
    value: "Day",
    label: "Day",
  },
  {
    value: "Week",
    label: "Week",
  },
  {
    value: "Month",
    label: "Month",
  },
];

const rewardByOptions = [
  {
    value: "Per Sale",
    label: "Per Sale",
  },
  {
    value: "Total Sales",
    label: "Total Sales",
  },
];

const driverType = [
  {
    label: "Self Collection",
    value: "Self Collection",
  },
  {
    label: "Direct Delivery",
    value: "Direct Delivery",
  },
];

export default function AddIncentiveModal({ setShowCreateIncentiveModal }) {
  const { backendUrl, user: authUser } = useAuth();
  const fetchSetUpData = async () => {
    // await waitFor(5000);
    let response = await fetch(`${backendUrl}/api/incentive/set-up`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();

    data.category = data.categories.map((el) => ({
      value: el.Cat_Name,
      label: el.Cat_Name,
    }));

    data.subcategory = data.subCategories.map((el) => ({
      value: el.Sub_Cat,
      label: el.Sub_Cat,
    }));

    data.region = data.region.map((el) => {
      return {
        value: el.region,
        label: el.region,
      };
    });

    data.depot = data.depot.map((el) => {
      return {
        value: el.Depot,
        label: el.Depot,
      };
    });

    data.plant = data.plant.map((el) => {
      return {
        value: el.Plant,
        label: el.Plant,
      };
    });

    console.log(data);

    return data;
  };

  const {
    data = { category: [], subcategory: [], region: [], depot: [], plant: [] },
  } = useQuery(["INCENTIVE_SETUP"], () => fetchSetUpData(), {
    keepPreviousData: true,
  });

  const createIncentive = async (payload) => {
    let response = await fetch(`${backendUrl}/api/incentive/create`, {
      method: "POST",
      // credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const createIncentiveMutation = useMutation(
    (payload) => createIncentive(payload),
    {
      onSuccess: ({ message, data }) => {
        toast.success(message);
        formik.resetForm();
        setShowCreateIncentiveModal(false);
      },
      onError: () => {
        toast.error(`Unable to perform action`);
      },
    }
  );

  const formik = useFormik({
    initialValues: {
      Name: "",
      Category: [],
      SubCategory: [],
      Delivery: [],
      Pickup_Location: [],
      Region: [],
      RewardBy: "Total Sales",
      RangeBy: "Month",
      Range: null,
      Quantity: 1,
      Amount: "",
    },
    validationSchema: yup.object().shape({
      Name: yup.string().required(),
    }),
    onSubmit: (values) => {
      console.log(values);

      if (!values.Amount) return toast.error("Please select an Amount");
      if (!values.Quantity) return toast.error("Please select a Quantity");

      const payload = {
        Name: values.Name,
        Category:
          values.Category.map((el) => el.value.trim()).join("&&&") + "&&&",
        Delivery:
          values.Delivery.map((el) => el.value.trim()).join("&&&") + "&&&",

        Region: values.Region.map((el) => el.value.trim()).join("&&&") + "&&&",
        PlantOrDepot:
          values.Pickup_Location.map((el) => el.value.trim()).join("&&&") +
          "&&&",
        Quantity: values.Quantity,
        RewardBy: values.RewardBy,
        RangeBy: values.RangeBy,
        Amount: values.Amount,
        RegDate: moment(),
        PreparedBy: authUser.Name,
      };

      createIncentiveMutation.mutate(payload);
    },
  });

  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      zIndex: 30000,
      backgroundColor: "red",
    }),
  };

  return (
    <Modal
      show={true}
      onHide={() => setShowCreateIncentiveModal(false)}
      dialogClassName="mx-auto"
      backdropClassName={`global-backdrop`}
      centered={true}
      animation={false}
      enforceFocus={false}
      fullscreen={styleHelper.isMobile}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h1 className="h5">Create Incentive</h1>
          <p className="fs-6">Add criterias</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-md-4">
        <Form noValidate onSubmit={formik.handleSubmit} autoComplete="off">
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm="2">
              Name
            </Form.Label>
            <Col sm="10">
              <Form.Control
                name="Name"
                placeholder="Enter Incentive name"
                value={formik.values.Name}
                onChange={formik.handleChange}
                isInvalid={formik.touched.Name && !!formik.errors.Name}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.Name}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm="2">
              Category
            </Form.Label>
            <Col sm="10">
              <Select
                options={data?.category}
                value={formik.values.Category}
                onChange={(value) => formik.setFieldValue("Category", value)}
                isMulti
                menuPosition="fixed"
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm="2">
              Sub Category
            </Form.Label>
            <Col sm="10">
              <Select
                options={data?.subcategory}
                value={formik.values.SubCategory}
                onChange={(value) => formik.setFieldValue("SubCategory", value)}
                isMulti
                menuPosition="fixed"
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm="2">
              Region
            </Form.Label>
            <Col sm="10">
              <Select
                options={data?.region}
                value={formik.values.region}
                onChange={(value) => formik.setFieldValue("Region", value)}
                isMulti
                menuPosition="fixed"
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm="2">
              Depot / Plant
            </Form.Label>
            <Col sm="10">
              <Select
                options={[...data?.depot, ...data?.plant]}
                value={formik.values.Pickup_Location}
                onChange={(value) =>
                  formik.setFieldValue("Pickup_Location", value)
                }
                isMulti
                menuPosition="fixed"
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm="2">
              Sale Type
            </Form.Label>
            <Col sm="10">
              <Select
                options={driverType}
                value={formik.values.Delivery}
                onChange={(value) => {
                  formik.setFieldValue("Delivery", value);
                }}
                isMulti
                menuPosition="fixed"
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm="2">
              Range By
            </Form.Label>
            <Col sm="4">
              <Select
                classNamePrefix={"form-select"}
                options={rangeOptions}
                value={rangeOptions.find(
                  (el) => el.values === formik.values.RangeBy
                )}
                onChange={({ value }) => {
                  formik.setFieldValue("RangeBy", value, true);
                }}
                isSearchable={false}
                menuPosition="fixed"
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm="2">
              Quantity
            </Form.Label>
            <Col sm="4">
              <NumberCustomInput
                value={formik.values.Quantity}
                onValueChange={(value) =>
                  formik.setFieldValue("Quantity", value)
                }
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm="2">
              Amount
            </Form.Label>
            <Col sm="8">
              <div className="row">
                <div className="col">
                  <CurrencyCustomInput
                    value={formik.values.Amount}
                    onValueChange={(value) =>
                      formik.setFieldValue("Amount", value)
                    }
                  />
                </div>
                <div className="col-4">
                  <Select
                    classNamePrefix={"form-select"}
                    value={rewardByOptions.find(
                      (el) => el.value === formik.values.RewardBy
                    )}
                    options={rewardByOptions}
                    onChange={({ value }) =>
                      formik.setFieldValue("RewardBy", value)
                    }
                    isSearchable={false}
                    menuPosition="fixed"
                  />
                </div>
              </div>
            </Col>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer className="gap-3">
        <Button
          onClick={() => alert("close modal")}
          className="px-4 py-2"
          variant="outline-primary"
        >
          Cancel
        </Button>
        <Button onClick={() => formik.submitForm()} className="px-4 py-2">
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
