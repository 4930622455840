import { Modal, Button, Table, InputGroup, Form } from "react-bootstrap";
import { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import Select from "react-select";
import { Popover } from "react-tiny-popover";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "react-query";
import currency from "currency.js";
import DateTime from "react-datetime";
import moment from "moment";
import { confirmable, createConfirmation } from "react-confirm";

import { CustomInput } from "../utils/CustomInput";
import { useAuth } from "../../hooks/useAuth";
import "../../assets/scss/scoped/issueItem.scss";
import { fetchActionsUtil } from "../../utils/helpers";
import GetTemplateModal from "./GetTemplateModal";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import { DeleteIcon } from "../Icons";
import ModalLoader from "../utils/ModalLoader";
import NewRequisitionModal from "../Requisition/NewRequisition";
import { useBackendUrl } from "../../utils/hooks";

function ShowProjectedItemsModal({ proceed, show, mainData }) {
  const [tableData, setTableData] = useState([]);
  const [activeTableIndex, setActiveTableIndex] = useState(null);
  const [selectedItem, setSelectedItem] = useState({});
  const [result, setResult] = useState();
  const [itemsRequested, setItemsRequested] = useState([]);
  const [showNewRequisitionModal, setShowNewRequisitionModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const backendUrl = useBackendUrl();

  const handleInputChange = ({ index, name, value }) => {
    const oldTableData = tableData;
    oldTableData[index][name] = value;
    setTableData([...oldTableData]);
  };

  const [isLoadingAddToCart, setIsLoadingAddToCart] = useState(false);

  const plantLocal = JSON.parse(window.localStorage.getItem("plantDetails"));

  useEffect(() => {
    const { prod_profiles, ...rest } = mainData;
    setResult(rest);

    const newProfiles = prod_profiles?.map((el) => ({
      ...el,
      actual: el?.quantity,
      damage: 0,
    }));
    setTableData(newProfiles);
    setSelectedItem({ prod_profiles: newProfiles, ...rest });
  }, [mainData]);

  const onDelete = (barcode) => {
    const item = tableData.filter((d) => d.Bar_Code !== barcode);
    setTableData(item);
  };

  const addToTable = async (e) => {
    if (e.key === "Enter") {
      const newPro = tableData?.map((el) => ({
        ...el,
        quantity: parseFloat(el?.actual) + parseFloat(el?.damage),
      }));

      const newitems = { ...result, prod_profiles: newPro };

      try {
        const { message } = await fetchActionsUtil(
          `${backendUrl}/api/production/check-quantity-items/${
            plantLocal.PLID
          }`,
          "POST",
          "",
          newitems
        );

        setIsLoadingAddToCart(false);
        if (message.length > 0) {
          setItemsRequested(message?.map((er) => er.item));

          return setErrorMessage("Item Required not enough");
          // message?.map((er) => toast.error(er.message));
        } else {
          setErrorMessage(null);
          setItemsRequested([]);
        }
      } catch (error) {
        console.log(error);

        setIsLoadingAddToCart(false);
        return;
      }

      setSelectedItem({ ...result, prod_profiles: tableData });
      // setErrorMessage(null);
      // setTableData([result]);
    }
  };

  return (
    <Modal
      onHide={() => proceed(false)}
      show={show}
      backdropClassName={`global-backdrop`}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="my-style-modal"
      animation={false}
      enforceFocus={false}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <div>
            <h1> Raw Materials</h1>
          </div>

          <p>Edit each raw material quantity by changing the input.</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div className="container">
            <div>
              <div>
                {/* <div>show here</div> */}

                {/*  */}
                {/* Table */}

                <div className="mb-4">
                  <Table
                    responsive
                    borderless
                    striped
                    className="product-table"
                  >
                    <thead>
                      <tr>
                        <th>S/N</th>
                        <th>Bar Code</th>
                        <th>Name</th>
                        <th>Estimated Qty</th>
                        <th>Actual Qty</th>
                        <th>Damages</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tableData?.length > 0 &&
                        tableData?.map((el, index) => (
                          <tr
                            key={index}
                            className={`${activeTableIndex === index &&
                              "active-row"}`}
                            onClick={() =>
                              activeTableIndex !== index &&
                              setActiveTableIndex(index)
                            }
                          >
                            <td>{index + 1}</td>
                            <td>{el.item}</td>
                            <td>{el.title}</td>
                            <td className="text-center">{el.quantity}</td>
                            <td>
                              <input
                                className="border-0 text-center w-50"
                                type="number"
                                value={el.actual}
                                onKeyDown={(e) => addToTable(e)}
                                name="actual"
                                onChange={(e) =>
                                  handleInputChange({
                                    index,
                                    name: e.target.name,
                                    value: e.target.value,
                                  })
                                }
                              />
                            </td>
                            <td>
                              <input
                                className="border-0 text-center w-50"
                                type="number"
                                value={el.damage}
                                onKeyDown={(e) => addToTable(e)}
                                name="damage"
                                onChange={(e) =>
                                  handleInputChange({
                                    index,
                                    name: e.target.name,
                                    value: e.target.value,
                                  })
                                }
                              />
                            </td>
                            {/* <td>{el?.UnitCost}</td>
                            <td className="d-flex align-items-center">
                              <span>
                                {currency(
                                  parseFloat(el?.UnitCost) *
                                    parseFloat(el?.Quantity),
                                  {
                                    symbol: "",
                                  }
                                ).format()}
                              </span>
                              <span
                                className="mx-3 p-cursor"
                                onClick={() => onDelete(el.Bar_Code)}
                              >
                                <DeleteIcon />
                              </span>
                            </td> */}
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </div>

                {/*  */}

                {errorMessage && (
                  <div className="d-flex justify-content-center">
                    <p className="text-danger">
                      <b>{errorMessage}</b>
                    </p>
                  </div>
                )}
                <div className="d-flex justify-content-end mt-3">
                  {/* <button
                    onClick={formik.handleSubmit}
                    className="btn btn-primary"
                    disabled={isLoadingAddToCart}
                  >
                    {isLoadingAddToCart ? "Checking..." : "Save Actual"}
                  </button> */}

                  <Button
                    onClick={() => proceed(false)}
                    variant="white"
                    className="border bg-white mx-4"
                  >
                    Cancel
                  </Button>
                  {!errorMessage && (
                    <Button
                      onClick={() => proceed(selectedItem)}
                      variant="primary"
                      autoFocus={true}
                    >
                      Save Actual
                    </Button>
                  )}

                  {/* {itemsRequested.length > 0 && (
                    <button
                      className="btn btn-primary mx-3"
                      onClick={() => setShowNewRequisitionModal(true)}
                    >
                      Request for items
                    </button>
                  )} */}
                </div>
              </div>
            </div>
            <ModalLoader show={isLoadingAddToCart} />

            {showNewRequisitionModal && (
              <NewRequisitionModal
                modalTitle="Send for Approval"
                defaultFormValues={{
                  title: ``,
                  description: "",
                  type: "Material Request",
                }}
                itemsRequested={itemsRequested}
                setShowNewRequisitionModal={setShowNewRequisitionModal}
                /* handleCreatedRequisition={handleCreatedRequisition} */
              />
            )}

            {/*  */}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default function ShowProjectedItemsDialog(props) {
  return createConfirmation(confirmable(ShowProjectedItemsModal))(props);
}
