import { useFormik } from "formik";
import { Col, Form, Row, Tab, Table } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import * as yup from "yup";
import Select from "react-select";
import { amountTypes, formatDate, waitFor } from "../../../utils/helpers";
import CurrencyCustomInput from "../../utils/CurrencyCustomInput";
import NoTableItem from "../../utils/NoTableItem";
import { useMemo, useState } from "react";
import currency from "currency.js";
import { addMonths, addQuarters, addYears, format } from "date-fns";
import DateTime from "react-datetime";
import { addDays } from "date-fns";
import useDebounce from "../../../utils/hooks";
import { cloneDeep, isEmpty } from "lodash";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "react-query";
import { useAuth } from "../../../hooks/useAuth";
import ConfirmDialog from "../../ConfirmDialogue";
import queryString from "query-string";
import { DeleteIcon, TrashIcon } from "../../Icons";
import ModalLoader from "../../utils/ModalLoader";
import CachedIcon from "mdi-react/CachedIcon";

const cycleOptions = [
  { label: "", value: "" },
  { label: "Daily", value: "Daily" },
  { label: "Monthly", value: "Monthly" },
  { label: "Quaterly", value: "Quaterly" },
  { label: "Annually", value: "Annually" },
];

export default function ProjectedDepreciationModal({
  showProjectedDepreciationModal,
  setShowProjectedDepreciationModal,
}) {
  const { backendUrl } = useAuth();

  const [queryParams, setQueryParams] = useState({
    Bar_Code: showProjectedDepreciationModal.Bar_Code,
    W_ID: showProjectedDepreciationModal.Warehouse,
    Item_Name: showProjectedDepreciationModal.Item_Name,
  });

  const debouncedqueryParams = useDebounce(queryParams, 800);

  const getBatches = async (debouncedqueryParams) => {
    let response = await fetch(
      `${backendUrl}/api/warehouse/asset-depreciation-projection?${queryString.stringify(
        debouncedqueryParams
      )}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const { data } = await response.json();
    return data;
  };

  const { data = { projections: [] }, refetch } = useQuery(
    ["PROJECTED_DEPRECIATION", debouncedqueryParams],
    () => getBatches(debouncedqueryParams),
    {}
  );

  const deleteItem = async (payload) => {
    //   await waitFor(4000);
    let response = await fetch(
      `${backendUrl}/api/warehouse/delete-depreciation-projection`,
      {
        method: "POST",
        credentials: "include",
        body: JSON.stringify(payload),
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
      }
    );
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const deleteMutation = useMutation((payload) => deleteItem(payload), {
    onSuccess: ({ message }) => {
      toast.success(message);
      refetch();
    },
    onError: ({ message = "" }) => {
      toast.error(`Unable to perform action: ${message}`);
    },
  });

  const deleteProjected = async (el) => {
    if (
      await ConfirmDialog({
        title: "Delete Projection",
        description: "Are you sure you want to delete?",
      })
    ) {
      deleteMutation.mutate(el);
    }
  };

  return (
    <>
      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        animation={false}
        enforceFocus={false}
        show={showProjectedDepreciationModal}
        onHide={() => {
          setShowProjectedDepreciationModal(false);
        }}
        scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter h6">
            Projected Depreciation{" "}
            <Button
              onClick={() => refetch()}
              variant=""
              className="text-primary"
            >
              <CachedIcon />{" "}
            </Button>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <p>
                <b>Item Code:</b> {showProjectedDepreciationModal?.Bar_Code}
              </p>
              <p>
                <b>Item Name:</b> {showProjectedDepreciationModal?.Item_Name}
              </p>
              <p>
                <b>Unit Cost:</b>{" "}
                {currency(showProjectedDepreciationModal.UnitCost, {
                  symbol: "",
                }).format()}
              </p>
            </Col>{" "}
            <Col>
              <p>
                <b>Cycle:</b> {data?.depreciation?.cycle}
              </p>
              <p>
                <b>Type:</b> {data?.depreciation?.type}
              </p>

              {data?.depreciation?.type === "Percentage" ? (
                <p>
                  <b>Percentage:</b> {data?.depreciation?.percentage}% -{" "}
                  {currency(data?.depreciation?.percentageValue, {
                    symbol: "",
                  }).format()}
                </p>
              ) : (
                <p>
                  <b>Fixed Amount:</b> {data?.depreciation?.fixedAmount}
                </p>
              )}

              <p>
                <b>Post Date:</b> {formatDate(data?.depreciation?.Post_Date)}
              </p>
            </Col>
          </Row>

          <hr />
          <Row>
            <Col>
              <p>
                <b>Original Purchase:</b>{" "}
                {showProjectedDepreciationModal?.originalUnitCost
                  ? currency(showProjectedDepreciationModal.originalUnitCost, {
                      symbol: "",
                    }).format()
                  : "..."}
              </p>

              <p>
                <b>Total Depreciated Value:</b>{" "}
                {currency(data.depreciatedValue, {
                  symbol: "",
                }).format()}
              </p>

              <p>
                <b>Current Value:</b>{" "}
                {currency(showProjectedDepreciationModal?.originalUnitCost, {
                  symbol: "",
                })
                  .subtract(data.depreciatedValue)
                  .format()}
              </p>
            </Col>
          </Row>
          <hr />

          <Table responsive borderless striped className="product-table">
            <thead>
              <tr>
                <th />
                <th>S/N</th>
                <th>Date</th>
              </tr>
            </thead>{" "}
            <tbody>
              {data?.projections.map((el, index) => (
                <tr
                  key={index}
                  style={
                    el.isDepreciated
                      ? { opacity: 0.5, pointerEvents: "none" }
                      : {}
                  }
                >
                  <td>
                    <Button
                      onClick={() => deleteProjected(el)}
                      variant=""
                      className="text-danger"
                    >
                      <DeleteIcon />
                    </Button>
                  </td>
                  <td>{index + 1}</td>
                  <td>{formatDate(el.projectionDate)}</td>{" "}
                  {/* <td>
                  {data?.depreciation?.type === "Percentage" ? (
                    <>
                      {currency(data?.depreciation?.percentageValue, {
                        symbol: "",
                      }).format()}
                    </>
                  ) : (
                    <>{data?.depreciation?.fixedAmount}</>
                  )}
                </td> */}
                </tr>
              ))}{" "}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant=""
            onClick={() => setShowProjectedDepreciationModal(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <ModalLoader show={deleteMutation.isLoading} />
    </>
  );
}
