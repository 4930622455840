import { Dropdown, Form, Button, Table } from "react-bootstrap";
import { useEffect, useState, useRef } from "react";
import { CSVLink } from "react-csv";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import { useSearchParams } from "react-router-dom";
import "./../../assets/scss/scoped/invoicelist.scoped.scss";
import useDebounce, { useEffectOnce, useQueryParams } from "../../utils/hooks";
import CachedIcon from "mdi-react/CachedIcon";
import NewEnquireModal from "../NewEnquireModal";
import { useQuery } from "react-query";
import queryString from "query-string";
import { format, parse } from "date-fns";
import {
  paginationOptions,
  searchParamsToObject,
  fetchActionsUtil,
} from "../../utils/helpers";
import { useAuth } from "../../hooks/useAuth";
import ReactPaginate from "react-paginate";
import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";
import RsDateRangePicker from "./../utils/RsDateRangePicker";
import NoTableItem from "./../utils/NoTableItem";
import NewItemModalWrapper from "../NewItemModalWrapper";
import MakeCutomerModal from "../modals/MakeCutomerModal";
import { ExcelIcon, PDFIcon, ExportIcon } from "../Icons";
import { useDownloadExcel } from "../../hooks/useDownloadExcel";

export default function ProspectiveCustomers() {
  const [searchParams] = useSearchParams();
  const { backendUrl } = useAuth();
  const [showCreateNewCustomerModal, setShowCreateNewCustomerModal] = useState(
    false
  );

  const [
    showCreateNewInventoryModal,
    setShowCreateNewInventoryModal,
  ] = useState(false);

  const [newItem, setNewItem] = useState(null);
  const [openMakeCutomer, setOpenMakeCutomer] = useState(false);
  const [cusID, setCusID] = useState("");

  const initialFilterParams = { q: "", page: 1, limit: 40 };

  const [queryParams, setQueryParams] = useQueryParams({
    ...initialFilterParams,
  });
  const [filterParams, setFilterParams] = useState({
    ...initialFilterParams,
    ...queryParams,
  });

  const [excelData, setExcelData] = useState([]);

  const CSVLinkRef = useRef(null);

  // fetch excel hook
  const [isfetchingExcel, fetchExcelData] = useDownloadExcel(
    excelData,
    CSVLinkRef
  );

  const debouncedFilterParams = useDebounce(filterParams, 500);

  useEffect(() => {
    setQueryParams({ ...queryParams, ...debouncedFilterParams });
  }, [debouncedFilterParams]);

  // action form url query on render once
  useEffectOnce(() => {
    const queryParams = searchParamsToObject(searchParams.entries());
    if (queryParams?.action === "create") {
      setShowCreateNewCustomerModal(true);
      // delete queryParams.action;
      setQueryParams({ ...initialFilterParams });
    }
  });

  const handleSearchQueryChange = (e) => {
    setFilterParams({
      ...filterParams,
      [e.target.name]: e.target.value,
    });
  };

  const clearDateRange = () => {
    setQueryParams({
      ...queryParams,
      startDate: "",
      endDate: "",
    });
  };

  const filterByDateRange = (date) => {
    setQueryParams({
      ...queryParams,
      startDate: format(date[0], "yyyy-MM-dd"),
      endDate: format(date[1], "yyyy-MM-dd"),
    });
  };

  const { data = { product: [] }, refetch } = useQuery(
    ["GET_ALL_PRODUCT_ENQUIRESRS", queryParams],
    () =>
      fetchActionsUtil(
        `${backendUrl}/api/customers/get-product-of-interest?${queryString.stringify(
          queryParams
        )}`,
        "GET"
      ),
    {
      enabled: true,
      keepPreviousData: true,
    }
  );

  // download excel function
  const { limit, page, ...rest } = queryParams;
  const onDownloadExcelData = async () => {
    let exData = await fetchExcelData(
      `${backendUrl}/api/customers/get-product-of-interest?${queryString.stringify(
        rest
      )}`,
      "GET"
    );

    const company = exData.company?.CompName;

    const recievables = exData.product.map((el, i) => [
      i + 1,
      el.Cust_Product_Interest.LastName,
      el.Cust_Product_Interest.PhoneNo1,
      el.Item,
      el.Staff_Product_Interest.Name,
      format(new Date(el.date), "E MMM d yyyy hh:mm:ss a"),
    ]);

    const date =
      rest.startDate && rest.endDate
        ? `Date Prepared: Between ${format(
            new Date(rest.startDate),
            "E MMM d yyyy hh:mm:ss a"
          )} to ${format(new Date(rest.endDate), "E MMM d yyyy k:mm:ss z")}`
        : "";

    exData = [
      [company],
      ["Enquire Report"],
      [date],
      [""],
      ["S/N", "Customer Name", "Customer Phone", "Item", "Staff", "Date"],
      ...recievables,
      [""],
    ];
    console.log(exData);
    setExcelData(exData);
  };

  return (
    <main className="bg-white">
      <header className="p-4">
        <div className="search-area d-flex flex-wrap justify-content-between gap-3 w-100">
          <div className="d-flex flex-wrap gap-3">
            <div className="d-flex flex-wrap gap-3">
              <div className="global-search-area">
                <MagnifyIcon />
                <Form.Control
                  id="queryParams-q"
                  className=""
                  name="q"
                  value={filterParams.q}
                  onChange={(e) => handleSearchQueryChange(e)}
                  placeholder="Search..."
                />
              </div>
              <Button
                onClick={() => refetch()}
                variant=""
                className="bg-light-blue border-0 text-primary"
              >
                <CachedIcon />
              </Button>
            </div>
          </div>

          <div
            className="d-flex flex-wrap gap-3"
            style={{ fontSize: "0.875rem" }}
          >
            <CSVLink
              className="btn print d-none"
              filename={`Enquirer Report(${format(
                new Date(),
                "dd-MMM-yyyy hh:mm:ss a"
              )}).csv`}
              data={excelData}
              ref={CSVLinkRef}
            />
            <Button
              variant="primary"
              onClick={() => setShowCreateNewCustomerModal(true)}
            >
              + Create New
            </Button>
            <Dropdown>
              <Dropdown.Toggle
                variant=""
                className="btn print"
                disabled={isfetchingExcel}
                bsPrefix=""
              >
                Export
                <ExportIcon color="#008000" />
              </Dropdown.Toggle>
              <Dropdown.Menu
                popperConfig={{
                  strategy: "fixed",
                }}
                renderOnMount
                className="text-center"
              >
                <Dropdown.Item
                  as="div"
                  onClick={onDownloadExcelData}
                  className="p-cursor"
                >
                  Excel <ExcelIcon color="#008000" />
                </Dropdown.Item>
                <Dropdown.Item as="div">
                  <a
                    href={`${backendUrl}/api/customers/pdf/get-product-of-interest?${queryString.stringify(
                      rest
                    )}`}
                    target="blank"
                  >
                    PDF <PDFIcon color="#ff0000" />
                  </a>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <RsDateRangePicker
              placement="topEnd"
              defaultValue={
                queryParams.startDate && queryParams.endDate
                  ? [
                      parse(queryParams.startDate, "yyyy-MM-dd", new Date()),
                      parse(queryParams.endDate, "yyyy-MM-dd", new Date()),
                    ]
                  : []
              }
              onClean={() => clearDateRange()}
              onOk={(date) => filterByDateRange(date)}
            />
          </div>
        </div>
      </header>

      <div className="content px-4 pb-4">
        <Table borderless striped responsive className="product-table">
          <thead className="sticky border-bottom">
            <tr>
              <th />

              <th>Customer Name</th>
              <th>Custome Phone</th>
              <th>Product Of Interest</th>
              <th>Staff</th>
              <th>Date</th>
            </tr>
          </thead>

          <tbody className="blue-hover">
            {data.product &&
              data.product.map((el, index) => (
                <tr
                  key={index}
                  style={
                    el.Cust_Product_Interest.TransType === "Enquirer"
                      ? {}
                      : { pointerEvents: "none", opacity: 0.5 }
                  }
                >
                  <td>
                    <Dropdown>
                      <Dropdown.Toggle
                        variant=""
                        className="bg-white border-0"
                        bsPrefix="print more"
                      >
                        <DotsVerticalIcon />
                      </Dropdown.Toggle>
                      <Dropdown.Menu
                        popperConfig={{
                          strategy: "fixed",
                        }}
                        renderOnMount
                        className="dropdown-with-icons"
                      >
                        {el.Cust_Product_Interest.TransType === "Enquirer" && (
                          <Dropdown.Item
                            onClick={() => {
                              setCusID(el.Cust_Product_Interest.Cust_ID);
                              setOpenMakeCutomer(true);
                            }}
                            as="button"
                          >
                            Make Customer
                          </Dropdown.Item>
                        )}
                        <Dropdown.Item
                          onClick={() => {
                            setNewItem({
                              Item_Name: el.Item,
                              Bar_Code: String(Date.now()),
                            });
                            setShowCreateNewInventoryModal(true);
                          }}
                          as="button"
                        >
                          Purchase Item
                        </Dropdown.Item>
                      </Dropdown.Menu>{" "}
                    </Dropdown>
                  </td>

                  <td>{el.Cust_Product_Interest.LastName}</td>
                  <td>{el.Cust_Product_Interest.PhoneNo1}</td>
                  <td>{el.Item}</td>
                  <td>{el.Staff_Product_Interest.Name}</td>
                  <td>
                    {format(new Date(el.date), "E MMM d yyyy hh:mm:ss a")}
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
        {data.product.length < 1 ? (
          <div className="d-flex justify-content-center text-center w-100 my-4">
            <NoTableItem queryParams={queryParams} />
          </div>
        ) : null}
      </div>

      <div className="d-flex justify-content-between px-4 align-items-center pagination pb-4 ">
        {/*<p className="m-0">
                Showing {data?.startIndex + 1} to{" "}
                {data?.endIndex <= data?.count ? data?.endIndex : data?.count}{" "}
                of {data.count} entries
                  </p> */}
        <div className="pagination_left d-flex gap-3 align-items-center">
          <p className="m-0 p-0">Show</p>
          <select
            value={queryParams.limit}
            name="limit"
            className="form-select "
            onChange={(e) => handleSearchQueryChange(e)}
          >
            <option value="10">10 rows</option>
            <option value="20">20 rows</option>
            <option value="30">30 rows</option>
            <option value="40">40 rows</option>
          </select>
        </div>

        <ReactPaginate
          {...paginationOptions}
          pageCount={Math.ceil(data.count / queryParams.limit)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={0}
          onPageChange={({ selected }) => {
            document.body.scrollTop = document.documentElement.scrollTop = 0;
            setQueryParams({
              ...queryParams,
              page: selected + 1,
            });
          }}
          forcePage={queryParams.page - 1}
        />
      </div>

      {showCreateNewCustomerModal && (
        <NewEnquireModal
          showCreateNewCustomerModal={showCreateNewCustomerModal}
          setShowCreateNewCustomerModal={setShowCreateNewCustomerModal}
          mainRefetch={refetch}
        />
      )}

      <NewItemModalWrapper
        setShowCreateNewInventoryModal={setShowCreateNewInventoryModal}
        showCreateNewInventoryModal={showCreateNewInventoryModal}
        thenewItem={newItem}
      />
      <MakeCutomerModal
        openMakeCutomer={openMakeCutomer}
        setOpenMakeCutomer={setOpenMakeCutomer}
        Cust_ID={cusID}
        mainRefetch={refetch}
      />
    </main>
  );
}
