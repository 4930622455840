import React, {
  useEffect,
  useCallback,
  useLayoutEffect,
  useState,
} from "react";
import eventBus from "../../../utils/EventBus";
import { useAsyncReference } from "../../../utils/hooks";

const Editor = React.forwardRef(
  (
    {
      setShowUsersList,
      resizeInput,
      pickFiles,
      setInputActive,
      inputActive,
      commentRef,
    },
    _ref
  ) => {
    const [text, setText] = useAsyncReference("", false);

    const _setText = useCallback(
      ({ newText }) => {
        console.log("set text");
        setText(newText);
      },
      [setText]
    );

    const [scrollValue, setScrollValue] = useState(0);

    useLayoutEffect(() => {
      const onScroll = (e) => {
        setScrollValue(e.target.scrollTop);
      };

      document.querySelector(".modal").addEventListener("scroll", onScroll);

      return () =>
        document
          .querySelector(".modal")
          .removeEventListener("scroll", onScroll);
    }, [scrollValue]);

    eventBus.useCustomEventListener("setText", _setText);

    const handleOnchange = (e) => {
      resizeInput(e);
      const text = e.target.value;
      const currentSelection = e.target.selectionStart;
      let currentText = text != "" ? text.substring(0, currentSelection) : text;
      const lastWord = currentText
        .replaceAll(/\r?\n|\r/g, " ")
        .split(" ")
        .splice(-1)[0];

      /*const secondToLastWord = currentText
        .replaceAll(/\r?\n|\r/g, " ")
        .split(" ")
        .splice(-2)[0];*/

      if (lastWord.startsWith("@") /*|| secondToLastWord.startsWith("@")*/) {
        setShowUsersList(true);
      } else {
        setShowUsersList(false);
      }
      setText(e.target.value);
      document.querySelector(".modal").scrollTop = scrollValue;
    };

    /*const onKeyDown = (e) => {
      let key = event.keyCode || event.charCode;

      if (key == 8 || key == 46) return false;
    };*/
    return (
      <>
        <textarea
          row="1"
          cols="1"
          placeholder="Write your message..."
          onPaste={(e) => {
            if (e.clipboardData?.files) pickFiles(e.clipboardData.files);
          }}
          onChange={(e) => {
            handleOnchange(e);
          }}
          onFocus={(e) => {
            if (!inputActive) setInputActive(true);
          }}
          value={text}
          ref={commentRef}
          spellCheck={false}
        />
      </>
    );
  }
);

export default Editor;
