import { useFormik } from "formik";
import CachedIcon from "mdi-react/CachedIcon";
import React from "react";
import { Modal, Form, Button, Table } from "react-bootstrap";
import { useMutation, useQuery } from "react-query";
import Select from "react-select";
import { toast } from "react-toastify";
import * as yup from "yup";
import { useBackendUrl } from "../../utils/hooks";
import ConfirmDialog from "../ConfirmDialogue";
import { DeleteIcon } from "../Icons";

export default function CreateRegion({
  showCreateRegionModal,
  setShowCreateRegionModal,
}) {
  const backendUrl = useBackendUrl();

  const fetchSetUpData = async () => {
    // await waitFor(5000);
    let response = await fetch(`${backendUrl}/api/incentive/set-up`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const { data } = await response.json();
    return data;
  };

  const {
    data = {
      category: [],
      subcategory: [],
      region: [],
      depot: [],
      plant: [],
      subCategoriesWithParent: [],
      plantAndDepot: [],
    },
    refetch,
  } = useQuery(["INCENTIVE_SETUP"], () => fetchSetUpData(), {
    keepPreviousData: true,
  });

  const createRegion = async (payload) => {
    let response = await fetch(`${backendUrl}/api/atc/create-region`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const createRegionMutation = useMutation((payload) => createRegion(payload), {
    onSuccess: ({ message, data }) => {
      toast.success(message);
      formik.resetForm();
      refetch();
    },
    onError: () => {
      toast.error(`Unable to perform action`);
    },
  });

  const formik = useFormik({
    initialValues: {
      region: "",
    },
    validationSchema: yup.object().shape({
      region: yup.string().required(),
    }),
    onSubmit: async (values) => {
      if (
        await ConfirmDialog({
          title: "Create Region",
          desc: `description: "Are you sure, you want create ${values.region}`,
        })
      ) {
        createRegionMutation.mutate(values);
      }
    },
  });

  const deleteRegion = async (payload) => {
    let response = await fetch(`${backendUrl}/api/atc/delete-region`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };

  const deleteRegionMutation = useMutation((payload) => deleteRegion(payload), {
    onSuccess: ({ message, data }) => {
      toast.success(message);
      formik.resetForm();
      refetch();
    },
    onError: () => {
      toast.error(`Unable to perform action`);
    },
  });

  const handleDeleteRegion = async (el) => {
    if (
      await ConfirmDialog({
        title: "Delete Region",
        description: `Are you sure, delete ${el.region}`,
      })
    ) {
      deleteRegionMutation.mutate(el);
    }
  };

  return (
    <Modal
      show={showCreateRegionModal}
      onHide={() => setShowCreateRegionModal(false)}
      size="md"
      centered
      animation={false}
    >
      <Modal.Header closeButton>
        <Modal.Title className="h5">
          Create Region{" "}
          <Button variant="" className="text-primary" onClick={() => refetch()}>
            {" "}
            <CachedIcon />{" "}
          </Button>{" "}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate onSubmit={formik.handleSubmit} autoComplete="off">
          <Form.Group className="mb-3 pb-2">
            <Form.Label className="mb-1">Name of Region</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Name of Region"
              name="region"
              value={formik.values.region}
              onChange={formik.handleChange}
              isInvalid={formik.touched.region && !!formik.errors.region}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.region}
            </Form.Control.Feedback>
          </Form.Group>

          <div className="d-flex gap-3 justify-content-end">
            {" "}
            <Button type="submit">Create</Button>
            <Button type="button" variant="outline-primary">
              Refresh
            </Button>
          </div>
        </Form>

        <Table borderless striped className="product-table mt-4 border">
          <thead>
            <tr>
              <th />
              <th>Region</th>
            </tr>
          </thead>
          <tbody>
            {data.region.map((el, index) => (
              <tr key={index}>
                <td style={{ whiteSpace: "nowrap", width: "1%" }}>
                  {" "}
                  <Button variant="" onClick={() => handleDeleteRegion(el)}>
                    {" "}
                    <DeleteIcon />{" "}
                  </Button>{" "}
                </td>
                <td>{el.region}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Modal.Body>
    </Modal>
  );
}
