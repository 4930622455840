import currency from "currency.js";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import { useQuery } from "react-query";
// import { services } from "../../config";
import { useAuth } from "../../hooks/useAuth";
import useDebounce from "../../utils/hooks";
import { queryActions } from "../../utils/reactQueryActions";
import "./../../assets/scss/scoped/items-table.scoped.scss";
import queryString from "query-string";
import ReactPaginate from "react-paginate";
import { paginationOptions, fetchActionsUtil } from "../../utils/helpers";
import NoTableItem from "../utils/NoTableItem";

export default function GetTemplateModal({
  handleSelectedItem,
  hideItemsNotInStock = false,
  productPlan,
  plant,
}) {
  const { backendUrl } = useAuth();
  const [queryParams, setQueryParams] = useState({
    page: 1,
    limit: 50,
    q: "",
    plant,
  });

  const debouncedqueryParams = useDebounce(queryParams, 500);

  //   const wareshouseLocal = JSON.parse(
  //     window.localStorage.getItem("warehouseName")
  //   );

  const { data, isLoading } = useQuery(
    ["GET_BILL_OF_QUANTITY", { debouncedqueryParams }],
    () =>
      productPlan
        ? fetchActionsUtil(
            `${backendUrl}/api/production/all-product-plan?&${queryString.stringify(
              queryParams
            )}`,
            "GET"
          )
        : fetchActionsUtil(
            `${backendUrl}/api/production/bill-of-quantity?&${queryString.stringify(
              queryParams
            )}`,
            "GET"
          ),
    {
      enabled: true,
      keepPreviousData: true,
    }
  );

  //   console.log(data);

  return (
    <div className="items-table-area rounded">
      <div className="search-area m-3">
        <MagnifyIcon />
        <input
          className="form-control search-input"
          name="q"
          value={queryParams.q}
          onChange={(e) =>
            setQueryParams({
              ...queryParams,
              page: 1,
              [e.target.name]: e.target.value,
            })
          }
          placeholder="Search item..."
          autoFocus
          autoComplete="off"
          type={"text"}
        />
      </div>

      <div className="content">
        <Table borderless hover striped className="product-table">
          <thead className="sticky text-nowrap">
            {productPlan ? (
              <tr>
                <th scope="col">Plan Name</th>
                <th scope="col">Plan Id</th>
                <th scope="col">Unit Cost</th>
                <th scope="col">Unit Price</th>
              </tr>
            ) : (
              <tr>
                <th scope="col">Item Code</th>
                <th scope="col">Item Name</th>
                <th scope="col">Unit Cost</th>
                <th scope="col">Unit Price</th>
              </tr>
            )}
          </thead>
          <tbody>
            {data?.billOfQuantities?.length > 0 &&
              data?.billOfQuantities

                ?.filter((el) =>
                  hideItemsNotInStock ? Number(el.Quantity) > 0 : el
                )
                ?.map((el, index) => (
                  <>
                    {productPlan ? (
                      <tr
                        className="p-cursor"
                        key={index}
                        onClick={() =>
                          handleSelectedItem && handleSelectedItem(el)
                        }
                      >
                        <td>{el.Plan_Name}</td>
                        <td title={el?.Quantity}>{el.Plan_Id}</td>
                        <td>
                          {currency(el.UnitCost, {
                            precision: 2,
                            symbol: "",
                          }).format()}
                        </td>
                        <td>
                          {currency(el.UnitPrice, {
                            precision: 2,
                            symbol: "",
                          }).format()}
                        </td>
                      </tr>
                    ) : (
                      <tr
                        className="p-cursor"
                        key={index}
                        onClick={() =>
                          handleSelectedItem && handleSelectedItem(el)
                        }
                      >
                        <td>{el.Bar_Code}</td>
                        <td title={el?.Quantity}>{el.Item_Name}</td>
                        <td>
                          {currency(el.UnitCost, {
                            precision: 2,
                            symbol: "",
                          }).format()}
                        </td>
                        <td>
                          {currency(el.UnitPrice, {
                            precision: 2,
                            symbol: "",
                          }).format()}
                        </td>
                      </tr>
                    )}
                  </>
                ))}
          </tbody>
        </Table>
        {data?.billOfQuantities?.length < 1 && !isLoading && (
          <div className="d-flex justify-content-center text-center w-100 my-4">
            <NoTableItem queryParams={queryParams} />
          </div>
        )}
      </div>

      <div className="d-flex justify-content-between px-3 my-3 align-items-center pagination">
        <p className="m-0">
          Showing {data?.startIndex + 1} to{" "}
          {data?.endIndex <= data?.count ? data?.endIndex : data?.count} of{" "}
          {data?.count} entries
        </p>

        <ReactPaginate
          {...paginationOptions}
          pageCount={Math.ceil(data?.count / queryParams.limit)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={0}
          onPageChange={({ selected }) => {
            document.body.scrollTop = document.documentElement.scrollTop = 0;
            setQueryParams({
              ...queryParams,
              page: selected + 1,
            });
          }}
          forcePage={queryParams.page - 1}
        />
      </div>
    </div>
  );
}
