import { Fragment, useState } from "react";
import { Modal, Button, Table, Form } from "react-bootstrap";
import { useAuth } from "../../hooks/useAuth";
import { employeeFullName, styleHelper } from "../../utils/helpers";
import { useQuery, useMutation } from "react-query";
import CurrencyInput from "react-currency-input-field";
import Datetime from "react-datetime";
import currency from "currency.js";
import { useMemo } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import moment from "moment";
import { useEffect } from "react";
import useDebounce from "../../utils/hooks";
import { toast } from "react-toastify";
import { toNumber } from "lodash";

function PayRow({ employee, index, editPay }) {
  const formik = useFormik({
    initialValues: {
      Bonuses: 0,
      Deductions: 0,
      Remark: "",
    },
    validationSchema: yup.object().shape({}),
    onSubmit: (values) => {},
    onReset: () => {},
  });

  const netSalary = useMemo(() => {
    return currency(employee.TotalWages, {
      symbol: "",
      separator: "",
    })
      .subtract(employee.SalaryAdvanceCollected)
      .add(formik.values.Bonuses)
      .subtract(formik.values.Deductions)
      .format();
  }, [formik.values, employee.SalaryAdvanceCollected, employee.TotalWages]);

  const formValues = useDebounce(formik.values, 500);

  useEffect(() => {
    editPay(index, { ...formValues, NetSalary: netSalary });
  }, [formValues, netSalary]);

  return (
    <tr>
      <td>{employee.EmployeeID}</td>
      <td>{employeeFullName(employee)}</td>
      <td>
        {currency(employee.TotalWages, {
          symbol: "",
        }).format()}
      </td>
      <td>
        {currency(employee.SalaryAdvanceCollected, {
          symbol: "",
        }).format()}
      </td>
      <td>
        <CurrencyInput
          className="form-control"
          name="Bonuses"
          placeholder="0.00"
          value={formik.values.Bonuses}
          onValueChange={(value, name) => {
            formik.setFieldValue(name, value);
          }}
          decimalScale={2}
        />
      </td>

      <td>
        <CurrencyInput
          className="form-control"
          name="Deductions"
          placeholder="0.00"
          value={formik.values.Deductions}
          onValueChange={(value, name) => {
            formik.setFieldValue(name, value);
          }}
          decimalScale={2}
        />
      </td>
      <td>
        <Form.Control
          name="Remark"
          value={formik.values.Remark}
          onChange={formik.handleChange}
        />
      </td>
      <td>
        {currency(netSalary, {
          symbol: "",
        }).format()}
      </td>
    </tr>
  );
}

export default function GenerateDriverSalaryModal({
  setShowGenerateSalaryModal,
  handleCreatedSalary,
}) {
  const { backendUrl, user: authUser } = useAuth();
  const [tableData, setTableData] = useState([]);
  const [date, setDate] = useState(new Date());

  const fetchSetupData = async (date) => {
    // await waitFor(5000);
    let response = await fetch(`${backendUrl}/api/driver/set-up?date=${date}`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    return data;
  };

  const { data = { drivers: [] }, refetch } = useQuery(
    ["DRIVER_SET_UP", date],
    () => fetchSetupData(date),
    {
      keepPreviousData: true,
      onSuccess: ({ drivers }) => {
        setTimeout(() => {
          setTableData([...drivers]);
        }, 50);
      },
    }
  );

  const create = async (payload) => {
    let response = await fetch(`${backendUrl}/api/driver/save-salary`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const createMutation = useMutation((payload) => create(payload), {
    onSuccess: ({ message }) => {
      toast.success(message);
      handleCreatedSalary(date);
    },
    onError: ({ message = "" }) => {
      toast.error(`Unable to perform action: ${message}`);
    },
  });

  const editPay = (index, formValues) => {
    tableData[index] = {
      ...tableData[index],
      ...formValues,
    };
    setTableData([...tableData]);
  };

  const save = () => {
    const driversSalary = tableData.map((el) => ({
      ...el,
      RegDate: date,
      PreparedBy: authUser.Name,
      Name: employeeFullName(el),
    }));
    createMutation.mutate({ driversSalary, date });
  };

  const totalNetSalary = useMemo(() => {
    // console.log(tableData);
    return tableData
      .map((el) => el.NetSalary)
      .filter((el) => Number(el) > 0)
      .reduce(
        (a, b) =>
          currency(a, {
            symbol: "",
            precision: 2,
          }).add(b),
        0
      );
  }, [tableData]);

  return (
    <Modal
      show={true}
      onHide={() => setShowGenerateSalaryModal(false)}
      dialogClassName="modal-90w mx-auto"
      backdropClassName={`global-backdrop`}
      centered={true}
      animation={false}
      enforceFocus={false}
      fullscreen={styleHelper.isMobile}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h1 className="h5">Generate Payslip</h1>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mb-3 col-md-2">
          <Datetime
            closeOnSelect={true}
            dateFormat="MMMM, YYYY"
            timeFormat={false}
            inputProps={{
              className: `date-input form-control`,
              placeholder: "Select date",
              readOnly: true,
            }}
            value={date}
            onChange={(date) => {
              setDate(date);
            }}
          />
        </div>
        <Table
          responsive
          borderless
          hover
          striped
          className="product-table text-nowrap"
        >
          <thead>
            <th>Employee ID</th>
            <th>Name</th>
            <th>Total Wages</th>
            <th>Salary Advance Collected</th>
            <th>Bonuses</th> <th>Deductions</th> <th>Remark</th>{" "}
            <th>Net Salary</th>
          </thead>
          <tbody>
            {tableData.map((el, index) => (
              <Fragment key={index}>
                <PayRow employee={el} index={index} editPay={editPay} />
              </Fragment>
            ))}
          </tbody>
        </Table>

        <div className="col-4 d-flex text-nowrap align-items-center gap-3">
          <p>Total Net Salary:</p>{" "}
          <CurrencyInput
            className="form-control"
            value={totalNetSalary}
            readOnly
          />
        </div>
      </Modal.Body>
      <Modal.Footer className="gap-3">
        <Button
          disabled={createMutation.isLoading}
          variant="outline-primary"
          className="px-4 py-2"
        >
          Cancel
        </Button>

        <Button
          disabled={createMutation.isLoading}
          onClick={() => save()}
          className="px-4 py-2"
        >
          {createMutation.isLoading ? "Please wait..." : "Save"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
