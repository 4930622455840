import { useMemo, useState } from "react";
import { NavLink } from "react-router-dom";
import { useResolveRootRoles } from "../../utils/hooks";

export function InventoryOnTransitNavs(props) {
  const { getRootRoles } = useResolveRootRoles();

  const items = useMemo(() => {
    const navItems = [
      {
        name: "Inventory on Transit",
        to: "inventory-on-transit",
      },

      {
        name: "PO On Transit",
        to: "items-expected-report",
      },
      {
        name: "Internal Transfer",
        to: "internal-stock-received",
      },
    ];

    return getRootRoles({
      parent: "Inventory on Transit",
      navItems,
    });
  }, [getRootRoles]);
  // const [items] = useState([
  //   {
  //     name: "Inventory On Transit",
  //     to: "transit-receive-inventory",
  //   },

  //   {
  //     name: "PO On Transit",
  //     to: "items-expected-report",
  //   },
  //   {
  //     name: "Internal Transfer",
  //     to: "internal-stock-received",
  //   },
  // ]);

  return (
    <div className="dashboard-tabs innerpage-tabs px-4">
      <ul className="nav nav-tabs">
        {items.map((item, index) => (
          <li key={index} className="nav-item">
            <NavLink className={`nav-link ga-nav-tab`} to={item.to}>
              {item.name}
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  );
}
