import { Modal, Form, Button } from "react-bootstrap";
import * as yup from "yup";
import { useFormik } from "formik";
import { useState, useEffect } from "react";
import CurrencyCustomInput from "./utils/CurrencyCustomInput";
import { toast } from "react-toastify";
import { useMutation } from "react-query";
import moment from "moment";
import Select from "react-select";
import ModalLoader from "./utils/ModalLoader";
import { useAuth } from "../hooks/useAuth";
import { fetchActionsUtil } from "../utils/helpers";
import ConfirmDialog from "./ConfirmDialogue";

export default function CreateShippingCostModal({
  createModal,
  setCreateModal,
  refetch,
  allStates,
  selected,
}) {
  const [cities, setcities] = useState([]);
  const [fetchingCities, setFetchingCities] = useState(false);

  const { backendUrl } = useAuth();

  const initialValues = {
    state: "",
    LGA: "",
    cost: 0,
    Date: moment(),
  };
  const schema = yup.object().shape({
    state: yup.string().required("required"),
    cost: yup.number().required("required"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: async (values) => {
      // console.log(values);
      handleShippingCost({ ...values });
    },
  });

  const saveShippingCostMutation = useMutation(
    (payload) =>
      fetchActionsUtil(
        `${backendUrl}/api/customers/save-shopping-cost`,
        "POST",
        "",
        payload
      ),
    {
      onSuccess: ({ message }) => {
        formik.resetForm();
        refetch();
        toast.success(message);
        setCreateModal(false);
      },
      onError: ({ message }) => {
        toast.error(message);
      },
    }
  );

  const editShippingCostMutation = useMutation(
    (payload) =>
      fetchActionsUtil(
        `${backendUrl}/api/customers/edit-shopping-cost`,
        "POST",
        "",
        payload
      ),
    {
      onSuccess: ({ message }) => {
        formik.resetForm();
        refetch();
        toast.success(message);
        setCreateModal(false);
      },
      onError: ({ message }) => {
        toast.error(message);
      },
    }
  );

  const handleShippingCost = async (item) => {
    if (
      await ConfirmDialog({
        title: `${selected ? "Edit" : "Save"} Shipping Cost`,
        description: `Are you sure you want to ${
          selected ? "Edit" : "Save"
        } this Shipping Cost`,
      })
    ) {
      selected
        ? editShippingCostMutation.mutate(item)
        : saveShippingCostMutation.mutate(item);
    }
  };

  const fetchLGA = async (state) => {
    setFetchingCities(true);
    const { lga } = await fetchActionsUtil(
      `${backendUrl}/api/customers/nigeria-lga/${state}`
    );
    setcities([
      { label: "Select City", value: "" },
      ...lga?.map((el) => ({ label: el, value: el })),
    ]);
    setFetchingCities(false);
  };

  useEffect(() => {
    if (selected) {
      fetchLGA(selected?.state);
      formik.setValues({
        state: selected?.state,
        cost: selected?.cost,
        LGA: selected?.LGA,
      });
    }
  }, [selected]);

  return (
    <>
      <Modal
        show={createModal}
        onHide={() => {
          setCreateModal(false);
        }}
        centered={true}
        animation={false}
        enforceFocus={false}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h1 className="h6">{selected ? "Edit" : "Add"} Shipping Cost</h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate onSubmit={formik.handleSubmit}>
            <Form.Group className="col mb-3 pb-1">
              <Form.Label>States</Form.Label>

              <Select
                classNamePrefix={`form-select`}
                options={allStates}
                value={allStates.find((el) => el.value === formik.values.state)}
                onChange={({ value }) => {
                  fetchLGA(value);
                  formik.setFieldValue("state", value);
                  formik.setFieldValue("LGA", "");
                }}
                onBlur={() => formik.setFieldTouched("state", true)}
                disabled={selected}
                isDisabled={selected}
              />
              {formik.touched.state && !!formik.errors.state ? (
                <span className="custom-invalid-feedback">
                  {formik.errors.state}
                </span>
              ) : null}
            </Form.Group>
            <Form.Group className="col mb-3 pb-1">
              <Form.Label>City</Form.Label>
              <Select
                isLoading={fetchingCities}
                classNamePrefix={`form-select`}
                options={cities}
                value={cities?.find((el) => el.value === formik.values.LGA)}
                onChange={({ value }) => formik.setFieldValue("LGA", value)}
                disabled={selected}
                isDisabled={selected}
              />
            </Form.Group>

            <Form.Group className="col-8 mb-3 pb-1">
              <Form.Label>Cost</Form.Label>
              <CurrencyCustomInput
                name="cost"
                value={formik.values.cost}
                onValueChange={(value, name) => {
                  formik.setFieldValue(name, value);
                }}
              />
              {formik.touched.cost && !!formik.errors.cost ? (
                <span className="custom-invalid-feedback">
                  {formik.errors.cost}
                </span>
              ) : null}
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer className="gap-3">
          <Button
            disabled={
              saveShippingCostMutation.isLoading ||
              editShippingCostMutation.isLoading
            }
            variant="primary"
            type="submit"
            onClick={() => {
              formik.submitForm();
            }}
          >
            {selected ? "Edit" : "Create"}
          </Button>
        </Modal.Footer>
      </Modal>
      <ModalLoader
        show={
          saveShippingCostMutation.isLoading ||
          editShippingCostMutation.isLoading
        }
      />
    </>
  );
}
