import { Table, Form, Button, InputGroup } from "react-bootstrap";
import {
  FilterCollapseIcon,
  FilterTwoIcon,
  PageHeaderListIcon,
} from "../Icons";
import "./../../assets/scss/reports/cashbook.scss";
import { useEffect, useState, useRef } from "react";
import CachedIcon from "mdi-react/CachedIcon";
import useDebounce, {
  useLocalStorage,
  useQueryParams,
} from "../../utils/hooks";
// import { reportActions } from "../../utils/reactQueryActions";
import { useMutation, useQuery } from "react-query";
import ReactPaginate from "react-paginate";
import queryString from "query-string";
import Datetime from "react-datetime";

import PageHeader from "../PageHeader";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import { Outlet } from "react-router-dom";
import { toast } from "react-toastify";
import { useAuth } from "../../hooks/useAuth";
import { fetchActionsUtil } from "../../utils/helpers";
import RejectModal from "../modals/RejectModal";
import { paginationOptions } from "../../utils/helpers";
import ConfirmDialog from "../ConfirmDialogue";
// import { backendApis } from "../../config";
import ModalLoader from "../utils/ModalLoader";
import ReceiveWarehouseTable from "./ReceiveWarehouseTable";
import { IsPrivileged } from "../DisplayChildElement";
import { backendApis } from "../../config";
import { format, parse } from "date-fns";
import RsDateRangePicker from "../utils/RsDateRangePicker";

export default function ReceiveItemWarehouse() {
  const [showFilter, setShowFilter] = useLocalStorage("showFilter", false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [allItems, setAllItems] = useState([]);
  const [openReject, setOpenReject] = useState(false);
  const [selectedAll, setSelectedAll] = useState(true);
  const [unRejectedItems, setUnRejectedItems] = useState([]);
  const [date, setDate] = useState(new Date());

  const { backendUrl } = useAuth();
  const initialFilterParams = {
    q: "",
    startDate: "",
    endDate: "",
    enableDateRange: true,
  };

  const refreshButton = useRef(null);

  const [queryParams, setQueryParams] = useQueryParams({
    page: 1,
    limit: 40,
    ...initialFilterParams,
  });
  const [filterParams, setFilterParams] = useState({
    ...initialFilterParams,
    ...queryParams,
  });
  const debouncedFilterParams = useDebounce(filterParams, 500);

  useEffect(() => {
    setQueryParams((p) => ({ ...p, ...debouncedFilterParams }));
  }, [debouncedFilterParams, setQueryParams]);

  const wareshouseLocal = JSON.parse(
    window.localStorage.getItem("warehouseName")
  );

  const { data, refetch } = useQuery(
    ["WAREHOUSE_RECEIVE_ITEMS", { queryParams, wareshouseLocal }],
    () =>
      fetchActionsUtil(
        `${backendUrl}/api/warehouse/pending/${
          wareshouseLocal?.whID
        }?&${queryString.stringify(queryParams)}`,
        "GET"
      ),
    {
      keepPreviousData: false,
      staleTime: 0,
    }
  );

  useEffect(() => {
    let _data = data?.data.filter((p) => p.Status !== "Rejected");
    _data = _data?.map((d) => {
      const sendTo = backendApis.find((el) => el.name === d.Vendor);
      const newItem = {
        ...d,
        sendTo: sendTo ? `${sendTo.url}/api/pending` : null,
        // ExpireDate: new Date(),
        // Batch_Name: "",
        oldQty: d.Quantity,
        allserialNumbers: d.serialNumbers,
      };

      return newItem;
    });

    setSelectedItems(_data);
    setUnRejectedItems(_data);
    setAllItems((prev) => _data);
  }, [data]);

  const handleSearchQueryChange = (e) => {
    setFilterParams({
      ...filterParams,
      [e.target.name]: e.target.value,
    });
  };

  const sendIssueItemMutation = useMutation(
    (payload) =>
      fetchActionsUtil(
        `${backendUrl}/api/warehouse/recieve-items/${wareshouseLocal?.cater}`,
        "POST",
        "",
        payload
      ),
    {
      onSuccess: ({ message, data }) => {
        refetch();
        toast.success(message);
      },
      onError: ({ message }) => {
        toast.error(message ? message : `Unable to perform action`);
      },
    }
  );

  const rejectItemMutation = useMutation(
    (payload) =>
      fetchActionsUtil(
        `${backendUrl}/api/warehouse/reject-items`,
        "POST",
        "",
        payload
      ),
    {
      onSuccess: ({ message, data }) => {
        refetch();
        // queryClient.setQueryData(
        //   ["WAREHOUSE_RECEIVE_ITEMS", queryParams],
        //   (oldData) => {
        //     let _data = data?.filter((p) => p.Status !== "Rejected");

        //     _data = _data?.map((d) => {
        //       const newItem = {
        //         ...d,
        //       };

        //       return newItem;
        //     });

        //     setSelectedItems(_data);
        //     setUnRejectedItems(_data);
        //     setAllItems((prev) => oldData?.data);
        //     return oldData;
        //   }
        // );
        toast.success(message);
      },
      onError: () => {
        toast.error(`Unable to perform action`);
      },
    }
  );

  // const selectFunction = (v) => {
  //   const _allItems = allItems
  //   if (v === "reject") {
  //     const aaa = _allItems.filter((d) => d.Status === "Rejected");

  //     setAllItems(aaa);

  //   } else if (v === "non") {
  //           const aaa = _allItems.filter(
  //             (d) => d.Status !== "Rejected"
  //           );
  //      setAllItems(aaa);
  //   } else {
  //     setAllItems();
  //   }
  // }

  const onSelectAll = (e) => {
    if (!e.target.checked) {
      setSelectedItems([]);
      setSelectedAll(!selectedAll);
    } else {
      // console.log(data);
      let _data = data?.data.filter((p) => p.Status !== "Rejected");

      _data = _data?.map((d) => {
        const sendTo = backendApis.find((el) => el.name === d.Vendor);
        const newItem = {
          ...d,
          sendTo: sendTo ? `${sendTo.url}/api/pending` : null,
          // ExpireDate: new Date(),
          // Batch_Name: "",
          oldQty: d.Quantity,
          allserialNumbers: d?.serialNumbers,
        };

        return newItem;
      });

      setSelectedItems((prev) => _data);
      setUnRejectedItems(_data);
      setSelectedAll(!selectedAll);
    }
  };

  const onChangeSelected = (item) => {
    const _selectedItems = [...selectedItems];

    if (_selectedItems.length > 0) {
      const index = _selectedItems.findIndex(
        (d) => d.Bar_Code === item.Bar_Code && d.Trans_ID === item.Trans_ID
      );
      if (index >= 0) {
        _selectedItems.splice(index, 1);
        setSelectedItems(_selectedItems);
      } else {
        setSelectedItems((prev) => [...prev, item]);
      }
    } else {
      setSelectedItems((prev) => [...prev, item]);
    }
  };

  const updateQuantity = (num, item) => {
    let _datas = selectedItems.find(
      (p, i) => p.Bar_Code === item.Bar_Code && p.Trans_ID === item.Trans_ID
    );
    const index = selectedItems.findIndex(
      (a) => a.Bar_Code === item.Bar_Code && a.Trans_ID === item.Trans_ID
    );
    _datas = { ..._datas, Quantity: parseFloat(num) };

    selectedItems.splice(index, 1, _datas);
  };

  const updateSerial = (serial, item) => {
    let _datas = selectedItems.find(
      (p, i) => p.Bar_Code === item.Bar_Code && p.Trans_ID === item.Trans_ID
    );
    const index = selectedItems.findIndex(
      (a) => a.Bar_Code === item.Bar_Code && a.Trans_ID === item.Trans_ID
    );

    _datas = { ..._datas, serialNumbers: JSON.stringify(serial) };
    selectedItems.splice(index, 1, _datas);
  };

  const updateBatch = (text, item) => {
    let _datas = selectedItems.find(
      (p, i) => p.Bar_Code === item.Bar_Code && p.Trans_ID === item.Trans_ID
    );
    const index = selectedItems.findIndex(
      (a) => a.Bar_Code === item.Bar_Code && a.Trans_ID === item.Trans_ID
    );
    _datas = { ..._datas, Batch_Name: text };

    selectedItems.splice(index, 1, _datas);
  };

  const updateDate = (date, item) => {
    let _datas = selectedItems.find(
      (p, i) => p.Bar_Code === item.Bar_Code && p.Trans_ID === item.Trans_ID
    );
    const index = selectedItems.findIndex(
      (a) => a.Bar_Code === item.Bar_Code && a.Trans_ID === item.Trans_ID
    );
    _datas = { ..._datas, ExpireDate: date };

    selectedItems.splice(index, 1, _datas);
  };

  const approveAll = async () => {
    const errors = selectedItems.filter((el) => el.Quantity > el.oldQty);
    if (errors.length > 0) {
      return toast.error(
        `Input a lower quantity for ${errors
          .map((el) => el.Item_Name)
          .join(",")}`
      );
    }

    const all = selectedItems
      ?.filter((ft) => ft.Quantity > 0)
      ?.map((el) => ({
        ...el,
        serialNumbers: el?.serialNumbers ? JSON.parse(el?.serialNumbers) : null,
        nonSelected: el?.serialNumbers
          ? JSON.parse(el?.allserialNumbers)?.filter((ft) =>
              JSON.parse(el?.serialNumbers)?.find(
                (fd) => ft.serialNum !== fd.serialNum
              )
            )
          : null,
      }));
    console.log(all);

    if (
      await ConfirmDialog({
        title: "Receive Item(s)",
        description:
          "Are you sure you want to receive this Item(s) to your inventry",
      })
    ) {
      sendIssueItemMutation.mutate({ selectedItems: all, date });
    }
  };

  const disapproveAll = async (reasonForReject) => {
    const data = { selectedItems, reasonForReject };

    console.log(data);
    if (
      await ConfirmDialog({
        title: "Reject Item(s)",
        description: "Are you sure you want to reject this Item(s)",
      })
    ) {
      rejectItemMutation.mutate(data);
      setOpenReject(false);
    }
  };

  window.addEventListener("storage", () => {
    refetch();
  });

  const search = () => {
    setQueryParams({
      ...queryParams,
      ...filterParams,
    });
  };

  const reset = () => {
    setFilterParams(initialFilterParams);
    setQueryParams({
      ...queryParams,
      ...initialFilterParams,
    });
  };

  const handleFilterParamsChange = (e) => {
    setFilterParams({
      ...filterParams,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  const filterByDateRange = (date) => {
    setFilterParams({
      ...filterParams,
      startDate: format(date[0], "yyyy-MM-dd"),
      endDate: format(date[1], "yyyy-MM-dd"),
    });
  };

  const clearDateRange = () => {
    setFilterParams({
      ...filterParams,
      startDate: "",
      endDate: "",
    });
  };

  return (
    <IsPrivileged roleName="Receive Item">
      <section className="cashbook-wrapped">
        <main className="cash-book">
          <div className="content">
            <div className="content-main">
              <div className={`filter-area ${showFilter ? "open" : "close"}`}>
                <div className="filter-header">
                  <h2>
                    <FilterTwoIcon /> Filters
                  </h2>

                  <Button
                    variant="white"
                    onClick={() => setShowFilter(!showFilter)}
                  >
                    <FilterCollapseIcon />
                  </Button>
                </div>
                <div className="filter-body">
                  <Form>
                    <div className="body">
                      <Form.Group className="">
                        <Form.Label>Item Code</Form.Label>
                        <InputGroup>
                          <InputGroup.Text>
                            <MagnifyIcon />
                          </InputGroup.Text>
                          <Form.Control
                            id="queryParams-q"
                            className=""
                            name="q"
                            value={filterParams.q}
                            onChange={(e) => handleSearchQueryChange(e)}
                            placeholder="Search..."
                          />
                        </InputGroup>
                      </Form.Group>

                      <hr className="my-4" />

                      <div className="mb-2-5">
                        <Form.Check
                          inline
                          label="Enable Date Range"
                          name="enableDateRange"
                          type={"checkbox"}
                          id={`enableDateRange`}
                          defaultChecked={JSON.parse(
                            filterParams?.enableDateRange || false
                          )}
                          onChange={(e) => handleFilterParamsChange(e)}
                        />
                      </div>

                      <Form.Group className="">
                        <Form.Label>Date Range</Form.Label>
                        <div
                          className="position-relative"
                          style={{ zIndex: 99 }}
                        >
                          <RsDateRangePicker
                            placement="auto"
                            value={
                              filterParams?.startDate && filterParams.endDate
                                ? [
                                    parse(
                                      filterParams.startDate,
                                      "yyyy-MM-dd",
                                      new Date()
                                    ),
                                    parse(
                                      filterParams.endDate,
                                      "yyyy-MM-dd",
                                      new Date()
                                    ),
                                  ]
                                : []
                            }
                            onClean={() => clearDateRange()}
                            onOk={(date) => filterByDateRange(date)}
                            style={{ zIndex: 99 }}
                          />
                        </div>
                      </Form.Group>
                    </div>

                    <div className="buttons rounded">
                      <Button onClick={() => reset()} variant="white">
                        Reset
                      </Button>
                      <Button onClick={() => search()} variant="primary">
                        Search
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>

              <div className="content-body">
                <header className="flex-wrap gap-3">
                  <div className="d-flex flex-grow-1">
                    {!showFilter && (
                      <button
                        onClick={() => setShowFilter(!showFilter)}
                        className="btn filter"
                      >
                        <FilterTwoIcon />
                      </button>
                    )}
                    <button
                      onClick={() => refetch()}
                      className="btn text-primary mx-3"
                      ref={refreshButton}
                    >
                      <CachedIcon />
                    </button>
                  </div>
                  <div className="d-flex align-items-center">
                    <div className="mx-2">Back Date</div>
                    <Datetime
                      dateFormat="MMM DD, YYYY"
                      timeFormat={false}
                      closeOnSelect={true}
                      closeOnClickOutside={true}
                      inputProps={{
                        placeholder: "Receive date",
                        readOnly: true,
                      }}
                      value={date}
                      onChange={(date) => {
                        setDate(date);
                      }}
                    />
                  </div>

                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="selectedItems"
                      onChange={onSelectAll}
                      checked={
                        selectedItems?.length === unRejectedItems?.length
                      }
                    />
                    <label className="form-check-label" htmlFor="selectedItems">
                      Select All
                    </label>
                  </div>

                  <div className="d-flex gap-3">
                    <Button
                      disabled={
                        selectedItems?.length < 1 ||
                        sendIssueItemMutation.isLoading ||
                        rejectItemMutation.isLoading
                      }
                      onClick={() => approveAll()}
                    >
                      {sendIssueItemMutation.isLoading
                        ? "Please wait..."
                        : "Receive Selected"}{" "}
                    </Button>
                    <Button
                      disabled={
                        selectedItems?.length < 1 ||
                        sendIssueItemMutation.isLoading ||
                        rejectItemMutation.isLoading
                      }
                      onClick={() => setOpenReject(true)}
                      variant="danger"
                      className="text-white px-3"
                    >
                      {rejectItemMutation.isLoading
                        ? "Please wait..."
                        : "Reject Selected"}{" "}
                    </Button>
                  </div>
                </header>

                <div className="px-md-4">
                  <Table
                    borderless
                    responsive
                    hover
                    striped
                    className="product-table"
                  >
                    <thead>
                      <tr>
                        <th />
                        <th className="ml-3">Item Code</th>
                        <th>Item Name</th>
                        <th>Remaining Quantity</th>
                        <th>Quantity</th>

                        {wareshouseLocal?.cater !== "Assets" && (
                          <>
                            <th className="text-center">Batch</th>
                            <th>Expiry Date</th>
                          </>
                        )}

                        <th>Sender</th>
                        <th>Date Log</th>
                        <th className="text-center">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {allItems?.map((el, index) => (
                        <ReceiveWarehouseTable
                          key={index}
                          el={el}
                          index={index}
                          onChangeSelected={onChangeSelected}
                          updateQuantity={updateQuantity}
                          selectedItems={selectedItems}
                          updateSerial={updateSerial}
                          updateBatch={updateBatch}
                          updateDate={updateDate}
                        />
                      ))}
                    </tbody>
                  </Table>
                </div>

                <div className="d-flex justify-content-between px-3 align-items-center pagination">
                  <div className="pagination_left">
                    <p className="m-0 p-0">Show</p>
                    <select
                      value={queryParams.limit}
                      name="limit"
                      className="form-select "
                      onChange={(e) => handleSearchQueryChange(e)}
                    >
                      <option value="10">10 rows</option>
                      <option value="20">20 rows</option>
                      <option value="30">30 rows</option>
                      <option value="40">40 rows</option>
                      <option value="50">50 rows</option>
                      <option value="100">100 rows</option>
                    </select>
                  </div>

                  <ReactPaginate
                    {...paginationOptions}
                    pageCount={Math.ceil(data?.count / queryParams?.limit)}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={0}
                    onPageChange={({ selected }) => {
                      document.body.scrollTop = document.documentElement.scrollTop = 0;
                      setQueryParams({
                        ...queryParams,
                        page: selected + 1,
                      });
                    }}
                    forcePage={parseInt(queryParams.page) - 1}
                  />
                </div>
              </div>
            </div>
          </div>
        </main>

        <Outlet />
        <RejectModal
          show={openReject}
          onHide={() => setOpenReject(false)}
          disapproveAll={disapproveAll}
        />
        <ModalLoader
          show={sendIssueItemMutation.isLoading || rejectItemMutation.isLoading}
        />
      </section>
    </IsPrivileged>
  );
}
