import { Table, InputGroup, Button, Form } from "react-bootstrap";
import { AddCircleIcon, EditIcon, FilterCollapseIcon } from "./../Icons";
import "./../../assets/scss/reports/cashbook.scss";
import { useEffect, useState } from "react";
import CachedIcon from "mdi-react/CachedIcon";
import { FilterTwoIcon } from "./../Icons";
import { queryActions } from "./../../utils/reactQueryActions";
import useDebounce, {
  useLocalStorage,
  useQueryParams,
  useScrollTop,
} from "./../../utils/hooks";
import queryString from "query-string";
import { useQuery } from "react-query";
import currency from "currency.js";
import { format, parse } from "date-fns";
import ReactPaginate from "react-paginate";
import {
  damageStatusOptions,
  formatForQty,
  paginationOptions,
  qtyFormat,
  qtyFormatToString,
  scrollToTop,
} from "./../../utils/helpers";
import RsDateRangePicker from "./../utils/RsDateRangePicker";
import { useAuth } from "./../../hooks/useAuth";

import { useStoreState } from "easy-peasy";
import Select from "react-select";
import { Popover } from "react-tiny-popover";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import DamageItemModal from "./DamageItemModal";
import UpdateDamageItemModal from "./UpdateDamagedItemModal";
// import DamagesTable from "./DamagesTable";
import PlantItemTable from "./PlantItemTable";
import { IsPrivileged } from "../DisplayChildElement";

export default function DamagesReturnRecorder() {
  const { backendUrl, isIronRod } = useAuth();

  const itemMeasurements = useStoreState((state) => state.itemMeasurements);
  const [showItemsPopover, setShowItemsPopover] = useState(false);
  const [showFilter, setShowFilter] = useLocalStorage("showFilter", true);
  const [showUpdateDamageItemModal, setShowUpdateDamageItemModal] = useState(
    false
  );

  useScrollTop();

  const [showDamageItemModal, setShowDamageItemModal] = useState(false);

  const initialFilterParams = {
    page: 1,
    limit: 40,
    barcode: "",
    itemName: "",
    withItemData: true,
  };
  const [queryParams, setQueryParams] = useQueryParams({
    ...initialFilterParams,
  });
  const [filterParams, setFilterParams] = useState({
    ...queryParams,
    ...initialFilterParams,
  });
  const [selectedFaultItem, setSelectedFaultItem] = useState(false);

  const debouncedFilterParams = useDebounce(filterParams, 500);
  useEffect(() => {
    setQueryParams((q) => ({ ...q, ...debouncedFilterParams }));
  }, [debouncedFilterParams, setQueryParams]);

  const wares = JSON.parse(window.localStorage.getItem("warehouseName"));

  const fetchItems = async (queryParams) => {
    // await waitFor(5000);
    let response = await fetch(
      `${backendUrl}/api/warehouse/damages/${
        wares.whID
      }?&${queryString.stringify(queryParams)}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();

    return data;
  };

  const { data = { count: 0, items: [] }, refetch } = useQuery(
    ["GET_DAMAGES_FROM_WAREHOUSE", queryParams],
    () => fetchItems(queryParams),
    {
      keepPreviousData: true,
    }
  );

  // console.log(data);
  const handleItemToEdit = (item) => {
    // console.log("Item ===>", item);
    setSelectedFaultItem({ ...item });
  };

  //-----------------------------
  useEffect(() => {
    if (selectedFaultItem) setShowUpdateDamageItemModal(true);
  }, [selectedFaultItem]);

  const handleFilterParamsChange = (e) => {
    setFilterParams({
      ...filterParams,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  const handleSearchQueryChange = (e) => {
    setQueryParams({
      ...queryParams,
      [e.target.name]: e.target.value,
    });
  };

  const filterByDateRange = (date) => {
    setFilterParams({
      ...filterParams,
      startDate: format(date[0], "yyyy-MM-dd"),
      endDate: format(date[1], "yyyy-MM-dd"),
    });
  };

  const clearDateRange = () => {
    setFilterParams({
      ...filterParams,
      startDate: "",
      endDate: "",
    });
  };

  const search = () => {
    setQueryParams({
      ...queryParams,
      ...filterParams,
    });
  };

  const reset = () => {
    setFilterParams({
      ...initialFilterParams,
    });
  };

  const handleSelectedItem = (item) => {
    setFilterParams({
      ...filterParams,
      barcode: item.Barcode,
      itemName: item.Item_Name,
    });
    setShowItemsPopover(false);
  };

  window.addEventListener("storage", () => {
    refetch();
  });

  return (
    <IsPrivileged
      roleName={[
        "Damage/Return Recorder",
        "Damages \n Tracker",
        "Item Manager",
      ]}
    >
      <section className="cashbook-wrapped">
        <main className="cash-book">
          <div className="content">
            <div className="content-main">
              <div className={`filter-area ${showFilter ? "open" : "close"}`}>
                <div className="filter-header">
                  <h2>
                    <FilterTwoIcon /> Filters
                  </h2>

                  <Button
                    variant="white"
                    onClick={() => setShowFilter(!showFilter)}
                  >
                    <FilterCollapseIcon />
                  </Button>
                </div>
                <div className="filter-body">
                  <Form>
                    <div className="body">
                      <Form.Group className="mb-2-5">
                        <Form.Label>Item Code</Form.Label>

                        <InputGroup>
                          <Form.Control
                            name="barcode"
                            placeholder="Enter Item Code"
                            value={filterParams?.barcode}
                            onChange={(e) => handleFilterParamsChange(e)}
                          />

                          <Popover
                            isOpen={showItemsPopover}
                            onClickOutside={() => setShowItemsPopover(false)}
                            content={() => (
                              <PlantItemTable
                                handleSelectedItem={handleSelectedItem}
                                showWarehouseDamage={true}
                              />
                            )}
                            position="bottom"
                          >
                            <InputGroup.Text
                              onClick={() =>
                                setShowItemsPopover(!showItemsPopover)
                              }
                              className="p-cursor"
                            >
                              <MagnifyIcon />
                            </InputGroup.Text>
                          </Popover>
                        </InputGroup>
                      </Form.Group>
                      <Form.Group className="mb-2-5">
                        <Form.Label>Item Name</Form.Label>
                        <Form.Control
                          name="itemName"
                          value={filterParams?.itemName}
                          placeholder="Enter Item Name"
                          onChange={(e) => handleFilterParamsChange(e)}
                        />
                      </Form.Group>

                      <Form.Group className="mb-2-5">
                        <Form.Label>Status</Form.Label>
                        <Select
                          classNamePrefix={"form-select"}
                          options={damageStatusOptions}
                          value={damageStatusOptions.find(
                            (el) => el.value === filterParams.DamageStatus
                          )}
                          onChange={({ value }) =>
                            setFilterParams({
                              ...filterParams,
                              DamageStatus: value,
                            })
                          }
                          isSearchable={false}
                        />
                      </Form.Group>

                      <hr className="mt-3 mb-4" />
                      <Form.Group className="">
                        <Form.Label>Date Range</Form.Label>
                        <div className="position-relative">
                          <RsDateRangePicker
                            placement="topStart"
                            value={
                              filterParams.startDate && filterParams.endDate
                                ? [
                                    parse(
                                      filterParams.startDate,
                                      "yyyy-MM-dd",
                                      new Date()
                                    ),
                                    parse(
                                      filterParams.endDate,
                                      "yyyy-MM-dd",
                                      new Date()
                                    ),
                                  ]
                                : []
                            }
                            onClean={() => clearDateRange()}
                            onOk={(date) => filterByDateRange(date)}
                          />
                        </div>
                      </Form.Group>
                    </div>

                    <div className="buttons rounded">
                      <Button onClick={() => reset()} variant="white">
                        Reset
                      </Button>
                      <Button onClick={() => search()} variant="primary">
                        Search
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>
              <div className="content-body">
                <header>
                  <h1>
                    {!showFilter && (
                      <button
                        onClick={() => setShowFilter(!showFilter)}
                        className="btn filter"
                      >
                        <FilterTwoIcon />
                      </button>
                    )}
                    All Damages
                    <button
                      onClick={() => refetch()}
                      className="btn text-primary"
                    >
                      <CachedIcon />
                    </button>
                  </h1>
                  <div className="actions mr-5">
                    <button
                      onClick={() => setShowDamageItemModal(true)}
                      className="btn sendSms"
                    >
                      Add Damaged Items <AddCircleIcon />
                    </button>

                    <button
                      onClick={() => setShowUpdateDamageItemModal(true)}
                      className="btn btn-outline-primary"
                    >
                      Update Damaged Items
                    </button>
                  </div>
                </header>

                <div className="px-md-4">
                  <Table
                    responsive
                    borderless
                    striped
                    className="product-table"
                  >
                    <thead>
                      <tr>
                        <th />
                        <th>Fault ID</th>
                        <th>Item Code</th>
                        <th>Item Name</th>
                        <th>QTY</th>
                        <th>QTY Replaced</th>

                        <th>Vendor</th>
                        <th>Status</th>
                        <th>Reasons for Return</th>
                        <th>Entry Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.items.map((el, index) => (
                        <tr
                          key={el?.FaultID}
                          style={
                            !["Replaced By Vendor", "Active"].includes(
                              el.DamageStatus
                            ) ||
                            Number(el.Quantity) === Number(el.QuantityReplaced)
                              ? {
                                  pointerEvents: "none",
                                  opacity: 0.5,
                                }
                              : {}
                          }
                        >
                          <td>
                            <Button
                              variant="white"
                              onClick={() => handleItemToEdit(el)}
                            >
                              <EditIcon />
                            </Button>
                          </td>
                          <td>{el?.FaultID}</td>
                          <td>{el?.Barcode}</td>
                          <td>{el?.Item_Name}</td>

                          <td className="p-cursor" title={el.Quantity}>
                            {isIronRod && el.itemData && el.Quantity
                              ? qtyFormatToString(
                                  qtyFormat(
                                    el.Quantity,
                                    el.itemData.Item_Desc,
                                    itemMeasurements
                                  )
                                )
                              : currency(el.Quantity, {
                                  symbol: "",
                                  precision: 2,
                                  format: formatForQty,
                                }).format()}
                          </td>
                          <td
                            className="p-cursor text-nowrap text-center"
                            title={el.QuantityReplaced}
                          >
                            {isIronRod && el.itemData && el.QuantityReplaced
                              ? qtyFormatToString(
                                  qtyFormat(
                                    el.QuantityReplaced,
                                    el.itemData.Item_Desc,
                                    itemMeasurements
                                  )
                                )
                              : currency(el.QuantityReplaced, {
                                  symbol: "",
                                  precision: 2,
                                  format: formatForQty,
                                }).format()}
                          </td>

                          <td className="text-nowrap">{el?.CompanySentTo}</td>
                          <td className="text-nowrap">{el?.DamageStatus}</td>
                          <td>{el.Faults}</td>
                          <td className="text-nowrap">
                            {el?.EntryDate
                              ? format(new Date(el.EntryDate), "dd MMM, yyyy")
                              : "..."}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>

                <div className="d-flex justify-content-between px-3 align-items-center pagination">
                  {/*<p className="m-0">
                Showing {data?.startIndex + 1} to{" "}
                {data?.endIndex <= data?.count ? data?.endIndex : data?.count}{" "}
                of {data.count} entries
                  </p> */}
                  <div className="pagination_left">
                    <p className="m-0 p-0">Show</p>
                    <select
                      value={queryParams.limit}
                      name="limit"
                      className="form-select "
                      onChange={(e) => handleSearchQueryChange(e)}
                    >
                      <option value="10">10 rows</option>
                      <option value="20">20 rows</option>
                      <option value="30">30 rows</option>
                      <option value="40">40 rows</option>
                      <option value="50">50 rows</option>
                      <option value="100">100 rows</option>
                    </select>
                  </div>

                  <ReactPaginate
                    {...paginationOptions}
                    pageCount={Math.ceil(data.count / queryParams.limit)}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={0}
                    onPageChange={({ selected }) => {
                      scrollToTop();
                      setQueryParams({
                        ...queryParams,
                        page: selected + 1,
                      });
                    }}
                    forcePage={queryParams.page - 1}
                  />
                </div>
              </div>
            </div>
          </div>

          {showDamageItemModal && (
            <DamageItemModal
              showDamageItemModal={showDamageItemModal}
              setShowDamageItemModal={setShowDamageItemModal}
              refetch={refetch}
            />
          )}

          {showUpdateDamageItemModal && (
            <UpdateDamageItemModal
              showUpdateDamageItemModal={showUpdateDamageItemModal}
              setShowUpdateDamageItemModal={setShowUpdateDamageItemModal}
              setSelectedFaultItem={setSelectedFaultItem}
              selectedFaultItem={selectedFaultItem}
              refetch={refetch}
            />
          )}
        </main>
      </section>
    </IsPrivileged>
  );
}
