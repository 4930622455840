import { Table, Button, Form, Modal, Row, Dropdown } from "react-bootstrap";
import {
  CalendarIcon,
  DeleteIcon,
  EditIcon,
  GearIcon,
  PrintIcon,
} from "./Icons";
import "./../assets/scss/reports/cashbook.scss";
import { useEffect, useRef, useState } from "react";
import DateRangePicker from "./utils/DateRangePicker";
import CachedIcon from "mdi-react/CachedIcon";
import {
  FilterTwoIcon,
  SummaryChartIconUp,
  SummaryChartIconDown,
  SummaryCloseIcon,
} from "./Icons";
import ChevronDownIcon from "mdi-react/ChevronDownIcon";
import ChevronUpIcon from "mdi-react/ChevronUpIcon";
import { reportActions } from "./../utils/reactQueryActions";
import useDebounce, {
  useBackendUrl,
  useEffectOnce,
  useIsAdmin,
  useQueryParams,
  useScrollTop,
} from "./../utils/hooks";
import queryString from "query-string";
import { services } from "./../config";
import { useMutation, useQuery } from "react-query";
import currency from "currency.js";
import { format, parse } from "date-fns";
import ReactPaginate from "react-paginate";
import { paginationOptions, scrollToTop } from "./../utils/helpers";
import RsDateRangePicker from "./utils/RsDateRangePicker";
import { useAuth } from "./../hooks/useAuth";
import PageHeader from "./PageHeader";
import { useFormik } from "formik";
import * as yup from "yup";
import Select from "react-select";
import CurrencyCustomInput from "./utils/CurrencyCustomInput";
import { toast } from "react-toastify";
import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";
import { isEmpty } from "lodash";
import LoginDialog from "./LoginDialog";
import ConfirmDialog from "./ConfirmDialogue";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import { UnAuthorized } from "./utils/UnAuthorized";
import { fetchActionsUtil } from "./../utils/helpers";
import DotsHorizontalIcon from "mdi-react/DotsHorizontalIcon";
import NoTableItem from "./utils/NoTableItem";

const initialValues = {
  Group: "",
  oldGroup: "",
};
const schema = yup.object().shape({
  Group: yup.string().required(),
});

function CreateGroupsModal({
  showCreateGroupsModal,
  setShowCreateGroupsModal,
  refetch,
}) {
  const backendUrl = useBackendUrl();

  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: async (values) => {
      createGroupsMutation.mutate({ ...values });
    },
  });

  const createGroups = async (payload) => {
    let response = await fetch(`${backendUrl}/api/customers/create-group`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const createGroupsMutation = useMutation((payload) => createGroups(payload), {
    onSuccess: ({ message }) => {
      toast.success(message);
      formik.resetForm();
      refetch();
      setShowCreateGroupsModal(false);
    },
    onError: ({ message = "" }) => {
      toast.error(`Unable to perform action: ${message}`);
    },
  });

  return (
    <Modal
      show={showCreateGroupsModal}
      onHide={() => setShowCreateGroupsModal(false)}
      centered={true}
      animation={false}
      enforceFocus={false}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h1 className="h6">Create Customer Group</h1>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate onSubmit={formik.handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Group</Form.Label>
            <Form.Control
              name="Group"
              placeholder="Group"
              type="text"
              value={formik.values.Group}
              onChange={formik.handleChange}
              isInvalid={formik.touched.Group && !!formik.errors.Group}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.Group}
            </Form.Control.Feedback>
          </Form.Group>

          <Button
            disabled={createGroupsMutation.isLoading}
            variant="primary"
            className="w-100 p-2"
            type="submit"
          >
            {createGroupsMutation.isLoading ? "Please wait…" : "Create Now"}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default function CustomerGroups() {
  const { backendUrl, token } = useAuth();
  const isAdmin = useIsAdmin();
  const [showCreateGroupsModal, setShowCreateGroupsModal] = useState(false);
  const [showEditGroupsModal, setShowEditGroupsModal] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState({});

  useScrollTop();
  const initialFilterParams = { q: "" };
  const [queryParams, setQueryParams] = useQueryParams({
    ...initialFilterParams,
  });
  const [filterParams, setFilterParams] = useState({
    ...initialFilterParams,
    ...queryParams,
  });
  const debouncedFilterParams = useDebounce(filterParams, 500);
  useEffect(() => {
    setQueryParams({ ...queryParams, ...debouncedFilterParams });
  }, [debouncedFilterParams]);

  const getGroups = async (debouncedqueryParams) => {
    let response = await fetch(
      `${backendUrl}/api/customers/group?${queryString.stringify(
        debouncedqueryParams
      )}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
          Authorization: `Bearer ${token}`,
        },
        credentials: "include",
      }
    );
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const { data } = await response.json();
    return data;
  };

  const { data = { groups: [] }, refetch, isFetching, isSuccess } = useQuery(
    ["GET_BUISINESS_GroupS", queryParams],
    () => getGroups(queryParams),
    {
      enabled: true,
      keepPreviousData: true,
    }
  );

  const deleteGroups = async (payload) => {
    let response = await fetch(`${backendUrl}/api/customers/delete-group`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const deleteGroupsMutation = useMutation((payload) => deleteGroups(payload), {
    onSuccess: ({ message }) => {
      toast.success(message);
      refetch();
    },
    onError: ({ message = "" }) => {
      toast.error(`Unable to perform action: ${message}`);
    },
  });

  const handleSearchQueryChange = (e) => {
    setFilterParams({
      ...filterParams,
      [e.target.name]: e.target.value,
      page: 1,
    });
  };

  const handleEdit = async (Group) => {
    setSelectedGroup(null);
    if (await LoginDialog()) {
      setSelectedGroup(Group);
      setShowEditGroupsModal(true);
    }
  };

  const handleDelete = async (Group) => {
    if (await LoginDialog()) {
      if (
        await ConfirmDialog({
          title: "Delete Group",
          description: "Are you sure you want to delete this Group",
        })
      ) {
        deleteGroupsMutation.mutate({ ...Group });
      }
    }
  };

  return (
    <>
      {isAdmin ? (
        <main className="bg-white">
          {" "}
          <header className="p-4">
            <div className="search-area d-flex flex-wrap justify-content-between gap-3 w-100">
              <div className="d-flex flex-wrap gap-3 col-md-4">
                <div className="global-search-area w-75">
                  <MagnifyIcon />
                  <Form.Control
                    className=""
                    name="q"
                    value={filterParams.q}
                    onChange={(e) => handleSearchQueryChange(e)}
                    placeholder="Search Customer Group..."
                  />
                </div>
                <Button
                  onClick={() => refetch()}
                  variant=""
                  className="bg-light-blue border-0 text-primary"
                >
                  <CachedIcon />
                </Button>
              </div>

              <div className="actions mr-5">
                <Button
                  onClick={() => setShowCreateGroupsModal(true)}
                  className="px-3"
                >
                  + Create New
                </Button>
              </div>
            </div>
          </header>
          <div className="content">
            <div className="px-md-4">
              <Table responsive borderless striped className="product-table">
                <thead>
                  <tr>
                    <th />
                    <th>S/N</th>
                    <th>Group</th>
                  </tr>
                </thead>
                <tbody>
                  {data.groups.map((el, index) => (
                    <tr key={index}>
                      <td>
                        <Dropdown>
                          <Dropdown.Toggle
                            variant=""
                            className="bg-white border-0"
                            bsPrefix="print more"
                          >
                            <DotsHorizontalIcon />
                          </Dropdown.Toggle>
                          <Dropdown.Menu
                            popperConfig={{
                              strategy: "fixed",
                            }}
                            renderOnMount
                            className="dropdown-with-icons"
                          >
                            <Dropdown.Item
                              as="button"
                              onClick={() => handleEdit(el)}
                            >
                              <EditIcon /> Edit
                            </Dropdown.Item>
                            <Dropdown.Item
                              as="button"
                              onClick={() => handleDelete(el)}
                            >
                              <DeleteIcon /> Delete
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>

                      <td>{index + 1}</td>
                      <td>{el.Group}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              {!isFetching && isSuccess && isEmpty(data?.groups) ? (
                <div>
                  <NoTableItem queryParams={queryParams} />
                </div>
              ) : null}
            </div>
          </div>
          {showCreateGroupsModal && (
            <CreateGroupsModal
              showCreateGroupsModal={showCreateGroupsModal}
              setShowCreateGroupsModal={setShowCreateGroupsModal}
              refetch={refetch}
            />
          )}
          {!isEmpty(selectedGroup) && (
            <EditGroupsModal
              showEditGroupsModal={showEditGroupsModal}
              setShowEditGroupsModal={setShowEditGroupsModal}
              selectedGroup={selectedGroup}
              setSelectedGroup={setSelectedGroup}
              refetch={refetch}
            />
          )}
        </main>
      ) : (
        <UnAuthorized />
      )}
    </>
  );
}

function EditGroupsModal({
  showEditGroupsModal,
  setShowEditGroupsModal,
  selectedGroup = {},
  refetch,
  setSelectedGroup,
}) {
  const backendUrl = useBackendUrl();

  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: async (values) => {
      editGroupsMutation.mutate({
        ...values,
      });
    },
  });

  useEffectOnce(() => {
    formik.setValues({
      Group: selectedGroup.Group,
      oldGroup: selectedGroup.Group,
    });
  });

  const editGroups = async (payload) => {
    let response = await fetch(`${backendUrl}/api/customers/update-group`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const editGroupsMutation = useMutation((payload) => editGroups(payload), {
    onSuccess: ({ message }) => {
      toast.success(message);
      formik.resetForm();
      refetch();
      setShowEditGroupsModal(false);
    },
    onError: ({ message = "" }) => {
      toast.error(`Unable to perform action: ${message}`);
    },
  });

  return (
    <Modal
      show={showEditGroupsModal}
      onHide={() => {
        setSelectedGroup(null);
        setShowEditGroupsModal(false);
      }}
      centered={true}
      animation={false}
      enforceFocus={false}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h1 className="h6">Edit {formik.values.oldGroup}</h1>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate onSubmit={formik.handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Type</Form.Label>
            <Form.Control
              name="Group"
              placeholder="Type"
              type="text"
              value={formik.values.Group}
              onChange={formik.handleChange}
              isInvalid={formik.touched.Group && !!formik.errors.Group}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.Group}
            </Form.Control.Feedback>
          </Form.Group>

          <Button
            disabled={editGroupsMutation.isLoading}
            variant="primary"
            className="w-100 p-2"
            type="submit"
          >
            {editGroupsMutation.isLoading ? "Please wait…" : "Save Changes"}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
