export const CustomInput = ({
  name,
  placeholder,
  typeOfInput,
  label,
  onChange,
  disabled,
  onBlur,
  value,
  inputclass,
  className,
  ...rest
}) => {
  return (
    <>
      <label htmlFor={name} className="form-label">
        {label}
      </label>
      <input
        type={typeOfInput}
        className={`form-control ${
          disabled ? "bg-white" : ""
        } ${inputclass} ${className}`}
        id={name}
        placeholder={placeholder}
        name={name}
        onChange={onChange}
        disabled={disabled}
        onBlur={onBlur}
        value={value}
        {...rest}
      />
    </>
  );
};
