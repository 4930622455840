import currency from "currency.js";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import { useState } from "react";
import { Table } from "react-bootstrap";
import { useQuery } from "react-query";
import { services } from "../../config";
import { useAuth } from "../../hooks/useAuth";
import {
  damageStatusOptions,
  qtyFormat,
  qtyFormatToString,
} from "../../utils/helpers";
import useDebounce from "../../utils/hooks";
import { queryActions } from "../../utils/reactQueryActions";
import { useStoreActions, useStoreState } from "easy-peasy";
import "./../../assets/scss/scoped/items-table.scoped.scss";
import { format } from "date-fns";
import Datetime from "react-datetime";
import queryString from "query-string";
import Select from "react-select";

export default function DamagesTable({ handleSelectedItem, isIronRod }) {
  const { backendUrl } = useAuth();
  const itemMeasurements = useStoreState((state) => state.itemMeasurements);
  const [queryParams, setQueryParams] = useState({
    q: "",
    withItemData: true,
  });
  const debouncedSearchString = useDebounce(queryParams, 500);

  const getItems = async (debouncedSearchString) => {
    // await waitFor(5000);
    let response = await fetch(
      `${backendUrl}/api/items/damages?${queryString.stringify(
        debouncedSearchString
      )}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    return data;
  };

  const { data = { items: [] }, isSuccess } = useQuery(
    [queryActions.GET_DAMAGES, debouncedSearchString],
    () => getItems(debouncedSearchString),
    {
      enabled: true,
      keepPreviousData: true,
    }
  );
  return (
    <div className="items-table-area damages rounded">
      <h2 className="h5 p-3 pb-1 m-0">Damages</h2>
      <div className="d-flex gap-2 align-items-center pe-3">
        <div className="search-area m-3 w-100">
          <MagnifyIcon />
          <input
            className="form-control search-input"
            name="q"
            value={queryParams.TransactionID}
            onChange={(e) =>
              setQueryParams({
                ...queryParams,
                page: 1,
                [e.target.name]: e.target.value,
              })
            }
            placeholder="Search Item..."
            autoFocus
            autoComplete="off"
            type={"text"}
          />
        </div>
      </div>

      <div className="p-3 pt-1 border-bottom mb-3 row">
        <div className="col-8">
          <Select
            classNamePrefix={"form-select"}
            options={damageStatusOptions}
            value={damageStatusOptions.find(
              (el) => el.value === queryParams.DamageStatus
            )}
            onChange={({ value }) =>
              setQueryParams({
                ...queryParams,
                DamageStatus: value,
              })
            }
            isSearchable={false}
            placeholder="Status"
          />
        </div>

        <div className="col-4">
          <Datetime
            dateFormat="MMM DD, YYYY"
            timeFormat={false}
            closeOnSelect={true}
            closeOnClickOutside={true}
            inputProps={{
              className: `date-input form-control`,
              placeholder: "Select date",
              readOnly: true,
            }}
            value={queryParams.date}
            onChange={(date) => {
              setQueryParams({
                ...queryParams,
                startDate: date,
                endDate: date,
              });
            }}
          />
        </div>
      </div>

      <div className="content">
        <Table
          borderless
          hover
          striped
          responsive
          className="product-table text-nowrap"
        >
          <thead className="sticky ">
            <tr>
              <th scope="col">Fault ID</th>
              <th scope="col">Item Code</th>
              <th scope="col">Item Name</th>
              <th scope="col">QTY</th>
              <th scope="col">Vendor</th>
              <th scope="col">Status</th>
              <th scope="col">Entry Date</th>
            </tr>
          </thead>
          <tbody>
            {data.items &&
              data.items.map((el, index) => (
                <tr
                  className="p-cursor"
                  key={index}
                  onClick={() => handleSelectedItem && handleSelectedItem(el)}
                  style={
                    !["Replaced By Vendor", "Active"].includes(
                      el.DamageStatus
                    ) || Number(el.Quantity) === Number(el.QuantityReplaced)
                      ? {
                          pointerEvents: "none",
                          opacity: 0.5,
                        }
                      : {}
                  }
                >
                  <td>{el?.FaultID}</td>
                  <td>{el?.Barcode}</td>
                  <td>{el?.Item_Name}</td>

                  <td className="p-cursor" title={el.Quantity}>
                    {isIronRod && el.itemData
                      ? qtyFormatToString(
                          qtyFormat(
                            el.Quantity,
                            el.itemData.Item_Desc,
                            itemMeasurements
                          )
                        )
                      : el.Quantity}
                  </td>
                  <td>{el?.Vendor}</td>
                  <td>{el?.DamageStatus}</td>
                  <td>
                    {el?.EntryDate
                      ? format(new Date(el.EntryDate), "dd MMM, yyyy")
                      : "..."}
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
}
