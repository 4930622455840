import Button from "react-bootstrap/Button";
import { Modal, Form } from "react-bootstrap";
import { useState, useRef, useMemo, useEffect } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import Datetime from "react-datetime";
import Select from "react-select";
import { Table } from "react-bootstrap";
import { Popover } from "react-tiny-popover";
import { toast } from "react-toastify";
import { useMutation } from "react-query";
import currency from "currency.js";
import CreatableSelect from "react-select/creatable";

import { SearchIcon, NoSelectedItemIcon } from "../Icons";
import { CustomInput } from "../utils/CustomInput";
import NumberCustomInput from "../utils/NumberCustomInput";
import CurrencyCustomInput from "../utils/CurrencyCustomInput";
// import ItemsTable from "../utils/ItemsTable";
import { useAuth } from "../../hooks/useAuth";
// import { backendApis } from "../../config";
// import IssueItenModalTable from "../IssueItemModalTable";
import "../../assets/scss/scoped/issueItem.scss";
import ConfirmDialog from "../ConfirmDialogue";
import { fetchActionsUtil } from "../../utils/helpers";
import WarehouseItemTable from "../warehouse/WarehouseItemTable";
import WarehouseIssueItemModalTable from "../warehouse/WareIssueItemModalTable";
import moment from "moment";
import ModalLoader from "../utils/ModalLoader";

export const WarehouseSerialIssuerModal = (props) => {
  const [carts, setCart] = useState([]);
  const [showItemsPopover, setShowItemsPopover] = useState(false);
  const [allcarts, setAllCart] = useState({});
  const [isLoading, setIsloading] = useState(false);
  const [allTotal, setAllTotal] = useState(0);

  const warehouseActive = props?.warehouseActive;

  const { backendUrl, token, user } = useAuth();

  const cashInput = useRef(null);

  const wareshouseLocal = JSON.parse(
    window.localStorage.getItem("warehouseName")
  );
  const formik = useFormik({
    initialValues: {
      issueTo: "",
      itemId: "",
      itemName: "",
      qtyinStock: "",
      issueDate: "",
      qty: 1,
      costPrice: "",
      receivingofficer: "",
      contracts: "",
      Comment: "",
      itemUnitPrice: "",
      serialNum: "",
    },
    validationSchema: yup.object().shape({
      issueTo: yup.string().required("Issue To is required"),
      issueDate: yup.string().required("Issue Date is required"),
      receivingofficer: yup.string().required("Receiving Officer is required"),
      qty: yup.string().required("Quantity is required"),
      itemId: yup.string().required("Item Id is required"),
      // costPrice: yup.string().required("costPrice is required"),
    }),
    onSubmit: async (values) => {
      // if (parseFloat(values.qty) > parseFloat(values.qtyinStock)) {
      //   return toast.error(`Quantity in stock can not be bigger than Quality`);
      // }

      const data = {
        subtotal: parseFloat(values.qty) * parseFloat(values.costPrice),
        ...values,
        ...allcarts,
      };

      data.Vendor = user?.company;
      data.WH_Category = wareshouseLocal?.cater;

      const _carts = [...carts];

      // const item = _carts.find((d) => d?.Bar_Code === values.itemId);
      // const index = _carts.findIndex((d) => d?.Bar_Code === values.itemId);

      const item = _carts.find((d) => d?.serialNum === values.serialNum);
      const index = _carts.findIndex((d) => d?.serialNum === values.serialNum);

      // check if the item is already in the cart then replace it else add it new
      if (carts.length > 0 && item?.serialNum === values.serialNum) {
        const available = {
          ...item,
          qty: values.qty,
          subtotal: parseFloat(values.qty) * parseFloat(values.costPrice),
        };
        carts.splice(index, 1, available);
      } else {
        setCart((prev) => [...prev, { ...data }]);
      }

      formik.resetForm({
        values: {
          ...values,
          itemId: "",
          itemName: "",
          qtyinStock: "",
          issueDate: "",
          qty: 1,
          costPrice: "",
          Comment: "",
          itemUnitPrice: "",
          serialNum: "",
        },
      });
    },
  });

  const handleSelectedItem = (el) => {
    formik.setValues({
      ...formik.values,
      itemId: el?.Items_Serial_WH?.Bar_Code,
      itemName: el?.Items_Serial_WH?.Item_Name,
      qtyinStock: el?.Items_Serial_WH?.quantityInStock,
      costPrice: el?.Batch_Serial_WH?.UnitCost
        ? el?.Batch_Serial_WH?.UnitCost
        : 0,
      UnitCost: el?.Batch_Serial_WH?.UnitCost
        ? el?.Batch_Serial_WH?.UnitCost
        : 0,
      // costPrice: el.UnitPrice ? el.UnitPrice : 0,
      issueDate: moment(),
      itemUnitPrice: el?.Items_Serial_WH?.UnitPrice,
      serialNum: el?.serialNum,
    });
    setAllCart(el);
    setShowItemsPopover(false);
    cashInput.current && cashInput.current.focus();
    setTimeout(() => {
      formik.submitForm();
    }, 50);
  };

  const updateQuantity = (num, index) => {
    carts
      .filter((p, i) => i === index)
      .map((e) => {
        e.qty = parseFloat(num);
        e.subtotal = parseFloat(e.qty) * parseFloat(e.costPrice);
        return "";
      });
  };

  const deleteItem = async (i) => {
    const recarts = [...carts];
    const filtered = recarts.filter((re, inx) => inx !== i);
    if (
      await ConfirmDialog({
        title: "Delete Item",
        description: "Are you sure you want to delete this Item from your cart",
      })
    ) {
      setCart(filtered);
    }
  };

  // /storage/issue-items

  const sendStorageItems = useMutation(
    (payload) =>
      fetchActionsUtil(
        `${backendUrl}/api/warehouse/storage/issue-items`,
        "POST",
        token,
        payload
      ),
    {
      onSuccess: ({ message, data }) => {
        setCart([]);
        setAllCart({});
        toast.success(message);
        props.refetchMain();
      },
      onError: ({ message }) => {
        toast.error(message ? message : `Unable to perform action`);
      },
    }
  );

  const sendIssueItemMutation = useMutation(
    (payload) =>
      fetchActionsUtil(
        `${backendUrl}/api/warehouse/issue-items`,
        "POST",
        token,
        payload
      ),
    {
      onSuccess: ({ message, data }) => {
        setCart([]);
        setAllCart({});
        toast.success(message);
        props.refetchMain();
      },
      onError: ({ message }) => {
        toast.error(message ? message : `Unable to perform action`);
      },
    }
  );

  const sendPlantsIssuesMutation = useMutation(
    (payload) =>
      fetchActionsUtil(
        `${backendUrl}/api/production/issue-items`,
        "POST",
        token,
        payload
      ),
    {
      onSuccess: ({ message, data }) => {
        setCart([]);
        setAllCart({});
        toast.success(message);
        props.refetchMain();
      },
      onError: ({ message }) => {
        toast.error(message ? message : `Unable to perform action`);
      },
    }
  );

  const sendCarts = async () => {
    const unique = [
      ...new Set(carts?.map((el) => `${el?.itemId} ${el?.costPrice}`)),
    ];
    // const cart = carts.map((el) => ({ ...el, contracts: cons }));
    // const conStorage = {
    //   allTotal,
    //   cons,
    //   type: wareshouseLocal?.cater,
    //   Warehouse: wareshouseLocal?.whID,
    // };

    let all = unique?.map((uni) => {
      const bar = uni.split(" ")[0];
      const cost = parseFloat(uni.split(" ")[1]);

      const serialNumbers = carts
        ?.filter((el) => el?.Bar_Code === bar && el?.costPrice === cost)
        ?.map((se) => ({ serialNum: se?.serialNum }));
      const items = carts?.find(
        (el) => el?.Bar_Code === bar && el?.costPrice === cost
      );
      const subtotal = carts
        ?.filter((el) => el?.Bar_Code === bar && el?.costPrice === cost)
        ?.reduce((p, c) => parseFloat(p) + parseFloat(c?.subtotal), 0);

      return {
        ...items,
        serialNumbers,
        subtotal,
        qty: serialNumbers?.length,
      };
    });

    all = all?.map((el) => ({
      ...el,
      receivingofficer: el.receivingofficer?.split("-")[0],
      userEmail: el.receivingofficer?.split("-")[1],
    }));

    console.log(all);

    if (
      await ConfirmDialog({
        title: "Issue Item",
        description: `Are you sure you want to issue these Items`,
      })
    ) {
      sendIssueItemMutation.mutate({ warehouseCarts: all });
      // if (warehouseActive?.Warehouse_Type !== "Storage") {
      //   const plantCarts = cart.filter(
      //     (d) => d.issueTo.split("-")[1] === "plant"
      //   );
      //   const warehouseCarts = cart.filter(
      //     (d) => d.issueTo.split("-")[1] !== "plant"
      //   );

      //   warehouseCarts.length > 0 &&
      //     sendIssueItemMutation.mutate({ warehouseCarts });

      //   plantCarts.length > 0 &&
      //     sendPlantsIssuesMutation.mutate({ plantCarts });
      // } else {
      //   // console.log({ carts: cart, conStorage });
      //   sendStorageItems.mutate({ carts: cart, conStorage });
      // }
    }
  };

  const issueToData = useMemo(() => {
    return props.warehouses
      ?.concat(props.plants)
      .concat({ label: "Sales", value: "Sales" });
  }, [props.warehouses, props.plants]);
  // console.log(props?.locations, props?.employees);

  useEffect(() => {
    const tol = carts.reduce((a, b) => a + b.subtotal, 0);
    setAllTotal(tol);
  }, [carts]);

  const fetchSerialNumberDetail = async ({ serial }) => {
    try {
      setIsloading(true);
      const data = await fetchActionsUtil(
        `${backendUrl}/api/warehouse/all/serial-item?Warehouse=${
          wareshouseLocal?.whID
        }&q=${serial}`
      );
      const el = data?.stockCounts[0];
      handleSelectedItem(el);
    } catch (error) {
      console.log(error);
      toast.error("Unable to load Item, Try again");
    } finally {
      setIsloading(false);
    }
  };

  return (
    <Modal
      onHide={props.onHide}
      show={props.show}
      backdropClassName={`global-backdrop`}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="my-style-modal"
      animation={false}
      enforceFocus={false}
      size="xl"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <div>
            <h1>Issue Item</h1>
          </div>

          <p>Issue item by filling in the following forms.</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div className="container">
            <div className="row">
              <div className="col-4 border p-2 rounded">
                <form
                  noValidate
                  onSubmit={formik.handleSubmit}
                  className="form-relative"
                >
                  <div className="mb-3">
                    <label htmlFor="date" className="form-label">
                      Issue to
                    </label>
                    {warehouseActive?.Warehouse_Type !== "Storage" ? (
                      <Select
                        classNamePrefix={"form-select"}
                        isSearchable={true}
                        value={props?.warehouses?.find(
                          (el) => el.value === formik.values.issueTo
                        )}
                        onChange={({ value }) => {
                          formik.setFieldValue("issueTo", value);
                        }}
                        options={issueToData}
                      />
                    ) : (
                      <CreatableSelect
                        isClearable
                        onChange={(selected) => {
                          formik.setFieldValue("issueTo", selected?.value);
                        }}
                        options={props?.locations}
                        value={props?.locations?.find(
                          (el) => el.value === formik.values.issueTo
                        )}
                      />
                    )}

                    {formik.touched.issueTo && !!formik.errors.issueTo ? (
                      <span className="custom-invalid-feedback">
                        {formik.errors.issueTo}
                      </span>
                    ) : null}
                  </div>
                  <div className="mb-3">
                    <label htmlFor="date" className="form-label">
                      Receiving Officer
                    </label>
                    {warehouseActive?.Warehouse_Type !== "Storage" ? (
                      <Select
                        classNamePrefix={"form-select"}
                        isSearchable={true}
                        value={props.staffs.find(
                          (el) => el.value === formik.values.receivingofficer
                        )}
                        onChange={({ value }) =>
                          formik.setFieldValue("receivingofficer", value)
                        }
                        options={props.staffs}
                      />
                    ) : (
                      <Select
                        classNamePrefix={"form-select"}
                        isSearchable={true}
                        value={props.staffs.find(
                          (el) => el.value === formik.values.receivingofficer
                        )}
                        onChange={({ value }) =>
                          formik.setFieldValue("receivingofficer", value)
                        }
                        options={props.staffs}
                      />
                    )}

                    {formik.touched.receivingofficer &&
                    !!formik.errors.receivingofficer ? (
                      <span className="custom-invalid-feedback">
                        {formik.errors.receivingofficer}
                      </span>
                    ) : null}
                  </div>

                  {/* {warehouseActive?.Warehouse_Type === "Storage" && (
                    <div className="mb-3">
                      <label htmlFor="date" className="form-label">
                        Contract/Job
                      </label>
                      <Select
                        classNamePrefix={"form-select"}
                        isSearchable={true}
                        value={props.contracts.find(
                          (el) => el.value === formik.values.contracts
                        )}
                        onChange={({ value }) =>
                          // formik.setFieldValue("contracts", value)
                          setCons(value)
                        }
                        options={props.contracts}
                      />
                    </div>
                  )} */}

                  {/*  */}

                  {/* {warehouseActive?.Warehouse_Type === "Storage" && (
                    <Form.Group className="">
                      <Form.Label className="mb-1">
                        Comment(Optional)
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder="Enter Comment"
                        name="Comment"
                        rows={5}
                        value={formik.values.Comment}
                        onChange={formik.handleChange}
                        isInvalid={
                          formik.touched.Comment && !!formik.errors.Comment
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.Comment}
                      </Form.Control.Feedback>
                    </Form.Group>
                  )} */}
                  {/*  */}

                  <div className="my-3 text-center">
                    <p className="fw-bold">Add Item</p>
                  </div>
                  <div class="mb-3">
                    <label
                      htmlFor="exampleFormControlInput1"
                      class="form-label"
                    >
                      Serial Number
                    </label>
                    <div className="input-group mb-3">
                      {/* <Popover
                        isOpen={showItemsPopover}
                        onClickOutside={() => setShowItemsPopover(false)}
                        content={() => (
                          <WarehouseItemTable
                            handleSelectedItem={handleSelectedItem}
                            serialNumbers
                          />
                        )}
                        position="bottom"
                      > */}
                      {/* <> */}
                      <CustomInput
                        className="p-cursor form-control"
                        name="serialNum"
                        placeholder="Enter Serial Number"
                        typeOfInput="text"
                        value={formik.values.serialNum}
                        onChange={formik.handleChange}
                        onKeyDown={(e) => {
                          if (e.keyCode === 13 || e.which === 13) {
                            e.preventDefault();
                            e.stopPropagation();
                            fetchSerialNumberDetail({ serial: e.target.value });
                          }
                        }}
                        // onClick={() => setShowItemsPopover(!showItemsPopover)}
                      />
                      <Popover
                        isOpen={showItemsPopover}
                        onClickOutside={() => setShowItemsPopover(false)}
                        content={() => (
                          <WarehouseItemTable
                            handleSelectedItem={handleSelectedItem}
                            serialNumbers
                          />
                        )}
                        position="bottom"
                      >
                        <span
                          className="input-group-text border p-cursor"
                          id="basic-addon1"
                          onClick={() => setShowItemsPopover(!showItemsPopover)}
                        >
                          <SearchIcon />
                        </span>
                        {/* </> */}
                      </Popover>
                    </div>
                    {formik.touched.serialNum && !!formik.errors.serialNum ? (
                      <span className="custom-invalid-feedback">
                        {formik.errors.serialNum}
                      </span>
                    ) : null}
                  </div>

                  <div className="mb-3">
                    <CustomInput
                      name="itemId"
                      placeholder="Enter Item Id"
                      typeOfInput="text"
                      value={formik.values.itemId}
                      disabled
                      onChange={formik.handleChange}
                      label="Item Id"
                    />
                  </div>

                  <div className="mb-3">
                    <CustomInput
                      name="itemName"
                      placeholder="Enter Item Name"
                      typeOfInput="text"
                      label="Item Name"
                      disabled
                      value={formik.values.itemName}
                      onChange={formik.handleChange}
                    />
                  </div>
                  {/* <div className="mb-3">
                    <label htmlFor="qty" className="form-label">
                      Quantity to issue
                    </label>
                    <NumberCustomInput
                      placeholder="0"
                      name="qty"
                      value={formik.values.qty}
                      onValueChange={(value, name) => {
                        formik.setFieldValue(name, value, true);
                      }}
                      isInvalid={formik.touched.qty && !!formik.errors.qty}
                      ref={cashInput}
                    />
                    {formik.touched.qty && !!formik.errors.qty ? (
                      <span className="custom-invalid-feedback">
                        {formik.errors.qty}
                      </span>
                    ) : null}
                  </div>
                  <div className="mb-3">
                    <CustomInput
                      name="qtyinStock"
                      placeholder=""
                      typeOfInput="number"
                      label="QTY in Stock"
                      disabled
                      value={formik.values.qtyinStock}
                      onChange={formik.handleChange}
                    />
                  </div> */}
                  {/* <div className="mb-3">
                    <label htmlFor="date" className="form-label">
                      Issue Date
                    </label>
                    <Datetime
                      dateFormat="MMM DD, YYYY"
                      timeFormat={false}
                      closeOnSelect={true}
                      closeOnClickOutside={true}
                      name="issueDate"
                      inputProps={{
                        className: `date-input form-control ${
                          formik.touched.issueDate && !!formik.errors.issueDate
                            ? "is-invalid"
                            : ""
                        }`,

                        placeholder: "Select date",
                        readOnly: true,
                      }}
                      value={formik.values.issueDate}
                      onChange={(date) => {
                        formik.setFieldValue("issueDate", date, true);
                      }}
                      onBlur={() => formik.setFieldTouched("issueDate", true)}
                    />
                    {formik.touched.issueDate && !!formik.errors.issueDate ? (
                      <span className="custom-invalid-feedback">
                        {formik.errors.issueDate}
                      </span>
                    ) : null}
                  </div> */}
                  {warehouseActive?.Warehouse_Type !== "Storage" && (
                    <div className="mb-3">
                      <label htmlFor="qty" className="form-label">
                        Cost Price
                      </label>
                      <CurrencyCustomInput
                        name="costPrice"
                        value={formik.values.costPrice}
                        onValueChange={(value, name) =>
                          formik.setFieldValue(name, value)
                        }
                        placeholder="0.00"
                        decimalsLimit={2}
                      />
                      {formik.touched.costPrice && !!formik.errors.costPrice ? (
                        <span className="custom-invalid-feedback">
                          {formik.errors.costPrice}
                        </span>
                      ) : null}
                    </div>
                  )}
                  <div className="add-to-cart-holder d-flex justify-content-end text-primary">
                    <Button
                      type="submit"
                      variant="outline-primary"
                      onClick={formik.handleSubmit}
                    >
                      Add to Cart
                    </Button>
                  </div>
                </form>
              </div>

              <div className="col-8 pl-4">
                <div className="px-md-4">
                  <Table
                    responsive
                    borderless
                    striped
                    className="product-table text-nowrap"
                  >
                    <thead>
                      <tr>
                        <th className="p-cursor">Serial No.</th>
                        <th className="p-cursor">Model No.</th>
                        <th className="p-cursor">Item Name</th>
                        <th className="p-cursor">QTY</th>
                        <th className="p-cursor">Cost Price</th>
                        <th className="p-cursor">Sub Total</th>
                      </tr>
                    </thead>
                    {
                      <tbody>
                        {carts.map((el, index) => (
                          <WarehouseIssueItemModalTable
                            index={index}
                            el={el}
                            deleteItem={deleteItem}
                            updateQuantity={updateQuantity}
                            key={index}
                            serialNum
                          />
                        ))}
                      </tbody>
                    }
                  </Table>
                  {carts.length < 1 && (
                    <div className="d-flex justify-content-center">
                      <div className="info text-center">
                        <NoSelectedItemIcon />
                        <h5 className="my-2">No Item In Cart</h5>
                        <p>
                          Please add an item from the form beside, to fill in
                          the <br />
                          cart.
                        </p>
                      </div>
                    </div>
                  )}
                </div>

                {carts.length > 0 && (
                  <div className="d-flex justify-content-between px-md-4">
                    {warehouseActive?.Warehouse_Type !== "Storage" && (
                      <div className="gridChild gridChildBorderLeftBlue">
                        <div className="gridChildLeft">
                          <p className="gridChld1">
                            {currency(
                              carts.reduce((a, b) => a + b.subtotal, 0),
                              {
                                symbol: "",
                              }
                            ).format()}
                          </p>
                          <p className="gridChld2">Total</p>
                        </div>
                      </div>
                    )}
                    <div className="mt-3">
                      <Button
                        disabled={
                          sendIssueItemMutation.isLoading ||
                          sendPlantsIssuesMutation.isLoading ||
                          sendStorageItems.isLoading
                        }
                        onClick={sendCarts}
                      >
                        {sendIssueItemMutation.isLoading ||
                        sendPlantsIssuesMutation.isLoading ||
                        sendStorageItems.isLoading
                          ? "Please wait..."
                          : "Issue Item"}
                      </Button>
                    </div>
                  </div>
                )}
              </div>
              <ModalLoader
                show={
                  sendIssueItemMutation.isLoading ||
                  sendPlantsIssuesMutation.isLoading ||
                  sendStorageItems.isLoading ||
                  isLoading
                }
              />
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
