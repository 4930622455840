// import { Pagination } from "react-bootstrap";
import CachedIcon from "mdi-react/CachedIcon";
import { useQuery } from "react-query";
import currency from "currency.js";
import { format, parse } from "date-fns";
import { useEffect, useState, useRef } from "react";
import queryString from "query-string";
import { CSVLink } from "react-csv";

import "./../../assets/scss/reports/cashbook.scss";
import useDebounce, {
  useCurrencies,
  useQueryParams,
  useScrollTop,
} from "../../utils/hooks";
import { useAuth } from "../../hooks/useAuth";
import ModalLoader from "../utils/ModalLoader";
import RsDateRangePicker from "../utils/RsDateRangePicker";
import { ExcelIcon, PDFIcon } from "../Icons";
import { useDownloadExcel } from "../../hooks/useDownloadExcel";
import { Link } from "react-router-dom";
import { IsPrivileged } from "../DisplayChildElement";
import { backendApis } from "../../config";
import { Form } from "react-bootstrap";
import { isNumber } from "lodash";
import Select from "react-select";

const months = [
  {
    value: "All",
    label: "All",
  },
  {
    value: "Date Range",
    label: "Date Range",
  },
  {
    value: "01-01",
    label: "January",
  },
  {
    value: "02-01",
    label: "February",
  },
  {
    value: "03-01",
    label: "March",
  },
  {
    value: "04-01",
    label: "April",
  },
  {
    value: "05-01",
    label: "May",
  },
  {
    value: "05-01",
    label: "June",
  },
  {
    value: "07-01",
    label: "July",
  },
  {
    value: "08-01",
    label: "August",
  },
  {
    value: "09-01",
    label: "September",
  },
  {
    value: "10-01",
    label: "October",
  },
  {
    value: "11-01",
    label: "November",
  },
  {
    value: "12-01",
    label: "December",
  },
];

function IncomeStatement() {
  useScrollTop();
  const initialFilterParams = {
    year: new Date().getFullYear(),
    month: "All",
    // startDate: "",
    // endDate: "",
  };

  const {
    deploymentCurrencies: currenciesOptions,
    prevailingCurrency,
    getCurrencySymbol,
  } = useCurrencies();
  const [disableDate, setDisableDate] = useState(null);
  const [excelData, setExcelData] = useState([]);
  const CSVLinkRef = useRef(null);

  // fetch excel hook
  const [isfetchingExcel, fetchExcelData] = useDownloadExcel(
    excelData,
    CSVLinkRef
  );

  const { backendUrl, brachData } = useAuth();

  const [queryParams, setQueryParams] = useQueryParams({
    ...initialFilterParams,
    currency: "",
    convertCurrency: prevailingCurrency,
  });

  const [filterParams, setFilterParams] = useState({
    ...initialFilterParams,
    ...queryParams,
  });

  const debouncedFilterParams = useDebounce(filterParams, 500);
  useEffect(() => {
    setQueryParams({
      ...queryParams,
      ...debouncedFilterParams,
    });
  }, [debouncedFilterParams]);

  useEffect(() => {
    filterParams?.startDate && filterParams?.endDate
      ? setDisableDate(false)
      : setDisableDate(true);
  }, [filterParams?.startDate, filterParams?.endDate]);

  const fetchCustomerLedgers = async (queryParams) => {
    let data = {};

    if (filterParams.consolidate) {
      const allDatas = await Promise.all(
        backendApis.map((endPoints) => {
          return new Promise(async (resolve, reject) => {
            try {
              let response = await fetch(
                `${
                  endPoints.url
                }/api/reports/income-statement?&${queryString.stringify(
                  queryParams
                )}`,
                {
                  method: "GET",
                  headers: {
                    Accept: "Application/json",
                    "Content-Type": "Application/json",
                  },
                  credentials: "include",
                }
              );

              if (!response.ok) {
                response = await response.json();
                throw new Error(response.message);
              }

              const { data } = await response.json();
              resolve(data);
            } catch (error) {
              console.log(error);
              reject(error);
            }
          });
        })
      );

      data = {
        accountIdsForReport: {},
        administrativeExpenses: {},
      };

      for (let branchData of allDatas) {
        for (let [key, value] of Object.entries(branchData)) {
          if (key == "accountIdsForReport") {
            for (let accId in value) {
              data[key][accId] = branchData[key]?.[accId];
            }
          } else if (key == "administrativeExpenses") {
            for (let accId in value) {
              data[key][accId] = currency(branchData[key]?.[accId]).add(
                data[key][accId]
              ).value;
            }
          } else {
            data[key] = isNumber(value)
              ? currency(data[key]).add(value).value
              : value;
          }
        }
      }
    } else {
      let response = await fetch(
        `${backendUrl}/api/reports/income-statement?&${queryString.stringify(
          queryParams
        )}`,
        {
          method: "GET",
          headers: {
            Accept: "Application/json",
            "Content-Type": "Application/json",
          },
          credentials: "include",
        }
      );

      if (!response.ok) {
        response = await response.json();
        throw new Error(response.message);
      }

      let responseData = await response.json();
      data = responseData.data;
    }

    // ===================================================
    data.grossProfit = currency(data.salesRevenue, {
      symbol: "",
    })
      .add(data.serviceFeeIncome)
      .add(data.otherIncome)
      .subtract(data.costOfgoodsold)
      .format();

    data.administrativeExpensesTotal = currency(
      Object.entries(data.administrativeExpenses).reduce(
        (a, c) => a + parseFloat(c[1]),
        0
      ),
      {
        symbol: "",
      }
    ).format();

    data.netIncome = currency(data.grossProfit, {
      symbol: "",
    })
      .subtract(data.administrativeExpensesTotal)
      .format();

    return data;
  };

  const {
    data = {
      count: 0,
      customerLedgers: [],
    },
    refetch,
    isFetching,
    // isSuccess,
  } = useQuery(
    ["INCOME_STATEMENT", queryParams],
    () => fetchCustomerLedgers(queryParams),
    {
      keepPreviousData: true,
    }
  );

  // download excel function
  const { limit, page, ...rest } = queryParams;
  const onDownloadExcelData = async () => {
    // NO need to get from api, there is no pagination
    let exData = {
      data,
    }; /* await fetchExcelData(
      `${backendUrl}/api/reports/income-statement?${queryString.stringify(
        rest
      )}`,
      "GET"
    );  */

    const {
      company,
      salesRevenue,
      otherIncome,
      costOfgoodsold,
      administrativeExpenses,
      serviceFeeIncome,
    } = exData.data;

    const grossProfit = currency(salesRevenue, {
      symbol: "",
    })
      .add(serviceFeeIncome)
      .add(otherIncome)
      .subtract(costOfgoodsold)
      .format();

    const administrativeExpensesTotal = currency(
      Object.entries(administrativeExpenses).reduce(
        (a, c) => a + parseFloat(c[1]),
        0
      ),
      {
        symbol: "",
      }
    ).format();

    const netIncome = currency(grossProfit, {
      symbol: "",
    })
      .subtract(administrativeExpensesTotal)
      .format();

    const admins = Object.entries(administrativeExpenses).map((a, i) => [
      a[0],
      a[1],
    ]);

    const date =
      rest.startDate && rest.endDate
        ? `Date Prepared: Between ${format(
            new Date(rest.startDate),
            "E MMM d yyyy k:mm:ss z"
          )} to ${format(new Date(rest.endDate), "E MMM d yyyy k:mm:ss z")}`
        : "";
    exData = [
      [company[0]?.CompName],
      ["Income Statement Report"],
      [date],
      [""],
      ["REVENUE"],
      [""],

      [
        "Sales Revenue",
        currency(serviceFeeIncome, {
          symbol: "",
        }).format(),
      ],
      [
        "Service / Fee Income",
        currency(salesRevenue, {
          symbol: "",
        })
          //  .subtract(otherIncome)
          .format(),
      ],
      ["Cost of Sales", costOfgoodsold],
      ["Other Income", otherIncome],
      [""],
      ["Gross Profit", grossProfit],
      [""],
      [""],
      ["Administrative Expenses"],
      ...admins,
      [""],
      ["Total Expenses", administrativeExpensesTotal],
      [""],
      [""],
      ["Net Income", netIncome],
    ];
    //  console.log(exData);
    setExcelData(exData);
  };

  const filterByPeroid = (e) => {
    const selected = e.target;
    if (selected.value === "Date Range") {
      setDisableDate(false);
      setFilterParams({
        ...filterParams,
        month: "",
        startDate: format(new Date(), "yyyy-MM-dd"),
        endDate: format(new Date(), "yyyy-MM-dd"),
      });
    } else {
      setFilterParams({
        ...filterParams,
        month: selected.value,
        startDate: "",
        endDate: "",
      });
      setDisableDate(true);
    }
  };

  const filterByDateRange = (date) => {
    setFilterParams({
      ...filterParams,
      startDate: format(date[0], "yyyy-MM-dd"),
      endDate: format(date[1], "yyyy-MM-dd"),
    });
  };

  const handleFilterParamsChange = (e) => {
    setFilterParams({
      ...filterParams,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  // isprivileged
  return (
    <IsPrivileged roleName="Financials">
      <main className="cash-book">
        <div className="content">
          <header className="pt-5">
            <h1>
              Income Statement
              <button
                title="Refresh"
                onClick={() => refetch()}
                className="btn text-primary"
              >
                <CachedIcon />
              </button>
            </h1>

            <div className="actions gap-3 justify-content-end ">
              <div className="position-relative">
                <label
                  htmlFor="months"
                  className="fw-5 position-absolute bottom-100 start-0 m-1"
                >
                  Accounting Period:
                </label>

                <select
                  id="months"
                  className="form-select"
                  aria-label="Default select example"
                  onChange={filterByPeroid}
                  value={
                    queryParams?.month === ""
                      ? "Date Range"
                      : queryParams?.month
                  }
                >
                  {months.map((d) => (
                    <option value={d.value}>{d.label}</option>
                  ))}
                </select>
              </div>
              <div className="position-relative">
                <RsDateRangePicker
                  disabled={disableDate}
                  placement="bottomStart"
                  value={
                    filterParams.startDate && filterParams.endDate
                      ? [
                          parse(
                            filterParams.startDate,
                            "yyyy-MM-dd",
                            new Date()
                          ),
                          parse(filterParams.endDate, "yyyy-MM-dd", new Date()),
                        ]
                      : []
                  }
                  // onClean={() => clearDateRange()}
                  onOk={(date) => filterByDateRange(date)}
                />
              </div>
              <div className="position-relative col-2">
                <label
                  htmlFor="years"
                  className="fw-5 position-absolute bottom-100 start-0 m-1"
                >
                  Accounting Year:
                </label>
                <input
                  name="year"
                  value={filterParams?.year}
                  onChange={(e) => handleFilterParamsChange(e)}
                  type="number"
                  className="form-control"
                  id="years"
                />
              </div>{" "}
              {brachData?.isHq && (
                <Form.Group className="">
                  <Form.Check
                    type="switch"
                    label="Consolidate"
                    name="consolidate"
                    className="ga-form-check fs-6"
                    checked={filterParams.consolidate}
                    onChange={(e) => handleFilterParamsChange(e)}
                  />
                </Form.Group>
              )}
              <Form.Group className="position-relative">
                <Form.Label className="fw-5 position-absolute bottom-100 start-0 m-1">
                  Filter By
                </Form.Label>
                <Select
                  classNamePrefix="form-select"
                  placeholder="All Currencies"
                  isSearchable={true}
                  options={currenciesOptions}
                  value={currenciesOptions.find(
                    (el) => el.value == filterParams.currency
                  )}
                  onChange={(selected) =>
                    setFilterParams({
                      ...filterParams,
                      page: 1,
                      currency: selected?.value,
                    })
                  }
                  isClearable={true}
                />
              </Form.Group>
              <Form.Group className="position-relative">
                <Form.Label className="fw-5 position-absolute bottom-100 start-0 m-1">
                  Report In{" "}
                </Form.Label>
                <Select
                  classNamePrefix="form-select"
                  placeholder="Select Currency"
                  isSearchable={true}
                  options={currenciesOptions}
                  value={currenciesOptions.find(
                    (el) => el.value === filterParams.convertCurrency
                  )}
                  onChange={({ value }) =>
                    setFilterParams({
                      ...filterParams,
                      page: 1,
                      convertCurrency: value,
                    })
                  }
                />
              </Form.Group>
              <CSVLink
                className="btn print d-none"
                filename={`Income Statement Report${
                  queryParams?.consolidate ? " Consolidated " : ""
                }(${format(new Date(), "dd-MMM-yyyy hh:mm:ss a")}).csv`}
                data={excelData}
                ref={CSVLinkRef}
              />
            </div>
          </header>

          <div className="d-flex w-100 gap-3 justify-content-end px-3">
            {!queryParams?.consolidate && (
              <>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`${backendUrl}/api/reports/pdf/income-statement?${queryString.stringify(
                    rest
                  )}`}
                  className="btn print income-statement-excel"
                >
                  PDF <PDFIcon color="#ff0000" />
                </a>{" "}
              </>
            )}
            <button className="btn print" onClick={onDownloadExcelData}>
              Excel <ExcelIcon color="#008000" />
            </button>
          </div>

          <div className="px-4 my-4">
            {/* REVENUE */}
            <div>
              <div className="fw-bold p-2 mb-2 bg-low-blue text-white ">
                <div className="container px-5">
                  <p className="">REVENUE</p>
                </div>
              </div>
              <div>
                <div className="container px-5">
                  <div className="d-flex justify-content-between">
                    <p className="fs-6 mb-1">
                      <Link
                        to={`/account-setup/chart-of-account-report?detailTypeFor=salesRevenue&startDate=${
                          data.start
                        }&endDate=${data.end}&currency=${
                          filterParams.currency
                        }&convertCurrency=${
                          filterParams.convertCurrency
                        }&totalValue=${data?.salesRevenue}`}
                      >
                        Sales Revenue
                      </Link>
                    </p>
                    <p className="fs-6 mb-1">
                      <Link
                        to={`/account-setup/chart-of-account-report?detailTypeFor=salesRevenue&startDate=${
                          data.start
                        }&endDate=${data.end}&currency=${
                          filterParams.currency
                        }&convertCurrency=${
                          filterParams.convertCurrency
                        }&totalValue=${data?.salesRevenue}`}
                      >
                        {currency(data?.salesRevenue, {
                          symbol: getCurrencySymbol(
                            filterParams.convertCurrency
                          ),
                          precision: 2,
                        }).format()}{" "}
                      </Link>
                    </p>
                  </div>

                  <div className="d-flex justify-content-between">
                    <p className="fs-6 mb-1">
                      <Link
                        to={`/account-setup/chart-of-account-report?detailTypeFor=serviceFeeIncome&startDate=${
                          data.start
                        }&endDate=${data.end}&currency=${
                          filterParams.currency
                        }&convertCurrency=${
                          filterParams.convertCurrency
                        }&totalValue=${data.serviceFeeIncome}`}
                      >
                        Service / Fee Income
                      </Link>
                    </p>
                    <p className="fs-6 mb-1">
                      <Link
                        to={`/account-setup/chart-of-account-report?detailTypeFor=serviceFeeIncome&startDate=${
                          data.start
                        }&endDate=${data.end}&currency=${
                          filterParams.currency
                        }&convertCurrency=${
                          filterParams.convertCurrency
                        }&totalValue=${data.serviceFeeIncome}`}
                      >
                        {currency(data.serviceFeeIncome, {
                          symbol: getCurrencySymbol(
                            filterParams.convertCurrency
                          ),
                          precision: 2,
                        }).format()}{" "}
                      </Link>
                    </p>
                  </div>

                  <div className="d-flex justify-content-between">
                    <p className="fs-6 mb-1">
                      <Link
                        to={`/account-setup/chart-of-account-report?detailTypeFor=otherIncome&startDate=${
                          data.start
                        }&endDate=${data.end}&currency=${
                          filterParams.currency
                        }&convertCurrency=${
                          filterParams.convertCurrency
                        }&totalValue=${data?.otherIncome}`}
                      >
                        Other Income
                      </Link>
                    </p>
                    <p className="fs-6 mb-1">
                      <Link
                        to={`/account-setup/chart-of-account-report?detailTypeFor=otherIncome&startDate=${
                          data.start
                        }&endDate=${data.end}&currency=${
                          filterParams.currency
                        }&convertCurrency=${
                          filterParams.convertCurrency
                        }&totalValue=${data?.otherIncome}`}
                      >
                        {currency(data?.otherIncome, {
                          symbol: getCurrencySymbol(
                            filterParams.convertCurrency
                          ),
                          precision: 2,
                        }).format()}
                      </Link>
                    </p>
                  </div>

                  <div>
                    <div className="d-flex justify-content-between">
                      <p className="fs-6 mb-1">
                        <Link
                          to={`/account-setup/chart-of-account-report?detailTypeFor=costOfgoodsold&startDate=${
                            data.start
                          }&endDate=${data.end}&currency=${
                            filterParams.currency
                          }&convertCurrency=${
                            filterParams.convertCurrency
                          }&totalValue=${data?.costOfgoodsold}`}
                        >
                          Cost of Sales
                        </Link>
                      </p>
                      <p className="fs-6 mb-1">
                        <Link
                          to={`/account-setup/chart-of-account-report?detailTypeFor=costOfgoodsold&startDate=${
                            data.start
                          }&endDate=${data.end}&currency=${
                            filterParams.currency
                          }&convertCurrency=${
                            filterParams.convertCurrency
                          }&totalValue=${data?.costOfgoodsold}`}
                        >
                          {currency(data?.costOfgoodsold, {
                            symbol: getCurrencySymbol(
                              filterParams.convertCurrency
                            ),
                            precision: 2,
                          }).format()}
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="fw-bold p-1 mt-3 bg-primary text-white">
                <div className="d-flex justify-content-between align-items-center container px-5">
                  <p className="fs-6 my-2">Gross Profit</p>
                  <p className="fs-6 my-2">
                    {currency(data?.grossProfit, {
                      symbol: getCurrencySymbol(filterParams.convertCurrency),
                      precision: 2,
                    }).format()}
                  </p>
                </div>
              </div>
            </div>

            {/* Administrative Expenses */}
            <div className="mt-5">
              <div className="fw-bold p-2 mb-2 bg-low-blue text-white ">
                <div className="container px-5">
                  <p>Administrative Expenses</p>
                </div>
              </div>
              <div>
                <div className="container px-5">
                  {data?.administrativeExpenses &&
                    Object.entries(data.administrativeExpenses).map((a, i) => (
                      <div className="d-flex justify-content-between" key={i}>
                        <p className="fs-6 mb-1">
                          <Link
                            to={`/account-setup/account-list?AccountID=${
                              data?.accountIdsForReport?.[a[0]]
                            }&Description=${encodeURIComponent(
                              a[0]
                            )}&currency=${
                              filterParams.currency
                            }&convertCurrency=${filterParams.convertCurrency}`}
                          >
                            {a[0]}
                          </Link>
                        </p>
                        <p className="fs-6 mb-1">
                          <Link
                            to={`/account-setup/account-list?AccountID=${
                              data?.accountIdsForReport?.[a[0]]
                            }&Description=${encodeURIComponent(
                              a[0]
                            )}&startDate=${data.start}&endDate=${
                              data.end
                            }&currency=${
                              filterParams.currency
                            }&convertCurrency=${filterParams.convertCurrency}`}
                          >
                            {currency(a[1], {
                              symbol: getCurrencySymbol(
                                filterParams.convertCurrency
                              ),
                            }).format()}{" "}
                          </Link>
                        </p>
                      </div>
                    ))}
                </div>
              </div>
              <div className="fw-bold p-1 mt-3 bg-primary text-white">
                <div className="d-flex justify-content-between container px-5 align-items-center">
                  <p className="fs-6">Total Expenses</p>
                  <p className="fs-6 my-2">
                    {currency(data?.administrativeExpensesTotal, {
                      symbol: getCurrencySymbol(filterParams.convertCurrency),
                    }).format()}
                  </p>
                </div>
              </div>
            </div>

            <div className="fw-bold p-1 mt-3 bg-primary text-white">
              <div className="d-flex justify-content-between container px-5 align-items-center">
                <p className="fs-6">Net Income</p>
                <p className="fs-6 my-2">
                  {currency(data?.netIncome, {
                    symbol: getCurrencySymbol(filterParams.convertCurrency),
                  }).format()}
                </p>
              </div>
            </div>
          </div>
        </div>
        <ModalLoader
          show={isFetching || isfetchingExcel}
          {...(queryParams.consolidate ? { title: "Consolidating..." } : {})}
        />
      </main>
    </IsPrivileged>
  );
}

export default IncomeStatement;
