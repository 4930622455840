import { Modal, Form, Button, Row, Nav } from "react-bootstrap";
import * as yup from "yup";
import { useFormik } from "formik";
import { useState } from "react";
import { confirmable, createConfirmation } from "react-confirm";
import {
  useBackendUrl,
  useCustomerBackendUrl,
  useEffectOnce,
  useScrollTop,
} from "../../utils/hooks";
import moment from "moment";
import DateTime from "react-datetime";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";

export default function ContentPage({}) {
  useScrollTop();
  const backendUrl = useCustomerBackendUrl();
  const location = useLocation();
  const [content, setContent] = useState("");

  const fetchSetUpData = async () => {
    let response = await fetch(`${backendUrl}/api/company/get-content-page`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();

    if (data?.pages) {
      setContent(
        location.pathname.includes("terms-and-conditions")
          ? data.pages?.find((el) => el.title === "Terms and Conditions")
              ?.content
          : data.pages?.find((el) => el.title === "Return Policy")?.content
      );
    }

    // -------
    return data;
  };

  const { isFetching } = useQuery(
    ["CONTENT_PAGE_SETUP"],
    () => fetchSetUpData(),
    {
      keepPreviousData: false,
    }
  );

  return (
    <main className="store-main">
      <div
        className="bg-white p-3 p-md-5 editor-content rounded"
        style={{ whiteSpace: "pre-line", minHeight: "100vh" }}
        dangerouslySetInnerHTML={{ __html: content }}
      />
    </main>
  );
}
