import { Dropdown, Table, Form, Button, InputGroup } from "react-bootstrap";
import { CSVLink } from "react-csv";
import DetailedIcon, {
  ExportIcon,
  FilterIcon,
  FilterTwoIcon,
  BusinessBillIcon,
  PrintIcon,
  SummaryChartIconUp,
  SummaryChartIconDown,
  SummaryCloseIcon,
  ChartPieSliceTitleIcon,
  FilterCollapseIcon,
  ExchangeFunds,
  ExcelIcon,
  PDFIcon,
} from "../Icons";
import "./../../assets/scss/reports/cashbook.scss";
import { useEffect, useRef, useState } from "react";
import CachedIcon from "mdi-react/CachedIcon";
import Select from "react-select";
import useDebounce, {
  useLocalStorage,
  useQueryParams,
  useScrollTop,
  useSingleAndDoubleClick,
} from "../../utils/hooks";
import { services } from "../../config";
import { reportActions } from "../../utils/reactQueryActions";
import { useQuery } from "react-query";
import currency from "currency.js";
import { format, parse } from "date-fns";
import ReactPaginate from "react-paginate";
import {
  customerFullName,
  defaultSelectValue,
  paginationOptions,
  qtyFormat,
  qtyFormatToString,
  reactSelectTheme,
  scrollToTop,
} from "../../utils/helpers";
import RsDateRangePicker from "./../utils/RsDateRangePicker";
import queryString from "query-string";
import { Link, useNavigate } from "react-router-dom";
import { useStoreActions, useStoreState } from "easy-peasy";
import { Popover } from "react-tiny-popover";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import { useAuth } from "../../hooks/useAuth";
import PageHeader from "../PageHeader";
import ImprestTransferModal from "../SalesAndInvoicing/ImprestTransferModal";
import AddAccounts from "../modals/AddAccounts";
import { useDownloadExcel } from "../../hooks/useDownloadExcel";
import { IsPrivileged } from "../DisplayChildElement";

export default function ChartOfAccountImprest() {
  useScrollTop();
  const navigate = useNavigate();

  const initialFilterParams = {
    usage: "chart of accounts",
    calculateRunningBalance: true,
    Description: "",
    DetailType: "IMPREST",
  };

  const [showCreateNewAccountModal, setShowCreateNewAccountModal] = useState(
    false
  );
  const [showItemsPopover, setShowItemsPopover] = useState(false);
  const itemMeasurements = useStoreState((state) => state.itemMeasurements);
  const { backendUrl, isIronRod, isCement } = useAuth();
  const [showFilter, setShowFilter] = useLocalStorage("showFilter", true);
  const [showImprestTransferModal, setShowImprestTransferModal] = useState(
    false
  );
  const [queryParams, setQueryParams] = useQueryParams({
    page: 1,
    limit: 40,
    ...initialFilterParams,
  });

  const [filterParams, setFilterParams] = useState({
    ...initialFilterParams,
    ...queryParams,
  });

  const [excelData, setExcelData] = useState([]);

  const CSVLinkRef = useRef(null);

  // fetch excel hook
  const [isfetchingExcel, fetchExcelData] = useDownloadExcel(
    excelData,
    CSVLinkRef
  );

  const debouncedFilterParams = useDebounce(filterParams, 500);
  useEffect(() => {
    setQueryParams({ ...queryParams, ...debouncedFilterParams });
  }, [debouncedFilterParams]);

  const getExpenses = async (debouncedqueryParams) => {
    // await waitFor(5000);
    let response = await fetch(
      `${backendUrl}/api/journal?${queryString.stringify(
        debouncedqueryParams
      )}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    /*  data.customers = data.customers.map((el) => ({
      ...el,
      label: customerFullName(el),
      value: el.Cust_ID,
    })); */
    return data;
  };

  const { data = { count: 0, journal: [] }, refetch } = useQuery(
    [reportActions.SALES_INVENTORY, queryParams],
    () => getExpenses(queryParams),
    {
      keepPreviousData: true,
    }
  );

  const handleFilterParamsChange = (e) => {
    setFilterParams({
      ...filterParams,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  const handleSearchQueryChange = (e) => {
    setQueryParams({
      ...queryParams,
      [e.target.name]: e.target.value,
    });
  };

  const filterByDateRange = (date) => {
    setFilterParams({
      ...filterParams,
      startDate: format(date[0], "yyyy-MM-dd"),
      endDate: format(date[1], "yyyy-MM-dd"),
    });
  };

  const clearDateRange = () => {
    setFilterParams({
      ...filterParams,
      startDate: "",
      endDate: "",
    });
  };

  const search = () => {
    setQueryParams({
      ...queryParams,
      ...filterParams,
    });
  };

  const reset = () => {
    setFilterParams(initialFilterParams);
    setQueryParams({
      ...queryParams,
      ...initialFilterParams,
    });
  };

  const handleSelectedItem = (item) => {
    setFilterParams({
      ...filterParams,
      itemId: item.Bar_Code,
      itemName: item.Item_Name,
    });
    setShowItemsPopover(false);
  };

  const openList = (e, el) => {
    if (e.detail === 2) {
      navigate(
        `/account-setup/account-list?AccountID=${el?.AccountID}&Description=${
          el?.Description
        }`
      );
    }
  };

  // download excel function
  const { limit, page, ...rest } = queryParams;
  const onDownloadExcelData = async () => {
    let exData = await fetchExcelData(
      `${backendUrl}/api/journal?${queryString.stringify(rest)}`,
      "GET"
    );

    const company = exData.data?.company?.CompName;

    const journal = exData?.data?.journal.map((el, i) => [
      el.AccountID,
      el.Description,
      el.Type,
      el.DetailType || "...",
      currency(el?.RunningBalanceCalculated, {
        symbol: "",
      }).format(),
    ]);

    const date =
      rest.startDate && rest.endDate
        ? `Date Prepared: Between ${format(
            new Date(rest.startDate),
            "E MMM d yyyy k:mm:ss z"
          )} to ${format(new Date(rest.endDate), "E MMM d yyyy k:mm:ss z")}`
        : "";

    exData = [
      [company],
      ["Impress Report"],
      [date],
      [""],
      ["Account ID", "Description", "Account Type", "Detail Type", "Balance"],
      ...journal,
      [""],
    ];

    console.log(exData);
    setExcelData(exData);
  };

  // isprivileged
  return (
    <IsPrivileged roleName="Imprest Account">
      <>
        <main className="cash-book">
          <div className="content">
            <div className="content-main">
              <div className="content-body">
                <header>
                  <div className="d-flex gap-3 flex-grow-1">
                    <h1>
                      {!showFilter && (
                        <button
                          onClick={() => setShowFilter(!showFilter)}
                          className="btn filter"
                        >
                          <FilterTwoIcon />
                        </button>
                      )}
                      Imprest Accounts
                      <button
                        onClick={() => refetch()}
                        className="btn text-primary"
                      >
                        <CachedIcon />
                      </button>
                    </h1>

                    <div className="global-search-area col-md-3">
                      <MagnifyIcon />
                      <Form.Control
                        className=""
                        name="Description"
                        value={filterParams.Description}
                        onChange={(e) => handleFilterParamsChange(e)}
                        placeholder="Search Description..."
                      />
                    </div>
                  </div>
                  <div className="actions mr-5">
                    <CSVLink
                      className="btn print d-none"
                      filename={`Impress Report(${format(
                        new Date(),
                        "dd-MMM-yyyy hh:mm:ss a"
                      )}).csv`}
                      data={excelData}
                      ref={CSVLinkRef}
                    />

                    {/* <button /> */}
                    <Dropdown>
                      <Dropdown.Toggle
                        variant=""
                        className="btn print"
                        disabled={isfetchingExcel}
                        bsPrefix=""
                      >
                        Export
                        <ExportIcon color="#008000" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu
                        popperConfig={{
                          strategy: "fixed",
                        }}
                        renderOnMount
                        className="text-center"
                      >
                        <Dropdown.Item
                          as="div"
                          onClick={onDownloadExcelData}
                          className="p-cursor"
                        >
                          Excel <ExcelIcon color="#008000" />
                        </Dropdown.Item>
                        <Dropdown.Item as="div">
                          <a
                            href={`${backendUrl}/api/journal/pdf?${queryString.stringify(
                              rest
                            )}`}
                            target="blank"
                          >
                            PDF
                            <PDFIcon color="#ff0000" />
                          </a>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>

                    <button
                      onClick={() => setShowImprestTransferModal(true)}
                      className="btn print"
                    >
                      Make Imprest Transfer
                    </button>

                    <button
                      onClick={() => setShowCreateNewAccountModal(true)}
                      className="btn btn-primary"
                    >
                      Create New Account
                    </button>
                  </div>
                </header>

                <div className="px-4">
                  <Table
                    responsive
                    borderless
                    striped
                    hover
                    className="product-table text-nowrap"
                  >
                    <thead>
                      <tr>
                        <th scope="col">Account ID</th>
                        <th scope="col">Description</th>
                        <th scope="col">Account Type</th>
                        <th scope="col">Detail Type</th>
                        <th scope="col">Balance</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.journal?.map((el, index) => (
                        <tr
                          key={index}
                          className="p-cursor"
                          onClick={(e) => openList(e, el)}
                        >
                          <td>{el.AccountID}</td>
                          <td> {el.Description} </td>
                          <td>{el.Type}</td>
                          <td>{el.DetailType || "..."}</td>
                          <td>
                            {currency(el?.RunningBalanceCalculated, {
                              symbol: "",
                            }).format()}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>

                <div className="d-flex justify-content-between px-3 align-items-center pagination">
                  {/*<p className="m-0">
                Showing {data?.startIndex + 1} to{" "}
                {data?.endIndex <= data?.count ? data?.endIndex : data?.count}{" "}
                of {data.count} entries
                  </p> */}
                  <div className="pagination_left">
                    <p className="m-0 p-0">Show</p>
                    <select
                      value={queryParams.limit}
                      name="limit"
                      className="form-select "
                      onChange={(e) => handleSearchQueryChange(e)}
                    >
                      <option value="10">10 rows</option>
                      <option value="20">20 rows</option>
                      <option value="30">30 rows</option>
                      <option value="40">40 rows</option>
                      <option value="50">50 rows</option>
                      <option value="100">100 rows</option>
                    </select>
                  </div>

                  <ReactPaginate
                    {...paginationOptions}
                    pageCount={Math.ceil(data.count / queryParams.limit)}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={0}
                    onPageChange={({ selected }) => {
                      scrollToTop();
                      setQueryParams({
                        ...queryParams,
                        page: selected + 1,
                      });
                    }}
                    forcePage={queryParams.page - 1}
                  />
                </div>
              </div>
            </div>
          </div>
        </main>

        {showImprestTransferModal && (
          <ImprestTransferModal
            setShowImprestTransferModal={setShowImprestTransferModal}
            refetch={() => refetch()}
          />
        )}

        {showCreateNewAccountModal && (
          <AddAccounts
            show={showCreateNewAccountModal}
            onHide={() => setShowCreateNewAccountModal(false)}
            refetch={() => refetch()}
            usage="imprest"
          />
        )}
      </>
    </IsPrivileged>
  );
}
