import React from "react";

import { useStoreState } from "easy-peasy";
import { useIsAdmin } from "../utils/hooks";

export default function PrivilegedAction({ children, eachRole }) {
  const savedStaffRoles = useStoreState((state) => state.savedStaffRoles);
  const adminRootRoles = useStoreState((state) => state.rootRoles);
  const isAdmin = useIsAdmin();
  let privilege = isAdmin ? adminRootRoles : savedStaffRoles;

  const findRole = privilege.find((role) => {
    return (
      role.name.toLowerCase() === eachRole.name.toLowerCase() &&
      role.parent.toLowerCase() === eachRole.parent.toLowerCase() &&
      role.checked
    );
  });
  if (findRole) {
    return children;
  }
}
