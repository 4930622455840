import { Outlet } from "react-router-dom";

export default function HomeLayout() {
  return (
    <div>
      <main>
        <Outlet />
      </main>
      <footer />
    </div>
  );
}
