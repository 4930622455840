import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useAuth } from "../../hooks/useAuth";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useEffect, useMemo, useState } from "react";
import useDebounce from "../../utils/hooks";
import { useQuery } from "react-query";
import queryString from "query-string";
import { truncate } from "lodash";
import Select from "react-select";
import { formatDate } from "../../utils/helpers";
import { isEmpty } from "lodash";
import { format, parse } from "date-fns";
import RsDateRangePicker from "../utils/RsDateRangePicker";
import { Form } from "react-bootstrap";
import ChartBarIcon from "mdi-react/ChartBarIcon";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const selectOptions = [
  {
    label: "Quantity",
    value: "QTY",
  },
  {
    label: "Profitability",
    value: "Profit",
  },
  {
    label: "Revenue",
    value: "SubTotal",
  },
];

export function SerialNumberInAndOutChart({
  showSerialNumberInAndOutChart,
  setShowSerialNumberInAndOutChart,
  filterParams,
  clearDateRange,
  filterByDateRange,
  chartData = [],
}) {
  return (
    <Modal
      show={showSerialNumberInAndOutChart}
      onHide={() => setShowSerialNumberInAndOutChart(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="h5">
          <ChartBarIcon /> Inbound/Outbound Inventory
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex justify-content-between gap-2">
          <div>
            {" "}
            <Form.Group className="mb-2-5 d-flex align-items-center gap-2">
              <div className="position-relative">
                <RsDateRangePicker
                  placement="auto"
                  value={
                    filterParams.startDate && filterParams.endDate
                      ? [
                          parse(
                            filterParams.startDate,
                            "yyyy-MM-dd",
                            new Date()
                          ),
                          parse(filterParams.endDate, "yyyy-MM-dd", new Date()),
                        ]
                      : []
                  }
                  onClean={() => clearDateRange()}
                  onOk={(date) => filterByDateRange(date)}
                  cleanable={false}
                />
              </div>
            </Form.Group>
          </div>
        </div>
        <Bar
          options={{
            //  responsive: true,
            plugins: {
              legend: {
                position: "bottom",
                labels: {
                  fontColor: "#94A3B8",
                  fontSize: 12,
                  boxHeight: 8,
                  boxWidth: 8,
                  pointStyle: "circle",
                  usePointStyle: true,
                },
              },
              title: {
                display: false,
              },
              tooltip: {
                backgroundColor: "#00000080",
                boxHeight: 8,
                boxWidth: 8,
                pointStyle: "circle",
                usePointStyle: true,
                boxPadding: 8,
              },
            },

            scales: {
              y: {
                grid: {
                  color: "#F4F7FA",
                },
                ticks: {
                  color: "#94A3B8",
                  stepSize: 100000000,
                  fontSize: 12,
                },
              },
              x: {
                grid: {
                  color: "#fff",
                },
                ticks: {
                  color: "#94A3B8",
                  fontSize: 12,
                },
              },
            },
          }}
          data={{
            labels: chartData.map(
              (el) =>
                `${
                  el?.actionDate
                    ? formatDate(el?.actionDate, "dd MMM, yyyy")
                    : "..."
                } - ${el.Item_Name}`
            ),
            datasets: [
              {
                label: "Qty Inbound",
                data: chartData.map((el) => el.qtyInbound),
                backgroundColor: "#2463AE",
                borderRadius: 100,
                barThickness: 16,
              },
              {
                label: "Qty Outbound",
                data: chartData.map((el) => el.qtyOutbound),
                backgroundColor: "#93C5FD",
                borderRadius: 100,
                barThickness: 16,
              },
            ],
          }}
        />
      </Modal.Body>
    </Modal>
  );
}
