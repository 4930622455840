import PageHeader from "./PageHeader";
import { Dropdown, Table, Form, Button, InputGroup } from "react-bootstrap";
import DetailedIcon, {
  ExportIcon,
  FilterIcon,
  FilterTwoIcon,
  BusinessBillIcon,
  PrintIcon,
  SummaryChartIconUp,
  SummaryChartIconDown,
  SummaryCloseIcon,
  ChartPieSliceTitleIcon,
  FilterCollapseIcon,
  ExchangeFunds,
  BookIcon,
  PageHeaderListIcon,
  ChartPieSliceIcon,
  LockIcon,
  DropdownEyeIcon,
} from "./Icons";
import "./../assets/scss/reports/cashbook.scss";
import { forwardRef, useEffect, useRef, useState } from "react";
import CachedIcon from "mdi-react/CachedIcon";
import Select from "react-select";
import useDebounce, {
  useEffectOnce,
  useIsAdmin,
  useIsSupplyAndLogistics,
  useLocalStorage,
  useQueryParams,
  useScrollTop,
} from "../utils/hooks";
import { services, supplyOptions, supplyOptionsParams } from "../config";
import { reportActions } from "../utils/reactQueryActions";
import { useMutation, useQuery, useQueryClient } from "react-query";
import currency from "currency.js";
import { format, parse } from "date-fns";
import ReactPaginate from "react-paginate";
import {
  customerFullName,
  defaultSelectValue,
  fetchActionsUtil,
  formatForQty,
  paginationOptions,
  qtyFormat,
  qtyFormatToString,
  reactSelectTheme,
  tonsToPcs,
} from "../utils/helpers";
import RsDateRangePicker from "./utils/RsDateRangePicker";
import queryString from "query-string";
import { useAuth } from "../hooks/useAuth";
import CurrencyInput from "react-currency-input-field";
import NumberCustomInput from "./utils/NumberCustomInput";
import { toast } from "react-toastify";
import { startOfMonth } from "date-fns";
import { useStoreActions, useStoreState } from "easy-peasy";
import ModalLoader from "./utils/ModalLoader";
import { useFormik } from "formik";
import * as yup from "yup";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import TransactionsTable from "./utils/TransactionsTable";
import { Popover } from "react-tiny-popover";
import { isEmpty, reject } from "lodash";
import NoTableItem from "./utils/NoTableItem";
import { useMemo } from "react";
import { createRef } from "react";
import { useImperativeHandle } from "react";
import ConfirmDialog from "./ConfirmDialogue";
import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";
import SalesHistoryModal from "./modals/SalesHistoryModal";
import WayBillDatesModal from "./modals/WayBillDates";
import DriverInfoDialog from "./Production/DriverInfoDialog";
import { IsPrivileged } from "./DisplayChildElement";
import RecalculateProfitDialog from "./RecalulateProfitDialog";

// const sortBy = [
//   {
//     value: "Bar_Code",
//     label: "Item ID",
//   },
//   {
//     value: "Item_Name",
//     label: "Item Name",
//   },
//   {
//     value: "Product_Name",
//     label: "Product",
//   },

//   {
//     value: "Cat_Name",
//     label: "Category",
//   },
// ];
const UpdateBatchTonsInline = forwardRef(
  (
    { getDataForEdit, desc, itemMeasurements, item, handleChangeQuantity },
    _ref
  ) => {
    const initialValues = {
      Tons: "",
      Pieces: "",
      formatType: "",
    };

    const formik = useFormik({
      initialValues,

      onSubmit: async (values) => {
        try {
          const tons =
            values?.Tons && values.formatType !== "default"
              ? tonsToPcs(values?.Tons, desc, itemMeasurements)
              : 0;

          let Quantity = Number(
            currency(tons, { symbol: "", separator: "" })
              .add(values.Pieces)
              .format()
          );
          handleChangeQuantity(item, Quantity);
        } catch (err) {
          console.log(err);
        }
      },
    });

    const quantityToPcs = (values) => {
      const tons =
        values?.Tons && values.formatType !== "default"
          ? tonsToPcs(values?.Tons, desc, itemMeasurements)
          : 0;

      let Quantity = Number(
        currency(tons, { symbol: "", separator: "" })
          .add(values.Pieces)
          .format()
      );
      return Quantity;
    };

    const setUp = () => {
      const { Tons, Pieces, formatType } = getDataForEdit();
      //console.log(Tons, Pieces, formatType);

      formik.setValues({
        Tons,
        Pieces,
        formatType,
      });
    };

    useEffectOnce(() => {
      setUp();
    });

    useImperativeHandle(_ref, () => ({
      getQuantity: () => quantityToPcs(formik.values),
    }));

    return (
      <div
        id={item.ID}
        /* noValidate */
        className="d-flex gap-2"
        /*  onSubmit={formik.handleSubmit} */
      >
        {formik.values.formatType !== "normal" ? (
          <>
            <Form.Group className="mb-3">
              <Form.Label>Tons</Form.Label>
              <NumberCustomInput
                type="text"
                placeholder=""
                name="Tons"
                id={`${item.ID}-Tons`}
                value={formik.values.Tons}
                onValueChange={(value, name) => {
                  formik.setFieldValue(name, value);
                }}
                onKeyDown={(e) => e.keyCode === 13 && formik.submitForm()}
                style={{ width: "5rem" }}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Pieces</Form.Label>
              <NumberCustomInput
                type="text"
                placeholder=""
                name="Pieces"
                id={`${item.ID}-Pieces`}
                value={formik.values.Pieces}
                onValueChange={(value, name) => {
                  formik.setFieldValue(name, value);
                }}
                onKeyDown={(e) => e.keyCode === 13 && formik.submitForm()}
                style={{ width: "5rem" }}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.Pieces}
              </Form.Control.Feedback>
            </Form.Group>
          </>
        ) : (
          <Form.Group className="mb-3">
            <Form.Label>Quantity</Form.Label>
            <NumberCustomInput
              type="text"
              placeholder=""
              name="Pieces"
              value={formik.values.Pieces}
              onValueChange={(value, name) => {
                formik.setFieldValue(name, value);
              }}
              onKeyDown={(e) => e.keyCode === 13 && formik.submitForm()}
              isInvalid={formik.touched.Pieces && !!formik.errors.Pieces}
              style={{ width: "5rem" }}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.Pieces}
            </Form.Control.Feedback>
          </Form.Group>
        )}
      </div>
    );
  }
);

export default function SupplyCenter() {
  useScrollTop();

  const initialFilterParams = {
    startDate: "" /* format(startOfMonth(new Date()), "yyyy-MM-dd") */,
    endDate: "" /* format(new Date(), "yyyy-MM-dd"), */,
    status: "Not Supplied & Partial Supply",
  };
  const queryClient = useQueryClient();
  const [isLocking, setIsLocking] = useState(false);
  const [showTransactionPopover, setShowTransactionPopover] = useState(false);
  const itemMeasurements = useStoreState((state) => state.itemMeasurements);
  const { backendUrl, isIronRod } = useAuth();
  const [showFilter, setShowFilter] = useLocalStorage("showFilter", true);
  const isAdmin = useIsAdmin();
  const isSupplyAndLogistics = useIsSupplyAndLogistics();
  const generalSettings = useStoreState((state) => state.generalSettings);

  const [showSalesHistory, setshowSalesHistory] = useState({
    show: false,
    sales: {},
  });

  const [showWayBill, setshowWayBill] = useState({
    show: false,
    salesID: "",
  });

  const [queryParams, setQueryParams] = useQueryParams({
    page: 1,
    limit: 10,
    ...initialFilterParams,
  });

  const [filterParams, setFilterParams] = useState({
    ...initialFilterParams,
    ...queryParams,
  });

  const debouncedFilterParams = useDebounce(filterParams, 500);
  useEffect(() => {
    setQueryParams({ ...queryParams, ...debouncedFilterParams, page: 1 });
  }, [debouncedFilterParams]);

  const fetchSupply = async (queryParams) => {
    // await waitFor(5000);
    let response = await fetch(
      `${backendUrl}/api/items/supply?&${queryString.stringify(queryParams)}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    //  Remove  Duplicates
    if (data.supply) {
      data.supply = Array.from(
        new Set(data.supply.map((obj) => JSON.stringify(obj)))
      ).map((str) => JSON.parse(str));
    }
    return data;
  };

  const { data = { count: 0, supply: [] }, refetch, isFetching } = useQuery(
    ["SUPPLY", queryParams],
    () => fetchSupply(queryParams),
    {
      keepPreviousData: true,
    }
  );

  const refsById = useMemo(() => {
    const refs = {};
    for (let el of data?.supply) {
      el.items.forEach((sale) => {
        refs[sale.ID] = createRef(null);
      });
    }

    return refs;
  }, [data?.supply]);

  // const handleFilterParamsChange = (e) => {
  //   setFilterParams({
  //     ...filterParams,
  //     [e.target.name]:
  //       e.target.type === "checkbox" ? e.target.checked : e.target.value,
  //   });
  // };

  const handleSearchQueryChange = (e) => {
    setQueryParams({
      ...queryParams,
      [e.target.name]: e.target.value,
    });
  };

  const filterByDateRange = (date) => {
    setQueryParams({
      ...queryParams,
      startDate: format(date[0], "yyyy-MM-dd"),
      endDate: format(date[1], "yyyy-MM-dd"),
    });
  };

  const clearDateRange = () => {
    setQueryParams({
      ...queryParams,
      startDate: "",
      endDate: "",
    });
  };

  const recalculateAction = async ({ items, payload }) => {
    const dialogAction = await RecalculateProfitDialog({
      title: "Re-calculate Profit / Incentive with another Batch",
      description:
        "Not enough stock in batch that the Invoice was raised with, Items will be supplied from another batch",
      items,
    });

    if (dialogAction) {
      updateSupplyMutation.mutate({ ...payload, recalculate: true });
    }
  };

  const updateSupply = async (payload) => {
    let response = await fetch(`${backendUrl}/api/items/sale-update`, {
      method: "POST",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        ...payload,
      }),
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const { data } = await response.json();
    return data;
  };
  const updateSupplyMutation = useMutation((payload) => updateSupply(payload), {
    onSuccess: (
      {
        updatedItem,
        updatedSaleItems = [],
        TransactionID,
        itemsThatWillBeRecalculated = [],
      },
      payload
    ) => {
      //
      if (!isEmpty(itemsThatWillBeRecalculated)) {
        recalculateAction({
          items: itemsThatWillBeRecalculated,
          payload,
        });
        return;
      }

      queryClient.setQueryData(["SUPPLY", queryParams], (data) => {
        // For Multiple
        if (!isEmpty(updatedSaleItems)) {
          const index = data.supply.findIndex(
            (el) => el.TransactionID === TransactionID
          );
          data.supply[index].items = [...updatedSaleItems];
          return data;
        }

        //  For One Default
        const index = data.supply.findIndex(
          (el) => el.TransactionID === updatedItem.TransactionID
        );
        data.supply[index].items = data.supply[index].items.map((el) =>
          el.ID === updatedItem.ID ? { ...updatedItem } : { ...el }
        );
        return data;
      });
      toast.success(`Success: ${updatedItem?.Shipping_Status || ""}`);
    },
    onError: async ({ message }) => {
      toast.error(`Unable to perform action: ${message}`);

      /*  if (message === "Failed to fetch") {
        await ConfirmDialog({
          title: "Request took too long, check your connection",
          description: "Refresh Records",
        });

        refetch();
      } */
    },
  });

  const handleChangeShippingStatus = async (sale, Shipping_Status) => {
    if (Shipping_Status === "Supplied") {
      const quantityEntered = refsById[sale.ID].current.getQuantity();
      handleChangeQuantity(sale, quantityEntered);

      return;
    }
    updateSupplyMutation.mutate({
      editedSale: sale,
      Shipping_Status,
    });
  };

  const supplyAll = async ({ TransactionID, driverDetails }) => {
    try {
      const saleItems = data.supply.find(
        (el) => el.TransactionID === TransactionID
      ).items;

      const dataToSupply = await Promise.all(
        saleItems.map(
          (sale) =>
            new Promise((resolve, reject) => {
              try {
                let Quantity = refsById[sale.ID].current.getQuantity();
                Quantity = currency(Quantity, {
                  symbol: "",
                  separator: "",
                }).value;

                const balanceLeftToShip = currency(sale.QTY, {
                  symbol: "",
                  separator: "",
                  precision: 2,
                  format: formatForQty,
                })
                  .subtract(sale.QTY_Shipped)
                  .format();

                if (Number(Quantity) > Number(balanceLeftToShip)) {
                  return reject(
                    `${sale.Bar_Code}: ${
                      sale.Item_Name
                    } Quantity to supply cannot be higher than balance`
                  );
                }

                const statusToUpdate =
                  Number(Quantity) === Number(balanceLeftToShip)
                    ? "Supplied"
                    : "Partial Supply";

                resolve({
                  editedSale: sale,
                  Quantity,
                  statusToUpdate,
                  driverDetails,
                });
              } catch (err) {
                console.log(err);
                toast.error(err);
                reject(err);
              }
            })
        )
      );

      updateSupplyMutation.mutate({
        salesItems: dataToSupply.filter((el) => el.Quantity),
        TransactionID,
        supplyAll: true,
      });
    } catch (err) {
      console.log("error", err);
      toast.error(err);
    }
  };

  const handleChangeQuantity = async (sale, Quantity) => {
    Quantity = currency(Quantity, { symbol: "", separator: "" }).format();

    if (Number(Quantity) <= 0) {
      return toast.error("Please enter a valid balance to supply");
    }

    const balanceLeftToShip = currency(sale.QTY, {
      symbol: "",
      separator: "",
      precision: 2,
      format: formatForQty,
    })
      .subtract(sale.QTY_Shipped)
      .format();

    if (Number(Quantity) > Number(balanceLeftToShip)) {
      /*  const readableBalance = isIronRod
        ? qtyFormatToString(
            qtyFormat(
              Number(balanceLeftToShip),
              sale.Serial_Number,
              itemMeasurements
            )
          )
        : balanceLeftToShip; */
      return toast.error("Quantity to supply cannot be higher than balance");

      /*  return toast.error(`You have ${balanceLeftToShip} left to Ship`); */
    }

    const statusToUpdate =
      Number(Quantity) === Number(balanceLeftToShip)
        ? "Supplied"
        : "Partial Supply";

    if (
      await ConfirmDialog({
        title: statusToUpdate,
        description: "Are you sure you want to supply this item ?",
      })
    ) {
      updateSupplyMutation.mutate({
        editedSale: sale,
        Quantity,
        statusToUpdate,
      });
    }
  };

  const getDataForEdit = ({ qty, desc, itemMeasurements = [] }) => {
    try {
      qty = qty <= 0 ? 0 : qty;
      const tonsAndPcs = qtyFormat(qty, desc, itemMeasurements);
      const Tons = tonsAndPcs.split("-")[0];
      const Pieces = tonsAndPcs.split("-")[1];
      const formatType = tonsAndPcs.split("-")[2];

      // console.log(tonsAndPcs, desc, qty);

      return {
        Tons,
        Pieces,
        formatType,
      };
    } catch (err) {
      console.log(err);
      return {};
    }
  };

  const handleSelectedTransaction = (transaction) => {
    setFilterParams({
      ...filterParams,
      TransactionID: transaction.TransactionID,
    });
    setShowTransactionPopover(false);

    // fetch sales data
  };

  const lockItemFromBeenSold = async ({
    isLocked,
    Bar_Code,
    TransactionID,
  }) => {
    try {
      setIsLocking(true);
      const res = await fetchActionsUtil(
        `${backendUrl}/api/sales/lock`,
        "POST",
        "",
        {
          isLocked,
          Bar_Code,
          TransactionID,
        }
      );
      refetch();
      toast.success(res?.message);
    } catch (err) {
      console.log(err);
      toast.error("Check connection and Try again");
    } finally {
      setIsLocking(false);
    }
  };

  return (
    <IsPrivileged roleName={["Supply Center"]}>
      <main className="inner-page-outlet">
        <PageHeader
          name="Supply Center"
          description=""
          icon={<ChartPieSliceTitleIcon />}
        />

        <main className="cash-book">
          <div className="content">
            <div className="content-main">
              <div className="content-body">
                <header>
                  <h1>
                    {!showFilter && (
                      <button
                        onClick={() => setShowFilter(!showFilter)}
                        className="btn filter"
                      >
                        <FilterTwoIcon />
                      </button>
                    )}
                    Supply Center
                    <button
                      onClick={() => refetch()}
                      className="btn text-primary"
                    >
                      <CachedIcon />
                    </button>
                  </h1>
                  <div className="actions mr-5 align-item-center">
                    <Form.Group className="col-md-6">
                      <InputGroup>
                        <Form.Control
                          className=""
                          type="text"
                          placeholder="Transaction ID"
                          name="TransactionID"
                          value={filterParams.TransactionID}
                          onChange={(e) =>
                            setFilterParams({
                              ...filterParams,
                              TransactionID: e.target.value,
                            })
                          }
                        />

                        <Popover
                          isOpen={showTransactionPopover}
                          onClickOutside={() =>
                            setShowTransactionPopover(false)
                          }
                          content={() => (
                            <TransactionsTable
                              handleSelectedTransaction={
                                handleSelectedTransaction
                              }
                            />
                          )}
                          position="bottom"
                        >
                          <InputGroup.Text
                            onClick={() =>
                              setShowTransactionPopover(!showTransactionPopover)
                            }
                          >
                            <MagnifyIcon />
                          </InputGroup.Text>
                        </Popover>
                      </InputGroup>
                    </Form.Group>

                    <div
                      style={{
                        width: "350px",
                        position: "relative",
                        zIndex: "10",
                      }}
                    >
                      <Select
                        menuPosition="fixed"
                        menuPlacement="auto"
                        placeholder={"Sort by"}
                        classNamePrefix={"form-select"}
                        name="status"
                        value={supplyOptionsParams.find(
                          (el) => el.value === queryParams?.status
                        )}
                        options={supplyOptionsParams}
                        isSearchable={false}
                        onChange={({ value }) =>
                          setQueryParams({
                            ...queryParams,
                            status: value,
                          })
                        }
                      />
                    </div>
                    <RsDateRangePicker
                      placement="bottomEnd"
                      defaultValue={
                        queryParams.startDate && queryParams.endDate
                          ? [
                              parse(
                                queryParams.startDate,
                                "yyyy-MM-dd",
                                new Date()
                              ),
                              parse(
                                queryParams.endDate,
                                "yyyy-MM-dd",
                                new Date()
                              ),
                            ]
                          : []
                      }
                      onClean={() => clearDateRange()}
                      onOk={(date) => filterByDateRange(date)}
                    />
                  </div>
                </header>

                {data.supply.map((el, index) => (
                  <div key={el.TransactionID + index} className="px-4 mb-5">
                    <div className="d-flex gap-3 align-items-center mb-3">
                      <>
                        <Dropdown>
                          <Dropdown.Toggle
                            variant=""
                            //  className="bg-white border-0"
                            bsPrefix="print more"
                          >
                            <DotsVerticalIcon />
                          </Dropdown.Toggle>
                          <Dropdown.Menu
                            popperConfig={{
                              strategy: "fixed",
                            }}
                            renderOnMount
                            className="dropdown-with-icons"
                          >
                            {isAdmin && (
                              <>
                                <Dropdown.Item as="div">
                                  <a
                                    href={`${backendUrl}/api/invoice/pdf/invoice/${
                                      el.TransactionID
                                    }`}
                                    target="blank"
                                  >
                                    <BookIcon />
                                    Open Invoice - A4
                                  </a>
                                </Dropdown.Item>
                                <Dropdown.Item as="div">
                                  <a
                                    href={`${backendUrl}/api/invoice/pdf/invoice/${
                                      el.TransactionID
                                    }?thermalPrinter=true`}
                                    target="blank"
                                  >
                                    <BookIcon />
                                    Open Invoice - Thermal Printer
                                  </a>
                                </Dropdown.Item>
                              </>
                            )}

                            <Dropdown.Item
                              as="button"
                              onClick={() =>
                                setshowWayBill({
                                  show: true,
                                  salesID: el.TransactionID,
                                })
                              }
                            >
                              {/* <a
                              href={`${backendUrl}/api/invoice/pdf/waybill/${
                                el.TransactionID
                              }`}
                              target="blank"
                            >
                              <PageHeaderListIcon />
                              Open Waybill
                            </a> */}
                              <PageHeaderListIcon />
                              Open Waybill
                            </Dropdown.Item>
                            <Dropdown.Item
                              as="button"
                              onClick={async () => {
                                if (
                                  await ConfirmDialog({
                                    title: "Supply All",
                                    description:
                                      "Are you sure you want to supply all?",
                                  })
                                ) {
                                  supplyAll({
                                    TransactionID: el.TransactionID,
                                  });
                                }
                              }}
                            >
                              <ChartPieSliceIcon className="text-primary" />
                              Supply All
                            </Dropdown.Item>
                          </Dropdown.Menu>{" "}
                        </Dropdown>
                      </>
                      <h2 className="h5 m-0">
                        {" "}
                        {el?.customer?.LastName === "Walk-In"
                          ? `${el?.transaction?.ShipTo} (Walk-In)`
                          : customerFullName(el.customer)}
                      </h2>{" "}
                      {el.transaction ? (
                        <>
                          {" "}
                          <span>
                            <b>Amount Paid:</b>{" "}
                            {currency(el.transaction.Amount_Paid, {
                              symbol: "",
                            }).format()}
                          </span>
                        </>
                      ) : null}{" "}
                      {el?.order ? (
                        <>
                          {!el?.order?.shipToDifferentAddress ? (
                            <p>
                              <b>Customer Address:</b> {el?.order?.Address}{" "}
                              {el?.order?.state}, {el?.order?.LGA}
                            </p>
                          ) : (
                            <p>
                              <b>Shipping Address:</b>{" "}
                              {el?.order?.ShipToAddress} {el?.order?.state},{" "}
                              {el?.order?.LGA}
                            </p>
                          )}
                        </>
                      ) : null}
                    </div>
                    <Table
                      responsive
                      borderless
                      striped
                      hover
                      className="product-table text-nowrap mb-3"
                    >
                      <thead>
                        <tr>
                          <th />
                          <th>Item Name / Size</th>
                          <th>Quantity</th>
                          <th>Storage Location</th>
                          <th>Date</th>
                          <th>TransactionID</th>
                          <th>Status</th>
                          <th>Quantity Supplied</th>
                          <th>Balance</th>
                          <th />
                        </tr>
                      </thead>
                      <tbody>
                        {el?.items?.map((item, index, array) => (
                          <tr key={index}>
                            <td>
                              <Dropdown>
                                <Dropdown.Toggle
                                  variant=""
                                  //  className="bg-white border-0"
                                  bsPrefix="print more"
                                >
                                  <DotsVerticalIcon />
                                </Dropdown.Toggle>
                                <Dropdown.Menu
                                  popperConfig={{
                                    strategy: "fixed",
                                  }}
                                  renderOnMount
                                  className="dropdown-with-icons"
                                >
                                  <Dropdown.Item
                                    as="div"
                                    className="p-cursor"
                                    onClick={() => {
                                      setshowSalesHistory((d) => ({
                                        sales: item,
                                        show: true,
                                      }));
                                    }}
                                  >
                                    <DropdownEyeIcon />
                                    Open History
                                  </Dropdown.Item>

                                  <div className="d-flex p-cursor p-3">
                                    {isAdmin && (
                                      <div
                                        className="d-flex p-cursor"
                                        onClick={() =>
                                          lockItemFromBeenSold({
                                            isLocked: !item.lockItemFromBeenSold,
                                            Bar_Code: item.Bar_Code,
                                            TransactionID: item.TransactionID,
                                          })
                                        }
                                        style={
                                          isLocking
                                            ? {
                                                pointerEvents: "none",
                                                opacity: "0.5",
                                              }
                                            : {}
                                        }
                                      >
                                        <LockIcon />
                                        <Form.Check
                                          className="mx-3 p-cursor"
                                          type="switch"
                                          id="lockItemFromBeenSold"
                                          label="Lock Item"
                                          checked={item?.lockItemFromBeenSold}
                                        />
                                      </div>
                                    )}
                                  </div>
                                </Dropdown.Menu>
                              </Dropdown>
                            </td>
                            <td className="text-wrap">
                              {item.Bar_Code} {item.Item_Name} /{" "}
                              {item.Serial_Number || item?.Product_Model}
                            </td>
                            <td title={item.QTY}>
                              {isIronRod
                                ? qtyFormatToString(
                                    qtyFormat(
                                      item.QTY,
                                      item.Serial_Number,
                                      itemMeasurements
                                    )
                                  )
                                : item.QTY}
                            </td>
                            <td>{item?.storage_location || "..."}</td>
                            <td className="text-nowrap">
                              {item.Date_Log
                                ? format(new Date(item.Date_Log), "dd MMM yyyy")
                                : "..."}
                            </td>
                            <td>{item.TransactionID}</td>
                            <td>
                              <div
                                style={{
                                  width: "200px",
                                  position: "relative",
                                  zIndex: array.length + 3 - index,
                                }}
                              >
                                <Select
                                  key={item?.Shipping_Status}
                                  menuPosition="fixed"
                                  menuPlacement="auto"
                                  classNamePrefix={"form-select"}
                                  options={supplyOptions}
                                  value={supplyOptions.find(
                                    (el) => el.value === item?.Shipping_Status
                                  )}
                                  isSearchable={false}
                                  onChange={({ value }) =>
                                    handleChangeShippingStatus(item, value)
                                  }
                                  isDisabled={isSupplyAndLogistics}
                                />
                              </div>
                            </td>
                            <td title={item?.QTY_Shipped}>
                              {isIronRod && item?.QTY_Shipped
                                ? qtyFormatToString(
                                    qtyFormat(
                                      item?.QTY_Shipped,
                                      item.Serial_Number,
                                      itemMeasurements
                                    )
                                  )
                                : item?.QTY_Shipped}
                            </td>
                            <td>
                              {/* {isIronRod
                              ? qtyFormatToString(
                                  qtyFormat(
                                    item.QTY,
                                    item.Serial_Number,
                                    itemMeasurements
                                  )
                                )
                              : item.QTY} */}
                              {/*  <NumberCustomInput
                              defaultValue={currency(item?.QTY, {
                                symbol: "",
                               precision: 2,
          format: formatForQty,
                              })
                                .subtract(item?.QTY_Shipped)
                                .format()}
                              className={"bg-white"}
                              style={{ width: "5rem" }}
                              onKeyDown={(e) =>
                                e.keyCode === 13 &&
                                handleChangeQuantity(item, e.target.value)
                              }
                              allowNegativeValue={false}
                              allowDecimals={true}
                            /> */}
                              <UpdateBatchTonsInline
                                ref={refsById[item.ID]}
                                key={item?.QTY_Shipped}
                                getDataForEdit={() =>
                                  getDataForEdit({
                                    qty: currency(item?.QTY, {
                                      symbol: "",
                                      separator: "",
                                      precision: 2,
                                      format: formatForQty,
                                    })
                                      .subtract(item?.QTY_Shipped)
                                      .format(),
                                    desc: item?.Serial_Number,
                                    itemMeasurements,
                                  })
                                }
                                item={item}
                                desc={item?.Serial_Number}
                                itemMeasurements={itemMeasurements}
                                handleChangeQuantity={handleChangeQuantity}
                              />
                            </td>
                            <td />
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    {/*  <div className="d-flex justify-content-end total-info mt-0 px-4">
                    <div>
                      <table className="table table-borderless balance">
                        <tbody>
                          <tr>
                            <td>Total Debit</td>
                            <td>
                              {currency(el.debit, {
                                symbol: "",
                              }).format()}
                            </td>
                          </tr>
                          <tr>
                            <td>Total Credit</td>
                            <td>
                              {currency(el.credit, {
                                symbol: "",
                              }).format()}
                            </td>
                          </tr>

                          <tr>
                            <td>Total Balance</td>
                            <td>
                              {currency(el?.balance, {
                                symbol: "",
                              }).format()}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div> */}
                    <hr />
                  </div>
                ))}

                {isEmpty(data.supply) && !isFetching ? (
                  <div className="d-flex justify-content-center text-center w-100 my-4">
                    <NoTableItem queryParams={queryParams} />
                  </div>
                ) : null}

                <div className="d-flex justify-content-between px-3 align-items-center pagination">
                  {/*<p className="m-0">
                Showing {data?.startIndex + 1} to{" "}
                {data?.endIndex <= data?.count ? data?.endIndex : data?.count}{" "}
                of {data.count} entries
                  </p> */}
                  <div className="pagination_left">
                    <p className="m-0 p-0">Show</p>
                    <select
                      value={queryParams.limit}
                      name="limit"
                      className="form-select "
                      onChange={(e) => handleSearchQueryChange(e)}
                    >
                      <option value="10">10 rows</option>
                      <option value="20">20 rows</option>
                      <option value="30">30 rows</option>
                      <option value="40">40 rows</option>
                      <option value="40">100 rows</option>
                    </select>
                  </div>

                  <ReactPaginate
                    {...paginationOptions}
                    pageCount={Math.ceil(data.count / queryParams.limit)}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={0}
                    onPageChange={({ selected }) => {
                      setQueryParams({
                        ...queryParams,
                        page: selected + 1,
                      });
                      document.body.scrollTop = document.documentElement.scrollTop = 0;
                    }}
                    forcePage={queryParams.page - 1}
                  />
                </div>

                {/*    <div className="summaryNew ">
                <div className="gridCont">
                  <div className="gridChild gridChildBorderLeftBlue">
                    <div className="gridChildLeft">
                      <p className="gridChld1">
                        {currency(data?.totalItemInStock, {
                          symbol: "",
                        }).format()}
                      </p>
                      <p className="gridChld2">Total Debit</p>
                    </div>

                    <div className="gridChildRight">
                      <SummaryChartIconUp />
                      <p>5%</p>
                    </div>
                  </div>
                  <div className="gridChild gridChildBorderLeftDarkGreen">
                    <div className="gridChildLeft">
                      <p className="gridChld1">
                        {currency(data?.totalValueOfItemInStock, {
                          symbol: "",
                        }).format()}
                      </p>
                      <p className="gridChld2">Total Credit</p>
                    </div>

                    <div className="gridChildRight">
                      <SummaryChartIconUp />
                      <p>5%</p>
                    </div>
                  </div>

                  <div className="gridChild gridChildBorderLeftOrange">
                    <div className="gridChildLeft">
                      <p className="gridChld1">
                        {currency(data?.totalValueOfItemInStock, {
                          symbol: "",
                        }).format()}
                      </p>
                      <p className="gridChld2">Balance</p>
                    </div>

                    <div className="gridChildRight">
                      <SummaryChartIconUp />
                      <p>5%</p>
                    </div>
                  </div>
                </div>
              </div> */}
              </div>
            </div>
          </div>
        </main>
        <SalesHistoryModal
          onHide={() => setshowSalesHistory((d) => ({ ...d, show: false }))}
          show={showSalesHistory.show}
          sales={showSalesHistory.sales}
        />

        <WayBillDatesModal
          onHide={() => setshowWayBill((d) => ({ ...d, show: false }))}
          show={showWayBill.show}
          salesID={showWayBill.salesID}
        />
        <ModalLoader show={updateSupplyMutation.isLoading} />
      </main>
    </IsPrivileged>
  );
}
