import { Form, InputGroup, Modal } from "react-bootstrap";
import { useFormik } from "formik";
import EyeOffOutline from "mdi-react/EyeOffOutlineIcon";
import EyeOutline from "mdi-react/EyeOutlineIcon";
import { useState } from "react";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import ModalLoader from "./utils/ModalLoader";
import * as yup from "yup";

import { useAuth } from "./../hooks/useAuth";
import "./../assets/scss/new-entity-modal.scss";
import { useEffect } from "react";
import { useEffectOnce } from "../utils/hooks";
import { isEmpty } from "lodash";

export const SendPasswordResetLink = ({
  backendUrl,
  showSendPasswordResetLinkModal,
  setShowsSendPasswordResetLinkModal,
  company,
  usage,
}) => {
  const resetUser = async (values) => {
    let response = await fetch(`${backendUrl}/api/auth/reset-password-link`, {
      method: "POST",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      body: JSON.stringify({
        ...values,
        company,
        usage,
      }),
      credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      if (response.errors) formik.setErrors(response.errors);
      throw new Error(response.message);
    }

    return await response.json();
  };

  const sendPasswordResetLinkMutation = useMutation(
    (payload) => resetUser(payload),
    {
      onSuccess: (data) => {
        toast.success("Link Sent");
        setShowsSendPasswordResetLinkModal(false);
      },
      onError: (errors) => {
        console.log(errors);
      },
    }
  );

  const formik = useFormik({
    initialValues: {
      Email: "",
    },
    validationSchema: yup.object().shape({
      Email: yup
        .string()
        .required()
        .email(),
    }),
    onSubmit: (values) => {
      sendPasswordResetLinkMutation.mutate({
        ...values,
      });
    },
  });

  return (
    <>
      <Modal
        show={showSendPasswordResetLinkModal}
        onHide={() => setShowsSendPasswordResetLinkModal()}
        dialogClassName="small-modal"
        backdropClassName={`global-backdrop`}
        size="md"
        centered={true}
        animation={false}
        enforceFocus={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h1>Reset Password</h1>
            <p>
              Please enter your email Address, you will receive a link to create
              a new password.
            </p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="px-1">
            <Form noValidate onSubmit={formik.handleSubmit} autoComplete="off">
              {/*  */}
              <Form.Group className="mb-3 pb-2">
                <Form.Label className="mb-1">Email Address</Form.Label>
                <Form.Control
                  className=""
                  type="email"
                  placeholder="Enter email"
                  name="Email"
                  value={formik.values.Email}
                  onChange={formik.handleChange}
                  isInvalid={formik.touched.Email && !!formik.errors.Email}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.Email}
                </Form.Control.Feedback>
              </Form.Group>
              {/*  */}

              {/*  */}
              <div className="d-flex justify-content-end">
                <button
                  disabled={sendPasswordResetLinkMutation.isLoading}
                  className="btn btn-primary w-100 p-3"
                  type="submit"
                >
                  {sendPasswordResetLinkMutation.isLoading
                    ? "Please wait..."
                    : "Reset Password"}
                </button>
              </div>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
