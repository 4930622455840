import { useFormik } from "formik";
import CachedIcon from "mdi-react/CachedIcon";
import React from "react";
import { Modal, Form, Button, Table } from "react-bootstrap";
import { useMutation, useQuery } from "react-query";
import Select from "react-select";
import { toast } from "react-toastify";
import * as yup from "yup";
import { useBackendUrl } from "../../utils/hooks";
import ConfirmDialog from "../ConfirmDialogue";
import { DeleteIcon } from "../Icons";

export default function CreatePlant({
  showCreatePlantModal,
  setShowCreatePlantModal,
}) {
  const backendUrl = useBackendUrl();
  const fetchSetUpData = async () => {
    // await waitFor(5000);
    let response = await fetch(`${backendUrl}/api/incentive/set-up`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const { data } = await response.json();
    data.vendorsSelect = data.vendors.map((el) => ({
      label: el.CompanyName,
      value: el.CompanyName,
    }));
    data.regionSelect = data.region.map((el) => {
      return {
        value: el.region,
        label: el.region,
      };
    });
    return data;
  };

  const {
    data = {
      plant: [],
      vendorsSelect: [],
      regionSelect: [],
    },
    refetch,
  } = useQuery(["INCENTIVE_SETUP", "PLANT"], () => fetchSetUpData(), {
    keepPreviousData: true,
  });

  const createPlant = async (payload) => {
    let response = await fetch(`${backendUrl}/api/atc/create-plant`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const createPlantMutation = useMutation((payload) => createPlant(payload), {
    onSuccess: ({ message, data }) => {
      toast.success(message);
      formik.resetForm();
      refetch();
    },
    onError: () => {
      toast.error(`Unable to perform action`);
    },
  });

  const formik = useFormik({
    initialValues: {
      Plant: "",
      Address: "",
      Contact_Person: "",
      Phone_Number: "",
      Vendor: "",
      Region: "",
    },
    validationSchema: yup.object().shape({
      Plant: yup.string().required(),
      Region: yup.string().required(),
      Vendor: yup.string().required(),
    }),
    onSubmit: (values) => {
      createPlantMutation.mutate({ ...values });
    },
  });

  const deletePlant = async (payload) => {
    let response = await fetch(`${backendUrl}/api/atc/delete-plant`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };

  const deletePlantMutation = useMutation((payload) => deletePlant(payload), {
    onSuccess: ({ message, data }) => {
      toast.success(message);
      formik.resetForm();
      refetch();
    },
    onError: () => {
      toast.error(`Unable to perform action`);
    },
  });

  const handleDeletePlant = async (el) => {
    if (
      await ConfirmDialog({
        title: "Delete Plant",
        description: `Are you sure, delete ${el.Plant}`,
      })
    ) {
      deletePlantMutation.mutate(el);
    }
  };

  return (
    <Modal
      show={showCreatePlantModal}
      onHide={() => setShowCreatePlantModal(false)}
      size="md"
      centered
      animation={false}
    >
      <Modal.Header closeButton>
        <Modal.Title className="h5">
          Create Plant{" "}
          <Button variant="" className="text-primary" onClick={() => refetch()}>
            {" "}
            <CachedIcon />{" "}
          </Button>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate onSubmit={formik.handleSubmit} autoComplete="off">
          <Form.Group className="mb-3 pb-2">
            <Form.Label className="mb-1">Vendor</Form.Label>

           
            <Select
              classNamePrefix={"form-select"}
              options={data?.vendorsSelect}
              className={
                formik.touched.Vendor && !!formik.errors.Vendor
                  ? "is-invalid"
                  : ""
              }
              isSearchable={false}
              value={
                (data?.vendorsSelect || []).find(
                  (el) => el.value === formik.values.Vendor
                ) || ""
              }
              onChange={(selected) => {
                formik.setFieldValue("Vendor", selected.value);
              }}
            />
            {formik.touched.Vendor && !!formik.errors.Vendor ? (
              <span className="text-danger mt-2">{formik.errors.Vendor}</span>
            ) : null}
          </Form.Group>

          <Form.Group className="mb-3 pb-2">
            <Form.Label className="mb-1">Plant Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Plant name"
              name="Plant"
              value={formik.values.Plant}
              onChange={formik.handleChange}
              isInvalid={formik.touched.Plant && !!formik.errors.Plant}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.Plant}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3 pb-2">
            <Form.Label className="mb-1">Region</Form.Label>
            <Select
              classNamePrefix={"form-select"}
              options={data?.regionSelect}
              className={
                formik.touched.Region && !!formik.errors.Region
                  ? "is-invalid"
                  : ""
              }
              isSearchable={false}
              value={
                (data?.regionSelect || []).find(
                  (el) => el.value === formik.values.Region
                ) || ""
              }
              onChange={(selected) => {
                formik.setFieldValue("Region", selected.value);
              }}
            />
            {formik.touched.Region && !!formik.errors.Region ? (
              <span className="text-danger mt-2">{formik.errors.Region}</span>
            ) : null}
          </Form.Group>

          <Form.Group className="mb-3 pb-2">
            <Form.Label className="mb-1">Address</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Address"
              name="Address"
              value={formik.values.Address}
              onChange={formik.handleChange}
              isInvalid={formik.touched.Address && !!formik.errors.Address}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.Address}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3 pb-2">
            <Form.Label className="mb-1">Contact Person</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Contact Person"
              name="Contact_Person"
              value={formik.values.Contact_Person}
              onChange={formik.handleChange}
              isInvalid={
                formik.touched.Contact_Person && !!formik.errors.Contact_Person
              }
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.Contact_Person}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3 pb-2">
            <Form.Label className="mb-1">Phone Number</Form.Label>
            <Form.Control
              type="tel"
              placeholder="Enter Phone Number"
              name="Phone_Number"
              value={formik.values.Phone_Number}
              onChange={formik.handleChange}
              isInvalid={
                formik.touched.Phone_Number && !!formik.errors.Phone_Number
              }
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.Phone_Number}
            </Form.Control.Feedback>
          </Form.Group>

          <div className="d-flex gap-3 justify-content-end">
            <Button type="submit">Create</Button>
            <Button type="button" variant="outline-primary">
              Refresh
            </Button>
          </div>
        </Form>
        <Table
          responsive
          borderless
          striped
          className="product-table mt-4 border"
        >
          <thead>
            <tr>
              <th />
              <th> Plant </th>
              <th> Address </th>
              <th>Contact_Person</th>
              <th> Phone_Number</th>
              <th> Vendor</th>
            </tr>
          </thead>
          <tbody>
            {data.plant.map((el, index) => (
              <tr key={index}>
                <td style={{ whiteSpace: "nowrap", width: "1%" }}>
                  <Button variant="" onClick={() => handleDeletePlant(el)}>
                    <DeleteIcon />
                  </Button>
                </td>
                <td>{el.Plant}</td>
                <td>{el.Address}</td>
                <td>{el.Contact_Person}</td>
                <td>{el.Phone_Number}</td>
                <td>{el.Vendor}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Modal.Body>
    </Modal>
  );
}
