import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useUpdateEffect } from "../utils/hooks";

export default function OfflineCheck() {
  const isDev =
    process.env.REACT_APP_ENV !== "production" ||
    /electron/i.test(navigator.userAgent);
  let [online, isOnline] = useState(isDev ? true : navigator.onLine);

  const toastId = React.useRef(null);

  const notify = () => {
    toastId.current = toast.error("You're offline. check internet connection", {
      autoClose: false,
      transition: null,
      pauseOnFocusLoss: false,
      // position: "bottom-right",
    });
  };

  const dismiss = () => toast.dismiss(toastId.current);

  // const dismissAll = () => toast.dismiss();

  const setOnline = () => {
    isOnline(true);
  };
  const setOffline = () => {
    isOnline(false);
  };

  useUpdateEffect(() => {
    if (!online) {
      notify();
    } else {
      dismiss();
      /* toast.success("Online", {
        pauseOnFocusLoss: false,
        //  position: "bottom-right",
      }); */
    }
  }, [online]);

  // Register the event listeners
  useEffect(() => {
    window.addEventListener("offline", setOffline);
    window.addEventListener("online", setOnline);

    // cleanup if we unmount
    return () => {
      window.removeEventListener("offline", setOffline);
      window.removeEventListener("online", setOnline);
    };
  }, []);

  return null;
}
