import { Modal, Form, Button } from "react-bootstrap";
// import ReactDatePicker from "react-datepicker";
import "./../assets/scss/new-entity-modal.scss";
import Select from "react-select";
// import DatePickerCustomInput from "./utils/DatePickerCustomInput";
// import CurrencyCustomInput from "./utils/CurrencyCustomInput";
// import { LockIcon } from "./Icons";
import { useFormik } from "formik";
import CreatableSelect from "react-select/creatable";
import * as yup from "yup";
import Datetime from "react-datetime";
import { useMutation, useQuery } from "react-query";
// import { services } from "../config";
import { toast } from "react-toastify";
// import LoginDialog from "./LoginDialog";
import { useMemo } from "react";
// import { nanoid } from "../utils/helpers";
import moment from "moment";
import { useAuth } from "../hooks/useAuth";
import { fetchActionsUtil } from "../utils/helpers";

export default function NewEnquireModal({
  showCreateNewCustomerModal,
  setShowCreateNewCustomerModal,
  mainRefetch,
}) {
  const didYouKnowOptions = [
    {
      value: "Walk In",
      label: "Walk In",
    },
    {
      value: "Flyers",
      label: "Flyers",
    },
    {
      value: "Radio",
      label: "Radio",
    },
    {
      value: "Bill board",
      label: "Bill board",
    },
    {
      value: "Friends",
      label: "Friends",
    },
    {
      value: "Television",
      label: "Television",
    },
    {
      value: "Website",
      label: "Website",
    },
    {
      value: "Search Engine",
      label: "Search Engine (Google, Bing, Yahoo etc)",
    },
    {
      value: "Social Media",
      label: "Social Media",
    },
  ];

  const { backendUrl, user } = useAuth();

  const formik = useFormik({
    initialValues: {
      Cust_ID: String(Date.now()),
      FirstName: "",
      LastName: "",
      MiddleName: "",
      Email: "",
      PhoneNo1: "",
      DOB: moment(),
      DateLog: moment(),
      CompanyAddress: "",
      ContactAddress: "",
      FollowUpComment: "",
      //   credit: "",
      TransType: "Enquirer",
      How_Did_Know: "Walk In",
      Product_Int1: "",
      Product_Int2: "",
      item: [],
      staff: user.Staff_ID,
      //   customerLoyalty: false,
    },
    validationSchema: yup.object().shape({
      //  FirstName: yup.string().required(),
      LastName: yup.string().required("Business name is required"),
      //  MiddleName: yup.string().required("Lastname is required"),
      Email: yup
        .string()

        .email(),
      PhoneNo1: yup.string().required(),
      DOB: yup.string().required(), // moment
      DateLog: yup.string().required(), // moment
      // CompanyAddress: yup.string().required("Company Address is required"),
      // ContactAddress: yup.string().required("Contact Address is required"),
      FollowUpComment: yup.string(),
      //   credit: yup.string(),
      Product_Int1: yup.string(),
      Product_Int2: yup.string(),
    }),
    onSubmit: (values) => {
      const { DOB, DateLog, item } = values;
      if (typeof DOB === "string") {
        formik.setFieldError("DOB", "Invalid date");
      }
      if (typeof DateLog === "string") {
        formik.setFieldError("DateLog", "Invalid date");
      }
      if (item.length < 1) {
        alert("Please add Product of Interest");
        return formik.setFieldError("item", "Please add Product of Interest");
      }
      if (
        typeof DOB === "string" ||
        typeof DateLog === "string" ||
        item.length < 1
      ) {
        return;
      }

      submit({
        ...values,
        DOB: DOB.format(),
        DateLog: DateLog.format(),
      });
    },
  });

  const { data } = useQuery(
    ["GET_PRODUCT_INTERSET_ITEMS"],
    () =>
      fetchActionsUtil(
        `${backendUrl}/api/customers/product-interest-item`,
        "GET"
      ),
    {
      enabled: true,
      keepPreviousData: true,
    }
  );

  const getItems = useMemo(() => {
    return data?.product.map((el) => ({ label: el, value: el }));
  }, [data?.product]);

  const createCustomerMutation = useMutation(
    (payload) =>
      fetchActionsUtil(
        `${backendUrl}/api/customers/create`,
        "POST",
        "",
        payload
      ),
    // createCustomer(payload)
    {
      onSuccess: ({ message, data }) => {
        toast.success(message);
        formik.resetForm();
        setShowCreateNewCustomerModal(false);
        mainRefetch();
      },
      onError: () => {
        toast.error(`Unable to perform action`);
      },
    }
  );

  const submit = (payload) => {
    createCustomerMutation.mutate(payload);
    // console.log(payload);
  };

  return (
    <Modal
      show={showCreateNewCustomerModal}
      onHide={() => setShowCreateNewCustomerModal(false)}
      dialogClassName="new-entity-modal"
      backdropClassName={`global-backdrop`}
      centered={true}
      animation={false}
      enforceFocus={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h1>Create New Inquirer</h1>
          <p>Create a new enquirer by filling in the following forms.</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form
          noValidate
          onSubmit={formik.handleSubmit}
          className="row pb-2"
          autoComplete="off"
        >
          <div className="col-md-6">
            <h2 className="mb-3 pb-1">Inquirer Information</h2>

            <Form.Group className="mb-2">
              <Form.Label className="mb-2">Inquirer ID</Form.Label>
              <Form.Control
                type="text"
                placeholder="xxxxxx"
                name="Cust_ID"
                value={formik.values.Cust_ID}
                style={{ pointerEvents: "none" }}
              />
            </Form.Group>

            <Form.Group className="mb-3 pb-2">
              <Form.Label className="mb-1">Business Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter business name"
                name="LastName"
                value={formik.values.LastName}
                onChange={formik.handleChange}
                isInvalid={formik.touched.LastName && !!formik.errors.LastName}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.LastName}
              </Form.Control.Feedback>
            </Form.Group>

            <div className="row">
              <Form.Group className="col-6 mb-3 pb-2">
                <Form.Label className="mb-1">First Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter first name"
                  name="FirstName"
                  value={formik.values.FirstName}
                  onChange={formik.handleChange}
                  isInvalid={
                    formik.touched.FirstName && !!formik.errors.FirstName
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.FirstName}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="col-6 mb-3 pb-2">
                <Form.Label className="mb-1">Last Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter last name"
                  name="MiddleName"
                  value={formik.values.MiddleName}
                  onChange={formik.handleChange}
                  isInvalid={
                    formik.touched.MiddleName && !!formik.errors.MiddleName
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.MiddleName}
                </Form.Control.Feedback>
              </Form.Group>
            </div>

            <div className="row">
              <Form.Group className="col-6 mb-3 pb-2">
                <Form.Label className="mb-1">Email Address</Form.Label>
                <Form.Control
                  className=""
                  type="email"
                  placeholder="Enter email"
                  name="Email"
                  value={formik.values.Email}
                  onChange={formik.handleChange}
                  isInvalid={formik.touched.Email && !!formik.errors.Email}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.Email}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="col-6 mb-3 pb-2">
                <Form.Label className="mb-1">Phone Number</Form.Label>
                <Form.Control
                  className=""
                  type="tel"
                  placeholder="(555) xxxx xxxx"
                  name="PhoneNo1"
                  value={formik.values.PhoneNo1}
                  onChange={formik.handleChange}
                  isInvalid={
                    formik.touched.PhoneNo1 && !!formik.errors.PhoneNo1
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.PhoneNo1}
                </Form.Control.Feedback>
              </Form.Group>
            </div>

            <Form.Group className=" mb-3 pb-2">
              <Form.Label className="mb-1" htmlFor="DOB">
                Date of Birth
              </Form.Label>
              <Datetime
                dateFormat="MMM DD, YYYY"
                timeFormat={false}
                closeOnSelect={true}
                closeOnClickOutside={true}
                name="DOB"
                inputProps={{
                  className: `date-input form-control ${
                    formik.touched.DOB && !!formik.errors.DOB
                      ? "is-invalid"
                      : ""
                  }`,
                  placeholder: "Select date",
                  readOnly: true,
                }}
                value={formik.values.DOB}
                onChange={(date) => {
                  formik.setFieldValue("DOB", date, true);
                }}
                onBlur={() => formik.setFieldTouched("BOB", true)}
              />
              {formik.touched.DOB && !!formik.errors.DOB ? (
                <span className="custom-invalid-feedback">
                  {formik.errors.DOB}
                </span>
              ) : null}
            </Form.Group>

            <Form.Group className=" mb-3 pb-2">
              <Form.Label className="mb-1">Inquirer Address</Form.Label>
              <Form.Control
                as="textarea"
                placeholder="Enter your customer address"
                name="ContactAddress"
                rows={5}
                value={formik.values.ContactAddress}
                onChange={formik.handleChange}
                isInvalid={
                  formik.touched.ContactAddress &&
                  !!formik.errors.ContactAddress
                }
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.ContactAddress}
              </Form.Control.Feedback>
            </Form.Group>
          </div>

          <div className="col-md-6 d-flex flex-column justify-content-between">
            <div>
              <h2 className="mt-4 mt-md-0 mb-3  pb-1">Other Information</h2>

              <div className="row">
                <Form.Group className="col-6 mb-3 pb-2">
                  <Form.Label className="mb-1">Inquirer Log Date</Form.Label>
                  <Datetime
                    dateFormat="MMM DD, YYYY"
                    timeFormat={false}
                    closeOnSelect={true}
                    closeOnClickOutside={true}
                    name="DateLog"
                    inputProps={{
                      className: `date-input form-control ${
                        formik.touched.DateLog && !!formik.errors.DateLog
                          ? "is-invalid"
                          : ""
                      }`,
                      placeholder: "Select date",
                      readOnly: true,
                    }}
                    value={formik.values.DateLog}
                    onChange={(date) => {
                      formik.setFieldValue("DateLog", date, true);
                    }}
                    onBlur={() => formik.setFieldTouched("DateLog", true)}
                  />
                  {formik.touched.DateLog && !!formik.errors.DateLog ? (
                    <span className="custom-invalid-feedback">
                      {formik.errors.DateLog}
                    </span>
                  ) : null}
                </Form.Group>
                <Form.Group className="col-6 mb-3 pb-2">
                  <Form.Label className="mb-1 text-nowrap">
                    How did you hear About Us?
                  </Form.Label>
                  <Select
                    classNamePrefix={"form-select"}
                    isSearchable={false}
                    value={didYouKnowOptions.find(
                      (el) => el.value === formik.values.How_Did_Know
                    )}
                    onChange={({ value }) =>
                      formik.setFieldValue("How_Did_Know", value)
                    }
                    options={didYouKnowOptions}
                  />
                  <Form.Control.Feedback type="invalid" />
                </Form.Group>
              </div>

              <Form.Group className=" mb-3 pb-2">
                <Form.Label className="mb-1">Product of Interest</Form.Label>

                {formik.values.item.length > 0 && (
                  <div className="mt-2">
                    {formik.values.item.map((d, i) => (
                      <CreatableSelect
                        className="mb-2"
                        onChange={(selected) => {
                          if (selected?.value !== undefined) {
                            const array = [...formik.values.item];
                            array.splice(i, 1, selected?.value);
                            formik.setFieldValue("item", array);
                          }
                        }}
                        options={getItems}
                        value={{ value: d, label: d }}
                      />
                    ))}
                  </div>
                )}

                <CreatableSelect
                  isClearable
                  onChange={(selected) => {
                    if (selected?.value !== undefined) {
                      const array = [...formik.values.item];
                      array.push(selected?.value);
                      const unqiue = [...new Set(array)];
                      formik.setFieldValue("item", unqiue);
                    }
                  }}
                  options={getItems}
                  value={""}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.item}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className=" mb-3 pb-2">
                <Form.Label className="mb-1">
                  Follow Up Comment (optional)
                </Form.Label>
                <Form.Control
                  className=""
                  as="textarea"
                  placeholder=""
                  name="FollowUpComment"
                  value={formik.values.FollowUpComment}
                  onChange={formik.handleChange}
                  rows={5}
                />
              </Form.Group>

              <Form.Group className="">
                <Form.Label className="mb-1">Company's Address</Form.Label>
                <Form.Control
                  as="textarea"
                  placeholder="Enter company's address"
                  name="CompanyAddress"
                  rows={5}
                  value={formik.values.CompanyAddress}
                  onChange={formik.handleChange}
                  isInvalid={
                    formik.touched.CompanyAddress &&
                    !!formik.errors.CompanyAddress
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.CompanyAddress}
                </Form.Control.Feedback>
              </Form.Group>
            </div>

            <Button
              variant="primary"
              type="submit"
              className="w-100 submit-btn mt-3"
              disabled={createCustomerMutation.isLoading}
            >
              {createCustomerMutation.isLoading
                ? "Please wait..."
                : "Create Customer"}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
