import PageHeader from "./PageHeader";
import { Dropdown, Table, Form, Button, InputGroup } from "react-bootstrap";
import DetailedIcon, {
  ExportIcon,
  FilterIcon,
  FilterTwoIcon,
  BusinessBillIcon,
  PrintIcon,
  SummaryChartIconUp,
  SummaryChartIconDown,
  SummaryCloseIcon,
  ChartPieSliceTitleIcon,
  FilterCollapseIcon,
  ExchangeFunds,
} from "./Icons";
import "./../assets/scss/reports/cashbook.scss";
import { useEffect, useRef, useState } from "react";
import CachedIcon from "mdi-react/CachedIcon";
import Select from "react-select";
import useDebounce, {
  useLocalStorage,
  useQueryParams,
  useScrollTop,
} from "../utils/hooks";
import { services } from "../config";
import { reportActions } from "../utils/reactQueryActions";
import { useQuery } from "react-query";
import currency from "currency.js";
import { format, parse } from "date-fns";
import ReactPaginate from "react-paginate";
import {
  customerFullName,
  defaultSelectValue,
  paginationOptions,
  qtyFormat,
  qtyFormatToString,
  reactSelectTheme,
  scrollToTop,
} from "../utils/helpers";
import RsDateRangePicker from "./utils/RsDateRangePicker";
import queryString from "query-string";
import { useAuth } from "../hooks/useAuth";
import NewItemModalWrapper from "./NewItemModalWrapper";
import { Link } from "react-router-dom";
import { useStoreActions, useStoreState } from "easy-peasy";
import { Popover } from "react-tiny-popover";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import ItemsTable from "./utils/ItemsTable";
import ConvertQuantity from "./utils/ConvertQuantity";

export default function SalesInventory() {
  useScrollTop();
  const sortBy = [
    {
      value: "Bar_Code",
      label: "Item ID",
    },
    {
      value: "Item_Name",
      label: "Item Name",
    },
    {
      value: "Product_Name",
      label: "Product",
    },

    {
      value: "Cat_Name",
      label: "Category",
    },
  ];
  const initialFilterParams = {
    // startDate: format(new Date(), "yyyy-MM-dd"),
    //  endDate: format(new Date(), "yyyy-MM-dd"),
    sortBy: "Bar_Code",
  };

  const [showItemsPopover, setShowItemsPopover] = useState(false);
  const itemMeasurements = useStoreState((state) => state.itemMeasurements);
  const { backendUrl, isIronRod, isCement } = useAuth();
  const [showFilter, setShowFilter] = useLocalStorage("showFilter", true);
  const [
    showCreateNewInventoryModal,
    setShowCreateNewInventoryModal,
  ] = useState(false);
  const [queryParams, setQueryParams] = useQueryParams({
    page: 1,
    limit: 40,
    ...initialFilterParams,
  });

  const [filterParams, setFilterParams] = useState({
    ...initialFilterParams,
    ...queryParams,
  });

  const debouncedFilterParams = useDebounce(filterParams, 500);
  useEffect(() => {
    setQueryParams({ ...queryParams, ...debouncedFilterParams });
  }, [debouncedFilterParams]);

  const fetchSalesInventory = async (queryParams) => {
    // await waitFor(5000);
    let response = await fetch(
      `${backendUrl}/api/reports/sales-inventory?&${queryString.stringify(
        queryParams
      )}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    data.product = [
      {
        label: "Select",
        value: "",
      },
      ...data.product.map((el) => ({
        label: el.Product_Name,
        value: el.Product_Name,
      })),
    ];

    data.category = [
      {
        label: "Select",
        value: "",
      },
      ...data.category.map((el) => ({
        label: el.Cat_Name,
        value: el.Cat_Name,
      })),
    ];

    return data;
  };

  const { data = { count: 0, items: [] }, refetch } = useQuery(
    [reportActions.SALES_INVENTORY, queryParams],
    () => fetchSalesInventory(queryParams),
    {
      keepPreviousData: true,
    }
  );

  const handleFilterParamsChange = (e) => {
    setFilterParams({
      ...filterParams,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  const handleSearchQueryChange = (e) => {
    setQueryParams({
      ...queryParams,
      [e.target.name]: e.target.value,
    });
  };

  const filterByDateRange = (date) => {
    setFilterParams({
      ...filterParams,
      startDate: format(date[0], "yyyy-MM-dd"),
      endDate: format(date[1], "yyyy-MM-dd"),
    });
  };

  const clearDateRange = () => {
    setFilterParams({
      ...filterParams,
      startDate: "",
      endDate: "",
    });
  };

  const search = () => {
    setQueryParams({
      ...queryParams,
      ...filterParams,
    });
  };

  const reset = () => {
    setFilterParams(initialFilterParams);
    setQueryParams({
      ...queryParams,
      ...initialFilterParams,
    });
  };

  const handleSelectedItem = (item) => {
    setFilterParams({
      ...filterParams,
      itemId: item.Bar_Code,
      itemName: item.Item_Name,
    });
    setShowItemsPopover(false);
  };
  return (
    <main className="inner-page-outlet">
      <PageHeader
        name="Sales Inventory"
        description="Your current sales Inventory"
        icon={<ChartPieSliceTitleIcon />}
      />

      <main className="cash-book">
        <div className="content">
          <div className="content-main">
            <div className={`filter-area ${showFilter ? "open" : "close"}`}>
              <div className="filter-header">
                <h2>
                  <FilterTwoIcon /> Filters
                </h2>

                <Button
                  variant="white"
                  onClick={() => setShowFilter(!showFilter)}
                >
                  <FilterCollapseIcon />
                </Button>
              </div>
              <div className="filter-body">
                <Form>
                  <div className="body">
                    <Form.Group className="mb-2-5">
                      <Form.Label>Item Code</Form.Label>

                      <InputGroup>
                        <Form.Control
                          name="barcode"
                          placeholder="Enter Item Code"
                          value={filterParams?.barcode}
                          onChange={(e) => handleFilterParamsChange(e)}
                        />

                        <Popover
                          isOpen={showItemsPopover}
                          onClickOutside={() => setShowItemsPopover(false)}
                          content={() => (
                            <ItemsTable
                              handleSelectedItem={handleSelectedItem}
                            />
                          )}
                          position="bottom"
                        >
                          <InputGroup.Text
                            onClick={() =>
                              setShowItemsPopover(!showItemsPopover)
                            }
                          >
                            <MagnifyIcon />
                          </InputGroup.Text>
                        </Popover>
                      </InputGroup>
                    </Form.Group>
                    <Form.Group className="mb-2-5">
                      <Form.Label>Item Name</Form.Label>
                      <Form.Control
                        name="itemName"
                        value={filterParams?.itemName}
                        placeholder="Enter Item Name"
                        onChange={(e) => handleFilterParamsChange(e)}
                      />
                    </Form.Group>
                    <Form.Group className="mb-2-5">
                      <Form.Label>Product</Form.Label>
                      <Select
                        classNamePrefix="form-select"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        placeholder="All"
                        name="product"
                        isSearchable={true}
                        key={data?.product}
                        onChange={(selected) => {
                          setFilterParams({
                            ...filterParams,
                            product: selected.value,
                          });
                        }}
                        defaultValue={defaultSelectValue(
                          queryParams?.product,
                          data?.product,
                          { value: "", label: "Select" }
                        )}
                        options={data?.product || []}
                      />
                    </Form.Group>
                    <Form.Group className="mb-2-5">
                      <Form.Label>Category</Form.Label>
                      <Select
                        classNamePrefix="form-select"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        placeholder="All"
                        name="category"
                        isSearchable={true}
                        key={data?.category}
                        onChange={(selected) => {
                          setFilterParams({
                            ...filterParams,
                            category: selected.value,
                          });
                        }}
                        defaultValue={defaultSelectValue(
                          queryParams?.category,
                          data?.category,
                          { value: "", label: "Select" }
                        )}
                        options={data?.category || []}
                      />
                    </Form.Group>
                    <Form.Group className="mb-2-5">
                      <Form.Label>Branch</Form.Label>
                      <Select
                        classNamePrefix="form-select"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        placeholder="All"
                        name="branch"
                        isSearchable={true}
                        key={data?.branch}
                        onChange={(selected) => {
                          setFilterParams({
                            ...filterParams,
                            branch: selected.value,
                          });
                        }}
                        defaultValue={defaultSelectValue(
                          queryParams?.branch,
                          data?.branch,
                          { value: "", label: "All" }
                        )}
                        options={data?.branch || []}
                      />
                    </Form.Group>
                    <hr className="mt-3 mb-4" />
                    <Form.Group className="">
                      <Form.Label>Date Range</Form.Label>
                      <div className="position-relative">
                        <RsDateRangePicker
                          placement="auto"
                          value={
                            filterParams.startDate && filterParams.endDate
                              ? [
                                  parse(
                                    filterParams.startDate,
                                    "yyyy-MM-dd",
                                    new Date()
                                  ),
                                  parse(
                                    filterParams.endDate,
                                    "yyyy-MM-dd",
                                    new Date()
                                  ),
                                ]
                              : []
                          }
                          onClean={() => clearDateRange()}
                          onOk={(date) => filterByDateRange(date)}
                        />
                      </div>
                    </Form.Group>
                  </div>

                  <div className="buttons rounded">
                    <Button onClick={() => reset()} variant="white">
                      Reset
                    </Button>
                    <Button onClick={() => search()} variant="primary">
                      Search
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
            <div className="content-body">
              <header>
                <h1>
                  {!showFilter && (
                    <button
                      onClick={() => setShowFilter(!showFilter)}
                      className="btn filter"
                    >
                      <FilterTwoIcon />
                    </button>
                  )}
                  Inventory Table
                  <button
                    onClick={() => refetch()}
                    className="btn text-primary"
                  >
                    <CachedIcon />
                  </button>
                </h1>
                <div className="actions mr-5">
                  <Form.Group className="d-flex align-items-center gap-2">
                    <Form.Label className="mb-0">Sort By:</Form.Label>
                    <Select
                      classNamePrefix="form-select"
                      placeholder="All"
                      name="category"
                      isSearchable={false}
                      theme={reactSelectTheme}
                      onChange={(selected) => {
                        setQueryParams({
                          ...queryParams,
                          sortBy: selected.value,
                        });
                      }}
                      defaultValue={defaultSelectValue(
                        queryParams?.sortBy,
                        sortBy,
                        sortBy[0]
                      )}
                      options={sortBy || []}
                    />
                  </Form.Group>
                  {/*  <button className="btn print">
                    Export <ExportIcon />
                  </button> */}

                  {isIronRod && (
                    <button
                      onClick={() => setShowCreateNewInventoryModal(true)}
                      className="btn sendSms"
                    >
                      Create New <ExchangeFunds />
                    </button>
                  )}
                  {isCement && (
                    <>
                      <Link className="btn btn-primary px-3" to="/load-atc">
                        Load ATC
                      </Link>
                    </>
                  )}
                </div>
              </header>

              <div className="px-4">
                <Table
                  responsive
                  borderless
                  striped
                  hover
                  className="product-table text-nowrap"
                >
                  <thead>
                    <tr>
                      <th>S/N</th>
                      <th>Item Code</th>
                      <th>Item Name</th>
                      <th>Quantity</th>
                      <th>UOM</th>
                      <th>Cat Name</th>
                      <th>Product Name</th>
                      <th>Unit Cost</th>
                      <th>Unit Price (RP)</th>
                      <th>Unit Price (WP)</th>
                      <th>Unit Price (DP)</th>
                      <th>Total Value available</th>
                      <th>Man Date</th>
                      <th>Expiry Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.items?.map((el, index) => (
                      <tr key={index}>
                        <td>{data?.startIndex + index + 1}</td>
                        <td>{el.Bar_Code}</td>
                        <td>{el.Item_Name}</td>
                        <td title={`${el.Quantity} - ${el.Item_Desc}`}>
                          <ConvertQuantity
                            quantity={el.Quantity}
                            desc={el.Item_Desc}
                          />
                        </td>
                        <td>{el.Product_Model}</td>
                        <td>{el.Cat_Name}</td>
                        <td>{el.Product_Name}</td>
                        <td>
                          {currency(el.UnitCost, {
                            symbol: "",
                          }).format()}
                        </td>
                        <td>
                          {currency(el.UnitPrice, {
                            symbol: "",
                          }).format()}
                        </td>
                        <td>
                          {currency(el.Tax, {
                            symbol: "",
                          }).format()}
                        </td>
                        <td>
                          {currency(el.Vat_5, {
                            symbol: "",
                          }).format()}
                        </td>
                        <td>
                          {currency(el.StockTotal, {
                            symbol: "",
                          }).format()}
                        </td>
                        <td className="text-nowrap">
                          {el.ManDate
                            ? format(new Date(el.ManDate), "dd MMM yyyy")
                            : "..."}
                        </td>
                        <td className="text-nowrap">
                          {el.ExpireDate
                            ? format(new Date(el.ExpireDate), "dd MMM yyyy")
                            : "..."}
                        </td>

                        {/*   {currency(el.Amount, {
                          symbol: "",
                        }).format()}
                      </td> */}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>

              <div className="d-flex justify-content-between px-3 align-items-center pagination">
                {/*<p className="m-0">
                Showing {data?.startIndex + 1} to{" "}
                {data?.endIndex <= data?.count ? data?.endIndex : data?.count}{" "}
                of {data.count} entries
                  </p> */}
                <div className="pagination_left">
                  <p className="m-0 p-0">Show</p>
                  <select
                    value={queryParams.limit}
                    name="limit"
                    className="form-select "
                    onChange={(e) => handleSearchQueryChange(e)}
                  >
                    <option value="10">10 rows</option>
                    <option value="20">20 rows</option>
                    <option value="30">30 rows</option>
                    <option value="40">40 rows</option>
                    <option value="50">50 rows</option>
                    <option value="100">100 rows</option>
                  </select>
                </div>

                <ReactPaginate
                  {...paginationOptions}
                  pageCount={Math.ceil(data.count / queryParams.limit)}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={0}
                  onPageChange={({ selected }) => {
                    scrollToTop();
                    setQueryParams({
                      ...queryParams,
                      page: selected + 1,
                    });
                  }}
                  forcePage={queryParams.page - 1}
                />
              </div>

              <div className="summaryNew ">
                <div className="gridCont">
                  <div className="gridChild gridChildBorderLeftBlue">
                    <div className="gridChildLeft">
                      <p className="gridChld1">
                        {currency(data?.totalItemInStock, {
                          symbol: "",
                        }).format()}
                      </p>
                      <p className="gridChld2">Total Item in Stock</p>
                    </div>

                    <div className="gridChildRight">
                      <SummaryChartIconUp />
                      <p>5%</p>
                    </div>
                  </div>
                  <div className="gridChild gridChildBorderLeftDarkGreen">
                    <div className="gridChildLeft">
                      <p className="gridChld1">
                        {currency(data?.totalValueOfItemInStock, {
                          symbol: "",
                        }).format()}
                      </p>
                      <p className="gridChld2">Total Value of Item in Stock</p>
                    </div>

                    <div className="gridChildRight">
                      <SummaryChartIconUp />
                      <p>5%</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      {showCreateNewInventoryModal && (
        <NewItemModalWrapper
          setShowCreateNewInventoryModal={setShowCreateNewInventoryModal}
          showCreateNewInventoryModal={showCreateNewInventoryModal}
          refetch={refetch}
        />
      )}
    </main>
  );
}
