import { Modal, Form, Button, Row, Nav } from "react-bootstrap";
import * as yup from "yup";
import { useFormik } from "formik";
import { useState } from "react";
import { confirmable, createConfirmation } from "react-confirm";
import { useBackendUrl, useEffectOnce } from "../../utils/hooks";
import moment from "moment";
import DateTime from "react-datetime";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";

export default function ContentPage({}) {
  const backendUrl = useBackendUrl();
  const [isLoading, setIsLoading] = useState(false);
  const [activeKey, setActiveKey] = useState("termsAndConditions");

  const initialValues = {
    returnPolicy: "",
    termsAndConditions: "",
  };
  const schema = yup.object().shape({});

  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: async (values) => {
      createContentPageMutation.mutate(values);
    },
  });

  const fetchSetUpData = async () => {
    let response = await fetch(`${backendUrl}/api/company/get-content-page`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();

    if (data?.pages) {
      formik.setValues({
        returnPolicy: data.pages?.find((el) => el.title === "Return Policy")
          ?.content,
        termsAndConditions: data.pages?.find(
          (el) => el.title === "Terms and Conditions"
        )?.content,
      });
    }

    // -------
    return data;
  };

  const { isFetching } = useQuery(
    ["CONTENT_PAGE_SETUP"],
    () => fetchSetUpData(),
    {
      keepPreviousData: false,
    }
  );

  const createContentPage = async (payload) => {
    let response = await fetch(`${backendUrl}/api/company/add-content-page`, {
      method: "POST",
      // credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      if (response.errors) formik.setErrors(response.errors);
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const createContentPageMutation = useMutation(
    (payload) => createContentPage(payload),
    {
      onSuccess: ({ message, data }) => {
        toast.success(message);
      },
      onError: ({ message }) => {
        toast.error(`Unable to perform action: ${message}`);
      },
    }
  );

  return (
    <>
      <div className="row container px-5">
        <div className="my-4">
          <h6>Online Store</h6>
        </div>
        <Form noValidate onSubmit={formik.handleSubmit}>
          <Nav
            variant="tabs"
            defaultActiveKey="termsAndConditions"
            onSelect={(key) => setActiveKey(key)}
            className="mb-3"
          >
            <Nav.Item>
              <Nav.Link eventKey="termsAndConditions">
                Terms and Conditons
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="returnPolicy">Return Policy</Nav.Link>
            </Nav.Item>
          </Nav>

          {activeKey === "termsAndConditions" && (
            <Form.Group className="mb-3 pb-1">
              <Form.Label className="mb-1">Terms and Conditons</Form.Label>
              <ReactQuill
                theme="snow"
                className="notranslate"
                value={formik.values.termsAndConditions}
                onChange={(text) =>
                  formik.setFieldValue("termsAndConditions", text)
                }
              />
            </Form.Group>
          )}

          {activeKey === "returnPolicy" && (
            <Form.Group className="mb-3 pb-1">
              <Form.Label className="mb-1">Return Policy</Form.Label>
              <ReactQuill
                theme="snow"
                value={formik.values.returnPolicy}
                onChange={(text) => formik.setFieldValue("returnPolicy", text)}
              />
            </Form.Group>
          )}

          <Button
            disabled={createContentPageMutation.isLoading || isFetching}
            variant="primary"
            className="p-2 px-4 mx-auto"
            type="submit"
          >
            {createContentPageMutation.isLoading ? "Please wait…" : "Save"}
          </Button>
        </Form>
      </div>{" "}
    </>
  );
}
