import { Dropdown, Form } from "react-bootstrap";
import CachedIcon from "mdi-react/CachedIcon";
import { useQuery } from "react-query";
import currency from "currency.js";
import {
  format,
  parse,
  startOfMonth,
  endOfMonth,
  startOfYear,
  endOfYear,
} from "date-fns";
import { useEffect, useMemo, useRef, useState } from "react";
import queryString from "query-string";
import { CSVLink } from "react-csv";
import Select from "react-select";
import { ExcelIcon, PDFIcon } from "../Icons";
import "./../../assets/scss/reports/cashbook.scss";
import useDebounce, {
  useCurrencies,
  useQueryParams,
  useScrollTop,
} from "../../utils/hooks";
import { useAuth } from "../../hooks/useAuth";
import { useDownloadExcel } from "../../hooks/useDownloadExcel";
import ModalLoader from "../utils/ModalLoader";
import RsDateRangePicker from "../utils/RsDateRangePicker";
import { calculateBalanceSheet } from "../../utils/calculateBalanceSheet";
import { IsPrivileged } from "../DisplayChildElement";
import { useNavigate } from "react-router-dom";
import { formatDate, months } from "../../utils/helpers";
import { addDays } from "date-fns";
import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";

function BalanceSheet() {
  useScrollTop();
  const navigate = useNavigate();
  const initialFilterParams = {
    startDate: format(new Date(), "yyyy-MM-dd"),
    endDate: format(new Date(), "yyyy-MM-dd"),
  };

  const { backendUrl } = useAuth();
  const [excelData, setExcelData] = useState([]);
  const {
    deploymentCurrencies: currenciesOptions,
    prevailingCurrency,
    getCurrencySymbol,
  } = useCurrencies();
  const CSVLinkRef = useRef(null);

  // fetch excel hook
  const [isfetchingExcel, fetchExcelData] = useDownloadExcel(
    excelData,
    CSVLinkRef
  );

  const [queryParams, setQueryParams] = useQueryParams({
    ...initialFilterParams,
    currency: "",
    convertCurrency: prevailingCurrency,
  });

  const [filterParams, setFilterParams] = useState({
    ...initialFilterParams,
    ...queryParams,
  });

  const debouncedFilterParams = useDebounce(filterParams, 500);
  useEffect(() => {
    setQueryParams({
      ...queryParams,
      ...debouncedFilterParams,
    });
  }, [debouncedFilterParams]);

  const fetchCustomerLedgers = async (queryParams) => {
    // await waitFor(5000);
    let response = await fetch(
      `${backendUrl}/api/reports/balance-sheet?&${queryString.stringify(
        queryParams
      )}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    data.journal = data?.journal
      .filter((el) => Number(el.RunningBalanceCalculated) != 0)
      .map((el) => {
        return {
          ...el,
          DetailType: el.DetailType ? el.DetailType : el.Description,
        };
      });
    return data;
  };

  const {
    data = {
      count: 0,
      main: {},
      journal: [],
    },
    refetch,
    isFetching,
    // isSuccess,
  } = useQuery(
    ["TrailBalance", queryParams],
    () => fetchCustomerLedgers(queryParams),
    {
      keepPreviousData: true,
    }
  );

  // download excel function
  const { limit, page, ...rest } = queryParams;
  const onDownloadExcelData = async () => {
    let exData;

    const date =
      rest.startDate && rest.endDate
        ? `Date Prepared: Between ${format(
            new Date(rest.startDate),
            "E MMM d yyyy k:mm:ss z"
          )} to ${format(new Date(rest.endDate), "E MMM d yyyy k:mm:ss z")}`
        : "";

    exData = [
      [data.company?.CompName],
      ["Balance Sheet Report"],
      [date],
      [""],
      ["ASSETS"],
      ["Current Assets"],
      ...[
        data?.journal.find((el) => el.DetailType === "BANK")
          ? [
              "BANK",
              currency(
                data?.journal.find((el) => el.DetailType === "BANK")
                  ?.RunningBalanceCalculated,
                {
                  symbol: getCurrencySymbol(filterParams.convertCurrency),
                }
              ).format(),
            ]
          : [],
      ],
      ...[
        data?.journal.find(
          (el) =>
            el.Type === "CASH AND CASH EQUIVALENT" && el.DetailType !== "BANK"
        )
          ? [
              "CASH AND CASH EQUIVALENT",
              currency(
                data?.journal.find(
                  (el) =>
                    el.Type === "CASH AND CASH EQUIVALENT" &&
                    el.DetailType !== "BANK"
                )?.RunningBalanceCalculated,
                {
                  symbol: getCurrencySymbol(filterParams.convertCurrency),
                }
              ).format(),
            ]
          : [],
      ],
      // ========
      ...data?.journal
        .filter((el) => el.Type === "CURRENT ASSET")
        .map((el) => [
          el.DetailType,
          currency(el.RunningBalanceCalculated, {
            symbol: getCurrencySymbol(filterParams.convertCurrency),
          }).format(),
        ]),

      // ========
      ...[
        data?.journal.find((el) => el.Type === "ACCOUNTS RECEIVABLE")
          ? [
              "ACCOUNTS RECEIVABLE",
              currency(
                data?.journal.find((el) => el.Type === "ACCOUNTS RECEIVABLE")
                  ?.RunningBalanceCalculated,
                {
                  symbol: getCurrencySymbol(filterParams.convertCurrency),
                }
              ).format(),
            ]
          : [],
      ],
      //========
      [
        "Total Current Assets",
        currency(
          data?.journal
            .filter(
              (el) =>
                el.Type === "CASH AND CASH EQUIVALENT" ||
                el.Type === "ACCOUNTS RECEIVABLE" ||
                el.Type === "CURRENT ASSET"
            )
            .map((el) => el.RunningBalanceCalculated)
            .reduce((a, b) => currency(a).add(b), 0),
          {
            symbol: getCurrencySymbol(filterParams.convertCurrency),
          }
        ).format(),
      ],
      [""],
      ["Non-Current Assets"],
      ...data?.journal
        .filter((el) => el.Type === "NON-CURRENT ASSETS")
        .map((el) => [
          el.DetailType,
          currency(el.RunningBalanceCalculated, {
            symbol: getCurrencySymbol(filterParams.convertCurrency),
          }).format(),
        ]),
      //========
      ...data?.journal
        .filter((el) => el.Type === "FIXED ASSET")
        .map((el) => [
          el.DetailType,
          currency(el.RunningBalanceCalculated, {
            symbol: getCurrencySymbol(filterParams.convertCurrency),
          }).format(),
        ]),
      //========
      [
        "Total Non-Current Assets",
        currency(
          data?.journal
            .filter(
              (el) =>
                el.Type === "FIXED ASSET" || el.Type === "NON-CURRENT ASSETS"
            )
            .map((el) => el.RunningBalanceCalculated)
            .reduce((a, b) => currency(a).add(b), 0),
          {
            symbol: getCurrencySymbol(filterParams.convertCurrency),
          }
        ).format(),
      ],
      [" "],
      ["Other Assets"],
      ...data?.journal
        .filter((el) => el.Type === "ACCUMULATED DEPRECIATION")
        .map((el) => [
          el.DetailType,
          currency(el.RunningBalanceCalculated, {
            symbol: getCurrencySymbol(filterParams.convertCurrency),
          }).format(),
        ]),
      // =================
      [
        "Total Other Assets:",
        currency(
          data?.journal
            .filter((el) => el.Type === "ACCUMULATED DEPRECIATION")
            .map((el) => el.RunningBalanceCalculated)
            .reduce((a, b) => currency(a).add(b), 0),
          {
            symbol: getCurrencySymbol(filterParams.convertCurrency),
          }
        ).format(),
      ],
      [
        "Total Assets",
        currency(
          data?.journal
            .filter(
              (el) =>
                el.Type === "ACCUMULATED DEPRECIATION" ||
                el.Type === "FIXED ASSET" ||
                el.Type === "NON-CURRENT ASSETS" ||
                el.Type === "CASH AND CASH EQUIVALENT" ||
                el.Type === "ACCOUNTS RECEIVABLE" ||
                el.Type === "CURRENT ASSET"
            )
            .map((el) => el.RunningBalanceCalculated)
            .reduce((a, b) => currency(a).add(b), 0),
          {
            symbol: getCurrencySymbol(filterParams.convertCurrency),
          }
        ).format(),
      ],
      [" "],
      ["Current Liabilities"],
      // =============================
      ...data?.journal
        .filter((el) => el.Type === "CURRENT LIABILITIES")
        .map((el) => [
          el.DetailType,
          currency(el.RunningBalanceCalculated, {
            symbol: getCurrencySymbol(filterParams.convertCurrency),
          }).format(),
        ]),
      // =============================
      ...data?.journal
        .filter((el) => el.Type === "ACCOUNTS PAYABLE")
        .map((el) => [
          el.DetailType,
          currency(el.RunningBalanceCalculated, {
            symbol: getCurrencySymbol(filterParams.convertCurrency),
          }).format(),
        ]),
      // ==================================
      [
        "Total Current Liabilities",
        currency(
          data?.journal
            .filter(
              (el) =>
                el.Type === "CURRENT LIABILITIES" ||
                el.Type === "ACCOUNTS PAYABLE"
            )
            .map((el) => el.RunningBalanceCalculated)
            .reduce((a, b) => currency(a).add(b), 0),
          {
            symbol: getCurrencySymbol(filterParams.convertCurrency),
          }
        ).format(),
      ],
      [""],
      ["Non-Current Liabilities"],
      ...data?.journal
        .filter((el) => el.Type === "NON-CURRENT LIABILITY")
        .map((el) => [
          el.DetailType,
          currency(el.RunningBalanceCalculated, {
            symbol: getCurrencySymbol(filterParams.convertCurrency),
          }).format(),
        ]),
      [
        "Total Non-Current Liabilities",
        currency(
          data?.journal
            .filter((el) => el.Type === "NON-CURRENT LIABILITY")
            .map((el) => el.RunningBalanceCalculated)
            .reduce((a, b) => currency(a).add(b), 0),
          {
            symbol: getCurrencySymbol(filterParams.convertCurrency),
          }
        ).format(),
      ],
      [" "],
      ["Long-Term Liabilities"],
      ...data?.journal
        .filter((el) => el.Type === "LONG-TERM LIABILITIES")
        .map((el) => [
          el.DetailType,
          currency(el.RunningBalanceCalculated, {
            symbol: getCurrencySymbol(filterParams.convertCurrency),
          }).format(),
        ]),
      [
        "Total Long-Term Liabilities",
        currency(
          data?.journal
            .filter((el) => el.Type === "LONG-TERM LIABILITIES")
            .map((el) => el.RunningBalanceCalculated)
            .reduce((a, b) => currency(a).add(b), 0),
          {
            symbol: getCurrencySymbol(filterParams.convertCurrency),
          }
        ).format(),
      ],
      [" "],
      ["Owner's Equity"],
      ...data?.journal
        .filter((el) => el.Type === "OWNER'S EQUITY")
        .map((el) => [
          el.DetailType,
          currency(el.RunningBalanceCalculated, {
            symbol: getCurrencySymbol(filterParams.convertCurrency),
          }).format(),
        ]),
      [
        "Total Owner's Equity",
        currency(
          data?.journal
            .filter((el) => el.Type === "OWNER'S EQUITY")
            .map((el) => el.RunningBalanceCalculated)
            .reduce((a, b) => currency(a).add(b), 0),
          {
            symbol: getCurrencySymbol(filterParams.convertCurrency),
          }
        ).format(),
      ],
    ];

    // console.log(exData);
    setExcelData(exData);
  };

  const filterByDateRange = (date) => {
    setFilterParams({
      ...filterParams,
      startDate: format(date[0], "yyyy-MM-dd"),
      endDate: format(date[1], "yyyy-MM-dd"),
      enableDateRange: true,
    });
  };

  const clearDateRange = () => {
    setFilterParams({
      ...filterParams,
      startDate: "",
      endDate: "",
    });
  };
  // isprivileged

  const currencySymbol = useMemo(() => {
    return getCurrencySymbol(
      filterParams.convertCurrency
        ? filterParams.convertCurrency
        : prevailingCurrency
    );
  }, [filterParams.convertCurrency, prevailingCurrency]);

  const viewAccount = ({ e, el }) => {
    if (e.detail === 2) {
      el?.Description
        ? navigate(
            `/account-setup/chart-of-account-report?Description=${
              el.Description
            }&currency=${filterParams.currency}&convertCurrency=${
              filterParams.convertCurrency
            }&startDate=${filterParams.startDate}&endDate=${
              filterParams.endDate
            }&subText=${el?.subText || ""}`
          )
        : navigate(
            `/account-setup/chart-of-account-report?Type=${
              el.Type
            }&DetailType=${el.DetailType}&currency=${
              filterParams.currency
            }&convertCurrency=${filterParams.convertCurrency}&startDate=${
              filterParams.startDate
            }&endDate=${filterParams.endDate}&subText=${el?.subText ||
              ""}&notDetailType=${el?.notDetailType || ""}`
          );
    }
  };

  const handleAccountingPeriod = ({ month, year }) => {
    if (!year) {
      year = new Date().getFullYear();
    }

    // Invalid year
    if (year < 1000) {
      setFilterParams({
        ...filterParams,
        startDate: "",
        endDate: "",
      });
      return;
    }

    if (month === "Date Range") {
      //
      setFilterParams({
        ...filterParams,
        month,
      });
    } else if (month === "All") {
      // All in year
      const start = startOfYear(new Date(year, 0));
      const end = endOfYear(new Date(year, 0));

      setFilterParams({
        ...filterParams,
        month,
        startDate: format(start, "yyyy-MM-dd"),
        endDate: format(end, "yyyy-MM-dd"),
        year,
      });
    } else {
      // All in month and year
      const start = startOfMonth(new Date(year, month));
      const end = endOfMonth(new Date(year, month));

      setFilterParams({
        ...filterParams,
        month,
        startDate: format(start, "yyyy-MM-dd"),
        endDate: format(end, "yyyy-MM-dd"),
        year,
      });
    }
  };

  const handleFilterParamsChange = (e) => {
    setFilterParams({
      ...filterParams,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  return (
    <IsPrivileged roleName="Financials">
      <main className="cash-book">
        <div className="content pb-5">
          <header className="container pt-5">
            <h1>
              Balance Sheet
              <button
                title="Refresh"
                onClick={() => refetch()}
                className="btn text-primary"
              >
                <CachedIcon />
              </button>
            </h1>
            <div className="actions gap-3">
              <CSVLink
                className="btn print d-none"
                filename={`Balance Sheet(${format(
                  new Date(),
                  "dd-MMM-yyyy hh:mm:ss a"
                )}).csv`}
                data={excelData}
                ref={CSVLinkRef}
              />

              <Form.Group className="position-relative">
                <Form.Label className="fw-5 position-absolute bottom-100 start-0 m-1">
                  Filter By
                </Form.Label>
                <Select
                  classNamePrefix="form-select"
                  placeholder="All Currencies"
                  isSearchable={true}
                  options={currenciesOptions}
                  value={currenciesOptions.find(
                    (el) => el.value == filterParams.currency
                  )}
                  onChange={(selected) =>
                    setFilterParams({
                      ...filterParams,
                      page: 1,
                      currency: selected?.value,
                    })
                  }
                  isClearable={true}
                />
              </Form.Group>

              <Form.Group className="position-relative">
                <Form.Label className="fw-5 position-absolute bottom-100 start-0 m-1">
                  Report In{" "}
                </Form.Label>
                <Select
                  classNamePrefix="form-select"
                  placeholder="Select Currency"
                  isSearchable={true}
                  options={currenciesOptions}
                  value={currenciesOptions.find(
                    (el) => el.value === filterParams.convertCurrency
                  )}
                  onChange={({ value }) =>
                    setFilterParams({
                      ...filterParams,
                      page: 1,
                      convertCurrency: value,
                    })
                  }
                />
              </Form.Group>

              <div className="position-relative">
                <RsDateRangePicker
                  value={
                    filterParams.startDate && filterParams.endDate
                      ? [
                          parse(
                            filterParams.startDate,
                            "yyyy-MM-dd",
                            new Date()
                          ),
                          parse(filterParams.endDate, "yyyy-MM-dd", new Date()),
                        ]
                      : []
                  }
                  onClean={() => clearDateRange()}
                  onOk={(date) => filterByDateRange(date)}
                />
              </div>

              <Dropdown>
                <Dropdown.Toggle variant="" className="btn print" bsPrefix="">
                  <DotsVerticalIcon />
                </Dropdown.Toggle>
                <Dropdown.Menu
                  popperConfig={{
                    strategy: "fixed",
                  }}
                  renderOnMount
                  className="dropdown-with-icons"
                >
                  <div>
                    <Form.Group className="mb-2-5">
                      <Form.Label>Accounting Period</Form.Label>
                      <Select
                        classNamePrefix="form-select"
                        placeholder="Accounting Period"
                        isSearchable={true}
                        options={months}
                        value={months.find(
                          (el) => el.value == filterParams.month
                        )}
                        onChange={(selected) =>
                          handleAccountingPeriod({
                            month: selected?.value,
                            year: filterParams.year,
                          })
                        }
                        isClearable={false}
                      />
                    </Form.Group>

                    <Form.Group className="mb-2-5">
                      <Form.Label>Year</Form.Label>
                      <Form.Control
                        type="number"
                        name="year"
                        value={filterParams?.year}
                        placeholder="Enter Year"
                        onChange={(e) => {
                          handleAccountingPeriod({
                            month: filterParams.month,
                            year: e.target.value,
                          });
                        }}
                      />
                    </Form.Group>

                    <div className="mb-2-5">
                      <Form.Check
                        inline
                        label={`Include Opening Balance at 
                        ${
                          queryParams.startDate
                            ? formatDate(
                                addDays(new Date(queryParams.startDate), 0)
                              )
                            : ""
                        }`}
                        name="endingBalanceAtStartDate"
                        type={"checkbox"}
                        id={`endingBalanceAtStartDate`}
                        checked={filterParams?.endingBalanceAtStartDate}
                        onChange={(e) => handleFilterParamsChange(e)}
                      />
                    </div>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
              <a
                target="_blank"
                rel="noreferrer"
                href={`${backendUrl}/api/reports/pdf/balance-sheet?${queryString.stringify(
                  rest
                )}`}
                className="btn print"
              >
                PDF <PDFIcon color="#ff0000" />
              </a>
              <button className="btn print" onClick={onDownloadExcelData}>
                Excel <ExcelIcon color="#008000" />
              </button>
            </div>
          </header>
          <div className="container mb-5">
            {/* ASSETS */}
            <div>
              <div className="fw-bold p-2 px-5 mb-2 bg-low-blue text-white">
                <p>ASSETS</p>
              </div>
              <div>
                <div className="fw-semibold p-2 px-5 mb-2 bg-light text-white">
                  <p className="container px-0">Current Assets</p>
                </div>
                <div className="container px-0">
                  <div className="px-5">
                    {data?.journal.find((el) => el.DetailType === "BANK") && (
                      <div
                        className="d-flex justify-content-between p-cursor"
                        onClick={(e) =>
                          viewAccount({ e, el: { DetailType: "BANK" } })
                        }
                      >
                        <p className="fs-6 mb-1">BANK</p>
                        <p className="fs-6 mb-1">
                          {currency(
                            data?.journal.find((el) => el.DetailType === "BANK")
                              ?.RunningBalanceCalculated,
                            {
                              symbol: getCurrencySymbol(
                                filterParams.convertCurrency
                              ),
                            }
                          ).format()}
                        </p>
                      </div>
                    )}

                    {data?.journal.find(
                      (el) =>
                        el.Type === "CASH AND CASH EQUIVALENT" &&
                        el.DetailType !== "BANK"
                    ) && (
                      <div
                        className="d-flex justify-content-between p-cursor"
                        onClick={(e) =>
                          viewAccount({
                            e,
                            el: {
                              Type: "CASH AND CASH EQUIVALENT",
                              notDetailType: "BANK",
                            },
                          })
                        }
                      >
                        <p className="fs-6 mb-1">CASH AND CASH EQUIVALENT</p>
                        <p className="fs-6 mb-1">
                          {currency(
                            data?.journal
                              .filter(
                                (el) =>
                                  el.Type === "CASH AND CASH EQUIVALENT" &&
                                  el.DetailType !== "BANK"
                              )
                              .map((el) => el.RunningBalanceCalculated)
                              .reduce((a, b) => currency(a).add(b), 0),
                            {
                              symbol: getCurrencySymbol(
                                filterParams.convertCurrency
                              ),
                            }
                          ).format()}
                        </p>
                      </div>
                    )}

                    {data?.journal
                      .filter((el) => el.Type === "CURRENT ASSET")
                      .map((el) => (
                        <div
                          className="d-flex justify-content-between p-cursor"
                          onClick={(e) =>
                            viewAccount({
                              e,
                              el: {
                                DetailType: el.DetailType,
                              },
                            })
                          }
                        >
                          <p className="fs-6 mb-1">{el.DetailType}</p>
                          <p className="fs-6 mb-1">
                            {currency(el.RunningBalanceCalculated, {
                              symbol: getCurrencySymbol(
                                filterParams.convertCurrency
                              ),
                            }).format()}
                          </p>{" "}
                        </div>
                      ))}

                    {data?.journal.find(
                      (el) => el.Type === "ACCOUNTS RECEIVABLE"
                    ) && (
                      <div
                        className="d-flex justify-content-between p-cursor"
                        onClick={(e) =>
                          viewAccount({
                            e,
                            el: {
                              Type: "ACCOUNTS RECEIVABLE",
                            },
                          })
                        }
                      >
                        <p className="fs-6 mb-1">ACCOUNTS RECEIVABLE</p>
                        <p className="fs-6 mb-1">
                          {currency(
                            data?.journal.find(
                              (el) => el.Type === "ACCOUNTS RECEIVABLE"
                            )?.RunningBalanceCalculated,
                            {
                              symbol: getCurrencySymbol(
                                filterParams.convertCurrency
                              ),
                            }
                          ).format()}
                        </p>
                      </div>
                    )}
                  </div>
                  <div
                    className="d-flex justify-content-end mt-3 border-black  border-top border-bottom p-4 px-5 ms-auto"
                    style={{ width: "fit-content" }}
                  >
                    <p className="fw-bold fs-6 mb-1 me-4">
                      Total Current Assets:
                    </p>
                    <span className="fw-bold fs-6 mb-1">
                      {currency(
                        data?.journal
                          .filter(
                            (el) =>
                              el.Type === "CASH AND CASH EQUIVALENT" ||
                              el.Type === "ACCOUNTS RECEIVABLE" ||
                              el.Type === "CURRENT ASSET"
                          )
                          .map((el) => el.RunningBalanceCalculated)
                          .reduce((a, b) => currency(a).add(b), 0),
                        {
                          symbol: getCurrencySymbol(
                            filterParams.convertCurrency
                          ),
                        }
                      ).format()}
                    </span>
                  </div>
                </div>
              </div>

              <div className="mt-4">
                <div className="fw-semibold p-2 px-5 mb-2 bg-light text-white">
                  <p className="container px-0">Non-Current Assets</p>
                </div>
                <div className="container px-5">
                  {data?.journal
                    .filter((el) => el.Type === "NON-CURRENT ASSETS")
                    .map((el) => (
                      <div
                        className="d-flex justify-content-between p-cursor"
                        onClick={(e) =>
                          viewAccount({
                            e,
                            el: {
                              DetailType: el.DetailType,
                            },
                          })
                        }
                      >
                        <p className="fs-6 mb-1">{el.DetailType}</p>
                        <p className="fs-6 mb-1">
                          {currency(el.RunningBalanceCalculated, {
                            symbol: getCurrencySymbol(
                              filterParams.convertCurrency
                            ),
                          }).format()}
                        </p>{" "}
                      </div>
                    ))}

                  {data?.journal
                    .filter((el) => el.Type === "FIXED ASSET")
                    .map((el) => (
                      <div
                        className="d-flex justify-content-between p-cursor"
                        onClick={(e) =>
                          viewAccount({
                            e,
                            el: {
                              DetailType: el.DetailType,
                            },
                          })
                        }
                      >
                        <p className="fs-6 mb-1">{el.DetailType}</p>
                        <p className="fs-6 mb-1">
                          {currency(el.RunningBalanceCalculated, {
                            symbol: getCurrencySymbol(
                              filterParams.convertCurrency
                            ),
                          }).format()}
                        </p>{" "}
                      </div>
                    ))}
                </div>
                <div
                  className="d-flex justify-content-end mt-3 border-black  border-top border-bottom p-4 px-5 ms-auto"
                  style={{ width: "fit-content" }}
                >
                  <p className="fw-bold fs-6 mx-4">Total Non-Current Assets:</p>
                  <span className="fw-bold fs-6">
                    {currency(
                      data?.journal
                        .filter(
                          (el) =>
                            el.Type === "FIXED ASSET" ||
                            el.Type === "NON-CURRENT ASSETS"
                        )
                        .map((el) => el.RunningBalanceCalculated)
                        .reduce((a, b) => currency(a).add(b), 0),
                      {
                        symbol: getCurrencySymbol(filterParams.convertCurrency),
                      }
                    ).format()}
                  </span>
                </div>
              </div>

              <div className="mt-4">
                <div className="fw-semibold p-2 px-5 mb-2 bg-light text-white">
                  <p className="container">Other Assets</p>
                </div>
                <div className="container px-5">
                  {data?.journal
                    .filter((el) => el.Type === "ACCUMULATED DEPRECIATION")
                    .map((el) => (
                      <div
                        className="d-flex justify-content-between p-cursor"
                        onClick={(e) =>
                          viewAccount({
                            e,
                            el: {
                              DetailType: el.DetailType,
                            },
                          })
                        }
                      >
                        <p className="fs-6 mb-1">{el.DetailType}</p>
                        <p className="fs-6 mb-1">
                          {currency(el.RunningBalanceCalculated, {
                            symbol: getCurrencySymbol(
                              filterParams.convertCurrency
                            ),
                          }).format()}
                        </p>{" "}
                      </div>
                    ))}
                </div>
                <div
                  className="d-flex justify-content-end mt-3 border-black  border-top border-bottom p-4 px-5 ms-auto"
                  style={{ width: "fit-content" }}
                >
                  <p className="fw-bold fs-6 mb-1 me-4">Total Other Assets:</p>
                  <span className="fw-bold fs-6 mb-1">
                    {currency(
                      data?.journal
                        .filter((el) => el.Type === "ACCUMULATED DEPRECIATION")
                        .map((el) => el.RunningBalanceCalculated)
                        .reduce((a, b) => currency(a).add(b), 0),
                      {
                        symbol: getCurrencySymbol(filterParams.convertCurrency),
                      }
                    ).format()}
                  </span>
                </div>
              </div>

              <div className="fw-bold p-1 mt-3 bg-light text-white">
                <div className="d-flex justify-content-between align-items-center container px-5">
                  <p className="fs-6 mb-1">Total Assets</p>
                  <p className="fs-6 mb-1">
                    {currency(
                      data?.journal
                        .filter(
                          (el) =>
                            el.Type === "ACCUMULATED DEPRECIATION" ||
                            el.Type === "FIXED ASSET" ||
                            el.Type === "NON-CURRENT ASSETS" ||
                            el.Type === "CASH AND CASH EQUIVALENT" ||
                            el.Type === "ACCOUNTS RECEIVABLE" ||
                            el.Type === "CURRENT ASSET"
                        )
                        .map((el) => el.RunningBalanceCalculated)
                        .reduce((a, b) => currency(a).add(b), 0),
                      {
                        symbol: getCurrencySymbol(filterParams.convertCurrency),
                      }
                    ).format()}
                  </p>
                </div>
              </div>
            </div>

            {/* Liabilities and Owner's Equity */}
            <div className="mt-5">
              <div className="fw-bold p-2 px-5 mb-2 bg-low-blue text-white">
                <p className="fs-6">Liabilities and Owner's Equity</p>
              </div>
              <div>
                <div className="fw-semibold p-2 px-5 mb-2 bg-light text-white">
                  <p className="container px-0">Current Liabilities</p>
                </div>
                <div className="container px-5">
                  {data?.journal
                    .filter((el) => el.Type === "CURRENT LIABILITIES")
                    .map((el) => (
                      <div
                        className="d-flex justify-content-between p-cursor"
                        onClick={(e) =>
                          viewAccount({
                            e,
                            el: {
                              DetailType: el.DetailType,
                            },
                          })
                        }
                      >
                        <p className="fs-6 mb-1">{el.DetailType}</p>
                        <p className="fs-6 mb-1">
                          {currency(el.RunningBalanceCalculated, {
                            symbol: getCurrencySymbol(
                              filterParams.convertCurrency
                            ),
                          }).format()}
                        </p>{" "}
                      </div>
                    ))}

                  {data?.journal
                    .filter((el) => el.Type === "ACCOUNTS PAYABLE")
                    .map((el) => (
                      <div
                        className="d-flex justify-content-between p-cursor"
                        onClick={(e) =>
                          viewAccount({
                            e,
                            el: {
                              DetailType: el.DetailType,
                            },
                          })
                        }
                      >
                        <p className="fs-6 mb-1">{el.DetailType}</p>
                        <p className="fs-6 mb-1">
                          {currency(el.RunningBalanceCalculated, {
                            symbol: getCurrencySymbol(
                              filterParams.convertCurrency
                            ),
                          }).format()}
                        </p>{" "}
                      </div>
                    ))}
                </div>

                <div
                  className="d-flex justify-content-end mt-3 border-black border-top border-bottom p-4 px-5 ms-auto"
                  style={{ width: "fit-content" }}
                >
                  <p className="fw-bold fs-6 mb-1 me-4">
                    Total Current Liabilities:
                  </p>
                  <span className="fs-6 mb-1 fw-bold">
                    {currency(
                      data?.journal
                        .filter(
                          (el) =>
                            el.Type === "CURRENT LIABILITIES" ||
                            el.Type === "ACCOUNTS PAYABLE"
                        )
                        .map((el) => el.RunningBalanceCalculated)
                        .reduce((a, b) => currency(a).add(b), 0),
                      {
                        symbol: getCurrencySymbol(filterParams.convertCurrency),
                      }
                    ).format()}
                  </span>
                </div>
              </div>

              <div>
                <div className="fw-semibold p-2 px-5 mb-2 mt-4 bg-light text-white">
                  <p className="container px-0">Non-Current Liabilities</p>
                </div>
                <div className="container px-5">
                  {data?.journal
                    .filter((el) => el.Type === "NON-CURRENT LIABILITY")
                    .map((el) => (
                      <div
                        className="d-flex justify-content-between p-cursor"
                        onClick={(e) =>
                          viewAccount({
                            e,
                            el: {
                              DetailType: el.DetailType,
                            },
                          })
                        }
                      >
                        <p className="fs-6 mb-1">{el.DetailType}</p>
                        <p className="fs-6 mb-1">
                          {currency(el.RunningBalanceCalculated, {
                            symbol: getCurrencySymbol(
                              filterParams.convertCurrency
                            ),
                          }).format()}
                        </p>{" "}
                      </div>
                    ))}
                </div>

                <div
                  className="d-flex justify-content-end mt-3 border-black  border-top border-bottom p-4 px-5 ms-auto"
                  style={{ width: "fit-content" }}
                >
                  <p className="fw-bold fs-6 mb-1 me-4">
                    Total Non-Current Liabilities:
                  </p>
                  <span className="fs-6 mb-1 fw-bold">
                    {currency(
                      data?.journal
                        .filter((el) => el.Type === "NON-CURRENT LIABILITY")
                        .map((el) => el.RunningBalanceCalculated)
                        .reduce((a, b) => currency(a).add(b), 0),
                      {
                        symbol: getCurrencySymbol(filterParams.convertCurrency),
                      }
                    ).format()}
                  </span>
                </div>
              </div>

              <div>
                <div className="fw-semibold p-2 px-5 mb-2 mt-4 bg-light text-white">
                  <p className="container px-0">Long-Term Liabilities</p>
                </div>
                <div className="container px-5">
                  {data?.journal
                    .filter((el) => el.Type === "LONG-TERM LIABILITIES")
                    .map((el) => (
                      <div
                        className="d-flex justify-content-between p-cursor"
                        onClick={(e) =>
                          viewAccount({
                            e,
                            el: {
                              DetailType: el.DetailType,
                            },
                          })
                        }
                      >
                        <p className="fs-6 mb-1">{el.DetailType}</p>
                        <p className="fs-6 mb-1">
                          {currency(el.RunningBalanceCalculated, {
                            symbol: getCurrencySymbol(
                              filterParams.convertCurrency
                            ),
                          }).format()}
                        </p>{" "}
                      </div>
                    ))}
                </div>

                <div
                  className="d-flex justify-content-end mt-3 border-black  border-top border-bottom p-4 px-5 ms-auto"
                  style={{ width: "fit-content" }}
                >
                  <p className="fw-bold fs-6 mb-1 me-4">
                    Total Long-Term Liabilities:
                  </p>
                  <span className="fs-6 mb-1 fw-bold">
                    {currency(
                      data?.journal
                        .filter((el) => el.Type === "LONG-TERM LIABILITIES")
                        .map((el) => el.RunningBalanceCalculated)
                        .reduce((a, b) => currency(a).add(b), 0),
                      {
                        symbol: getCurrencySymbol(filterParams.convertCurrency),
                      }
                    ).format()}
                  </span>
                </div>
              </div>

              <div className="mt-3">
                <div className="fw-semibold p-2  px-5 mb-2 bg-light text-white">
                  <p className="container px-0">Owner's Equity</p>
                </div>
                <div className="container px-5">
                  {data?.journal
                    .filter((el) => el.Type === "OWNER'S EQUITY")
                    .map((el) => (
                      <div
                        className="d-flex justify-content-between p-cursor"
                        onClick={(e) =>
                          viewAccount({
                            e,
                            el: {
                              DetailType: el.DetailType,
                            },
                          })
                        }
                      >
                        <p className="fs-6 mb-1">
                          {el?.DetailType ? el?.DetailType : el.Description}
                        </p>
                        <p className="fs-6 mb-1">
                          {currency(el.RunningBalanceCalculated, {
                            symbol: getCurrencySymbol(
                              filterParams.convertCurrency
                            ),
                          }).format()}
                        </p>{" "}
                      </div>
                    ))}
                </div>
                <div
                  className="d-flex justify-content-end mt-3 border-black  border-top border-bottom p-4 px-5 ms-auto"
                  style={{ width: "fit-content" }}
                >
                  <p className="fw-bold fs-6 mb-1 me-4">
                    Total Owner's Equity:
                  </p>
                  <span className="fw-bold fs-6 mb-1">
                    {currency(
                      data?.journal
                        .filter((el) => el.Type === "OWNER'S EQUITY")
                        .map((el) => el.RunningBalanceCalculated)
                        .reduce((a, b) => currency(a).add(b), 0),
                      {
                        symbol: getCurrencySymbol(filterParams.convertCurrency),
                      }
                    ).format()}
                  </span>
                </div>
              </div>
              <div className="fw-bold p-1 mt-4 bg-light text-white">
                <div className="d-flex justify-content-between align-items-center container px-5">
                  <p className="fs-6">Total Liabilities and Owner's Equity</p>
                  <p className="fs-6">
                    {currency(
                      data?.journal
                        .filter(
                          (el) =>
                            el.Type === "CURRENT LIABILITIES" ||
                            el.Type === "ACCOUNTS PAYABLE" ||
                            el.Type === "NON-CURRENT LIABILITY" ||
                            el.Type === "LONG-TERM LIABILITIES" ||
                            el.Type === "OWNER'S EQUITY"
                        )
                        .map((el) => el.RunningBalanceCalculated)
                        .reduce((a, b) => currency(a).add(b), 0),
                      {
                        symbol: getCurrencySymbol(filterParams.convertCurrency),
                      }
                    ).format()}
                  </p>
                </div>
              </div>
            </div>

            {/* <div className="container p-0 mt-5">
              <div className="fw-semibold p-2 px-5 mb-2 bg-light text-white">
                <p className="container px-0">Cost of Sales</p>
              </div>

              <div className="px-5">
                {data?.journal
                  .filter((el) => el.Type === "COST OF SALES")
                  .map((el) => (
                    <div
                      className="d-flex justify-content-between p-cursor"
                      onClick={(e) =>
                        viewAccount({
                          e,
                          el: {
                            DetailType: el.DetailType,
                          },
                        })
                      }
                    >
                      <p className="fs-6 mb-1">
                        {el?.DetailType ? el?.DetailType : el.Description}
                      </p>
                      <p className="fs-6 mb-1">
                        {currency(el.RunningBalanceCalculated, {
                          symbol: getCurrencySymbol(
                            filterParams.convertCurrency
                          ),
                        }).format()}
                      </p>{" "}
                    </div>
                  ))}
              </div>

              <div className="d-flex justify-content-end mt-3 border-black  border-top border-bottom p-4 px-5 mb-5">
                <p className="fw-bold fs-6 mb-1 me-4">Total Cost of Sales:</p>
                <span className="fs-6 mb-1 fw-bold">
                  {currency(
                    data?.journal
                      .filter((el) => el.Type === "COST OF SALES")
                      .map((el) => el.RunningBalanceCalculated)
                      .reduce((a, b) => currency(a).add(b), 0),
                    {
                      symbol: getCurrencySymbol(filterParams.convertCurrency),
                    }
                  ).format()}
                </span>
              </div>

              <div className="fw-semibold p-2 px-5 mb-2 bg-light text-white">
                <p className="container px-0">Total Income</p>
              </div>
              <div className="px-5">
                {data?.journal
                  .filter((el) => el.Type === "INCOME")
                  .map((el) => (
                    <div
                      className="d-flex justify-content-between p-cursor"
                      onClick={(e) =>
                        viewAccount({
                          e,
                          el: {
                            DetailType: el.DetailType,
                          },
                        })
                      }
                    >
                      <p className="fs-6 mb-1">
                        {el?.DetailType ? el?.DetailType : el.Description}
                      </p>
                      <p className="fs-6 mb-1">
                        {currency(el.RunningBalanceCalculated, {
                          symbol: getCurrencySymbol(
                            filterParams.convertCurrency
                          ),
                        }).format()}
                      </p>{" "}
                    </div>
                  ))}
              </div>

              <div className="d-flex justify-content-end mt-3 border-black  border-top border-bottom p-4 px-5 mb-5">
                <p className="fw-bold fs-6 mb-1 me-4">Total Income:</p>
                <span className="fs-6 mb-1 fw-bold">
                  {currency(
                    data?.journal
                      .filter((el) => el.Type === "INCOME")
                      .map((el) => el.RunningBalanceCalculated)
                      .reduce((a, b) => currency(a).add(b), 0),
                    {
                      symbol: getCurrencySymbol(filterParams.convertCurrency),
                    }
                  ).format()}
                </span>
              </div>

              <div className="fw-semibold p-2 px-5 mb-2 bg-light text-white">
                <p className="container px-0">Expenses</p>
              </div>
              <div className="px-5">
                {data?.journal
                  .filter((el) => el.Type === "EXPENSES")
                  .map((el) => (
                    <div
                      className="d-flex justify-content-between p-cursor"
                      onClick={(e) =>
                        viewAccount({
                          e,
                          el,
                        })
                      }
                    >
                      <p className="fs-6 mb-1">
                        {el?.DetailType ? el?.DetailType : el.Description}
                      </p>
                      <p className="fs-6 mb-1">
                        {currency(el.RunningBalanceCalculated, {
                          symbol: getCurrencySymbol(
                            filterParams.convertCurrency
                          ),
                        }).format()}
                      </p>{" "}
                    </div>
                  ))}
              </div>

              <div className="d-flex justify-content-end mt-3 border-black  border-top border-bottom p-4 px-5 mb-5">
                <p className="fw-bold fs-6 mb-1 me-4">Total Expenses:</p>
                <span className="fs-6 mb-1 fw-bold">
                  {currency(
                    data?.journal
                      .filter((el) => el.Type === "EXPENSES")
                      .map((el) => el.RunningBalanceCalculated)
                      .reduce((a, b) => currency(a).add(b), 0),
                    {
                      symbol: getCurrencySymbol(filterParams.convertCurrency),
                    }
                  ).format()}
                </span>
              </div>
            </div> */}
          </div>
        </div>
        <ModalLoader show={isfetchingExcel || isFetching} />
      </main>
    </IsPrivileged>
  );
}

export default BalanceSheet;
