import { Fragment, useState } from "react";
import { Modal, Button, Table, Form } from "react-bootstrap";
import { useAuth } from "../../hooks/useAuth";
import { employeeFullName, styleHelper } from "../../utils/helpers";
import { useQuery, useMutation } from "react-query";
import CurrencyInput from "react-currency-input-field";
import Datetime from "react-datetime";
import currency from "currency.js";
import { useMemo } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import moment from "moment";
import { useEffect } from "react";
import useDebounce, { useEffectOnce } from "../../utils/hooks";
import { toast } from "react-toastify";
import { toNumber } from "lodash";

function PayRow({ employee, index, editPay }) {
  const formik = useFormik({
    initialValues: {
      Bonuses: 0,
      Deductions: 0,
      Remark: "",
    },
    validationSchema: yup.object().shape({}),
    onSubmit: (values) => {},
    onReset: () => {},
  });

  useEffectOnce(() => {
    formik.setValues({
      Bonuses: employee.Bonuses,
      Deductions: employee.Deductions,
      Remark: employee.Remark,
    });
  });

  const netSalary = useMemo(() => {
    return currency(employee.TotalWages, {
      symbol: "",
      separator: "",
    })
      .subtract(employee.SalaryAdvanceCollected)
      .add(formik.values.Bonuses)
      .subtract(formik.values.Deductions)
      .format();
  }, [formik.values, employee.SalaryAdvanceCollected, employee.TotalWages]);

  const formValues = useDebounce(formik.values, 500);

  useEffect(() => {
    editPay(index, { ...formValues, NetSalary: netSalary });
  }, [formValues, netSalary]);

  return (
    <tr>
      <td>{employee.EmployeeID}</td>
      <td>{employee.Name}</td>
      <td>
        {currency(employee.TotalWages, {
          symbol: "",
        }).format()}
      </td>
      <td>
        {currency(employee.SalaryAdvanceCollected, {
          symbol: "",
        }).format()}
      </td>
      <td>
        <CurrencyInput
          className="form-control"
          name="Bonuses"
          placeholder="0.00"
          value={formik.values.Bonuses}
          onValueChange={(value, name) => {
            formik.setFieldValue(name, value);
          }}
          decimalScale={2}
        />
      </td>

      <td>
        <CurrencyInput
          className="form-control"
          name="Deductions"
          placeholder="0.00"
          value={formik.values.Deductions}
          onValueChange={(value, name) => {
            formik.setFieldValue(name, value);
          }}
          decimalScale={2}
        />
      </td>
      <td>
        <Form.Control
          name="Remark"
          value={formik.values.Remark}
          onChange={formik.handleChange}
          style={{ width: "25rem" }}
        />
      </td>
      <td>
        {currency(netSalary, {
          symbol: "",
        }).format()}
      </td>
    </tr>
  );
}

export default function EditDriverSalaryModal({
  setShowEditGenerateSalaryModal,
  handleEditedSalary,
  date,
}) {
  const { backendUrl, user: authUser } = useAuth();
  const [tableData, setTableData] = useState([]);

  const fetchSetupData = async (date) => {
    // await waitFor(5000);
    let response = await fetch(
      `${backendUrl}/api/driver/set-up?date=${date}&edit=true`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    return data;
  };

  const { data = { drivers: [] }, refetch } = useQuery(
    ["DRIVER_SET_UP", date],
    () => fetchSetupData(date),
    {
      keepPreviousData: true,
      onSuccess: ({ drivers }) => {
        setTimeout(() => {
          setTableData([...drivers]);
        }, 50);
      },
    }
  );

  const create = async (payload) => {
    let response = await fetch(
      `${backendUrl}/api/driver/save-salary?edit=true`,
      {
        method: "POST",
        credentials: "include",
        body: JSON.stringify(payload),
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
      }
    );
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const editMutation = useMutation((payload) => create(payload), {
    onSuccess: ({ message }) => {
      toast.success(message);
      handleEditedSalary(date);
    },
    onError: ({ message = "" }) => {
      toast.error(`Unable to perform action: ${message}`);
    },
  });

  const editPay = (index, formValues) => {
    tableData[index] = {
      ...tableData[index],
      ...formValues,
    };
    setTableData([...tableData]);
  };

  const save = () => {
    const driversSalary = tableData.map((el) => ({
      ...el,
      RegDate: date,
      PreparedBy: authUser.Name,
      Name: el.Name,
    }));
    editMutation.mutate({ driversSalary, date });
  };

  const totalNetSalary = useMemo(() => {
    return tableData
      .map((el) => el.NetSalary)
      .filter((el) => Number(el) > 0)
      .reduce(
        (a, b) =>
          currency(a, {
            symbol: "",
            precision: 2,
          }).add(b),
        0
      );
  }, [tableData]);

  return (
    <Modal
      show={true}
      onHide={() => setShowEditGenerateSalaryModal(false)}
      dialogClassName="modal-90w mx-auto"
      backdropClassName={`global-backdrop`}
      centered={true}
      animation={false}
      enforceFocus={false}
      fullscreen={styleHelper.isMobile}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h1 className="h5">Edit Payslip</h1>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table
          responsive
          borderless
          hover
          striped
          className="product-table text-nowrap"
        >
          <thead>
            <th>Employee ID</th>
            <th>Name</th>
            <th>Total Wages</th>
            <th>Salary Advance Collected</th>
            <th>Bonuses</th> <th>Deductions</th> <th>Remark</th>{" "}
            <th>Net Salary</th>
          </thead>
          <tbody>
            {tableData.map((el, index) => (
              <Fragment key={index}>
                <PayRow employee={el} index={index} editPay={editPay} />
              </Fragment>
            ))}
          </tbody>
        </Table>

        <div className="col-4 d-flex text-nowrap align-items-center gap-3">
          <p>Total Net Salary:</p>{" "}
          <CurrencyInput
            className="form-control"
            value={totalNetSalary}
            readOnly
          />
        </div>
      </Modal.Body>
      <Modal.Footer className="gap-3">
        <Button
          disabled={editMutation.isLoading}
          variant="outline-primary"
          className="px-4 py-2"
          onClick={() => setShowEditGenerateSalaryModal(false)}
        >
          Cancel
        </Button>

        <Button
          disabled={editMutation.isLoading}
          onClick={() => save()}
          className="px-4 py-2"
        >
          {editMutation.isLoading ? "Please wait..." : "Save Changes"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
