import Modal from "react-bootstrap/Modal";
import { useState } from "react";
import EyeOffOutline from "mdi-react/EyeOffOutlineIcon";
import EyeOutline from "mdi-react/EyeOutlineIcon";
import { useMutation } from "react-query";
import { toast } from "react-toastify";

import { useAuth } from "../../hooks/useAuth";
import { fetchActionsUtil } from "../../utils/helpers";
import ConfirmDialog from "../ConfirmDialogue";
import CloseIcon from "mdi-react/CloseIcon";

const initials = {
  password: "",
  confirmpassword: "",
};

const CustomersPasswordModal = (props) => {
  const { backendUrl } = useAuth();

  const [inputData, setInputData] = useState(initials);
  const [showText, setShowText] = useState({
    password: false,
    confirmpassword: false,
  });

  const OnChangeInput = (e) => {
    const { name, value } = e.target;
    setInputData({ ...inputData, [name]: value });
  };

  const changePassword = useMutation(
    (payload) =>
      fetchActionsUtil(
        `${backendUrl}/api/customers/change-customer-password`,
        "POST",
        "",
        payload
      ),
    {
      onSuccess: () => {
        toast.success("Password Changed Successfully");
        setInputData(initials);
        props.onHide();
      },
      onError: () => {
        toast.error("Unable to change password");
      },
    }
  );

  const onChangePassword = async () => {
    const { password, confirmpassword } = inputData;
    if (!confirmpassword || !password)
      return toast.error("Enter Pasword and Confirm Password");
    if (confirmpassword !== password) return toast.error("MisMatch Password");

    if (
      await ConfirmDialog({
        title: "Change Password",
        description: `Are you sure you want to CHANGE ${
          props.modalName
        }'s Password`,
      })
    ) {
      const payLoad = {
        Cust_ID: props.userId,
        newPassword: password,
      };
      changePassword.mutate(payLoad);
    }
  };

  return (
    <Modal
      onHide={props.onHide}
      show={props.show}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div>
          <div>
            <div className="d-flex justify-content-between">
              <h6>Change Password</h6>
              <div role="button" className="text-lg" onClick={props.onHide}>
                <CloseIcon />
              </div>
            </div>
            <p className="text-muted">{`Change ${
              props.modalName
            }'s Password`}</p>
          </div>
          <div className="mt-3">
            {/*  */}
            <div className="mb-3">
              <label className="col-form-label fw-bold">Password</label>
              <div>
                <div className="input-group">
                  <input
                    type={showText.password ? "text" : "password"}
                    className="form-control"
                    value={inputData.password}
                    name="password"
                    onChange={OnChangeInput}
                    placeholder="********"
                  />
                  {showText.password ? (
                    <span
                      onClick={() =>
                        setShowText({ ...showText, password: false })
                      }
                      className="input-group-text text-xs bg-white"
                    >
                      <EyeOutline />
                    </span>
                  ) : (
                    <span
                      onClick={() =>
                        setShowText({ ...showText, password: true })
                      }
                      className="input-group-text text-xs bg-white"
                    >
                      <EyeOffOutline />
                    </span>
                  )}
                </div>
              </div>
            </div>
            {/*  */}
            <div className="mb-3">
              <label className="col-form-label fw-bold">Comfirm Password</label>
              <div>
                <div className="input-group">
                  <input
                    type={showText.confirmpassword ? "text" : "password"}
                    className="form-control"
                    value={inputData.confirmpassword}
                    name="confirmpassword"
                    onChange={OnChangeInput}
                    placeholder="********"
                  />

                  {showText.confirmpassword ? (
                    <span
                      onClick={() =>
                        setShowText({
                          ...showText,
                          confirmpassword: false,
                        })
                      }
                      className="input-group-text text-xs bg-white"
                    >
                      <EyeOutline />
                    </span>
                  ) : (
                    <span
                      onClick={() =>
                        setShowText({
                          ...showText,
                          confirmpassword: true,
                        })
                      }
                      className="input-group-text text-xs bg-white"
                    >
                      <EyeOffOutline />
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <div className="d-inline-flex justify-content-end m-4">
        <button
          className="btn bg print mx-4"
          onClick={props.onHide}
          disabled={changePassword.isLoading}
        >
          Cancel
        </button>
        <button
          className="btn btn-primary"
          onClick={onChangePassword}
          disabled={changePassword.isLoading}
        >
          {!changePassword.isLoading ? "Change Password" : "Please wait..."}
        </button>
      </div>
    </Modal>
  );
};

export default CustomersPasswordModal;
