import Modal from "react-bootstrap/Modal";
import { Table } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import queryString from "query-string";
import { toast } from "react-toastify";
import { format } from "date-fns";
import CachedIcon from "mdi-react/CachedIcon";

import useDebounce, { useQueryParams } from "../../utils/hooks";
import { reportActions } from "../../utils/reactQueryActions";
import { fetchActionsUtil } from "../../utils/helpers";
import { useAuth } from "../../hooks/useAuth";

const ViewInventryTransit = (props) => {
  const { backendUrl } = useAuth();
  const initialFilterParams = { q: "" };
  const queryClient = useQueryClient();

  const [queryParams, setQueryParams] = useQueryParams({});
  const [filterParams, setFilterParams] = useState({
    ...initialFilterParams,
    ...queryParams,
  });
  const debouncedFilterParams = useDebounce(filterParams, 500);

  useEffect(() => {
    setQueryParams({ ...queryParams, ...debouncedFilterParams });
  }, [debouncedFilterParams]);

  const { data, refetch } = useQuery(
    [reportActions.PENDING_PERMITS, queryParams],
    () =>
      fetchActionsUtil(
        `${backendUrl}/api/pending?&${queryString.stringify(queryParams)}`,
        "GET"
      ),
    {
      keepPreviousData: false,
      staleTime: 0,
    }
  );

  // console.log("view =>", data);
  return (
    <Modal
      onHide={props.onHide}
      show={props.show}
      backdropClassName={`global-backdrop`}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="my-style-modal"
      animation={false}
      enforceFocus={false}
      size="xl"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <header>
            <h1>
              Item In Transit
              <button
                title="Refresh"
                onClick={() => refetch()}
                className="btn text-primary mx-2"
              >
                <CachedIcon />
              </button>
            </h1>
          </header>

          <p>View item in transit</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div className="px-md-4">
            <Table
              borderless
              responsive
              hover
              striped
              className="product-table"
            >
              <thead>
                <tr className="text-center">
                  <th>Item Code</th>
                  <th>Item Name</th>
                  <th>Quantity</th>
                  <th>Status</th>
                  <th>Comment</th>
                  <th>Date Log</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {data?.data?.length > 0 &&
                  data?.data?.map((el, index) => (
                    <tr key={index}>
                      <td className="text-center">{el.Bar_Code}</td>
                      <td>{el.Item_Name}</td>
                      <td className="text-center">{el.Quantity}</td>
                      <td>
                        <div
                          className={
                            el.Status === "Rejected"
                              ? "view_status_reject"
                              : "view_status_partly"
                          }
                        >
                          {el.Status}
                        </div>
                      </td>

                      <td className="text-wrap">{el.Comment}</td>
                      <td>{format(new Date(el?.Date_Log), "yyyy-MM-dd")}</td>
                      <td>
                        <div className="btn btn-primary">
                          Return to Inventry
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        </div>
      </Modal.Body>
      <div className="d-inline-flex justify-content-end m-4">
        <button className="btn bg print mx-4" onClick={props.onHide}>
          Cancel
        </button>
      </div>
    </Modal>
  );
};

export default ViewInventryTransit;
