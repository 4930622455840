import { useFormik } from "formik";
import { Col, Form, Row, Tab, Table } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import * as yup from "yup";
import Select from "react-select";
import { amountTypes, formatDate } from "../../../utils/helpers";
import CurrencyCustomInput from "../../utils/CurrencyCustomInput";
import NoTableItem from "../../utils/NoTableItem";
import { useMemo, useState } from "react";
import currency from "currency.js";
import { addMonths, addQuarters, addYears } from "date-fns";
import DateTime from "react-datetime";
import { addDays } from "date-fns";
import useDebounce from "../../../utils/hooks";
import { cloneDeep, isEmpty } from "lodash";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "react-query";
import { useAuth } from "../../../hooks/useAuth";
import ConfirmDialog from "../../ConfirmDialogue";
import queryString from "query-string";
import CachedIcon from "mdi-react/CachedIcon";

const cycleOptions = [
  { label: "", value: "" },
  { label: "Daily", value: "Daily" },
  { label: "Monthly", value: "Monthly" },
  { label: "Quaterly", value: "Quaterly" },
  { label: "Bi-annually", value: "Bi-anually" },
  { label: "Annually", value: "Annually" },
];

export default function DepreciationModal({
  showDepreciationModal = true,
  setShowDepreciationModal,
}) {
  const { backendUrl } = useAuth();

  // GET old Data
  const getBatches = async () => {
    let response = await fetch(
      `${backendUrl}/api/warehouse/asset-depreciation-projection?${queryString.stringify(
        {
          Bar_Code: showDepreciationModal.Bar_Code,
          W_ID: showDepreciationModal.W_ID,
          Item_Name: showDepreciationModal.Item_Name,
        }
      )}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const { data } = await response.json();
    if (data?.depreciation) {
      formik.setValues({
        ...data.depreciation,
      });
    }
    return data;
  };

  const { isLoading, refetch } = useQuery(
    ["PROJECTED_DEPRECIATION"],
    () => getBatches(),
    {}
  );

  const formik = useFormik({
    initialValues: {
      cycle: "",
      type: "",
      fixedAmount: "",
      percentage: "",
      Post_Date: new Date(),
      Start_Date: new Date(),
    },
    validationSchema: yup.object().shape({
      cycle: yup.string().required(),
    }),
    onSubmit: async (values) => {
      if (
        await ConfirmDialog({
          title: "Post Depreciation",
          description: "Are you sure, you want to submit",
        })
      ) {
        // values.projection = project();
        values.item = showDepreciationModal; //item is saved here
        values.percentageValue = percentageValue;
        postAssetDepreciationMutation.mutate(values);
      }
    },
  });

  const percentageValue = useMemo(() => {
    return currency(formik.values.percentage, {
      precision: 4,
    })
      .divide(100)
      .multiply(showDepreciationModal.UnitCost).value;
  }, [formik.values.percentage, showDepreciationModal.UnitCost]);

  /*  const project = () => {
    let amount = currency(showDepreciationModal.UnitCost).value;
    const depreciationAmount =
      formik.values.type === "Fixed" ? formik.values.amount : percentageValue;

    const cycles = [];
    let index = 0;
    console.log(cycles, depreciationAmount, amount);

    do {
      index++;
      amount =
        Number(amount) <= Number(depreciationAmount)
          ? 0
          : currency(amount).subtract(depreciationAmount).value;

      cycles.push({
        index,
        amount,
        projectionDate:
          formik.values.type === "Monthly"
            ? addMonths(new Date(), index)
            : formik.values.type === "Quaterly"
            ? addQuarters(new Date(), index)
            : formik.values.type === "Annually"
            ? addYears(new Date(), index)
            : addDays(new Date(), index),
      });
    } while (amount > 0);

    // console.log(cycles, depreciationAmount, amount);
    return { cycles };
  }; */

  const addAssetDepreciation = async (payload) => {
    let response = await fetch(
      `${backendUrl}/api/warehouse/add-asset-depreciation`,
      {
        method: "POST",
        credentials: "include",
        body: JSON.stringify(payload),
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
      }
    );
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };

  const postAssetDepreciationMutation = useMutation(
    (payload) => addAssetDepreciation(payload),
    {
      onSuccess: ({ message, data }) => {
        toast.success(message);
      },
      onError: () => {
        toast.error(`Unable to perform action`);
      },
    }
  );

  return (
    <Modal
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      animation={false}
      enforceFocus={false}
      show={showDepreciationModal}
      onHide={() => {
        setShowDepreciationModal(false);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Depreciation {"  "}{" "}
          <Button variant="" onClick={() => refetch()}>
            <CachedIcon />
          </Button>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          <b>Item Code:</b> {showDepreciationModal?.Bar_Code}
        </p>

        <p>
          <b>Item Name:</b> {showDepreciationModal?.Item_Name}
        </p>

        <p>
          <b>Original Purchase:</b>{" "}
          {showDepreciationModal?.originalUnitCost
            ? "..."
            : currency(showDepreciationModal.originalUnitCost, {
                symbol: "",
              }).format()}
        </p>

        <p>
          <b>Unit Cost:</b>{" "}
          {currency(showDepreciationModal.UnitCost, {
            symbol: "",
          }).format()}
        </p>

        <hr />

        <Form noValidate onSubmit={formik.handleSubmit}>
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Cycle</Form.Label>
                <Select
                  classNamePrefix={"form-select"}
                  options={cycleOptions}
                  value={cycleOptions.find(
                    (el) => el.value === formik.values.cycle
                  )}
                  onChange={(selected) =>
                    formik.setFieldValue("cycle", selected.value)
                  }
                />
                {formik.touched.cycle && !!formik.errors.cycle ? (
                  <span className="text-danger mt-2">
                    {formik.errors.cycle}
                  </span>
                ) : null}
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Type</Form.Label>
                <Select
                  classNamePrefix={"form-select"}
                  options={amountTypes}
                  value={amountTypes?.find(
                    (el) => el.value === formik.values?.type
                  )}
                  onChange={(selected) =>
                    formik.setFieldValue("type", selected.value)
                  }
                />
              </Form.Group>

              {formik.values?.type === "Percentage" ? (
                <Row>
                  <Col>
                    <Form.Group className="mb-3 pb-2">
                      <Form.Label className="mb-1">Percentage (%)</Form.Label>
                      <Form.Control
                        type="number"
                        name="percentage"
                        value={formik.values.percentage}
                        onChange={formik.handleChange}
                      />{" "}
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3 pb-2">
                      <Form.Label className="mb-1">Amount</Form.Label>
                      <CurrencyCustomInput
                        //   name="amount"
                        placeholder="0.00"
                        value={percentageValue}
                        onValueChange={(value, name) => {}}
                        readOnly
                      />
                    </Form.Group>
                  </Col>
                </Row>
              ) : formik.values?.type === "Fixed" ? (
                <Form.Group className="mb-3 pb-2">
                  <Form.Label className="mb-1">Fixed Amount</Form.Label>
                  <CurrencyCustomInput
                    name="fixedAmount"
                    placeholder="0.00"
                    value={formik.values.fixedAmount}
                    onValueChange={(value, name) => {
                      formik.setFieldValue(name, value);
                    }}
                  />{" "}
                </Form.Group>
              ) : null}

              <Form.Group className="mb-3">
                <Form.Label>Start Date</Form.Label>
                <DateTime
                  timeFormat={false}
                  closeOnSelect={true}
                  closeOnClickOutside={true}
                  dateFormat="MMM DD, YYYY"
                  inputProps={{
                    className: `date-input form-control`,
                    placeholder: "Select date",
                    readOnly: true,
                  }}
                  value={formik.values.Start_Date}
                  onChange={(date) => {
                    formik.setFieldValue("Start_Date", date, true);
                  }}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Post Date</Form.Label>
                <DateTime
                  timeFormat={false}
                  closeOnSelect={true}
                  closeOnClickOutside={true}
                  dateFormat="MMM DD, YYYY"
                  inputProps={{
                    className: `date-input form-control`,
                    placeholder: "Select date",
                    readOnly: true,
                  }}
                  value={formik.values.Post_Date}
                  onChange={(date) => {
                    formik.setFieldValue("Post_Date", date, true);
                  }}
                />
              </Form.Group>

              {/*  <Form.Group className="mb-3">
                <Form.Label></Form.Label>
                <Form.Check
                  type="switch"
                  label="Activate Customer Loyalty"
                  name="customerLoyalty"
                  className="custom-form-check mb-3"
                  checked={formik.values.customerLoyalty}
                  onChange={formik.handleChange}
                />{" "}
              </Form.Group> */}
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="" onClick={() => setShowDepreciationModal(false)}>
          Close
        </Button>
        <Button
          onClick={() => formik.submitForm()}
          className="px-4"
          disabled={postAssetDepreciationMutation.isLoading}
        >
          {postAssetDepreciationMutation.isLoading ? "Please wait.." : "Post"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
