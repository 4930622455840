import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import currency from "currency.js";

import { DeleteIcon } from "./Icons";
import { isEmpty } from "lodash";

const IssueItemModalTable = (props) => {
  const [showInput, setShowInput] = useState(false);
  const [inputValue, setInputValue] = useState(0);

  const update = (e, inx) => {
    if (e.key === "Enter") {
      if (inputValue === "" || parseFloat(inputValue) < 1)
        return toast.error(`Quantity can not be empty or less than one`);

      setShowInput(false);
      props.updateQuantity(inputValue, inx);
    }
  };

  useEffect(() => {
    setInputValue(props.el.qty);
  }, [props.el.qty]);

  return (
    <tr>
      {props?.serialNumbers && <td>{props.el.serialNum}</td>}
      <td>{props.el.itemId}</td>
      <td onClick={() => setShowInput(false)}>{props.el.itemName}</td>
      <td onClick={() => !props?.serialNumbers && setShowInput(true)}>
        {showInput ? (
          <input
            type="number"
            className="inputTable"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyDown={(e) => update(e, props.index)}
          />
        ) : (
          props.el.qty
        )}
      </td>
      <td onClick={() => setShowInput(false)}>{props.el.costPrice}</td>
      <td onClick={() => setShowInput(false)}>
        {props?.el?.Warehouse === "" ? "" : props?.el?.Warehouse?.split("-")[2]}
      </td>
      <td className="text-nowrap">
        {currency(props.el.subtotal, {
          symbol: "",
        }).format()}
      </td>
      {/* {!isEmpty(props.el?.selectedBatches) ? (
        <td>
          {props.el?.selectedBatches
            ?.map((el) => `${el.Bar_Code} ${el.Batch_Name || ""}`)
            .join(", ")}
        </td>
      ) : null} */}
      <td
        className="mx-3 p-cursor"
        onClick={() => props.deleteItem(props.index)}
      >
        {/* <DeleteIcon color="#FF0000" width="14" height="14" /> */}
        <DeleteIcon width="14" height="14" />
      </td>
    </tr>
  );
};

export default IssueItemModalTable;
