import { useState } from "react";

import MagnifyIcon from "mdi-react/MagnifyIcon";
import { Modal, Table, Form, Button, InputGroup } from "react-bootstrap";
import { services, durationOptions, appSettings } from "../../config";
import useDebounce, { useEffectOnce, useUpdateEffect } from "../../utils/hooks";
import { queryActions } from "../../utils/reactQueryActions";
import "./../../assets/scss/item-select-modal.scss";
import currency from "currency.js";
import CurrencyCustomInput from "../utils/CurrencyCustomInput";
import NumberCustomInput from "../utils/NumberCustomInput";
import { useFormik } from "formik";
import * as yup from "yup";
import Select from "react-select";
import { useEffect } from "react";
import {
  pcsToTons,
  qtyFormat,
  qtyFormatToString,
  tonsToPcs,
} from "../../utils/helpers";
import ConfirmDialog from "../ConfirmDialogue";
import { useAuth } from "../../hooks/useAuth";
import { lowerCase } from "lodash";
import { useStoreActions, useStoreState } from "easy-peasy";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "react-query";
import ModalLoader from "../utils/ModalLoader";

import Datetime from "react-datetime";
import { format } from "date-fns";
import moment from "moment";

const transferFrom = [
  {
    label: "Sales Cash",
    value: "Sales Cash",
  },
  {
    label: "Bank Account",
    value: "Bank Account",
  },
];

export default function ImprestTransferModal({
  setShowImprestTransferModal,
  refetch,
}) {
  const { backendUrl } = useAuth();
  const fetchSetUpData = async () => {
    // await waitFor(5000);
    let response = await fetch(`${backendUrl}/api/journal/set-up`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();

    data.banks = data?.banks
      .filter((el) => el?.Description)
      .map((el) => ({
        ...el,
        label: `${el?.Description} ${
          el?.currency ? `(${el?.currency})` : `(NGN)`
        }`,
        value: el?.Description,
      }));

    data.imprest = data?.imprest
      .filter((el) => el?.Description)
      .map((el) => ({
        ...el,
        label: el?.Description,
        value: el?.Description,
      }));

    return data;
  };

  const { data = { banks: [], imprest: [] } } = useQuery(
    [queryActions.JOURNAL_SETUP],
    () => fetchSetUpData(),
    {
      keepPreviousData: true,
    }
  );

  const imprestTransfer = async (payload) => {
    let response = await fetch(`${backendUrl}/api/journal/imprest-transfer`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const imprestTransferMutation = useMutation(
    (payload) => imprestTransfer(payload),
    {
      onSuccess: ({ message }) => {
        toast.success(message);
        formik.resetForm();
        if (refetch) refetch();
      },
      onError: ({ message = "" }) => {
        toast.error(`Unable to perform action: ${message}`);
      },
    }
  );

  const formik = useFormik({
    initialValues: {
      transferFrom: "Sales Cash",
      bank: "",
      newInstallment: "",
      accountName: "",
      salesDate: moment(),
      remark: "",
    },
    validationSchema: yup.object().shape({
      newInstallment: yup.string().required("Required"),
      accountName: yup.string().required("Required"),
    }),
    onSubmit: async (values) => {
      if (values.transferFrom === "Bank Account" && !values.bank) {
        formik.setFieldError("bank", "Please select Bank");
        // return toast.error("Please select Bank");
        return;
      }

      if (
        await ConfirmDialog({
          title: "Post Transaction",
          description: "Are you sure, you want to make this transaction",
          summaryTitle: "Summary",
          summary: {
            Account: values.accountName,
            "Transfer From": `${values.transferFrom} ${values.bank}`,
            "Transfer Amount": currency(values.newInstallment, {
              symbol: "",
            }).format(),
            Date: format(new Date(values.salesDate), "dd MMM, yyyy"),
          },
        })
      ) {
        imprestTransferMutation.mutate({
          ...values,
        });
      }
    },
  });

  return (
    <>
      <Modal
        show={true}
        onHide={() => setShowImprestTransferModal(false)}
        dialogClassName="item-select-modal edit"
        backdropClassName={`global-backdrop`}
        centered={true}
        animation={false}
        enforceFocus={false}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h1>Imprest Transfer</h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="body-content">
            <div className="postion-relative">
              <Form noValidate onSubmit={formik.handleSubmit}>
                <Form.Group className="mb-3 pb-1">
                  <Form.Label>Account Name</Form.Label>
                  <Select
                    classNamePrefix="form-select"
                    options={data.imprest}
                    value={data.imprest.find(
                      (el) => el.value === formik.values.accountName
                    )}
                    onChange={({ value }) =>
                      formik.setFieldValue("accountName", value)
                    }
                    className={
                      formik.touched.accountName && !!formik.errors.accountName
                        ? "is-invalid"
                        : ""
                    }
                  />
                  {formik.touched.accountName && formik.errors.accountName ? (
                    <span className="text-danger mt-2">
                      {formik.errors.accountName}
                    </span>
                  ) : null}
                </Form.Group>

                <Form.Group className="mb-3 pb-1">
                  <Form.Label>Trans Type</Form.Label>
                  <Form.Control defaultValue="Imprest" />
                </Form.Group>

                <Form.Group className="mb-3 pb-1">
                  <Form.Label>Balance on Imprest Account</Form.Label>
                  <CurrencyCustomInput
                    name="PriceSold"
                    placeholder="0.00"
                    value={
                      data.imprest.find(
                        (el) => el.value === formik.values.accountName
                      )?.RunningBalanceCalculated
                    }
                    isDisabled={true}
                  />
                </Form.Group>

                <hr className="my-4" />

                <Form.Group className="mb-3 pb-1">
                  <Form.Label>Transfer Amount</Form.Label>
                  <CurrencyCustomInput
                    name="newInstallment"
                    placeholder="0.00"
                    decimalsLimit={2}
                    value={formik.values.newInstallment}
                    onValueChange={(value, name) =>
                      formik.setFieldValue(name, value)
                    }
                    isInvalid={
                      formik.touched.newInstallment &&
                      !!formik.errors.newInstallment
                    }
                    onBlur={() =>
                      formik.setFieldTouched("newInstallment", true)
                    }
                  />
                  {formik.touched.newInstallment &&
                  !!formik.errors.newInstallment ? (
                    <span className="custom-invalid-feedback">
                      {formik.errors.newInstallment}
                    </span>
                  ) : null}
                </Form.Group>

                <Form.Group className="mb-3 pb-1">
                  <Form.Label>Transfer From</Form.Label>
                  <Select
                    classNamePrefix={"form-select"}
                    options={transferFrom}
                    value={transferFrom.find(
                      (el) => el.value === formik.values.transferFrom
                    )}
                    onChange={({ value }) =>
                      formik.setFieldValue("transferFrom", value)
                    }
                  />
                </Form.Group>

                <Form.Group className="mb-3 pb-1">
                  <Form.Label>Select Bank</Form.Label>
                  <Select
                    classNamePrefix={"form-select"}
                    className={
                      formik.touched.bank && !!formik.errors.bank
                        ? "is-invalid"
                        : ""
                    }
                    options={data.banks}
                    value={data.banks.find(
                      (el) => el.value === formik.values.bank
                    )}
                    isDisabled={formik.values.transferFrom !== "Bank Account"}
                    onChange={({ value }) =>
                      formik.setFieldValue("bank", value)
                    }
                  />
                  {formik.touched.bank && formik.errors.bank ? (
                    <span className="text-danger mt-2">
                      {formik.errors.bank}
                    </span>
                  ) : null}
                </Form.Group>

                <Form.Group className="mb-3 pb-1">
                  <Form.Label> Date</Form.Label>
                  <Datetime
                    timeFormat={false}
                    closeOnSelect={true}
                    closeOnClickOutside={true}
                    dateFormat="MMM DD, YYYY"
                    name="salesDate"
                    inputProps={{
                      className: `date-input form-control ${
                        formik.touched.salesDate && !!formik.errors.salesDate
                          ? "is-invalid"
                          : ""
                      }`,
                      placeholder: "Select date",
                      readOnly: true,
                    }}
                    value={formik.values.salesDate}
                    onChange={(date) => {
                      formik.setFieldValue("salesDate", date, true);
                    }}
                    onBlur={() => formik.setFieldTouched("salesDate", true)}
                  />
                </Form.Group>

                <Form.Group className="mb-3 pb-1">
                  <Form.Label>Remark</Form.Label>
                  <Form.Control
                    name="remark"
                    value={formik.values.remark}
                    onChange={formik.handleChange}
                    maxLength={50}
                  />
                </Form.Group>
              </Form>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => formik.submitForm()}
            type="submit"
            className="w-100 submit-btn py-3"
            disabled={imprestTransferMutation.isLoading}
          >
            Make Transfer
          </Button>
        </Modal.Footer>
      </Modal>

      <ModalLoader show={imprestTransferMutation.isLoading} />
    </>
  );
}
