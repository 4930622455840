import { Outlet } from "react-router-dom";
import PageHeader from "./PageHeader";
import { IsPrivileged } from "./DisplayChildElement";

export default function InnerPageLayout({
  Nav,
  name,
  description,
  icon,
  sideComponent,
}) {
  return (
    <>
      <main className="inner-page-outlet">
        {name && (
          <div className="d-flex justify-content-between bg-white">
            <PageHeader {...{ name, description, icon }} />

            {sideComponent && sideComponent}
          </div>
        )}

        {Nav && <div className="nav-bar">{Nav}</div>}

        <Outlet />
      </main>
    </>
  );
}
