import { useState, useEffect } from "react";
import { Modal, Table, Form } from "react-bootstrap";
import queryString from "query-string";
import { useMutation, useQuery } from "react-query";
import ReactPaginate from "react-paginate";
import currency from "currency.js";
import { format } from "date-fns";
import { toast } from "react-toastify";

import useDebounce from "../../utils/hooks";

import { paginationOptions, scrollToTop } from "../../utils/helpers";
import { useAuth } from "../../hooks/useAuth";
import ModalLoader from "../utils/ModalLoader";

export default function BatchesModalPlant({
  selectedItem,
  onHide,
  plantLocal,
  show,
  theWarehouse,
}) {
  const initialFilterParams = {
    showAll: false,
    Bar_Code: selectedItem?.Bar_Code,
  };

  const { backendUrl, token } = useAuth();
  const [queryParams, setQueryParams] = useState({
    page: 1,
    limit: 40,
    ...initialFilterParams,
  });
  const debouncedqueryParams = useDebounce(queryParams, 500);

  const getBatches = async (debouncedqueryParams) => {
    let response = null;
    let data = null;
    if (theWarehouse !== "" && theWarehouse !== "Default") {
      response = await fetch(
        `${backendUrl}/api/warehouse/batches/${theWarehouse}?${queryString.stringify(
          debouncedqueryParams
        )}`,
        {
          method: "GET",
          headers: {
            Accept: "Application/json",
            "Content-Type": "Application/json",
          },
          credentials: "include",
        }
      );
      if (!response.ok) {
        response = await response.json();
        throw new Error(response.message);
      }

      const dd = await response.json();
      data = dd.data;
    } else {
      response = await fetch(
        `${backendUrl}/api/production/batches/${
          plantLocal?.PLID
        }?${queryString.stringify(debouncedqueryParams)}`,
        {
          method: "GET",
          headers: {
            Accept: "Application/json",
            "Content-Type": "Application/json",
          },
          credentials: "include",
        }
      );
      if (!response.ok) {
        response = await response.json();
        throw new Error(response.message);
      }
      data = await response.json();
    }

    return data;
  };

  const { data = { batches: [] }, refetch } = useQuery(
    ["GET_BATCHES_OF_PLANTS", debouncedqueryParams],
    () => getBatches(debouncedqueryParams),
    {
      enabled: true,
      keepPreviousData: false,
    }
  );

  useEffect(() => {
    setQueryParams((p) => ({ ...p, Bar_Code: selectedItem?.Bar_Code }));
  }, [selectedItem?.Bar_Code]);

  const handleSearchQueryChange = (e) => {
    setQueryParams({
      ...queryParams,
      page: 1,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  //       method: "DELETE",
  //       credentials: "include",
  //       body: JSON.stringify(payload),
  //       headers: {
  //         Accept: "Application/json",
  //         "Content-Type": "Application/json",
  //         Authorization: `Bearer ${token}`,
  //       },
  //     });
  //     if (!response.ok) {
  //       response = await response.json();
  //       throw new Error(response.message);
  //     }
  //     const res = await response.json();
  //     return res;
  //   };
  //   const deleteMutation = useMutation((payload) => deleteBatch(payload), {
  //     onSuccess: ({ message, data: { batch } }) => {
  //       toast.success(message);
  //       queryClient.setQueryData(["BATCHES", queryParams], (data) => {
  //         data.batches = data.batches.filter(
  //           (el) => el.Bar_Code !== batch.Bar_Code
  //         );
  //         return data;
  //       });
  //     },
  //     onError: ({ message = "" }) => {
  //       toast.error(`Unable to perform action: ${message}`);
  //     },
  //   });

  const updateBatch = async (payload) => {
    let response = await fetch(`${backendUrl}/api/warehouse/update-batch`, {
      method: "POST",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
        Authorization: `Bearer ${token}`,
      },
      credentials: "include",
      body: JSON.stringify({
        ...payload,
      }),
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const { data } = await response.json();
    return data;
  };

  const updateBatchMutation = useMutation((payload) => updateBatch(payload), {
    onSuccess: () => {
      toast.success(`Success`);
      refetch();
    },
    onError: () => {
      toast.error(`Unable to perform action`);
    },
  });

  return (
    <Modal
      show={show}
      onHide={() => onHide()}
      dialogClassName="requisition-details-modal approve-modal"
      backdropClassName={`global-backdrop`}
      centered={true}
      animation={false}
      enforceFocus={false}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h1 className="h5">Batch Details</h1>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-4 d-flex flex-column">
        <div className="search-area d-flex justify-content-between align-items-center gap-3 w-100 px-3 mb-4 h6">
          <h2>
            {selectedItem?.Item_Name} / {selectedItem?.Bar_Code}
          </h2>

          <Form.Group>
            <Form.Check
              type="switch"
              label="Show All"
              name="showAll"
              className="ga-form-check fs-6"
              checked={queryParams.showAll}
              onChange={handleSearchQueryChange}
            />
          </Form.Group>
        </div>

        <div className="content px-1 pb-4 pe-2 flex-grow-1">
          <Table borderless responsive striped className="product-table">
            <thead className="sticky border-bottom">
              <tr>
                {/* <th /> */}
                <th>Batch</th>
                <th>Unit Cost</th>
                <th>Quantity</th>
                <th>Sub-Total</th>
                <th>Unit Price</th>
                {theWarehouse !== "" && theWarehouse !== "Default" ? null : (
                  <th>Storage Bin</th>
                )}
                <th>Reg Date</th>
              </tr>
            </thead>

            <tbody className="blue-hover">
              {data?.batches &&
                data.batches.map((el, index) => (
                  <tr key={index} className={`p-cursor`}>
                    {/* <td>
                      <Dropdown>
                        <Dropdown.Toggle
                          variant=""
                          className="bg-white border-0"
                          bsPrefix="print more"
                        >
                          <DotsVerticalIcon />
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                          popperConfig={{
                            strategy: "fixed",
                          }}
                          renderOnMount
                          className="dropdown-with-icons"
                        >
                          <Dropdown.Item
                            as="button"
                            onClick={() => handleEdit(el)}
                          >
                            <EditIcon /> Edit
                          </Dropdown.Item>
                          <Dropdown.Item
                            as="button"
                            onClick={() => handleDelete(el)}
                          >
                            <DeleteIcon /> Delete
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td> */}
                    <td>{el.Bar_Code ? el.Bar_Code.split("-")[1] : "..."}</td>
                    <td>
                      {currency(el.UnitCost, {
                        symbol: "",
                      }).format()}
                    </td>
                    <td>{el.Quantity}</td>
                    <td>
                      {currency(el.subTotal, {
                        symbol: "",
                      }).format()}
                    </td>
                    <td>
                      {currency(el.UnitPrice, {
                        symbol: "",
                      }).format()}
                    </td>
                    {theWarehouse !== "" &&
                    theWarehouse !== "Default" ? null : (
                      <td>{el?.Storage_StockBatch?.Storage_Name}</td>
                    )}
                    <td>
                      {el.RegDate
                        ? format(new Date(el.RegDate), "dd MMM, yyyy")
                        : "..."}
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>

        <div className="d-flex justify-content-between px-3 align-items-center pagination">
          <p className="m-0">
            Showing {data?.startIndex + 1} to{" "}
            {data?.endIndex <= data?.count ? data?.endIndex : data?.count} of{" "}
            {data.count} entries
          </p>

          <p className="fw-6">
            {data?.batches?.reduce(
              (p, c) => parseFloat(p) + parseFloat(c.Quantity),
              0
            )}
          </p>

          <p className="fw-6">
            {currency(
              data?.batches?.reduce(
                (p, c) =>
                  parseFloat(p) +
                  parseFloat(c.Quantity) * parseFloat(c.UnitCost),
                0
              ),
              { symbol: "" }
            ).format()}
          </p>

          <ReactPaginate
            {...paginationOptions}
            pageCount={Math.ceil(data.count / queryParams.limit)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={0}
            onPageChange={({ selected }) => {
              scrollToTop();
              setQueryParams({
                ...queryParams,
                page: selected + 1,
              });
            }}
            forcePage={queryParams.page - 1}
          />
        </div>
      </Modal.Body>

      <ModalLoader show={updateBatchMutation.isLoading} />
    </Modal>
  );
}
