import { useEffect } from "react";
import { useState } from "react";
import { useRef } from "react";
import { Button, Modal } from "react-bootstrap";
import { useQuery } from "react-query";
import { useAuth } from "../../hooks/useAuth";
import eventBus from "../../utils/EventBus";
import { useEffectOnce } from "../../utils/hooks";
import { cacheTimes, queryActions } from "../../utils/reactQueryActions";
import ModalLoader from "../utils/ModalLoader";
import { useStoreActions, useStoreState } from "easy-peasy";

export default function FormBuilderModal({
  showFormBuilderModal,
  setShowFormBuilderModal,
  saveForm,
  template,
}) {
  const iframeRef = useRef();
  const { backendUrl } = useAuth();

  const [isLoadingIframe, setIsLoadingIframe] = useState(true);
  const currentLoggedInCompany = useStoreState(
    (state) => state.currentLoggedInCompany
  );
  const handleMessage = (message) => {
    //  console.log(message);
    if (message.data.type === "CLOSE") {
      setShowFormBuilderModal(false);
    } else if (message.data.type === "SAVE") {
      saveForm({ data: message.data.value, isDraft: message.data?.isDraft });
    }
  };

  useEffect(() => {
    window.addEventListener("message", handleMessage);

    // cleanup this component
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  // fetch data for form
  const getDepartments = async () => {
    // await waitFor(5000);
    let response = await fetch(
      `${backendUrl}/api/users/departments-with-users`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    return data;
  };

  const {
    data = { departments: [], staffs: [], jobGrades: [] },
    isFetching,
  } = useQuery(
    [queryActions.GET_USERS_WITH_DEPARTMENTS],
    () => getDepartments(),
    {
      enabled: true,
      keepPreviousData: true,
      cacheTime: cacheTimes.GET_USERS_WITH_DEPARTMENTS,
    }
  );

  const sendSetupData = () => {
    //   console.log(currentLoggedInCompany, "kdkk");
    const iframe = iframeRef.current;
    if (iframe && iframe.contentWindow) {
      iframe.contentWindow.postMessage(
        {
          data: {
            ...data,
            template: template ? JSON.parse(template) : null,
            backendUrl,
            companyData: currentLoggedInCompany,
          },
        },
        process.env.REACT_APP_FORM_BUILDER_URL
      );
    }
  };

  eventBus.useCustomEventListener(
    "SEND_FILE_TO_IFRAME",
    ({ files, elementId, elementType }) => {
      const iframe = iframeRef.current;
      if (iframe && iframe.contentWindow) {
        iframe.contentWindow.postMessage(
          {
            data: {
              files,
              elementId,
              elementType,
            },
            type: "FILES_FROM_PARENT",
          },
          process.env.REACT_APP_FORM_BUILDER_URL
        );
      }
    }
  );

  return (
    <>
      <Modal
        show={showFormBuilderModal}
        fullscreen={true}
        animation={false}
        onHide={() => setShowFormBuilderModal(false)}
      >
        <Modal.Body className="p-0">
          {!isFetching ? (
            <iframe
              ref={iframeRef}
              id="Frame"
              onLoad={(e) => {
                setIsLoadingIframe(false);
                sendSetupData();
              }}
              className="w-100 h-100"
              title="Form Builder"
              src={`${process.env.REACT_APP_FORM_BUILDER_URL}/form/build`}
            />
          ) : null}
        </Modal.Body>

        {/*  <Button onClick={(e) => sendSetupData()}>...</Button> */}
      </Modal>

      <ModalLoader show={isLoadingIframe || isFetching} />
    </>
  );
}
