import { isEmpty } from "lodash";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Link, useLocation } from "react-router-dom";
import { backendApis } from "../config";
import { useAuth } from "../hooks/useAuth";
import { searchParamsToObject } from "../utils/helpers";
import { useEffectOnce } from "../utils/hooks";
import ModalLoader from "./utils/ModalLoader";

export default function Authorize() {
  const navigate = useNavigate();
  const { login } = useAuth();
  const location = useLocation();

  const auth = async () => {
    let url = new URL(document.location.href);
    const { invextoken = "", redirect = "", route = "" } = searchParamsToObject(
      url.searchParams.entries()
    );

    // TODO - secure unique key
    const loginResponse = await fetch(`${route}/api/auth/auth`, {
      method: "POST",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      body: JSON.stringify({ token: invextoken }),
      credentials: "include",
    });

    const { success, user } = await loginResponse.json();

    if (!success) {
      toast.error("Unable to login, check your connection and refresh the page");
    } else {
      const company = backendApis.find(el => el.url === route)
      login({ ...user, company: company.name });
      window.location.href = redirect
      //navigate(redirect, { replace: true });
    }
  };

  useEffectOnce(() => {
    auth();
  });

  return <ModalLoader show={true} />;
}
