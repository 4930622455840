import { Dropdown } from "react-bootstrap";
import { CSVLink } from "react-csv";
import { ExportIcon, ExcelIcon, PDFIcon, FilterTwoIcon } from "./../../Icons";
import "./../../../assets/scss/reports/cashbook.scss";
import CachedIcon from "mdi-react/CachedIcon";
import { useEffect, useState, useRef } from "react";
import useDebounce, {
  useIsAdmin,
  useIsStore,
  useScrollTop,
} from "../../../utils/hooks";
import { appSettings } from "../../../config";
import queryString from "query-string";
import { useQuery } from "react-query";
import { reportActions } from "../../../utils/reactQueryActions";
import { format, parse } from "date-fns";
import {
  customerFullName,
  employeeFullName,
  formatForQty,
  getStockQuantityAfter,
  getStockQuantityBefore,
  paginationOptions,
  qtyFormat,
  qtyFormatToString,
} from "../../../utils/helpers";
import ReactPaginate from "react-paginate";
import currency from "currency.js";
import { isEmpty } from "lodash";
import { useAuth } from "../../../hooks/useAuth";
import { useStoreState } from "easy-peasy";
import NoTableItem from "../../utils/NoTableItem";
import { useDownloadExcel } from "../../../hooks/useDownloadExcel";

import TableComponent from "../../TableComponent";
import { useNavigate, useOutletContext } from "react-router-dom";
import ConvertQuantity from "../../utils/ConvertQuantity";
import ModalLoader from "../../utils/ModalLoader";
import { OverlayTooltip } from "../../Component";

export default function Sales() {
  useScrollTop();
  const {
    queryParams,
    setQueryParams,
    showFilter,
    setShowFilter,
    setProfit,
  } = useOutletContext();
  const navigate = useNavigate();

  const initialFilterParams = {};

  const isStore = useIsStore();

  const itemMeasurements = useStoreState((state) => state.itemMeasurements);
  const { backendUrl, isIronRod, isCement } = useAuth();
  const isAdmin = useIsAdmin();
  const [excelData, setExcelData] = useState([]);

  const CSVLinkRef = useRef(null);

  // fetch excel hook
  const [isfetchingExcel, fetchExcelData] = useDownloadExcel(
    excelData,
    CSVLinkRef
  );

  const [filterParams] = useState({
    ...initialFilterParams,
    ...queryParams,

    // Get the date in url and parse so date picker can use it
    date: queryParams?.date
      ? parse(queryParams?.date, "yyyy-MM-dd", new Date())
      : "",
  });

  const debouncedFilterParams = useDebounce(filterParams, 500);
  useEffect(() => {
    setQueryParams({
      ...queryParams,
      ...debouncedFilterParams,
    });
  }, [debouncedFilterParams]);

  const fetchSales = async (queryParams) => {
    console.log(queryParams);
    // await waitFor(5000);
    let response = await fetch(
      `${backendUrl}/api/reports/sales-analysis?&${queryString.stringify(
        queryParams
      )}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    data.items = data.items.map((el) => ({
      label: el.Item_Name,
      value: el.Item_Name,
    }));

    data.branch = [
      {
        label: "All",
        value: "",
      },
      ...data.branch
        .map((el) => ({
          label: el.Branch,
          value: el.Branch,
        }))
        .filter((el) => Boolean(el.value)),
    ];

    data.salesRep = data.salesRep
      .map((el) => ({
        label: employeeFullName(el),
        value: el.EmployeeID,
      }))
      .filter((el) => el.label);

    data.overwriteOfficer = data.overwriteOfficer.map((el) => ({
      label: el.OverwriteOfficer,
      value: el.OverwriteOfficer,
    }));

    data.type = data.type.map((el) => ({
      label: el.Item_Type,
      value: el.Item_Type,
    }));

    data.productName = [
      {
        label: "All",
        value: "",
      },
      ...data.productName
        .map((el) => ({
          label: el.ProductName,
          value: el.ProductName,
        }))
        .filter((el) => el.value),
    ];

    //console.log(data);
    setProfit(
      currency(data.totalProfit, {
        symbol: "",
      }).format()
    );
    return data;
  };

  const {
    data = {
      count: 0,
      sales: [],
      quantityInStockRemaining: 0,
      productName: [],
    },
    refetch,
    isFetching,
    isSuccess,
  } = useQuery(
    [reportActions.GET_SALES, queryParams],
    () => fetchSales(queryParams),
    {
      keepPreviousData: true,
    }
  );

  // download excel function
  const { limit, page, ...rest } = queryParams;
  const newQuery = {
    ...rest,
    isCement,
    isAdmin,
    isIronRod,
  };

  const onDownloadExcelData = async () => {
    let exData = await fetchExcelData(
      `${backendUrl}/api/reports/sales-analysis?${queryString.stringify(rest)}`,
      "GET"
    );

    const company = exData.data?.company?.CompName;

    exData =
      queryParams.reportType !== "Summary"
        ? exData.data.sales.map((row) => ({
            ...row,
            Date_Log: format(new Date(row?.Date_Log), "dd-MMM-yyyy hh:mm:ss a"),
          }))
        : exData.data.sales;

    const date =
      rest.startDate && rest.endDate
        ? `Date Prepared: Between ${format(
            new Date(rest.startDate),
            "E MMM d yyyy k:mm:ss z"
          )} to ${format(new Date(rest.endDate), "E MMM d yyyy k:mm:ss z")}`
        : "";

    const Summary = [
      "S/N",
      isCement ? "ATC" : "Item Code",
      "Item Name",
      "QTY Sold",
      "Subtotal",
      isAdmin ? "Profit" : isCement ? "Loading Charge" : "",
      isCement ? "Offloading Charge" : "",
    ];

    const SummaryData = exData.map((d, i) => [
      i + 1,
      d.Bar_Code,
      d.Item_Name,
      isIronRod
        ? qtyFormatToString(
            qtyFormat(d.qtySold, d.Serial_Number, itemMeasurements)
          )
        : d.qtySold,

      currency(d.SubTotal, {
        symbol: "",
      }).format(),
      isAdmin
        ? currency(d.Profit, {
            symbol: "",
          }).format()
        : isCement
        ? currency(d?.deliveryInfo?.LoadingCharge, {
            symbol: "",
          }).format()
        : "",
      isCement
        ? currency(d?.deliveryInfo?.OffloadingCharge, {
            symbol: "",
          }).format()
        : "",
    ]);

    const Full = [
      "S/N",
      "Date",
      isCement ? "ATC" : "Item Code",
      "Description",
      "QTY",
      "Price",
      "Subtotal",
      "Discount",
      "VAT",
      "Amount",
      isAdmin ? "Profit" : "",
      "Valuation",
      "Cost",
      "Sales Type",
      "Cashier",
      "Invoice Number",
      "Manufacturer",
      "Customer",
      "Processby",
      "Orderby",
      isCement ? "Loading Charge" : "",
      isCement ? "Offloading Charge" : "",
    ];

    const FullData = exData.map((d, i) => [
      i + 1,
      d.Date_Log ? format(new Date(d.Date_Log), "yyyy-MM-dd") : "...",
      d.Bar_Code,
      d.Item_Name,
      isIronRod
        ? qtyFormatToString(qtyFormat(d.QTY, d.Serial_Number, itemMeasurements))
        : d.QTY,

      currency(d.PriceSold, {
        symbol: "",
      }).format(),
      currency(d.SubTotal, {
        symbol: "",
      }).format(),

      currency(d.Discount, {
        symbol: "",
      }).format(),
      0,
      currency(d.SubTotal, {
        symbol: "",
      }).format(),
      isAdmin
        ? currency(d.Profit, {
            symbol: "",
          }).format()
        : "",
      currency(d.UnitCost, {
        symbol: "",
      })
        .multiply(d.QTY)
        .format(),
      currency(d.UnitCost, {
        symbol: "",
      }).format(),
      d.OverwriteOfficer,
      d.UserName,
      d.TransactionID,
      d.ProductName,
      customerFullName(d.customer),
      "",
      "",
      isCement
        ? currency(d?.deliveryInfo?.LoadingCharge, {
            symbol: "",
          }).format()
        : "",
      isCement
        ? currency(d?.deliveryInfo?.OffloadingCharge, {
            symbol: "",
          }).format()
        : "",
    ]);

    const Detailed = [
      queryParams?.customerId ? "Customer" : "",
      isCement ? "ATC" : "Model No",
      !queryParams?.customerId ? "Item Name" : "",
      "QTY",
      "Price Sold",
      "Unit Cost",
      "Subtotal",
      isAdmin ? "Profit" : "",
      !queryParams?.customerId ? "Sales Date" : "",
      isCement ? "Loading Charge" : "",
      isCement ? "Offloading Charge" : "",
    ];

    const DetailedData = exData.map((d) => [
      queryParams?.customerId ? customerFullName(d.customer) : "",
      d.Bar_Code,
      !queryParams?.customerId ? d.Item_Name : "",
      isIronRod
        ? qtyFormatToString(qtyFormat(d.QTY, d.Serial_Number, itemMeasurements))
        : d.QTY,
      currency(d.PriceSold, {
        symbol: "",
      }).format(),
      currency(d.Unit_Price, {
        symbol: "",
      }).format(),
      currency(d.SubTotal, {
        symbol: "",
      }).format(),

      isAdmin
        ? currency(d.Profit, {
            symbol: "",
          }).format()
        : "",

      !queryParams?.customerId ? d.Date_Log : "",

      isCement
        ? currency(d?.deliveryInfo?.LoadingCharge, {
            symbol: "",
          }).format()
        : "",

      isCement
        ? currency(d?.deliveryInfo?.OffloadingCharge, {
            symbol: "",
          }).format()
        : "",
    ]);

    const headers =
      queryParams.reportType === "Summary"
        ? Summary
        : queryParams.reportType === "Full"
        ? Full
        : queryParams.reportType === "Detailed"
        ? Detailed
        : [""];

    exData =
      queryParams.reportType === "Summary"
        ? SummaryData
        : queryParams.reportType === "Full"
        ? FullData
        : queryParams.reportType === "Detailed"
        ? DetailedData
        : [""];

    exData = [
      [company],
      ["Sales Analysis Report"],
      [date],
      [""],
      headers,
      ...exData,
      [""],
      ["Summary"],
      [
        "Total Sales",
        currency(data.totalSales, {
          symbol: "",
        }).format(),
      ],
      isAdmin
        ? [
            "Total Profit",
            currency(data.totalProfit, {
              symbol: "",
            }).format(),
          ]
        : [""],
      [
        "Quantity Sold",
        currency(data.qtySold, {
          symbol: "",
          precision: 2,
          format: formatForQty,
        }).format(),
      ],
      isCement
        ? [
            "Total Loading Charge",
            currency(data.totalLoadingCharge, {
              symbol: "",
              precision: 2,
            }).format(),
          ]
        : [""],
      isCement
        ? [
            "Total Offloading Charge",
            currency(data.totalOffloadingCharge, {
              symbol: "",
              precision: 2,
            }).format(),
          ]
        : [""],
    ];
    console.log(exData);
    setExcelData(exData);
  };

  const handleSearchQueryChange = (e) => {
    setQueryParams({
      // ...searchParamsToObject(searchQuery.entries()),
      ...queryParams,
      [e.target.name]: e.target.value,
    });
  };

  const SummaryTableHead = () => {
    return (
      <thead>
        <tr>
          <th>S/N</th>
          <th>{isCement ? "ATC" : "Item Code"}</th>
          <th>Item Name</th>
          <th>QTY Sold</th>
          <th>Subtotal</th>
          {isAdmin ? <th>Profit</th> : null}
          {/*  <th>Sales Date</th> */}
          {isCement || !appSettings.isBatchStandard ? (
            <>
              <th>Loading Charge</th>
              <th>Offloading Charge</th>
              {isIronRod ? (
                <>
                  <th>Transport Charge</th>
                  <th>Pos Charge</th>
                </>
              ) : null}
            </>
          ) : null}
        </tr>
      </thead>
    );
  };

  const SummaryTableData = (el, index) => {
    return (
      <>
        <td>{data?.startIndex + index + 1}</td>
        <td>{el.Bar_Code}</td>
        <td>{el.Item_Name}</td>

        <td>
          <ConvertQuantity quantity={el.qtySold} desc={el.Serial_Number} />
        </td>
        <td>
          {currency(el.SubTotal, {
            symbol: "",
          }).format()}
        </td>
        {isAdmin ? (
          <td>
            {currency(el.Profit, {
              symbol: "",
            }).format()}
          </td>
        ) : null}
        {/*  <td>
                      {el.Date_Log
                        ? format(new Date(el.Date_Log), "yyyy-MM-dd")
                        : "..."}
                    </td> */}
        {isCement || !appSettings.isBatchStandard ? (
          <>
            <td>
              {currency(el?.deliveryInfo?.LoadingCharge, {
                symbol: "",
              }).format()}
            </td>
            <td>
              {currency(el?.deliveryInfo?.OffloadingCharge, {
                symbol: "",
              }).format()}
            </td>
            {isIronRod ? (
              <>
                <td>
                  {currency(el?.deliveryInfo?.TransportCharge, {
                    symbol: "",
                  }).format()}
                </td>
                <td>
                  {currency(el?.deliveryInfo?.PosCharge, {
                    symbol: "",
                  }).format()}
                </td>
              </>
            ) : null}
          </>
        ) : null}
      </>
    );
  };

  const FullTableHead = () => {
    return (
      <thead>
        <tr>
          <th>S/N</th>
          <th>Date</th>
          <th>{isCement ? "ATC" : "Item Code"}</th>
          <th>Description</th>
          <th>QTY</th>

          <th>Price</th>
          <th>Subtotal</th>
          <th>Discount</th>
          <th>VAT</th>
          <th>Amount</th>
          {isAdmin ? <th>Profit</th> : null}
          <th>Valuation</th>
          <th>Cost</th>
          <th>Sales Type</th>
          <th>Cashier</th>
          <th>Invoice Number</th>
          <th>Manufacturer</th>
          <th>Business Name</th>
          <th>Processby</th>
          <th>Orderby</th>
          {isCement || !appSettings.isBatchStandard ? (
            <>
              <th>Loading Charge</th>
              <th>Offloading Charge</th>
              {isIronRod ? (
                <>
                  <th>Transport Charge</th>
                  <th>Pos Charge</th>
                </>
              ) : null}
            </>
          ) : null}
        </tr>
      </thead>
    );
  };

  const FullTableData = (el, index) => {
    return (
      <>
        <td>{data?.startIndex + index + 1}</td>
        <td>
          {el.Date_Log ? format(new Date(el.Date_Log), "yyyy-MM-dd") : "..."}
        </td>
        <td>{el.Bar_Code}</td>
        <td>{el.Item_Name}</td>
        <td>
          <ConvertQuantity quantity={el.QTY} desc={el.Serial_Number} />
        </td>

        <td>
          {currency(el.PriceSold, {
            symbol: "",
          }).format()}
        </td>
        <td>
          {currency(el.SubTotal, {
            symbol: "",
          }).format()}
        </td>
        <td>
          {currency(el.Discount, {
            symbol: "",
          }).format()}
        </td>
        <td>{0}</td>
        <td>
          {currency(el.SubTotal, {
            symbol: "",
          }).format()}
        </td>
        {isAdmin ? (
          <td>
            {currency(el.Profit, {
              symbol: "",
            }).format()}
          </td>
        ) : null}
        <td>
          {currency(el.UnitCost, {
            symbol: "",
          })
            .multiply(el.QTY)
            .format()}
        </td>
        <td>
          {currency(el.UnitCost, {
            symbol: "",
          }).format()}
        </td>
        <td>{el.OverwriteOfficer}</td>
        <td>{el.UserName}</td>
        <td>{el.TransactionID}</td>
        <td>{el.ProductName}</td>

        <td>
          {" "}
          {el?.customer?.LastName === "Walk-In"
            ? `${el?.transaction?.ShipTo} (Walk-In)`
            : customerFullName(el.customer)}
          {el?.TransactionFrom && ` (${el?.TransactionFrom})`}
        </td>
        <td>{el.UserName}</td>
        <td> </td>
        {isCement || !appSettings.isBatchStandard ? (
          <>
            <td>
              {currency(el?.deliveryInfo?.LoadingCharge, {
                symbol: "",
              }).format()}
            </td>
            <td>
              {currency(el?.deliveryInfo?.OffloadingCharge, {
                symbol: "",
              }).format()}
            </td>
            {isIronRod ? (
              <>
                <td>
                  {currency(el?.deliveryInfo?.TransportCharge, {
                    symbol: "",
                  }).format()}
                </td>
                <td>
                  {currency(el?.deliveryInfo?.PosCharge, {
                    symbol: "",
                  }).format()}
                </td>
              </>
            ) : null}
          </>
        ) : null}
      </>
    );
  };

  const DetailedTableHead = () => {
    return (
      <thead>
        <tr>
          <th>Transaction ID</th>
          {/* queryParams?.customerId && */ <th>Business Name</th>}
          <th>{isCement ? "ATC" : "Model No"}</th>
          {!queryParams?.customerId && <th>Item Name</th>}

          {isStore || isAdmin ? (
            <>
              <th>QTY Before Sale </th>
              <th>QTY Sold</th>
              <th>QTY After Sale </th>
            </>
          ) : (
            <>
              <th>QTY Sold</th>
            </>
          )}

          <th>Price Sold</th>
          <th>Unit Cost</th>
          <th>Subtotal</th>
          {isAdmin ? <th>Profit</th> : null}
          {!queryParams?.customerId && <th>Sales Date</th>}
          {isCement || !appSettings.isBatchStandard ? (
            <>
              <th>Loading Charge</th>
              <th>Offloading Charge</th>
              {isIronRod ? (
                <>
                  <th>Transport Charge</th>
                  <th>Pos Charge</th>
                </>
              ) : null}
            </>
          ) : null}
        </tr>
      </thead>
    );
  };

  const DetailedTableData = (el, index) => {
    return (
      <>
        <td>{el.TransactionID}</td>

        <td>
          {el?.customer?.LastName === "Walk-In"
            ? `${el?.transaction?.ShipTo} (Walk-In)`
            : customerFullName(el.customer)}
          {el?.TransactionFrom && ` (${el?.TransactionFrom})`}
        </td>
        <td>{el.Bar_Code}</td>
        {!queryParams?.customerId && <td>{el.Item_Name}</td>}

        {isStore || isAdmin ? (
          <>
            <td>
              <ConvertQuantity
                quantity={getStockQuantityBefore(el.Warranty)}
                desc={el.Serial_Number}
              />
            </td>

            <td>
              <ConvertQuantity quantity={el.QTY} desc={el.Serial_Number} />
            </td>

            <td>
              <ConvertQuantity
                quantity={getStockQuantityAfter(el.Warranty)}
                desc={el.Serial_Number}
              />
              {el.Shipping_Status !== "Supplied" && (
                <OverlayTooltip
                  text={`${el.Shipping_Status} `}
                  component={<span className="text-info">*</span>}
                />
              )}
            </td>
          </>
        ) : (
          <>
            <td>
              <ConvertQuantity quantity={el.QTY} desc={el.Serial_Number} />
            </td>
          </>
        )}

        <td>
          {currency(el.PriceSold, {
            symbol: "",
          }).format()}
        </td>
        <td>
          {currency(el.UnitCost, {
            symbol: "",
          }).format()}
        </td>
        <td>
          {currency(el.SubTotal, {
            symbol: "",
          }).format()}
        </td>
        {isAdmin ? (
          <td>
            {currency(el.Profit, {
              symbol: "",
            }).format()}
          </td>
        ) : null}
        {!queryParams?.customerId && (
          <td>
            {el.Date_Log ? format(new Date(el.Date_Log), "yyyy-MM-dd") : "..."}
          </td>
        )}
        {isCement || !appSettings.isBatchStandard ? (
          <>
            <td>
              {currency(el?.deliveryInfo?.LoadingCharge, {
                symbol: "",
              }).format()}
            </td>
            <td>
              {currency(el?.deliveryInfo?.OffloadingCharge, {
                symbol: "",
              }).format()}
            </td>
            {isIronRod ? (
              <>
                <td>
                  {currency(el?.deliveryInfo?.TransportCharge, {
                    symbol: "",
                  }).format()}
                </td>
                <td>
                  {currency(el?.deliveryInfo?.PosCharge, {
                    symbol: "",
                  }).format()}
                </td>
              </>
            ) : null}
          </>
        ) : null}
      </>
    );
  };

  const manageTransaction = (event, el) => {
    if (event.detail === 2 && el.TransactionID) {
      navigate(`/inventory-for-sales/manage-transaction`, {
        state: {
          TransactionID: el.TransactionID,
        },
      });
    }
  };

  return (
    <>
      <div className="absolute-title">
        <h1> Sales Reps Analysis</h1>
      </div>
      <main className="cash-book">
        <div className="content">
          <div className="content-main">
            <div className="content-body">
              <header>
                <h1 className="d-flex align-items-center gap-3">
                  {!showFilter && (
                    <button
                      onClick={() => setShowFilter(!showFilter)}
                      className="btn filter"
                    >
                      <FilterTwoIcon />
                    </button>
                  )}
                  Sales/Cash Spreadsheet
                  <button
                    title="Refresh"
                    onClick={() => refetch()}
                    className="btn text-primary"
                  >
                    <CachedIcon />
                  </button>
                </h1>

                <div className="actions">
                  <CSVLink
                    className="btn print d-none"
                    filename={`sales-analysis(${format(
                      new Date(),
                      "dd-MMM-yyyy hh:mm:ss a"
                    )}).csv`}
                    data={excelData}
                    ref={CSVLinkRef}
                  />

                  {/* <button /> */}
                  <Dropdown>
                    <Dropdown.Toggle
                      variant=""
                      className="btn print"
                      disabled={isfetchingExcel}
                      bsPrefix=""
                    >
                      Export
                      <ExportIcon color="#008000" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      popperConfig={{
                        strategy: "fixed",
                      }}
                      renderOnMount
                      className="text-center"
                    >
                      <Dropdown.Item
                        as="div"
                        onClick={onDownloadExcelData}
                        className="p-cursor"
                      >
                        Excel <ExcelIcon color="#008000" />
                      </Dropdown.Item>
                      <Dropdown.Item as="div">
                        <a
                          href={`${backendUrl}/api/reports/pdf/sales-analysis?${queryString.stringify(
                            newQuery
                          )}`}
                          target="blank"
                        >
                          PDF
                          <PDFIcon color="#ff0000" />
                        </a>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </header>

              <div className="px-md-4">
                <TableComponent
                  responsive
                  borderless
                  striped
                  tableHeadsFunction={
                    queryParams.reportType === "Summary"
                      ? SummaryTableHead
                      : queryParams.reportType === "Full"
                      ? FullTableHead
                      : queryParams.reportType === "Detailed"
                      ? DetailedTableHead
                      : null
                  }
                  mainDataArray={data?.sales}
                  tableDataRowFunction={
                    queryParams.reportType === "Summary"
                      ? SummaryTableData
                      : queryParams.reportType === "Full"
                      ? FullTableData
                      : queryParams.reportType === "Detailed"
                      ? DetailedTableData
                      : null
                  }
                  className="product-table text-nowrap"
                  tableRowClick={(e, el) => manageTransaction(e, el)}
                />

                {!isFetching && isSuccess && isEmpty(data?.sales) ? (
                  <NoTableItem queryParams={queryParams} />
                ) : null}
              </div>

              {!data?.paginationDisabled ? (
                <div className="d-flex justify-content-between px-3 align-items-center pagination">
                  <div className="pagination_left">
                    <p className="m-0 p-0">Show</p>

                    <select
                      value={queryParams.limit}
                      name="limit"
                      className="form-select "
                      onChange={(e) => handleSearchQueryChange(e)}
                    >
                      <option value="10">10 rows</option>
                      <option value="20">20 rows</option>
                      <option value="30">30 rows</option>
                      <option value="40">40 rows</option>
                      <option value="50">50 rows</option>
                      <option value="100">100 rows</option>
                    </select>
                  </div>

                  <ReactPaginate
                    {...paginationOptions}
                    pageCount={data.count / queryParams.limit}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={0}
                    onPageChange={({ selected }) => {
                      document.body.scrollTop = document.documentElement.scrollTop = 0;
                      setQueryParams({
                        ...queryParams,
                        page: selected + 1,
                      });
                    }}
                    forcePage={queryParams.page - 1}
                  />
                </div>
              ) : (
                <div className="py-2" />
              )}
            </div>
          </div>
        </div>
        <ModalLoader show={isfetchingExcel} />
      </main>
    </>
  );
}
