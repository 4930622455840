import MagnifyIcon from "mdi-react/MagnifyIcon";
import { useState } from "react";
import {
  Modal,
  Table,
  Form,
  Button,
  Dropdown,
  InputGroup,
} from "react-bootstrap";
// import "./../assets/scss/customer-select-modal.scss";
import useDebounce, { useIsAdmin, useIsSalesManager } from "../../utils/hooks";
import { services } from "../../config";
import queryString from "query-string";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { queryActions } from "../../utils/reactQueryActions";
import Select from "react-select";
import ReactPaginate from "react-paginate";
import {
  consumptionTypes,
  copyText,
  customerFullName,
  formatDate,
  paginationOptions,
} from "../../utils/helpers";
import currency from "currency.js";
import { useAuth } from "../../hooks/useAuth";
import CachedIcon from "mdi-react/CachedIcon";
import RsDateRangePicker from "../utils/RsDateRangePicker";
import { parse, format, differenceInWeeks } from "date-fns";
import DotsHorizontalIcon from "mdi-react/DotsHorizontalIcon";
import { DeleteIcon, EditIcon, LinkVariantIcon, RecieptIcon } from "../Icons";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import ConfirmDialog from "../ConfirmDialogue";
import ModalLoader from "../utils/ModalLoader";
import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";
import { isEmpty } from "lodash";
import NoTableItem from "../utils/NoTableItem";
import BatchSelector from "../utils/BatchSelector";
import ConvertQuantity from "../utils/ConvertQuantity";
import { Popover } from "react-tiny-popover";
import { useStoreState } from "easy-peasy";

export default function BatchProductionReportModal({
  setShowProductionReportModal,
  handleSelectedProductionReport,
  selectedBatchItem,
  setSelectedBatchItem,
  selectedItem,
}) {
  const { backendUrl } = useAuth();
  const queryClient = useQueryClient();
  const isAdmin = useIsAdmin();
  const isSalesManager = useIsSalesManager();
  const [showBatchItemsPopover, setShowBatchItemsPopover] = useState(false);

  const [queryParams, setQueryParams] = useState({
    page: 1,
    limit: 40,
    // Bar_Code: selectedItem.Bar_Code,
    parentBatchBarCode: selectedBatchItem.Bar_Code,
    showAll: true,
  });
  const generalSettings = useStoreState((state) => state.generalSettings);

  const debouncedqueryParams = useDebounce(queryParams, 500);

  const getProductionReport = async (debouncedqueryParams) => {
    let response = await fetch(
      `${backendUrl}/api/items/batches?${queryString.stringify(
        debouncedqueryParams
      )}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const { data } = await response.json();
    return data;
  };

  const { data = { batches: [] }, refetch, isFetching, isSuccess } = useQuery(
    ["CONSUMPTIONS", debouncedqueryParams],
    () => getProductionReport(debouncedqueryParams),
    {
      enabled: true,
      keepPreviousData: true,
    }
  );

  const handleSearchQueryChange = (e) => {
    setQueryParams({
      ...queryParams,
      page: 1,
      [e.target.name]: e.target.value,
    });
  };

  const clearDateRange = () => {
    setQueryParams({
      ...queryParams,
      startDate: "",
      endDate: "",
    });
  };

  const filterByDateRange = (date) => {
    setQueryParams({
      ...queryParams,
      startDate: format(date[0], "yyyy-MM-dd"),
      endDate: format(date[1], "yyyy-MM-dd"),
    });
  };

  const handleSelectedBatchItem = (batchItem) => {
    setQueryParams({
      ...queryParams,
      page: 1,
      parentBatchBarCode: batchItem.Bar_Code,
      parentBatchItemName: batchItem.ItemName,
      parentBatchName: batchItem.Batch_Name,
    });
    setShowBatchItemsPopover(false);
  };

  return (
    <>
      <>
        <Modal
          show={true}
          onHide={() => setShowProductionReportModal(false)}
          backdropClassName={`global-backdrop`}
          dialogClassName="modal-90w"
          centered={true}
          animation={false}
          enforceFocus={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <h1 className="h5">
                Production Report - {selectedBatchItem.ItemName}/
                {selectedBatchItem?.Bar_Code}/{selectedBatchItem.Batch_Name}
                <button
                  onClick={() => refetch()}
                  className="btn text-primary mx-3"
                >
                  <CachedIcon />
                </button>
              </h1>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex mb-4">
              <InputGroup className="w-50">
                <Form.Control
                  className=""
                  type="text"
                  placeholder="Select Parent Batch"
                  name="parentBatchBarCode"
                  value={`${queryParams.parentBatchBarCode ||
                    "..."} / ${queryParams.parentBatchItemName ||
                    "..."} / ${queryParams.parentBatchName || "..."}`}
                  onChange={() => {}}
                />

                <Popover
                  isOpen={showBatchItemsPopover}
                  onClickOutside={() => setShowBatchItemsPopover(false)}
                  content={() => (
                    <BatchSelector
                      handleSelectedBatchItem={handleSelectedBatchItem}
                    />
                  )}
                  position="bottom"
                >
                  <InputGroup.Text
                    onClick={() =>
                      setShowBatchItemsPopover(!showBatchItemsPopover)
                    }
                  >
                    <MagnifyIcon />
                  </InputGroup.Text>
                </Popover>
              </InputGroup>
              <div className="px-3 d-flex gap-3">
                <RsDateRangePicker
                  placement="bottomEnd"
                  defaultValue={
                    queryParams.startDate && queryParams.endDate
                      ? [
                          parse(
                            queryParams.startDate,
                            "yyyy-MM-dd",
                            new Date()
                          ),
                          parse(queryParams.endDate, "yyyy-MM-dd", new Date()),
                        ]
                      : []
                  }
                  onClean={() => clearDateRange()}
                  onOk={(date) => filterByDateRange(date)}
                />
              </div>
            </div>

            <hr />

            <div className="content px-1 pb-4 pe-2 flex-grow-1">
              <Table borderless responsive striped className="product-table">
                <thead className="sticky border-bottom">
                  <tr>
                    <th>Batch</th>
                    <th>{generalSettings.Batch_Name}</th>
                    <th>Vendor Invoice No</th> <th>Parent Batch</th>
                    <th>Unit Cost</th>
                    <th>Initial Quantity</th>
                    <th>Quantity</th>
                    <th>Sub-Total</th>
                    <th>Unit Price</th>
                    {selectedItem?.Product_Model === "Tons" ? (
                      <th>Unit Price (Pcs)</th>
                    ) : null}
                    <th>Reg Date</th>
                    <th>Man Date</th>
                    <th>Age</th>
                    <th>Exp Date</th>
                    <th>Driver Name</th>
                    <th>Phone Number</th>
                    <th>Truck Number</th>
                    <th>Delivery Date</th>
                  </tr>
                </thead>

                <tbody className="blue-hover">
                  {data?.batches &&
                    data.batches.map((el, index) => (
                      <tr key={index} className={`p-cursor`}>
                        <td>
                          {el.Bar_Code ? el.Bar_Code.split("-").pop() : "..."}
                        </td>
                        <td>{el.Batch_Name}</td>
                        <td>
                          {el?.InvoiceNo ? (
                            <Link
                              to={`/reports/purchase-history?NoDate=${true}&invoiceNumber=${
                                el?.InvoiceNo
                              }`}
                            >
                              {el?.InvoiceNo}
                            </Link>
                          ) : (
                            "..."
                          )}
                        </td>
                        <td>
                          {`${el.parentBatchBarCode ||
                            "..."} / ${el.parentBatchItemName ||
                            "..."} / ${el.parentBatchName || "..."}`}
                        </td>
                        <td>
                          {currency(el.UnitCost, {
                            symbol: "",
                          }).format()}
                        </td>

                        <td className="text-nowrap">
                          <ConvertQuantity
                            quantity={el.originalQuantity}
                            desc={data?.item?.Item_Desc}
                          />
                        </td>

                        <td className="text-nowrap">
                          <ConvertQuantity
                            quantity={el.Quantity}
                            desc={data?.item?.Item_Desc}
                          />
                        </td>

                        <td>
                          {currency(el.subTotal, {
                            symbol: "",
                          }).format()}
                        </td>
                        <td>
                          {currency(el.UnitPrice, {
                            symbol: "",
                          }).format()}
                        </td>
                        {selectedItem?.Product_Model === "Tons" ? (
                          <td>
                            {currency(el.VAT, {
                              symbol: "",
                            }).format()}
                          </td>
                        ) : null}

                        <td className="text-nowrap">
                          {el.RegDate
                            ? format(new Date(el.RegDate), "dd MMM, yyyy")
                            : "..."}
                        </td>
                        <td className="text-nowrap">
                          {el.ManDate
                            ? format(new Date(el.ManDate), "dd MMM, yyyy")
                            : "..."}
                        </td>
                        <td className="text-nowrap">
                          {el.ManDate
                            ? differenceInWeeks(
                                new Date(),
                                new Date(el.ManDate)
                              )
                            : "..."}{" "}
                          Weeks
                        </td>
                        <td className="text-nowrap">
                          {el.ExpireDate
                            ? format(new Date(el.ExpireDate), "dd MMM, yyyy")
                            : "..."}
                        </td>
                        <td>{el?.DriverName}</td>
                        <td>{el?.DriverPhoneNumber}</td>
                        <td>{el?.TruckNumber}</td>
                        <td>
                          {el?.DeliveryDate
                            ? format(new Date(el.DeliveryDate), "dd MMM, yyyy")
                            : "..."}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </div>

            <div className="d-flex justify-content-between px-3 align-items-center pagination">
              <p className="m-0">
                Showing {data?.startIndex + 1} to{" "}
                {data?.endIndex <= data?.count ? data?.endIndex : data?.count}{" "}
                of {data.count} entries
              </p>

              <ReactPaginate
                {...paginationOptions}
                pageCount={Math.ceil(data.count / queryParams.limit)}
                marginPagesDisplayed={2}
                pageRangeDisplayed={0}
                onPageChange={({ selected }) => {
                  document.body.scrollTop = document.documentElement.scrollTop = 0;
                  setQueryParams({
                    ...queryParams,
                    page: selected + 1,
                  });
                }}
                forcePage={queryParams.page - 1}
              />
            </div>
          </Modal.Body>
        </Modal>
      </>
    </>
  );
}
