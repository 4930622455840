import { useMemo, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { FieldArray, useFormik, FormikProvider } from "formik";
import { Form, Table } from "react-bootstrap";
import { useEffectOnce } from "../../utils/hooks";
import * as yup from "yup";
import Select from "react-select";
import CurrencyCustomInput from "../utils/CurrencyCustomInput";
import NumberCustomInput from "../utils/NumberCustomInput";
import { useAuth } from "../../hooks/useAuth";
import { backendApis } from "../../config";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "react-query";
import { fetchActionsUtil } from "../../utils/helpers";
import CachedIcon from "mdi-react/CachedIcon";
import { DeleteIcon } from "../Icons";
import { isEmpty } from "lodash";

export default function GeneralItemIssuerModal({
  showGeneralItemIssuer,
  setShowGeneralItemIssuer,
  initialTableData = [],
  requisition,
  refetch: refetchRequisition,
}) {
  const { user: authUser, backendUrl } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const handleClose = () => setShowGeneralItemIssuer(false);

  // GET Warehouses
  const { data, isFetching, refetch } = useQuery(
    ["FETCH_WAREHOUSE"],
    () => fetchActionsUtil(`${backendUrl}/api/warehouse`, "GET"),
    {}
  );

  const warehouseOptions = useMemo(() => {
    if (data?.warehouses) {
      const options = data?.warehouses?.map((el) => ({
        ...el,
        label: el.W_name,
        value: el.W_ID,
      }));

      return [
        {
          label: "Sales Inventory",
          value: "",
        },
        ...options,
      ];
    }
    return [];
  }, [data?.warehouses]);

  const formik = useFormik({
    initialValues: {
      issueTo: requisition?.branchUrl
        ? backendApis.find((el) => el.url === requisition.branchUrl)?.name
        : backendApis.find((el) => el.url === backendUrl)?.name,
      items: [
        {
          issueTo: "",
          Bar_Code: "",
          Item_Name: "",
          qtyinStock: "",
          issueDate: new Date(),
          qty: "",
          costPrice: "",
          receivingofficer: "",
          itemUnitPrice: "",
        },
      ],
    },
    validationSchema: yup.object().shape({}),
    onSubmit: async (values) => {
      // TODO - check if stock is enough

      addMutation.mutate({
        approvedRequisitionId: requisition.requestid,
        warehouseCarts: values.items
          .filter((el) => el.Warehouse)
          .map((el) => {
            const foundWarehouse = warehouseOptions.find(
              (ware) => ware.W_ID === el.Warehouse
            );
            return {
              ...el,
              WH_Category: foundWarehouse.W_Category,
            };
          }),
        carts: values.items.filter((el) => !el.Warehouse),
      });
    },
  });

  const addIssueItemApi = async (payload) => {
    /*  console.log(payload);
    return; */
    let response = await fetch(`${backendUrl}/api/itemissuer/general-issue`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const addMutation = useMutation((payload) => addIssueItemApi(payload), {
    onSuccess: ({ message }) => {
      toast.success(message);
      formik.resetForm();
      if (refetchRequisition) refetchRequisition();
      setShowGeneralItemIssuer(false);
    },
    onError: ({ message = "" }) => {
      toast.error(`Unable to perform action: ${message}`);
    },
  });

  useEffectOnce(() => {
    formik.setFieldValue(
      "items",
      initialTableData?.map((el) => {
        let issueTo = requisition?.branchUrl
          ? backendApis.find((el) => el.url === requisition.branchUrl)?.name
          : backendApis.find((el) => el.url === backendUrl)?.name;

        return {
          ...el,
          sendTo: `${
            requisition?.branchUrl ? requisition?.branchUrl : backendUrl
          }/api/itemissuer/receiver`,
          qtyinStock: el.Quantity,
          issueDate: new Date(),
          qty: el.quantityApproved ? el.quantityApproved : el.quantity,
          costPrice: el.UnitCost,
          receivingofficer: requisition?.requestby,
          itemUnitPrice: el.UnitPrice,
          issueTo,
          receivingofficer:
            requisition?.requestbyUser?.Name || requisition?.sentByName,
          Vendor: issueTo,
        };
      })
    );
  });

  const handleWarehouseChange = async ({ formik, index, selected }) => {
    // check if item is in the switch to warehouse
    try {
      setIsLoading(true);
      console.log(selected);

      // =======================================
      if (selected.value == "") {
        let response = await fetch(
          `${backendUrl}/api/items?q=${formik.values.items[index].Bar_Code}`,
          {
            method: "GET",
            headers: {
              Accept: "Application/json",
              "Content-Type": "Application/json",
            },
            credentials: "include",
          }
        );

        if (!response.ok) {
          response = await response.json();
          throw new Error();
        } else {
          const {
            data: { items = [] },
          } = await response.json();
          if (!isEmpty(items)) {
            // Set new warehouse data just like the useEffectOnce
            if (!isEmpty(items[0])) {
              formik.setFieldValue(`items[${index}].Warehouse`, "");
              formik.setFieldValue(
                `items[${index}].qtyinStock`,
                items[0].Quantity
              );
              formik.setFieldValue(
                `items[${index}].costPrice`,
                items[0].UnitCost
              );
              formik.setFieldValue(
                `items[${index}].itemUnitPrice`,
                items[0].UnitPrice
              );
            } else {
              toast.error(
                `${
                  formik.values.items[index].Item_Name
                } not found in Sales Inventory`
              );
            }
          } else {
            // Throw Error if not found
            toast.error("Item not found -");
          }
        }
        return;
      }
      // ========================================
      let response = await fetch(
        `${backendUrl}/api/warehouse/items?q=${
          formik.values.items[index].Bar_Code
        }`,
        {
          method: "GET",
          headers: {
            Accept: "Application/json",
            "Content-Type": "Application/json",
          },
          credentials: "include",
        }
      );

      if (!response.ok) {
        response = await response.json();
        throw new Error();
      } else {
        const { items = [] } = await response.json();
        if (items) {
          // Set new warehouse data just like the useEffectOnce
          const foundWarehouseItem = items.find(
            (item) => item.Warehouse === selected.value
          );
          if (foundWarehouseItem) {
            formik.setFieldValue(`items[${index}].Warehouse`, selected.value);
            formik.setFieldValue(
              `items[${index}].qtyinStock`,
              foundWarehouseItem.Quantity
            );
            formik.setFieldValue(
              `items[${index}].costPrice`,
              foundWarehouseItem.UnitCost
            );
            formik.setFieldValue(
              `items[${index}].itemUnitPrice`,
              foundWarehouseItem.UnitPrice
            );
          } else {
            toast.error(
              `${formik.values.items[index].Item_Name} not found in ${
                selected.W_name
              }`
            );
          }
        } else {
          // Throw Error if not found warehouse
          toast.error("Item not found");
        }
      }
    } catch (err) {
      console.log(err);

      // Throw Error if not found warehouse
      toast.error("Unable to get items, Try again");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Modal
        show={showGeneralItemIssuer}
        onHide={handleClose}
        backdrop="static"
        centered
        animation={false}
        dialogClassName="modal-90w"
      >
        <Modal.Header closeButton>
          <Modal.Title className="h5 d-flex align-items-center gap-3">
            Issue Items{" "}
            <Button
              onClick={() => refetch()}
              variant=""
              className="border-0 text-primary mt-3"
            >
              <CachedIcon />
            </Button>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="mb-3">
            <b>Requester:</b>{" "}
            {requisition?.requestbyUser?.Name || requisition?.sentByName} -{" "}
            {formik.values?.issueTo}
          </p>{" "}
          <FormikProvider value={formik}>
            <Form noValidate onSubmit={formik.handleSubmit} autoComplete="off">
              <FieldArray
                name="items"
                render={(arrayHelpers) => (
                  <>
                    <Table borderless className="product-table">
                      <thead>
                        <th />
                        <th>Item Code</th>
                        <th>Item Name</th>
                        <th>
                          Warehouse <small>(Pick From)</small>{" "}
                        </th>
                        <th>Qty In Stock</th>
                        <th>Qty</th>
                        <th>Cost</th>
                      </thead>

                      <tbody>
                        {formik.values.items.map((el, index) => (
                          <tr>
                            <td>
                              <Button
                                variant=""
                                onClick={() => arrayHelpers.remove(index)}
                              >
                                <DeleteIcon />
                              </Button>
                            </td>
                            <td>
                              {el.Bar_Code || `Not found - ${el.barcode}`}
                            </td>
                            <td>{el.Item_Name || "..."}</td>
                            <td>
                              <Select
                                isLoading={isFetching || isLoading}
                                options={warehouseOptions}
                                value={warehouseOptions?.find(
                                  (el) =>
                                    el.value ===
                                    formik.values.items[index].Warehouse
                                )}
                                onChange={(selected) => {
                                  if (selected) {
                                    handleWarehouseChange({
                                      formik,
                                      index,
                                      selected,
                                    });
                                  }
                                }}
                              />
                            </td>
                            <td>{el.qtyinStock}</td>
                            <td>
                              <NumberCustomInput
                                name={`items[${index}].qty`}
                                value={formik.values.items[index].qty}
                                onValueChange={(value, name) => {
                                  formik.setFieldValue(name, value);
                                }}
                                disabled={isFetching}
                              />
                            </td>
                            <td>
                              <CurrencyCustomInput
                                name={`items[${index}].costPrice`}
                                value={formik.values.items[index].costPrice}
                                onValueChange={(value, name) => {
                                  formik.setFieldValue(name, value);
                                }}
                                disabled={isFetching}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </>
                )}
              />
            </Form>
          </FormikProvider>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => formik.submitForm()}
            disabled={isFetching || addMutation.isLoading}
          >
            {addMutation.isLoading ? "Please wait..." : "Post"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
