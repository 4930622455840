import { useFormik } from "formik";
import CachedIcon from "mdi-react/CachedIcon";
import React from "react";
import { Modal, Form, Button, Table } from "react-bootstrap";
import { useMutation, useQuery } from "react-query";
import Select from "react-select";
import { toast } from "react-toastify";
import * as yup from "yup";
import eventBus from "../../utils/EventBus";
import { useBackendUrl } from "../../utils/hooks";
import ConfirmDialog from "../ConfirmDialogue";
import { DeleteIcon } from "../Icons";

export default function CreateCategory({
  showCreateCategoryModal,
  setShowCreateCategoryModal,
}) {
  const backendUrl = useBackendUrl();
  const fetchSetUpData = async () => {
    // await waitFor(5000);
    let response = await fetch(`${backendUrl}/api/incentive/set-up`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const { data } = await response.json();
    return data;
  };

  const createCategory = async (payload) => {
    let response = await fetch(`${backendUrl}/api/atc/create-category`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const createCategoryMutation = useMutation(
    (payload) => createCategory(payload),
    {
      onSuccess: ({ message, data }) => {
        toast.success(message);
        formik.resetForm();
        refetch();
      },
      onError: () => {
        toast.error(`Unable to perform action`);
      },
    }
  );

  const {
    data = {
      categories: [],
      subcategory: [],
      region: [],
      depot: [],
      plant: [],
      subCategoriesWithParent: [],
      plantAndDepot: [],
    },
    refetch,
  } = useQuery(["INCENTIVE_SETUP", "CATEGORY"], () => fetchSetUpData(), {
    keepPreviousData: true,
  });

  const formik = useFormik({
    initialValues: {
      Cat_Name: "",
      Sub_Cat: "",
    },
    validationSchema: yup.object().shape({
      Cat_Name: yup.string().required(),
      Sub_Cat: yup.string().required(),
    }),
    onSubmit: (values) => {
      createCategoryMutation.mutate(values);
    },
  });

  const deleteCategory = async (payload) => {
    let response = await fetch(`${backendUrl}/api/atc/delete-category`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };

  const deleteCategoryMutation = useMutation(
    (payload) => deleteCategory(payload),
    {
      onSuccess: ({ message, data }) => {
        toast.success(message);
        formik.resetForm();
        refetch();
      },
      onError: () => {
        toast.error(`Unable to perform action`);
      },
    }
  );

  const handleDeleteCategory = async (el) => {
    if (
      await ConfirmDialog({
        title: "Delete Category",
        description: `Are you sure, delete ${el.Cat_Name}`,
      })
    ) {
      deleteCategoryMutation.mutate(el);
    }
  };

  const createMoreSubCategory = () => {
    eventBus.dispatch("showCreateSubCategoryModal", true);
    setShowCreateCategoryModal(false);
  };

  return (
    <Modal
      show={showCreateCategoryModal}
      onHide={() => setShowCreateCategoryModal(false)}
      size="md"
      centered
      animation={false}
    >
      <Modal.Header closeButton>
        <Modal.Title className="h5">
          Create Product/Service Category{" "}
          <Button variant="" className="text-primary" onClick={() => refetch()}>
            {" "}
            <CachedIcon />{" "}
          </Button>{" "}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate onSubmit={formik.handleSubmit} autoComplete="off">
          <Form.Group className="mb-3 pb-2">
            <Form.Label className="mb-1">Category Name </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Category Name"
              name="Cat_Name"
              value={formik.values.Cat_Name}
              onChange={formik.handleChange}
              isInvalid={formik.touched.Cat_Name && !!formik.errors.Cat_Name}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.Cat_Name}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3 pb-2">
            <Form.Label className="mb-1">Sub Cat</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Sub Cat"
              name="Sub_Cat"
              value={formik.values.Sub_Cat}
              onChange={formik.handleChange}
              isInvalid={formik.touched.Sub_Cat && !!formik.errors.Sub_Cat}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.Sub_Cat}
            </Form.Control.Feedback>
          </Form.Group>

          <div className="d-flex gap-3 justify-content-end">
            <Button type="submit">Create</Button>

            <Button
              type="reset"
              onClick={() => formik.resetForm()}
              variant="outline-primary"
            >
              Refresh
            </Button>

            <Button
              type="button"
              variant="outline-primary"
              onClick={() => createMoreSubCategory()}
            >
              Create more Sub Category
            </Button>
          </div>
        </Form>

        <Table
          responsive
          borderless
          striped
          className="product-table mt-4 border"
        >
          <thead>
            <tr>
              <th />
              <th>Cat_Name</th>
            </tr>
          </thead>
          <tbody>
            {data?.categories &&
              data?.categories.map((el, index) => (
                <tr key={index}>
                  <td style={{ whiteSpace: "nowrap", width: "1%" }}>
                    <Button variant="" onClick={() => handleDeleteCategory(el)}>
                      <DeleteIcon />
                    </Button>
                  </td>
                  <td>{el.Cat_Name}</td>
                </tr>
              ))}
          </tbody>
        </Table>
      </Modal.Body>
    </Modal>
  );
}
