import { Table, Dropdown, Form } from "react-bootstrap";
// import { PageHeaderListIcon } from "../Icons";
import "./../../assets/scss/reports/cashbook.scss";
import { useEffect, useState, useRef } from "react";
import CachedIcon from "mdi-react/CachedIcon";
import { useQuery } from "react-query";
import ReactPaginate from "react-paginate";
import queryString from "query-string";
import currency from "currency.js";
import { format, parse } from "date-fns";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";

// import PageHeader from "../PageHeader";
import { Outlet } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { fetchActionsUtil } from "../../utils/helpers";
import { paginationOptions } from "../../utils/helpers";
import ModalLoader from "../utils/ModalLoader";
import { AddTemplateModal } from "./AddTemplateModal";
import { EditTemplateModal } from "./EditTempalateModal";
import PageHeader from "../PageHeader";
import { PageHeaderListIcon, ExchangeFunds } from "../Icons";
import useDebounce, { useQueryParams, useScrollTop } from "../../utils/hooks";
import RsDateRangePicker from "../utils/RsDateRangePicker";
import NoTableItem from "../utils/NoTableItem";
import { IsPrivileged } from "../DisplayChildElement";

export default function PlantTemplateItems({ hideInsideHeader }) {
  useScrollTop();

  const { backendUrl } = useAuth();
  const initialFilterParams = {
    q: "",
    // startDate: format(new Date(), "yyyy-MM-dd"),
    // endDate: format(new Date(), "yyyy-MM-dd"),
    startDate: "",
    endDate: "",
  };

  const [showCreateNew, setShowCreateNew] = useState(false);
  const [showEditItem, setShowEditItem] = useState({ status: false, item: {} });

  const refreshButton = useRef(null);

  const [queryParams, setQueryParams] = useQueryParams({
    page: 1,
    limit: 40,
    ...initialFilterParams,
  });
  const [filterParams, setFilterParams] = useState({
    ...initialFilterParams,
    ...queryParams,
  });
  const debouncedFilterParams = useDebounce(filterParams, 500);

  useEffect(() => {
    setQueryParams({ ...queryParams, ...debouncedFilterParams });
  }, [debouncedFilterParams]);

  const plantLocal = JSON.parse(window.localStorage.getItem("plantDetails"));

  const fechData = async (plant) => {
    const { billOfQuantities } = await fetchActionsUtil(
      `${backendUrl}/api/production/bill-of-quantity?&${queryString.stringify(
        queryParams
      )}`,
      "GET"
    );

    return billOfQuantities;
  };

  const { data, refetch, isLoading } = useQuery(
    ["ITEMS_BILL_OF_QUANTITY", { queryParams, plantLocal }],
    () => fechData(plantLocal),
    {
      keepPreviousData: false,
      staleTime: 0,
    }
  );

  // console.log(data);
  const handleSearchQueryChange = (e) => {
    setFilterParams({
      ...filterParams,
      [e.target.name]: e.target.value,
    });
  };

  const filterByDateRange = (date) => {
    setFilterParams({
      ...filterParams,
      startDate: format(date[0], "yyyy-MM-dd"),
      endDate: format(date[1], "yyyy-MM-dd"),
    });
  };

  const clearDateRange = () => {
    setFilterParams({
      ...filterParams,
      startDate: "",
      endDate: "",
    });
  };

  return (
    <IsPrivileged roleName={["Bill of Materials"]}>
      <main className="inner-page-outlet">
        {!hideInsideHeader && (
          <PageHeader
            name="Bill Of Materials"
            description="Create and edit your bill of materials"
            icon={<PageHeaderListIcon />}
          />
        )}

        <main className="cash-book">
          <div className="content">
            <div className="content-main">
              <div className="content-body">
                <header className="flex-wrap gap-3">
                  <div className="d-flex flex-grow-1">
                    <div className="global-search-area col col-md-6">
                      <MagnifyIcon />
                      <Form.Control
                        id="queryParams-q"
                        className=""
                        name="q"
                        value={filterParams.q}
                        onChange={(e) => handleSearchQueryChange(e)}
                        placeholder="Search by Bar Code and Name ..."
                      />
                    </div>
                    <button
                      onClick={() => refetch()}
                      className="btn text-primary mx-3"
                      ref={refreshButton}
                    >
                      <CachedIcon />
                    </button>
                  </div>
                  <div className="actions mr-5">
                    {/* <CSVLink
              className="btn print d-none"
              filename={`General Ledger Report(${format(
                new Date(),
                "dd-MMM-yyyy hh:mm:ss a"
              )}).csv`}
              data={excelData}
              ref={CSVLinkRef}
            /> */}

                    <div className="position-relative">
                      <RsDateRangePicker
                        placement={!hideInsideHeader ? "bottomEnd" : "topEnd"}
                        value={
                          filterParams.startDate && filterParams.endDate
                            ? [
                                parse(
                                  filterParams.startDate,
                                  "yyyy-MM-dd",
                                  new Date()
                                ),
                                parse(
                                  filterParams.endDate,
                                  "yyyy-MM-dd",
                                  new Date()
                                ),
                              ]
                            : []
                        }
                        onClean={() => clearDateRange()}
                        onOk={(date) => filterByDateRange(date)}
                      />
                    </div>

                    {!hideInsideHeader && (
                      <button
                        onClick={() => setShowCreateNew(true)}
                        className="btn bg-primary text-white"
                      >
                        Create New <ExchangeFunds color="white" />
                      </button>
                    )}
                    {/* <a
              target="_blank"
              rel="noreferrer"
              href={`${backendUrl}/api/reports/pdf/general-ledger?${queryString.stringify(
                queryParams
              )}`}
              className="btn print mx-4"
            >
              PDF <PDFIcon color="#ff0000" />
            </a>
            <button className="btn print" onClick={onDownloadExcelData}>
              Excel <ExcelIcon color="#008000" />
            </button> */}
                  </div>
                </header>

                <div className="px-md-4">
                  <Table
                    borderless
                    responsive
                    hover
                    striped
                    className="product-table"
                  >
                    <thead>
                      <tr>
                        {<tr />}
                        <th>Bar Code</th>
                        <th>Name/Title</th>
                        <th>Category</th>
                        <th>Unit Cost</th>
                        {/* <th>Unit Price</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {data?.map((el, i) => (
                        <tr
                          key={i}
                          className="p-cursor"
                          onDoubleClick={() =>
                            setShowEditItem({ item: el, status: true })
                          }
                        >
                          {
                            <td>
                              <Dropdown>
                                <Dropdown.Toggle
                                  variant=""
                                  className="bg-white border-0"
                                  bsPrefix="print more"
                                >
                                  <DotsVerticalIcon />
                                </Dropdown.Toggle>
                                <Dropdown.Menu
                                  popperConfig={{
                                    strategy: "fixed",
                                  }}
                                  renderOnMount
                                  className="dropdown-with-icons"
                                >
                                  <Dropdown.Item
                                    as="div"
                                    onClick={() =>
                                      setShowEditItem({
                                        item: el,
                                        status: true,
                                      })
                                    }
                                    className="p-cursor"
                                  >
                                    {!hideInsideHeader ? "Edit" : "View"}
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </td>
                          }
                          <td>{el.Bar_Code}</td>
                          <td>{el.Item_Name}</td>
                          <td>{el.Cat_Name}</td>
                          <td>
                            {currency(el.UnitCost, {
                              precision: 2,
                              symbol: "",
                            }).format()}
                          </td>
                          {/* <td>
                          {currency(el.UnitPrice, {
                            precision: 2,
                            symbol: "",
                          }).format()}
                        </td> */}
                        </tr>
                      ))}
                    </tbody>
                  </Table>

                  {data?.length < 1 && (
                    <NoTableItem queryParams={queryParams} />
                  )}

                  {data?.length > 0 && (
                    <div className="d-flex justify-content-start mt-4">
                      <div className="gridChild gridChildBorderLeftBlue">
                        <div className="gridChildLeft">
                          <p className="gridChld1">
                            {currency(
                              data?.reduce(
                                (a, b) => a + parseFloat(b?.UnitCost),
                                0
                              ),
                              {
                                symbol: "",
                              }
                            ).format()}
                          </p>
                          <p className="gridChld2">Total</p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                {data?.length > 0 && (
                  <div className="d-flex justify-content-between px-3 align-items-center pagination">
                    <div className="pagination_left">
                      <p className="m-0 p-0">Show</p>
                      <select
                        value={queryParams.limit}
                        name="limit"
                        className="form-select "
                        onChange={(e) => handleSearchQueryChange(e)}
                      >
                        <option value="2">2 rows</option>
                        <option value="10">10 rows</option>
                        <option value="20">20 rows</option>
                        <option value="30">30 rows</option>
                        <option value="40">40 rows</option>
                        <option value="50">50 rows</option>
                        <option value="100">100 rows</option>
                      </select>
                    </div>

                    <ReactPaginate
                      {...paginationOptions}
                      pageCount={Math.ceil(data?.count / queryParams?.limit)}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={0}
                      onPageChange={({ selected }) => {
                        document.body.scrollTop = document.documentElement.scrollTop = 0;
                        setQueryParams({
                          ...queryParams,
                          page: selected + 1,
                        });
                      }}
                      forcePage={parseInt(queryParams.page) - 1}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </main>
        <AddTemplateModal
          onHide={() => setShowCreateNew(false)}
          show={showCreateNew}
          refetchMain={refetch}
        />
        {!hideInsideHeader ? (
          <EditTemplateModal
            onHide={() => setShowEditItem({ ...showEditItem, status: false })}
            show={showEditItem.status}
            refetchMain={refetch}
            item={showEditItem.item}
          />
        ) : (
          <EditTemplateModal
            onHide={() => setShowEditItem({ ...showEditItem, status: false })}
            show={showEditItem.status}
            refetchMain={refetch}
            item={showEditItem.item}
            noEdit={true}
          />
        )}
        <Outlet />
        <ModalLoader show={isLoading} />
      </main>
    </IsPrivileged>
  );
}
