import { Modal, Form, Button, Table, FormCheck } from "react-bootstrap";
import * as yup from "yup";
import { useFormik, FormikProvider, FieldArray } from "formik";
import { useMemo, useState } from "react";
import { confirmable, createConfirmation } from "react-confirm";
import { useBackendUrl, useEffectOnce } from "../../utils/hooks";
import NumberCustomInput from "../utils/NumberCustomInput";
import CurrencyCustomInput from "../utils/CurrencyCustomInput";
import currency from "currency.js";
import { toast } from "react-toastify";
// import { qtyFormat } from "../utils/helpers";
import CachedIcon from "mdi-react/CachedIcon";
import { isEmpty } from "lodash";
import { formatForQty } from "../../utils/helpers";

function SelectBatch({
  proceed,
  show,
  batches = [],
  quantityToSell,
  previouslySelectedBatches = [],
  generalSettings = {},
  single = false,
  Bar_Code,
  loadBatchFromServer = false,
  itemsApiUrl = "/api/items",
  warehouse,
}) {
  const backendUrl = useBackendUrl();
  const [isLoading, setIsLoading] = useState(false);

  const initialValues = {
    batches: batches.map((batch) => ({
      ...batch,
      selected: previouslySelectedBatches.find(
        (el) => el.Bar_Code === batch.Bar_Code
      ),
    })),
  };
  const schema = yup.object().shape({});

  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: async (values) => {
      if (Number(quantityToSell) > Number(totalSelected)) {
        toast.error("Not Enough from selected Batches");
        return;
      }
      proceed({
        ...values,
        batches: values.batches.filter((el) => el.selected),
      });
    },
  });

  const canClose = () => {
    proceed({ batches: [] });
  };

  const totalSelected = useMemo(
    () =>
      formik.values?.batches
        .map((el) => (el?.selected ? el.Quantity : 0))
        .reduce((a, b) => currency(a).add(b).value, 0),
    [formik.values?.batches]
  );

  const getBatches = async () => {
    try {
      setIsLoading(true);
      let response = await fetch(
        warehouse
          ? `${backendUrl}${itemsApiUrl}/batches-for-sales/${warehouse}/${Bar_Code}`
          : `${backendUrl}${itemsApiUrl}/batches-for-sales/${Bar_Code}`,
        {
          method: "GET",
          headers: {
            Accept: "Application/json",
            "Content-Type": "Application/json",
          },
          credentials: "include",
        }
      );

      if (!response.ok) {
        response = await response.json();
        throw new Error();
      } else {
        const { data } = await response.json();
        if (data.batches) {
          formik.setFieldValue(
            "batches",
            data.batches.map((batch) => ({
              ...batch,
              selected: previouslySelectedBatches.find(
                (el) => el.Bar_Code === batch.Bar_Code
              ),
            }))
          );
        }
      }
    } catch (err) {
      console.log(err);
      toast.error("Unable to load batches, Try again");
    } finally {
      setIsLoading(false);
    }
  };

  useEffectOnce(() => {
    if (loadBatchFromServer && Bar_Code && isEmpty(batches)) getBatches();
  });

  return (
    <Modal
      show={show}
      onHide={() => canClose()}
      centered={true}
      animation={false}
      enforceFocus={false}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title className="d-flex align-items-center gap-3">
          <h1 className="h6 mb-0">Select Batch</h1>{" "}
          {loadBatchFromServer && Bar_Code ? (
            <Button variant="" onClick={() => getBatches()}>
              {" "}
              <CachedIcon />
            </Button>
          ) : null}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {" "}
        <p>
          <b>Quantity Required:</b>{" "}
          {currency(quantityToSell, {
            symbol: "",
            precision: 2,
            format: formatForQty,
          }).format()}
        </p>
        <p className="mb-4">
          <b>Total Quantity in selected Batches:</b>{" "}
          {currency(totalSelected, {
            symbol: "",
            precision: 2,
            format: formatForQty,
          }).format()}
        </p>
        <FormikProvider value={formik}>
          <Form noValidate onSubmit={formik.handleSubmit}>
            <FieldArray
              name="batches"
              render={(arrayHelpers) => (
                <Table borderless hover striped className="product-table">
                  <thead className="sticky text-nowrap">
                    <tr>
                      <th />
                      <th scope="col">Batch</th>
                      <th scope="col">{generalSettings?.Batch_Name}</th>
                      <th scope="col">Item Name</th>
                      <th scope="col">Unit Cost</th>
                      <th scope="col">Quantity</th>
                    </tr>
                  </thead>
                  <tbody>
                    {formik.values.batches &&
                      formik.values.batches.map((el, index) => (
                        <tr className="p-cursor" key={index}>
                          <td>
                            <FormCheck
                              name={`batches[${index}.selected]`}
                              checked={formik.values.batches[index].selected}
                              onChange={(e) => {
                                if (single) {
                                  formik.setFieldValue(
                                    "batches",
                                    formik.values.batches.map((batch) =>
                                      batch.Bar_Code === el.Bar_Code
                                        ? { ...batch, selected: true }
                                        : { ...batch, selected: false }
                                    )
                                  );
                                  return;
                                }
                                formik.setFieldValue(
                                  `batches[${index}].selected`,
                                  e.target.checked
                                );
                              }}
                            />
                          </td>
                          <td>
                            {el.Bar_Code ? el.Bar_Code.split("-").pop() : "..."}
                          </td>
                          <td>{el.Batch_Name || "..."}</td>
                          <td title={el?.Quantity}>{el.ItemName}</td>
                          <td>
                            {currency(el?.UnitCost, {
                              symbol: "",
                            }).format()}
                          </td>
                          <td>
                            {currency(el?.Quantity, {
                              precision: 2,
                              format: formatForQty,
                              symbol: "",
                            }).format()}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              )}
            />

            <div className="d-flex justify-content-between">
              <div />
              <Button
                disabled={isLoading}
                variant="primary"
                className="p-2 px-4"
                type="submit"
              >
                {isLoading ? "Please wait…" : "Continue"}
              </Button>
            </div>
          </Form>
        </FormikProvider>
      </Modal.Body>
    </Modal>
  );
}

export default function SelectBatchDialog(props) {
  return createConfirmation(confirmable(SelectBatch))({ ...props });
}
