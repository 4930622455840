import "../../assets/scss/store/storeFooter.scss";
import { Link, NavLink, useLocation } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import {
  FacebookIcon,
  InstagramIcon,
  SocialPhoneIcon,
  TwitterIcon,
} from "./StoreIcons";
import { useQuery } from "react-query";
import { Logo } from "../Icons";
import { appSettings } from "../../config";

const withHttp = (url) =>
  !/^https?:\/\//i.test(url) ? `https://${url?.trim()}` : url;

export default function StoreFooter() {
  const { customerBackendUrl: backendUrl } = useAuth();
  const fetchSetUpData = async () => {
    // await waitFor(5000);
    let response = await fetch(`${backendUrl}/api/company/details`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const data = await response.json();
    return data;
  };

  const { data = { company: {}, settings: {} } } = useQuery(
    ["COMPANY_DETAILS"],
    () => fetchSetUpData(),
    {
      keepPreviousData: true,
    }
  );

  return (
    <footer className="store-footer">
      <div className="d-md-flex justify-content-between">
        <div className="store-info">
          <NavLink
            to={appSettings.storeBaseUrl}
            className="logo-area d-flex gap-2 align-items-center mb-3"
          >
            <img
              src={`${backendUrl}/images/company-logo.png`}
              /*    width="25"
              height="26" */ width="50"
            />
            <h2>{data?.settings?.storeName || "Store"}</h2>
          </NavLink>

          <p className="fw-6 h6">{data.company?.CompName || "..."}</p>
          <p>
            {data.company.AddressLine1}
            <br />
            {data.company.AddressLine2}
          </p>

          <p>{data.company.Phone}</p>

          <p>{data.company.Email}</p>

          <p>
            <a href={withHttp(data.company.Website)}>{data.company.Website}</a>{" "}
          </p>
        </div>

        <div className="company-info mb-5">
          <div>
            <h2>Customer Service</h2>

            <ul>
              <li>
                <Link to={`${appSettings.storeBaseUrl}/terms-and-conditions`}>
                  Terms and Conditions
                </Link>
              </li>
              <li>
                <Link to={`${appSettings.storeBaseUrl}/return-policy`}>
                  Return Policy
                </Link>
              </li>
            </ul>
          </div>
        </div>

        <div className="company-info">
          {/*   <div>
            <h2>Customer Service</h2> 

            <ul>
              <li>
                <Link to={"/terms and conditions"}>Terms and Conditions</Link>
              </li>
              <li>
                <Link to={"/return policy"}>Return Policy</Link>
              </li>
              <li>
                <a href={withHttp(data.company.Website)}>FAQs</a>
              </li>
              <li>
                <a href={withHttp(data.company.Website)}>Orders and delivery</a>
              </li>
              <li>
                <a href={withHttp(data.company.Website)}>Returns and refunds</a>
              </li>
              <li>
                <a href={withHttp(data.company.Website)}>Payment and pricing</a>
              </li>
              <li>
                <a href={withHttp(data.company.Website)}>
                  Cryptocurrency payments
                </a>
              </li>
              <li>
                <a href={withHttp(data.company.Website)}>
                  Promotion terms and conditions
                </a>
              </li>
              <li>
                <a href={withHttp(data.company.Website)}>
                  Store Customer Promise
                </a>
              </li> 
            </ul>
          </div> */}

          {/*   <div>
            <h2 className="text-nowrap">About Us</h2>
            <ul>
              <li>
                <a href={withHttp(data.company.Website)}>About Us</a>
              </li>
              <li>
                <a href={withHttp(data.company.Website)}>Investors</a>
              </li>
              <li>
                <a href={withHttp(data.company.Website)}>Careers</a>
              </li>
            </ul>{" "}
          </div>  */}

          <div>
            <h2>Follow Us</h2>
            <div className="d-flex gap-4 rounded-circle">
              <a href={data.company.Phone} className="social-icon">
                <SocialPhoneIcon />
              </a>
              <a href={withHttp(data.company.Website)} className="social-icon">
                <InstagramIcon />
              </a>
              <a href={withHttp(data.company.Website)} className="social-icon">
                <FacebookIcon />
              </a>
              <a href={withHttp(data.company.Website)} className="social-icon">
                <TwitterIcon />
              </a>
            </div>
          </div>
        </div>
      </div>

      <hr />
      <p className="text-center text-light">
        <a href={"https://www.excellentbridge.com/"} className="text-light">
          <Logo />
        </a>
        ©{new Date().getFullYear()}{" "}
        <a href={"https://www.excellentbridge.com/"} className="text-light">
          Invex,
        </a>{" "}
        All rights reserved.
      </p>
    </footer>
  );
}
