import { Modal, Button } from "react-bootstrap";
import { useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import Select from "react-select";
import { Table } from "react-bootstrap";
import { Popover } from "react-tiny-popover";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "react-query";
// import currency from "currency.js";
// import CurrencyInput from "react-currency-input-field";

import { CustomInput } from "../utils/CustomInput";
import { useAuth } from "../../hooks/useAuth";
import "../../assets/scss/scoped/issueItem.scss";
import ConfirmDialog from "../ConfirmDialogue";
import { fetchActionsUtil } from "../../utils/helpers";
import AllWarehouseItemsModal from "./AllWarehouseItemsModal";
import { DeleteIcon } from "../Icons";
import ModalLoader from "../utils/ModalLoader";
import ItemsTable from "../utils/ItemsTable";

const unitRate = [
  { label: "KG", value: "KG" },
  { label: "G", value: "G" },
  { label: "Piece", value: "Piece" },
  { label: "Bags", value: "Bags" },
  { label: "Carton", value: "Carton" },
  { label: "Bundle", value: "Bundle" },
  { label: "Tons", value: "Tons" },
  {
    label: "Tuber",
    value: "Tuber",
  },
];

export const AddTemplateModal = (props) => {
  const [showItemsPopover, setShowItemsPopover] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [activeTableIndex, setActiveTableIndex] = useState(null);
  const [showGetItems, setShowGetItems] = useState(false);

  const { backendUrl, token } = useAuth();

  const handleInputChange = ({ index, name, value }) => {
    const oldTableData = tableData;
    oldTableData[index][name] = value;
    setTableData([...oldTableData]);
  };

  //   const plantLocal = JSON.parse(window.localStorage.getItem("plantDetails"));

  const fetchItems = async () => {
    const { data } = await fetchActionsUtil(
      `${backendUrl}/api/items/set-up`,
      "GET",
      token
    );

    data.categories = data.categories.map((el) => ({
      value: el.Cat_Name,
      label: el.Cat_Name,
    }));

    data.products = data.products.map((el) => ({
      value: el.Product_Name,
      label: el.Product_Name,
    }));

    return data;
  };

  const { data } = useQuery(["GET_CAT_PRO"], () => fetchItems(), {
    enabled: true,
  });

  const formik = useFormik({
    initialValues: {
      Bar_Code: "",
      Item_Name: "",
      Item_Desc: "",
      Product_Name: "",
      Cat_Name: "",
      Product_Model: "",
    },
    validationSchema: yup.object().shape({
      Bar_Code: yup.string().required("Bar Code is required"),
      Item_Name: yup.string().required("Item Name is required"),
      //   Item_Desc: yup.string().required("Description is required"),
      Product_Name: yup.string().required("Product is required"),
      Cat_Name: yup.string().required("Category by is required"),
      Product_Model: yup.string().required("Unit By is required"),
    }),
    onSubmit: async (values) => {
      if (tableData.length < 1) {
        return toast.error("Add Items");
      }

      if (
        await ConfirmDialog({
          title: "Save Template",
          description: "Are you sure you want to save this template",
        })
      ) {
        values.UnitCost = tableData.reduce(
          (c, a) =>
            parseFloat(c) + parseFloat(a.UnitCost) * parseFloat(a.Quantity),
          0
        );
        // values.plant = plantLocal?.PLID;
        const payload = {
          ...values,
          tableData,
        };
        console.log(payload);
        sendMaintance.mutate(payload);
      }
    },
  });

  const handleGetItem = (el) => {
    formik.setValues({
      ...formik.values,
      Bar_Code: el.Bar_Code,
      Item_Name: el.Item_Name,
      Item_Desc: el.Item_Desc,
      Product_Name: el.Product_Name,
      Cat_Name: el.Cat_Name,
      Product_Model: el.Product_Model,
    });
    // console.log(el);
    setShowGetItems(false);
  };

  const handleSelectedItem = (el) => {
    // console.log(el);
    if (!tableData.length) {
      tableData.push(el);
    } else {
      const index = tableData.findIndex((d) => d.Bar_Code === el.Bar_Code);
      if (index === -1) {
        setTableData([...tableData, el]);
      } else {
        tableData.splice(index, 1, el);
      }
    }

    setShowItemsPopover(false);
  };

  const onDelete = (barcode) => {
    const index = tableData.findIndex((d) => d.Bar_Code === barcode);
    tableData.splice(index, 1);
  };

  const sendMaintance = useMutation(
    (payload) =>
      fetchActionsUtil(
        `${backendUrl}/api/production/bill-of-quantity`,
        "POST",
        token,
        payload
      ),
    {
      onSuccess: ({ message, data }) => {
        toast.success(message);

        formik.resetForm({
          values: {
            Bar_Code: "",
            Item_Name: "",
            Item_Desc: "",
            Product_Name: "",
            Cat_Name: "",
            Product_Model: "",
          },
        });

        setTableData([]);
        props.refetchMain();
      },
      onError: () => {
        toast.error(`Unable to perform action`);
      },
    }
  );

  return (
    <Modal
      onHide={props.onHide}
      show={props.show}
      backdropClassName={`global-backdrop`}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="my-style-modal"
      animation={false}
      enforceFocus={false}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <div>
            <h1>Add Item</h1>
          </div>

          <p>Add item by filling in the following forms.</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div className="container">
            <div>
              <form noValidate className="form-relative">
                {/*  */}
                <div className="row">
                  <div className="col-6 mb-3">
                    <CustomInput
                      name="Bar_Code"
                      placeholder="Enter Bar Code"
                      typeOfInput="text"
                      label="Bar Code"
                      value={formik.values.Bar_Code}
                      onChange={formik.handleChange}
                    />
                    <Popover
                      isOpen={showGetItems}
                      onClickOutside={() => setShowGetItems(false)}
                      content={() => (
                        <ItemsTable handleSelectedItem={handleGetItem} />
                      )}
                      position="bottom"
                    >
                      <span
                        onClick={() => setShowGetItems(!showGetItems)}
                        className="d-flex justify-content-end text-primary p-cursor"
                      >
                        Add from Inventry
                      </span>
                    </Popover>

                    {formik.touched.Bar_Code && !!formik.errors.Bar_Code ? (
                      <span className="custom-invalid-feedback">
                        {formik.errors.Bar_Code}
                      </span>
                    ) : null}
                  </div>
                  <div className="col-6 mb-3">
                    <CustomInput
                      name="Item_Name"
                      placeholder="Enter Item Name"
                      typeOfInput="text"
                      label="Item Name"
                      value={formik.values.Item_Name}
                      onChange={formik.handleChange}
                    />
                    {formik.touched.Item_Name && !!formik.errors.Item_Name ? (
                      <span className="custom-invalid-feedback">
                        {formik.errors.Item_Name}
                      </span>
                    ) : null}
                  </div>
                </div>
                {/*  */}

                <div className="row">
                  <div className="col-6 mb-3">
                    <CustomInput
                      name="Item_Desc"
                      placeholder="Enter Description"
                      typeOfInput="text"
                      label="Description"
                      value={formik.values.Item_Desc}
                      onChange={formik.handleChange}
                    />

                    {formik.touched.Item_Desc && !!formik.errors.Item_Desc ? (
                      <span className="custom-invalid-feedback">
                        {formik.errors.Item_Desc}
                      </span>
                    ) : null}
                  </div>

                  <div className="col-6 mb-3">
                    <label htmlFor="date" className="form-label">
                      Product
                    </label>
                    <Select
                      classNamePrefix={"form-select"}
                      isSearchable={true}
                      value={data?.products?.find(
                        (el) => el.value === formik.values.Product_Name
                      )}
                      onChange={({ value }) => {
                        formik.setFieldValue("Product_Name", value);
                      }}
                      options={data?.products}
                    />

                    {formik.touched.Product_Name &&
                    !!formik.errors.Product_Name ? (
                      <span className="custom-invalid-feedback">
                        {formik.errors.Product_Name}
                      </span>
                    ) : null}
                  </div>
                </div>
                {/*  */}
                <div className="row">
                  <div className="col-6 mb-3">
                    <label htmlFor="date" className="form-label">
                      Category
                    </label>
                    <Select
                      classNamePrefix={"form-select"}
                      isSearchable={true}
                      value={data?.categories?.find(
                        (el) => el.value === formik.values.Cat_Name
                      )}
                      onChange={({ value }) => {
                        formik.setFieldValue("Cat_Name", value);
                      }}
                      options={data?.categories}
                    />

                    {formik.touched.Cat_Name && !!formik.errors.Cat_Name ? (
                      <span className="custom-invalid-feedback">
                        {formik.errors.Cat_Name}
                      </span>
                    ) : null}
                  </div>
                  <div className="col-6 mb-3">
                    <label htmlFor="date" className="form-label">
                      Unit
                    </label>
                    <Select
                      classNamePrefix={"form-select"}
                      isSearchable={true}
                      value={unitRate.find(
                        (el) => el.value === formik.values.Product_Model
                      )}
                      onChange={({ value }) => {
                        formik.setFieldValue("Product_Model", value);
                      }}
                      options={unitRate}
                    />

                    {formik.touched.Product_Model &&
                    !!formik.errors.Product_Model ? (
                      <span className="custom-invalid-feedback">
                        {formik.errors.Product_Model}
                      </span>
                    ) : null}
                  </div>
                </div>
                {/*  */}
                <div className="d-flex justify-content-end text-primary mt-3">
                  <Popover
                    isOpen={showItemsPopover}
                    onClickOutside={() => setShowItemsPopover(false)}
                    content={() => (
                      <AllWarehouseItemsModal
                        handleSelectedItem={handleSelectedItem}
                        hideItemsNotInStock={true}
                      />
                    )}
                    position="bottom"
                  >
                    <span
                      className="btn btn-primary"
                      onClick={() => setShowItemsPopover(true)}
                    >
                      Add
                    </span>
                  </Popover>
                </div>

                {/* Table */}

                <div className="mt-3 mb-4">
                  <Table
                    responsive
                    borderless
                    striped
                    className="product-table"
                  >
                    <thead>
                      <tr>
                        <th>S/N</th>
                        <th>Bar Code</th>
                        <th>Name</th>
                        <th className="text-center">Quantity</th>
                        <th>Unit Cost</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tableData &&
                        tableData?.map((el, index) => (
                          <tr
                            key={index}
                            className={`${activeTableIndex === index &&
                              "active-row"}`}
                            onClick={() =>
                              activeTableIndex !== index &&
                              setActiveTableIndex(index)
                            }
                          >
                            <td>{index + 1}</td>
                            <td>{el.Bar_Code}</td>
                            <td>{el.Item_Name}</td>
                            <td>
                              <input
                                className="border-0 text-center"
                                type="number"
                                value={el.Quantity}
                                onKeyDown={(e) =>
                                  e.key === "Enter" && e.target.blur()
                                }
                                name="Quantity"
                                onChange={(e) =>
                                  handleInputChange({
                                    index,
                                    name: e.target.name,
                                    value: e.target.value,
                                  })
                                }
                              />
                            </td>
                            <td>{el?.UnitCost}</td>
                            <td>
                              {/* <button
                                className="btn btn-danger mx-3 text-white"
                                onClick={() => onDelete(el.Bar_Code)}
                              >
                                Remove
                              </button> */}
                              <span
                                className="p-cursor"
                                onClick={() => onDelete(el.Bar_Code)}
                              >
                                <DeleteIcon />
                              </span>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </div>
                {/*  */}

                <div className="add-to-cart-holder d-flex justify-content-end text-primary mt-5">
                  <div>
                    <Button
                      variant="outline-primary"
                      onClick={formik.handleSubmit}
                    >
                      {sendMaintance.isLoading
                        ? "Sending..."
                        : "Save Bill of Quantity"}
                    </Button>
                  </div>
                </div>
              </form>
            </div>

            {/*  */}
          </div>
        </div>
        <ModalLoader show={sendMaintance.isLoading} />
      </Modal.Body>
    </Modal>
  );
};
