import { Table, Dropdown, Form, Button, InputGroup } from "react-bootstrap";
import { Popover } from "react-tiny-popover";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import { isEmpty } from "lodash";
import { useEffect, useState, useRef } from "react";
import CachedIcon from "mdi-react/CachedIcon";
import Select from "react-select";
import { useQuery } from "react-query";
import currency from "currency.js";
import { format, parse } from "date-fns";
import ReactPaginate from "react-paginate";
import queryString from "query-string";
import { Link } from "react-router-dom";
import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";
import ModalLoader from "../utils/ModalLoader";

import {
  FilterTwoIcon,
  ExchangeFunds,
  SummaryChartIconUp,
  SummaryCloseIcon,
  FilterCollapseIcon,
  FilePdfIcon,
  ExportIcon,
  ExcelIcon,
} from "../Icons";
import "../../assets/scss/reports/cashbook.scss";
import useDebounce, {
  useLocalStorage,
  useQueryParams,
  useScrollTop,
} from "../../utils/hooks";
// import { services } from "../../config";
// import { reportActions } from "../../utils/reactQueryActions";

import {
  formatDate,
  paginationOptions,
  reactSelectTheme,
} from "../../utils/helpers";
import RsDateRangePicker from "../utils/RsDateRangePicker";
import { useAuth } from "../../hooks/useAuth";
import NoTableItem from "../utils/NoTableItem";
import VendorSelector from "./VendorSelector";
import { useDownloadExcel } from "../../hooks/useDownloadExcel";
import { CSVLink } from "react-csv";
import { IsPrivileged } from "../DisplayChildElement";

const memos = [
  { value: "Credit Memo", label: "Credit Memo" },
  { value: "Debit Memo", label: "Debit Memo" },
  { value: "All", label: "All" },
];

export default function VendorCreditDebitMemo() {
  useScrollTop();
  const initialFilterParams = {
    customerName: "",
    customerId: "",
    startDate: format(new Date(), "yyyy-MM-dd"),
    endDate: format(new Date(), "yyyy-MM-dd"),
    enableDateRange: true,
    memoType: "All",
  };

  const { backendUrl } = useAuth();
  //   const [activeRow, setActiveRow] = useState(null);
  const [showItemsPopover, setShowItemsPopover] = useState(false);
  const [showFilter, setShowFilter] = useLocalStorage("showFilter", true);
  const [btnSummryActive, setBtnSummaryActive] = useState(false);
  const [showMoreSummary, setShowMoreSummary] = useState(true);
  const [active, setActive] = useState("");
  const [acount, setAcount] = useState(false);

  // fetch excel hook
  const [excelData, setExcelData] = useState([]);
  const CSVLinkRef = useRef(null);
  const [isfetchingExcel, fetchExcelData] = useDownloadExcel(
    excelData,
    CSVLinkRef
  );

  const close = () => {
    setShowMoreSummary(true);
    setBtnSummaryActive(false);
  };

  const [queryParams, setQueryParams] = useQueryParams({
    page: 1,
    limit: 40,
    ...initialFilterParams,
  });

  const [filterParams, setFilterParams] = useState({
    ...initialFilterParams,
    ...queryParams,
  });

  const debouncedFilterParams = useDebounce(filterParams, 250);
  useEffect(() => {
    setQueryParams({ ...queryParams, ...debouncedFilterParams });
  }, [debouncedFilterParams]);

  const fetchCustomerLedgers = async (queryParams) => {
    // await waitFor(5000);
    let response = await fetch(
      `${backendUrl}/api/vendors/vendor-ledger?&${queryString.stringify(
        queryParams
      )}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();

    console.log(data);
    data.invoiceCat = data.invoiceCat.map((el) => ({
      value: el,
      label: el,
    }));

    data.creditMemoAmout = data.customerLedgers.reduce(
      (p, c) => p + c.Credit,
      0
    );

    data.debitMemoAmout = data.customerLedgers.reduce((p, c) => p + c.Debit, 0);

    // console.log("data.debitMemoAmout => ", data.debitMemoAmout);
    // console.log("data.creditMemoAmout => ", data.creditMemoAmout);
    return data;
  };

  const {
    data = {
      count: 0,
      customerLedgers: [],
      debitMemoAmout: 0,
      creditMemoAmout: 0,
    },
    refetch,
    isFetching,
    isSuccess,
  } = useQuery(
    ["GET ALL VENDORS", queryParams],
    () => fetchCustomerLedgers(queryParams),
    {
      keepPreviousData: true,
    }
  );

  const handleFilterParamsChange = (e) => {
    setFilterParams({
      ...filterParams,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  const handleSearchQueryChange = (e) => {
    setQueryParams({
      ...queryParams,
      [e.target.name]: e.target.value,
    });
  };

  const filterByDateRange = (date) => {
    setFilterParams({
      ...filterParams,
      startDate: format(date[0], "yyyy-MM-dd"),
      endDate: format(date[1], "yyyy-MM-dd"),
      enableDateRange: true,
    });
  };

  const clearDateRange = () => {
    setFilterParams({
      ...filterParams,
      startDate: "",
      endDate: "",
    });
  };

  const search = () => {
    setQueryParams({
      ...queryParams,
      ...filterParams,
    });
  };

  const reset = () => {
    setFilterParams(initialFilterParams);
    setQueryParams({
      ...queryParams,
      ...initialFilterParams,
    });
  };

  const handleSelectedCustomer = (customer) => {
    setFilterParams({
      ...filterParams,
      customerId: customer.Vendor_ID,
      customerName: customer.CompanyName,
    });
    setShowItemsPopover(false);
  };

  const onDownloadExcelData = async () => {
    const { limit, page, ...rest } = queryParams;
    let exData = await fetchExcelData(
      `${backendUrl}/api/vendors/vendor-ledger?${queryString.stringify(rest)}`,
      "GET"
    );

    exData = exData?.data?.customerLedgers?.map((d, i) => [
      i + 1,
      d.vendor.CompanyName,
      d.Post_Time ? formatDate(d.Post_Time, "dd MMM, yyyy") : "",
      d.DocType,
      d.Trans_ID,
      d.PaymentType,
      currency(d.Credit, {
        symbol: "",
      }).format(),
      currency(d.Debit, {
        symbol: "",
      }).format(),
      currency(d.Balance, {
        symbol: "",
      }).format(),
    ]);
    const date =
      rest.startDate && rest.endDate
        ? `Date Prepared: Between ${format(
            new Date(rest.startDate),
            "E MMM d yyyy k:mm:ss z"
          )} to ${format(new Date(rest.endDate), "E MMM d yyyy k:mm:ss z")}`
        : "";
    exData = [
      ["Vendor Credit/Debit Memo Report"],
      [date],
      [""],
      [
        "S/N",
        "Company Name",
        "Trans Date",
        "Doc Type",
        "Trans ID",
        "Payment Type",
        "Credit Amount",
        "Debit Amount",
        "Balance",
      ],
      ...exData,
      [""],
      [""],
      ["Summary"],
      [
        "Total Credit Memo",
        currency(data?.creditMemoAmout, {
          symbol: "",
        }).format(),
      ],
      [
        "Total Debit Memo",
        currency(data?.debitMemoAmout, {
          symbol: "",
        }).format(),
      ],
    ];
    console.log(exData);
    setExcelData(exData);
  };

  return (
    <IsPrivileged roleName="Vendors Credit/Debit Memo">
      <section className="cashbook-wrapped">
        <main className="inner-page-outlet">
          <main className="cash-book">
            <div className="content">
              <div className="content-main">
                <div className={`filter-area ${showFilter ? "open" : "close"}`}>
                  <div className="filter-header">
                    <h2>
                      <FilterTwoIcon /> Filters
                    </h2>

                    <Button
                      variant="white"
                      onClick={() => setShowFilter(!showFilter)}
                    >
                      <FilterCollapseIcon />
                    </Button>
                  </div>
                  <div className="filter-body">
                    <Form>
                      <div className="body">
                        <Form.Group className="mb-2-5">
                          <Form.Label>Vendor ID</Form.Label>
                          <InputGroup>
                            <Form.Control
                              name="customerId"
                              placeholder="Enter Vendor ID"
                              value={filterParams?.customerId}
                              onChange={(e) => handleFilterParamsChange(e)}
                            />
                            <Popover
                              isOpen={showItemsPopover}
                              onClickOutside={() => setShowItemsPopover(false)}
                              content={() => (
                                <VendorSelector
                                  handleSelectedCustomer={
                                    handleSelectedCustomer
                                  }
                                  setShowCustomerSelector={setShowItemsPopover}
                                />
                              )}
                              position="bottom"
                            >
                              <InputGroup.Text
                                onClick={() =>
                                  setShowItemsPopover(!showItemsPopover)
                                }
                              >
                                <MagnifyIcon />
                              </InputGroup.Text>
                            </Popover>
                          </InputGroup>
                        </Form.Group>

                        <Form.Group className="mb-2-5">
                          <Form.Label>Vendor Name</Form.Label>
                          <Form.Control
                            name="customerName"
                            value={filterParams?.customerName}
                            placeholder="Enter Vendor Name"
                            onChange={(e) => handleFilterParamsChange(e)}
                          />
                        </Form.Group>

                        <Form.Group className="mb-2-5">
                          <Form.Label>Invoice Cat</Form.Label>
                          <Select
                            classNamePrefix="form-select"
                            menuPosition="fixed"
                            menuPlacement="auto"
                            placeholder="All"
                            theme={reactSelectTheme}
                            isSearchable={false}
                            key={data?.invoiceCat}
                            value={data?.invoiceCat?.find(
                              (el) => el.value === queryParams?.invoiceCat
                            )}
                            options={data?.invoiceCat || []}
                            onChange={(selected) => {
                              setFilterParams({
                                ...filterParams,
                                invoiceCat: selected.value,
                              });
                            }}
                          />
                        </Form.Group>

                        <Form.Group className="mb-2-5">
                          <Form.Label>Choose Memo Type</Form.Label>
                          <Select
                            classNamePrefix="form-select"
                            menuPosition="fixed"
                            menuPlacement="auto"
                            placeholder="All"
                            theme={reactSelectTheme}
                            isSearchable={false}
                            value={memos?.find(
                              (el) => el.value === queryParams?.invoiceCat
                            )}
                            options={memos}
                            onChange={(selected) => {
                              setFilterParams({
                                ...filterParams,
                                memoType: selected.value,
                              });
                            }}
                          />
                        </Form.Group>

                        <hr className="mt-3 mb-4" />

                        <div className="mb-2-5">
                          <Form.Check
                            inline
                            label="Enable Date Range"
                            name="enableDateRange"
                            type={"checkbox"}
                            id={`enableDateRange`}
                            checked={filterParams?.enableDateRange}
                            onChange={(e) => handleFilterParamsChange(e)}
                          />
                        </div>

                        <Form.Group className="">
                          <Form.Label>Date Range</Form.Label>
                          <div className="position-relative">
                            <RsDateRangePicker
                              placement="topStart"
                              value={
                                filterParams.startDate && filterParams.endDate
                                  ? [
                                      parse(
                                        filterParams.startDate,
                                        "yyyy-MM-dd",
                                        new Date()
                                      ),
                                      parse(
                                        filterParams.endDate,
                                        "yyyy-MM-dd",
                                        new Date()
                                      ),
                                    ]
                                  : []
                              }
                              onClean={() => clearDateRange()}
                              onOk={(date) => filterByDateRange(date)}
                            />
                          </div>
                        </Form.Group>
                      </div>

                      <div className="buttons rounded">
                        <Button onClick={() => reset()} variant="white">
                          Reset
                        </Button>
                        <Button onClick={() => search()} variant="primary">
                          Search
                        </Button>
                      </div>
                    </Form>
                  </div>
                </div>
                <div className="content-body">
                  <header>
                    <h1>
                      {!showFilter && (
                        <button
                          onClick={() => setShowFilter(!showFilter)}
                          className="btn filter"
                        >
                          <FilterTwoIcon />
                        </button>
                      )}
                      Vendor Credit/Debit Memo
                      <button
                        title="Refresh"
                        onClick={() => refetch()}
                        className="btn text-primary"
                      >
                        <CachedIcon />
                      </button>
                    </h1>

                    <div className="fw-bold">
                      <h6>{filterParams?.customerName}</h6>
                    </div>

                    <div className="actions">
                      {/* <button className="btn print">
              Filters <FilterIcon />
            </button> */}

                      <Link
                        to="/vendor/create-vendors-memo"
                        className="btn bg-primary text-white"
                      >
                        Create New <ExchangeFunds color="white" />
                      </Link>

                      <button
                        onClick={() => setBtnSummaryActive(true)}
                        className="btn summaryBtn "
                      >
                        Show Summary
                      </button>

                      <CSVLink
                        className="btn print d-none"
                        filename={`Vendor Credit/Debit Memo Report(${format(
                          new Date(),
                          "dd-MMM-yyyy hh:mm:ss a"
                        )}).csv`}
                        data={excelData}
                        ref={CSVLinkRef}
                      />
                      <Dropdown>
                        <Dropdown.Toggle
                          variant=""
                          className="btn print"
                          disabled={isfetchingExcel}
                          bsPrefix=""
                        >
                          Export
                          <ExportIcon color="#008000" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                          popperConfig={{
                            strategy: "fixed",
                          }}
                          renderOnMount
                          className="text-center"
                        >
                          <Dropdown.Item
                            as="div"
                            onClick={onDownloadExcelData}
                            className="p-cursor"
                          >
                            Excel <ExcelIcon color="#008000" />
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </header>

                  <div className="px-md-4">
                    <Table
                      responsive
                      borderless
                      // striped
                      className="product-table text-nowrap"
                    >
                      <thead>
                        <tr>
                          <th />
                          <th>Company Name</th>
                          <th>Trans Date</th>
                          <th>Doc Type</th>
                          <th>Trans ID</th>
                          <th>Payment Type</th>
                          <th>Credit Amount</th>
                          <th>Debit Amount</th>
                          <th>Balance</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.customerLedgers.map((el, index) => (
                          <tr
                            key={index}
                            className={`p-cursor tran-table ${active ===
                              index && "active-table"} ${index % 2 === 0 &&
                              "dark-row"}`}
                            onClick={() => setActive(index)}
                          >
                            <td>
                              {
                                <Dropdown>
                                  <Dropdown.Toggle
                                    variant=""
                                    className="bg-white border-0"
                                    bsPrefix="print more"
                                  >
                                    <DotsVerticalIcon />
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu
                                    popperConfig={{
                                      strategy: "fixed",
                                    }}
                                    renderOnMount
                                    className="dropdown-with-icons"
                                  >
                                    <Dropdown.Item as="div">
                                      {/* <a
                                    href={`${backendUrl}/api/customers/view-memo/pdf/${
                                      el.Trans_ID
                                    }/${el.Vendor_ID}`}
                                    target="blank"
                                  > */}
                                      <FilePdfIcon color="#2463AE" />
                                      <span className="text-primary">
                                        View PDF
                                      </span>
                                      {/* </a> */}
                                    </Dropdown.Item>
                                  </Dropdown.Menu>{" "}
                                </Dropdown>
                              }
                            </td>
                            <td>{el.vendor.CompanyName}</td>
                            <td>
                              {el.Post_Time
                                ? formatDate(el.Post_Time, "dd MMM, yyyy")
                                : ""}
                            </td>
                            <td>{el.DocType}</td>
                            <td>{el.Trans_ID}</td>
                            <td>{el.PaymentType}</td>
                            <td>
                              {currency(el.Credit, {
                                symbol: "",
                              }).format()}
                            </td>
                            <td>
                              {currency(el.Debit, {
                                symbol: "",
                              }).format()}
                            </td>
                            <td>
                              {currency(el.Balance, {
                                symbol: "",
                              }).format()}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    {!isFetching &&
                    isSuccess &&
                    isEmpty(data?.customerLedgers) ? (
                      <NoTableItem queryParams={queryParams} />
                    ) : null}
                  </div>

                  <div className="d-flex justify-content-between px-3 align-items-center pagination">
                    {/*<p className="m-0">
                Showing {data?.startIndex + 1} to{" "}
                {data?.endIndex <= data?.count ? data?.endIndex : data?.count}{" "}
                of {data.count} entries
                  </p> */}
                    <div className="pagination_left">
                      <p className="m-0 p-0">Show</p>
                      <select
                        value={queryParams.limit}
                        name="limit"
                        className="form-select "
                        onChange={(e) => handleSearchQueryChange(e)}
                      >
                        <option value="10">10 rows</option>
                        <option value="20">20 rows</option>
                        <option value="30">30 rows</option>
                        <option value="40">40 rows</option>
                        <option value="50">50 rows</option>
                        <option value="100">100 rows</option>
                      </select>
                    </div>

                    <ReactPaginate
                      {...paginationOptions}
                      pageCount={Math.ceil(data.count / queryParams.limit)}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={0}
                      onPageChange={({ selected }) => {
                        document.body.scrollTop = document.documentElement.scrollTop = 0;
                        setQueryParams({
                          ...queryParams,
                          page: selected + 1,
                        });
                      }}
                      forcePage={queryParams.page - 1}
                    />
                  </div>

                  <div
                    className={
                      btnSummryActive
                        ? `summaryNew border-top open`
                        : `summaryNew border-top`
                    }
                  >
                    <div className="summaryHeader">
                      <h2>Summary</h2>
                      {btnSummryActive && (
                        <button onClick={() => close()} className="btn p-0">
                          <SummaryCloseIcon />
                        </button>
                      )}
                    </div>

                    <div className="gridCont">
                      <div className="gridChild gridChildBorderLeftBlue">
                        <div className="gridChildLeft">
                          <p className="gridChld1">
                            {currency(data?.creditMemoAmout, {
                              symbol: "",
                            }).format()}
                          </p>
                          <p className="gridChld2">Total Credit Memo</p>
                        </div>

                        <div className="gridChildRight">
                          <SummaryChartIconUp />
                          <p>5%</p>
                        </div>
                      </div>

                      <div className="gridChild gridChildBorderLeftOrange">
                        <div className="gridChildLeft">
                          <p className="gridChld1">
                            {currency(data?.debitMemoAmout, {
                              symbol: "",
                            }).format()}
                          </p>
                          <p className="gridChld2">Total Debit Memo</p>
                        </div>

                        <div className="gridChildRight">
                          <SummaryChartIconUp />
                          <p>5%</p>
                        </div>
                      </div>

                      <ModalLoader
                        title="Downloading Excel..."
                        show={isfetchingExcel}
                      />

                      {/*  <AddAccounts show={acount} onHide={() => setAcount(false)} /> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </main>
      </section>
    </IsPrivileged>
  );
}
