import { useStoreActions, useStoreState } from "easy-peasy";
import { useEffect } from "react";
import { useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import eventBus from "../utils/EventBus";

export default function FileHolder() {
  const ref = useRef();
  const [accept, setAccept] = useState("image/*");
  const [isMultiple, setIsMultiple] = useState(false);
  const [elementToUpdate, setElementToUpdate] = useState();

  // const filePool = useStoreState((state) => state.filePool);
  //  const setFilePool = useStoreActions((actions) => actions.setFilePool);

  const handleFileInput = (e) => {
    e.preventDefault();
    const files = Array.from(e.target.files);
    const newFiles = files.map((file, i) => {
      const fileId = `${uuidv4()}-${i}`;
      const name = file.name;
      const lastDot = name.lastIndexOf(".");
      const fileName = name.substring(0, lastDot);
      const ext = name.substring(lastDot + 1);
      const size = file.size;

      return { fileId, name: fileName, ext, size, file, isUploaded: true };
    });

    // setFilePool([...filePool, ...newFiles]);

    // send to iframe
    console.log({ files: newFiles, ...elementToUpdate });

    eventBus.dispatch("SEND_FILE_TO_IFRAME", {
      files: newFiles,
      ...elementToUpdate,
    });
  };

  const handleMessage = (message) => {
    const { accept, elementId, elementType } = message.data;

    if (message.data.type === "SELECT_FILE_SINGLE") {
      setIsMultiple(false);
    } else if (message.data.type === "SELECT_FILE_MULTIPLE") {
      setIsMultiple(true);
    }

    if (
      message.data.type === "SELECT_FILE_SINGLE" ||
      message.data.type === "SELECT_FILE_MULTIPLE"
    ) {
      // setAccept(accept);
      setElementToUpdate({
        elementId,
        elementType,
      });
      setTimeout(() => {
        ref.current.click();
      }, 100);
    }
  };

  useEffect(() => {
    window.addEventListener("message", handleMessage);

    // cleanup this component
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  return (
    <div className="position-absolute .mt-n5" style={{ top: "-100vh" }}>
      <input
        ref={ref}
        type="file"
        // accept={accept}
        onChange={(e) => handleFileInput(e)}
        multiple={isMultiple}
      />
    </div>
  );
}
