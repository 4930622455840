import { Link, useLocation } from "react-router-dom";
import { appSettings } from "../../config";
import { useMemo } from "react";
import { useStoreState } from "easy-peasy";

export const CustomerSideBar = () => {
  const location = useLocation();
  const generalSettings = useStoreState((state) => state.generalSettings);

  const sideBars = useMemo(
    () => [
      ...(generalSettings?.customerCanEditPriceInCart
        ? [
            {
              name: "Quotes",
              link: `${appSettings.storeBaseUrl}/account/quotes`,
            },
          ]
        : []),
      { name: "Orders", link: `${appSettings.storeBaseUrl}/account/orders` },
      {
        name: "Invoices",
        link: `${appSettings.storeBaseUrl}/account/invoices`,
      },
      {
        name: "Payments",
        link: `${appSettings.storeBaseUrl}/account/payments`,
      },
      { name: "Ledger", link: `${appSettings.storeBaseUrl}/account/ledger` },
      {
        name: "Profile",
        link: `${appSettings.storeBaseUrl}/account/profile`,
      },
    ],
    [generalSettings?.customerCanEditPriceInCart]
  );

  return (
    <>
      <div className="border-bottom">
        <div className="text-center py-3">
          <h4>My Account</h4>
        </div>
      </div>

      {sideBars.map((el, i) => (
        <div
          key={i}
          className={`p-3 py-4 cursor ${
            location.pathname.startsWith(`${el.link}`) ? "active-bg" : ""
          }`}
        >
          <Link to={el.link}>
            <h6>
              <p>{el.name}</p>
            </h6>
          </Link>
        </div>
      ))}
    </>
  );
};
