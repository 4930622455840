import { useState, useEffect } from "react";

import { useAuth } from "../hooks/useAuth";

export const useFetchUsers = () => {
  const { backendUrl } = useAuth();
  const [users, setUsers] = useState([])

  const fetchUser = async () => {
    let response = await fetch(`${backendUrl}/api/users`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const data = await response.json();
    const users = data.staff.map(d => {
      return { label: d.Name, value: d.Staff_ID }
    })

    setUsers(users)
    // console.log(users)
  }

  useEffect(() => {
    fetchUser()
  }, [])

  return [...users]
}