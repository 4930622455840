import React from "react";
import { BackIcon, BucketIcon, UserIconTwo } from "../Icons";
import { Outlet } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { useQuery } from "react-query";
import BankIcon from "mdi-react/BankIcon";

export default function EBCompaniesWrapper({ Nav }) {
  const { backendUrl, token } = useAuth();
  const fetchSystemCompanyStats = async () => {
    const res = await fetch(
      `${backendUrl}/api/company/get-system-company-stats`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
          ...(token ? { Authorization: `Bearer ${token}` } : {}),
        },
      }
    );

    const data = await res.json();

    return data;
  };

  const { data, isFetching } = useQuery(
    ["GET_SYSTEM_COMPANY_STATS"],
    () => fetchSystemCompanyStats(),
    {
      enabled: true,
      keepPreviousData: false,
    }
  );

  return (
    <main className="invoice-wrapper">
      <section className=" stats mb-3">
        <div className="stat">
          <div className="icon bg-light-blue text-primary">
            <BankIcon />
          </div>
          <div className="details">
            <p>{data?.totalCompany || 0}</p>
            <span>Total Companies</span>
          </div>
        </div>
        <div className="stat">
          <div className="icon bg-light-blue">
            <BucketIcon />
          </div>
          <div className="details">
            <p>{data?.totalBranches || 0}</p>
            <span>Total Branches</span>
          </div>
        </div>
        <div className="stat">
          <div className="icon bg-light-blue">
            <UserIconTwo />
          </div>
          <div className="details">
            <p>{data?.totalUsers || 0}</p>
            <span>Total Users</span>
          </div>
        </div>
      </section>

      {Nav && <div className="nav-bar">{Nav}</div>}
      <Outlet />
    </main>
  );
}
