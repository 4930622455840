import { useFormik } from "formik";
import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import { Modal, Form, Nav, InputGroup, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import * as yup from "yup";
import EyeOffOutline from "mdi-react/EyeOffOutlineIcon";
import EyeOutline from "mdi-react/EyeOutlineIcon";

import useDebounce, {
  useBackendUrl,
  useEffectOnce,
  useIsAdmin,
} from "../utils/hooks";
import Avatar from "./utils/Avatar";
import { useAuth } from "../hooks/useAuth";
import { useStoreActions, useStoreState } from "easy-peasy";
import { GearIcon } from "./Icons";
import { useMutation, useQuery } from "react-query";
import { initialServiceSettings } from "../utils/helpers";
import Select from "react-select";
import CurrencyCustomInput from "./utils/CurrencyCustomInput";
import ExpensesSelector from "./utils/ExpensesSelector";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import { Popover } from "react-tiny-popover";
import eventBus from "../utils/EventBus";
import NumberCustomInput from "./utils/NumberCustomInput";
import { durationOptions } from "../config";

export default function ServiceSettingsModal({
  showServiceSettingsModal,
  setShowServiceSettingsModal,
}) {
  const { backendUrl } = useAuth();
  const isAdmin = useIsAdmin();
  const [showPassword, setShowPassword] = useState(false);
  //const generalSettings = useStoreState((state) => state.generalSettings);
  const [isValidated, setIsValidated] = useState(true);
  const [passKey, setPassKey] = useState("");
  const updateGeneralSettings = useStoreActions(
    (actions) => actions.updateGeneralSettings
  );

  const addSettings = async (payload) => {
    let response = await fetch(`${backendUrl}/api/users/company-settings`, {
      method: "POST",
      // credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const addSettingsMutation = useMutation((payload) => addSettings(payload), {
    onError: () => {
      toast.error(`Unable to perform action`);
    },
  });

  const formik = useFormik({
    initialValues: initialServiceSettings,
    onSubmit: (values) => {
      //  Save  to  server
      addSettingsMutation.mutate(
        { settings: values },
        {
          onSuccess: ({ message, data }) => {
            updateGeneralSettings(values);
            toast.success("Settings saved");
            setShowServiceSettingsModal(false);
          },
        }
      );
    },
  });

  const setUp = async () => {
    // await waitFor(5000);
    let response = await fetch(`${backendUrl}/api/users/company/settings`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    if (data.settings) {
      // use only required settings
      const requiredSettings = {};
      for (let [key, value] of Object.entries(data.settings)) {
        if (initialServiceSettings.hasOwnProperty(key)) {
          requiredSettings[key] = value;
        }
      }

      formik.setValues({ ...initialServiceSettings, ...requiredSettings });
    }
    if (data.staff) {
      data.staff = data.staff.map((el) => ({
        ...el,
        label: el.Name,
        value: el.Staff_ID,
      }));
    }

    return data;
  };

  const {
    data = {
      staff: [],
    },
    isFetching,
  } = useQuery(["GET_GLOBAL_SERVICES_SETTINGS"], () => setUp(), {
    enabled: true,
  });

  const verifyPasskey = async (payload) => {
    let response = await fetch(`${backendUrl}/api/company/verify-passKey`, {
      method: "POST",
      // credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const verifyPasskeyMutation = useMutation(
    (payload) => verifyPasskey(payload),
    {
      onSuccess: () => {
        setIsValidated(true);
      },
      onError: ({ message }) => {
        toast.error(`Unable to perform action: ${message}`);
      },
    }
  );

  return (
    <>
      <Modal
        show={showServiceSettingsModal}
        onHide={() => setShowServiceSettingsModal(false)}
        enforceFocus={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="h5">
            <GearIcon />
            {"  "}
            Service Settings
          </Modal.Title>
        </Modal.Header>

        {!isValidated ? (
          <>
            <Modal.Body>
              <Form.Group className="mb-4">
                <Form.Label>Password</Form.Label>
                <InputGroup>
                  <Form.Control
                    type={showPassword ? "text" : "password"}
                    name="password"
                    placeholder="*********"
                    value={passKey}
                    onChange={(e) => setPassKey(e.target.value)}
                  />
                  {showPassword ? (
                    <InputGroup.Text
                      onClick={() => setShowPassword(false)}
                      className="bg-white"
                    >
                      <EyeOutline />
                    </InputGroup.Text>
                  ) : (
                    <InputGroup.Text
                      onClick={() => setShowPassword(true)}
                      className="bg-white"
                    >
                      <EyeOffOutline />
                    </InputGroup.Text>
                  )}
                </InputGroup>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button
                onClick={() => verifyPasskeyMutation.mutate({ passKey })}
                variant="primary"
                disabled={verifyPasskeyMutation.isLoading}
                className="px-4"
              >
                {verifyPasskeyMutation.isLoading ? "Please wait..." : "Login"}
              </Button>
            </Modal.Footer>
          </>
        ) : (
          <>
            <Modal.Body>
              <Form
                noValidate
                onSubmit={formik.handleSubmit}
                autoComplete="off"
                style={
                  isFetching ? { opacity: "0.5", pointerEvents: "none" } : {}
                }
              >
                <Form.Group>
                  <Form.Check
                    inline
                    id="warehouse"
                    label="Warehouse"
                    name="warehouse"
                    type={"checkbox"}
                    checked={formik.values.warehouse}
                    onChange={formik.handleChange}
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Check
                    inline
                    id="production"
                    label="Production"
                    name="production"
                    type={"checkbox"}
                    checked={formik.values.production}
                    onChange={formik.handleChange}
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Check
                    inline
                    id="formsAndApproval"
                    label="Forms And Approval"
                    name="formsAndApproval"
                    type={"checkbox"}
                    checked={formik.values.formsAndApproval}
                    onChange={formik.handleChange}
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Check
                    inline
                    id="store"
                    label="Online Store"
                    name="store"
                    type={"checkbox"}
                    checked={formik.values.store}
                    onChange={formik.handleChange}
                  />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                onClick={() => formik.submitForm()}
                variant="primary"
                disabled={addSettingsMutation.isLoading}
                className="px-4"
              >
                {addSettingsMutation.isLoading ? "Please wait..." : "Save"}
              </Button>
            </Modal.Footer>
          </>
        )}
      </Modal>
    </>
  );
}
