import { format } from "date-fns";
import { NoSelectedItemIcon } from "../Icons";

export default function NoTableItem({
  queryParams,
  title = "No Records found",
}) {
  return (
    <div className="no-item p-3 pt-4 text-center bg-white">
      <div className="info">
        <NoSelectedItemIcon />
        <h2 className="mb-1 mt-3 h6">{title}</h2>
        {queryParams?.startDate && queryParams?.endDate ? (
          <p>
            {queryParams.startDate
              ? format(new Date(queryParams.startDate), "dd MMM, yyy")
              : ""}
            {" - "}
            {queryParams.endDate
              ? format(new Date(queryParams.endDate), "dd MMM, yyy")
              : ""}
            {/* <span>
                  {queryParams?.customerName
                    ? ` for ${queryParams?.customerName}`
                    : ""}
                </span> */}
          </p>
        ) : null}
      </div>
    </div>
  );
}
