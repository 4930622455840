import { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { confirmable, createConfirmation } from "react-confirm";
import { useQuery } from "react-query";
import { services } from "../../config";
import { requisitionActions } from "../../utils/reactQueryActions";
import { ApproveBlueModalIcon, RejectModalIcon } from "../Icons";
import "./../../assets/scss/requisition/index.scss";
import { styleHelper, waitFor } from "../../utils/helpers";
import { toast } from "react-toastify";
import { format } from "date-fns";
import eventBus from "../../utils/EventBus";
import { useBackendUrl, useEffectOnce } from "../../utils/hooks";

function Reject({
  proceed,
  show,
  requisition,
  authUser,
  previousRequisitionTrackId,
  requisitionTitle,
  isReservedUser,
}) {
  const backendUrl = useBackendUrl();
  const [comment, setComment] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const rejectRequisition = async () => {
    try {
      setIsLoading(true);
      let response = await fetch(`${backendUrl}/api/requisition/action`, {
        method: "POST",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
        body: JSON.stringify({
          requestid: requisition.requestid,
          receivedby: authUser.Staff_ID,
          sentby: requisition.sentby,
          status: "Disapproved",
          regdate: format(new Date(), "yyyy-MM-dd"),
          comment,
          previousRequisitionTrackId,
          isReservedUser,
        }),
      });

      if (!response.ok) {
        response = await response.json();
        throw new Error(response.message);
      }

      const { data } = await response.json();
      toast.success("Requisition Rejected");
      eventBus.dispatch("REQUISITION_UPDATED", data.requisition);
      proceed(true);
      // return data;
    } catch (err) {
      console.log(err);
      toast.error("Unable to Reject");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      show={show}
      onHide={() => !isLoading && proceed(false)}
      dialogClassName="requisition-details-modal"
      centered={true}
      animation={false}
      enforceFocus={false}
      fullscreen={styleHelper.isMobile}
    >
      <Modal.Header closeButton />
      <Modal.Body className="approve-action">
        <div className="text-info-h">
          <RejectModalIcon className="mb-4" />
          <h2>Reject Requisition</h2>
          <p>
            Are you sure you want to reject{" "}
            <span className="fw-5">{requisitionTitle}</span> from{" "}
            <span className="fw-5">{requisition?.sentbyUser?.Name} </span> ?
          </p>
        </div>
        <Form.Group className="my-4">
          <Form.Label className="d-none">Comment :</Form.Label>
          <label className="border p-0 rounded pb-2 message-area d-block">
            <Form.Control
              name="message"
              as="textarea"
              className="w-100 border-0 p-3"
              placeholder="Enter your comment"
              rows={4}
              autoFocus
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
          </label>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <span />
        <div className="proceed-actions">
          <Button
            disabled={isLoading}
            onClick={() => proceed(false)}
            variant="white"
            className="border bg-white"
          >
            Cancel
          </Button>
          <Button
            disabled={isLoading}
            onClick={() => rejectRequisition()}
            variant="primary"
          >
            {isLoading ? "Please wait" : "Okay"}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export function RejectDialog(props) {
  return createConfirmation(confirmable(Reject))(props);
}

function ApproveAndClose({
  proceed,
  show,
  requisition,
  authUser,
  previousRequisitionTrackId,
  requisitionTitle,
  tableData,
  isReservedUser,
}) {
  // console.log(requisition);
  const backendUrl = useBackendUrl();
  const [isLoading, setIsLoading] = useState(false);
  const [comment, setComment] = useState("");
  const approveRequisition = async () => {
    try {
      setIsLoading(true);
      let response = await fetch(`${backendUrl}/api/requisition/action`, {
        method: "POST",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
        body: JSON.stringify({
          requestid: requisition.requestid,
          receivedby: authUser.Staff_ID,
          sentby: requisition.sentby,
          status: "Approved & Closed",
          regdate: format(new Date(), "yyyy-MM-dd"),
          comment,
          previousRequisitionTrackId,
          tableData,
          isReservedUser,
        }),
      });

      if (!response.ok) {
        response = await response.json();
        throw new Error(response.message);
      }

      const { data } = await response.json();
      toast.success("Requisition Approved & Closed");
      eventBus.dispatch("REQUISITION_UPDATED", data.requisition);
      proceed(true);
      // return data;
    } catch (err) {
      console.log(err);
      toast.error("Unable to Approve & Close");
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Modal
      show={show}
      onHide={() => !isLoading && proceed(false)}
      dialogClassName="requisition-details-modal"
      centered={true}
      animation={false}
      enforceFocus={false}
      fullscreen={styleHelper.isMobile}
    >
      <Modal.Header closeButton />
      <Modal.Body className="approve-action">
        <div className="text-info-h">
          <ApproveBlueModalIcon className="mb-4" />
          <h2>Approve and Close Requisition</h2>
          <p>
            Are you sure you want to Approve the{" "}
            <span className="fw-5">{requisitionTitle}</span> from{" "}
            <span className="fw-5">{requisition?.sentbyUser?.Name} </span> ?
          </p>
        </div>
        <Form.Group className="my-4">
          <Form.Label className="d-none">Comment :</Form.Label>
          <label className="border p-0 rounded pb-2 message-area d-block">
            <Form.Control
              name="message"
              as="textarea"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              className="w-100 border-0 p-3"
              placeholder="Enter your comment"
              rows={4}
              autoFocus
            />
          </label>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <span />
        <div className="proceed-actions">
          <Button
            disabled={isLoading}
            onClick={() => proceed(false)}
            variant="white"
            className="border bg-white"
          >
            Cancel
          </Button>
          <Button
            disabled={isLoading}
            onClick={() => approveRequisition()}
            variant="primary"
          >
            {isLoading ? "Please wait" : "Okay"}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export function ApproveAndCloseDialog(props) {
  return createConfirmation(confirmable(ApproveAndClose))(props);
}

function LeaveComment({
  proceed,
  show,
  requisition,
  authUser,
  previousRequisitionTrackId,
  requisitionTitle,
  refetch,
}) {
  // console.log(requisition);
  const backendUrl = useBackendUrl();
  const [isLoading, setIsLoading] = useState(false);
  const [comment, setComment] = useState("");
  const approveRequisition = async () => {
    try {
      setIsLoading(true);
      let response = await fetch(
        `${backendUrl}/api/requisition/leave-comment`,
        {
          method: "POST",
          headers: {
            Accept: "Application/json",
            "Content-Type": "Application/json",
          },
          credentials: "include",
          body: JSON.stringify({
            requestid: requisition.requestid,
            comment,
            //----
          }),
        }
      );

      if (!response.ok) {
        response = await response.json();
        throw new Error(response.message);
      }

      const { data } = await response.json();
      toast.success("Comment Sent");
      //  eventBus.dispatch("REQUISITION_UPDATED", data.requisition);
      if (refetch) refetch();
      proceed(true);
      // return data;
    } catch (err) {
      console.log(err);
      toast.error("Unable to Send Comment");
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Modal
      show={show}
      onHide={() => !isLoading && proceed(false)}
      dialogClassName="requisition-details-modal"
      centered={true}
      animation={false}
      enforceFocus={false}
      fullscreen={styleHelper.isMobile}
    >
      <Modal.Header closeButton />
      <Modal.Body className="approve-action">
        <div className="text-info-h">
          <ApproveBlueModalIcon className="mb-4" />
          <h2>Comment</h2>
          <p>
            <span className="fw-5">{requisition?.title}</span>
          </p>
        </div>
        <Form.Group className="my-4">
          <Form.Label className="d-none">Comment :</Form.Label>
          <label className="border p-0 rounded pb-2 message-area d-block">
            <Form.Control
              name="message"
              as="textarea"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              className="w-100 border-0 p-3"
              placeholder="Enter your comment"
              rows={4}
              autoFocus
            />
          </label>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <span />
        <div className="proceed-actions">
          <Button
            disabled={isLoading}
            onClick={() => proceed(false)}
            variant="white"
            className="border bg-white"
          >
            Cancel
          </Button>
          <Button
            disabled={isLoading}
            onClick={() => approveRequisition()}
            variant="primary"
          >
            {isLoading ? "Please wait" : "Send"}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export function LeaveCommentDialog(props) {
  return createConfirmation(confirmable(LeaveComment))(props);
}

function EditLeaveComment({
  proceed,
  show,
  id,
  refetch,
  oldComment,
  requestid,
}) {
  const backendUrl = useBackendUrl();
  const [isLoading, setIsLoading] = useState(false);
  const [comment, setComment] = useState("");

  useEffectOnce(() => {
    setComment(oldComment);
  });

  const approveRequisition = async () => {
    try {
      setIsLoading(true);
      let response = await fetch(`${backendUrl}/api/requisition/edit-comment`, {
        method: "POST",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
        body: JSON.stringify({
          id,
          comment,
          requestid,
        }),
      });

      if (!response.ok) {
        response = await response.json();
        throw new Error(response.message);
      }

      const { data } = await response.json();
      toast.success("Comment Edited");
      if (refetch) refetch();
      proceed(true);
    } catch (err) {
      console.log(err);
      toast.error("Unable to Edit Comment");
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Modal
      show={show}
      onHide={() => !isLoading && proceed(false)}
      dialogClassName="requisition-details-modal"
      centered={true}
      animation={false}
      enforceFocus={false}
      fullscreen={styleHelper.isMobile}
    >
      <Modal.Header closeButton />
      <Modal.Body className="approve-action">
        <div className="text-info-h">
          <ApproveBlueModalIcon className="mb-4" />
          <h2>Edit Comment</h2>
        </div>
        <Form.Group className="my-4">
          <Form.Label className="d-none">Comment :</Form.Label>
          <label className="border p-0 rounded pb-2 message-area d-block">
            <Form.Control
              name="message"
              as="textarea"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              className="w-100 border-0 p-3"
              placeholder="Enter your comment"
              rows={4}
              autoFocus
            />
          </label>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <span />
        <div className="proceed-actions">
          <Button
            disabled={isLoading}
            onClick={() => proceed(false)}
            variant="white"
            className="border bg-white"
          >
            Cancel
          </Button>
          <Button
            disabled={isLoading}
            onClick={() => approveRequisition()}
            variant="primary"
          >
            {isLoading ? "Please wait" : "Save"}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export function EditLeaveCommentDialog(props) {
  return createConfirmation(confirmable(EditLeaveComment))(props);
}

function ApproveOnly({
  proceed,
  show,
  requisition,
  authUser,
  previousRequisitionTrackId,
  requisitionTitle,
  tableData,
  isReservedUser,
}) {
  // console.log(requisition);
  const backendUrl = useBackendUrl();
  const [isLoading, setIsLoading] = useState(false);
  const [comment, setComment] = useState("");
  const approveRequisition = async () => {
    try {
      setIsLoading(true);
      let response = await fetch(`${backendUrl}/api/requisition/action`, {
        method: "POST",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
        body: JSON.stringify({
          requestid: requisition.requestid,
          receivedby: authUser.Staff_ID,
          sentby: requisition.sentby,
          status: "Approved",
          regdate: format(new Date(), "yyyy-MM-dd"),
          comment,
          previousRequisitionTrackId,
          tableData,
          isReservedUser,
        }),
      });

      if (!response.ok) {
        response = await response.json();
        throw new Error(response.message);
      }

      const { data } = await response.json();
      toast.success("Requisition Approved");
      eventBus.dispatch("REQUISITION_UPDATED", data.requisition);
      proceed(true);
      // return data;
    } catch (err) {
      console.log(err);
      toast.error("Unable to Approve & Close");
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Modal
      show={show}
      onHide={() => !isLoading && proceed(false)}
      dialogClassName="requisition-details-modal"
      centered={true}
      animation={false}
      enforceFocus={false}
      fullscreen={styleHelper.isMobile}
    >
      <Modal.Header closeButton />
      <Modal.Body className="approve-action">
        <div className="text-info-h">
          <ApproveBlueModalIcon className="mb-4" />
          <h2>Approve Requisition</h2>
          <p>
            Are you sure you want to Approve the{" "}
            <span className="fw-5">{requisitionTitle}</span> from{" "}
            <span className="fw-5">{requisition?.sentbyUser?.Name} </span> ?
          </p>
        </div>
        <Form.Group className="my-4">
          <Form.Label className="d-none">Comment :</Form.Label>
          <label className="border p-0 rounded pb-2 message-area d-block">
            <Form.Control
              name="message"
              as="textarea"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              className="w-100 border-0 p-3"
              placeholder="Enter your comment"
              rows={4}
              autoFocus
            />
          </label>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <span />
        <div className="proceed-actions">
          <Button
            disabled={isLoading}
            onClick={() => proceed(false)}
            variant="white"
            className="border bg-white"
          >
            Cancel
          </Button>
          <Button
            disabled={isLoading}
            onClick={() => approveRequisition()}
            variant="primary"
          >
            {isLoading ? "Please wait" : "Okay"}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export function ApproveOnlyDialog(props) {
  return createConfirmation(confirmable(ApproveOnly))(props);
}
