import { Modal, Form, Button } from "react-bootstrap";
import { useFormik } from "formik";
import { confirmable, createConfirmation } from "react-confirm";
import Select from "react-select";
import { Units } from "../utils/helpers";

function SelectUnitsDialog({ proceed, show, saleType = "Each" }) {
  const formik = useFormik({
    initialValues: {
      unit: saleType,
    },
    onSubmit: (values) => {
      proceed(values.unit);
    },
  });

  return (
    <Modal
      show={show}
      onHide={() => proceed(false)}
      centered={true}
      animation={false}
      enforceFocus={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h1 className="h6">Select Unit </h1>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate onSubmit={formik.handleSubmit}>
          <Form.Group className="mb-3">
            <Select
              classNamePrefix="form-select"
              placeholder="Select"
              isSearchable={false}
              options={Units}
              value={Units.find((el) => el.value === formik.values.unit)}
              onChange={({ value }) => formik.setFieldValue("unit", value)}
              onBlur={() => formik.setFieldTouched("unit", true)}
              className={
                formik.touched.unit && !!formik.errors.unit ? "is-invalid" : ""
              }
            />
          </Form.Group>

          <Button variant="primary" className="w-100 p-2" type="submit">
            {"Continue"}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default function SelectItemUnitsDialog(props) {
  return createConfirmation(confirmable(SelectUnitsDialog))({ ...props });
}
