import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./../assets/scss/requisition/index.scss";

import { confirmable, createConfirmation } from "react-confirm";
import { ApproveBlueModalIcon } from "./Icons";
import { Form, Table } from "react-bootstrap";
import { map, isEmpty } from "lodash";
import { isNumber } from "lodash";
import currency from "currency.js";
import { useFormik } from "formik";
import * as yup from "yup";
import NumberCustomInput from "./utils/NumberCustomInput";
import { useMemo } from "react";
import {
  formatForQty,
  qtyFormat,
  qtyFormatToString,
  tonsToPcs,
} from "../utils/helpers";
import ConvertQuantity from "./utils/ConvertQuantity";
import ConfirmDialog from "./ConfirmDialogue";
import { useEffect } from "react";

function Dialog({
  proceed,
  show,
  title = "Conversion Ratio",
  description = "",
  // -----------
  rawMaterial,
  finishedItem,
  RawMaterialQuantity,
  FinishedItemQuantity,
  FinishedItemSize,
  RawMaterialSize,
  itemMeasurements = [],
  // ----------

  generalSettings,
  isIronRod,
}) {
  const initialValues = {
    quantityToDeduct: 0,
    quantityToAdd: 0,
  };
  const schema = yup.object().shape({
    quantityToDeduct: yup
      .number()
      .required("required")
      .moreThan(0, "must be greater than 0"),
  });

  const formik = useFormik({
    initialValues,
    schema,
    onSubmit: async (formValues) => {
      const values = { ...formValues };
      // convert to pieces
      if (RawMaterialSize === "Tons") {
        values.quantityToDeduct = tonsToPcs(
          values.quantityToDeduct,
          rawMaterial.Item_Desc,
          itemMeasurements
        );
      }

      if (Number(values.quantityToDeduct) > Number(rawMaterial?.Quantity)) {
        formik.setFieldError(
          "quantityToDeduct",
          `Not enough item in stock to convert`
        );
        return;
      }

      //  values.quantityToAdd = quantityToAdd;

      if (FinishedItemSize === "Tons") {
        values.quantityToAdd = tonsToPcs(
          values.quantityToAdd,
          finishedItem.Item_Desc,
          itemMeasurements
        );
      }
      // console.log(values);

      if (
        await ConfirmDialog({
          title: "Post Transaction",
          description: "Are you sure, you want to make this conversion",
        })
      ) {
        proceed(values);
      }
    },
  });

  /*  const quantityToAdd = useMemo(() => {
    return currency(formik.values.quantityToDeduct, {
      symbol: "",
      separator: "",
      precision: 2,
      format: formatForQty,
    })
      .multiply(FinishedItemQuantity)
      .divide(RawMaterialQuantity)
      .format();
  }, [formik.values.quantityToDeduct]); */

  useEffect(() => {
    const qty = currency(formik.values.quantityToDeduct, {
      symbol: "",
      separator: "",
      precision: 2,
      format: formatForQty,
    })
      .multiply(FinishedItemQuantity)
      .divide(RawMaterialQuantity)
      .format();
    formik.setFieldValue("quantityToAdd", qty);
  }, [formik.values.quantityToDeduct]);

  return (
    <Modal
      show={show}
      onHide={() => proceed(false)}
      dialogClassName="requisition-details-modal"
      centered={true}
      animation={false}
      enforceFocus={false}
    >
      <Modal.Body className="approve-action">
        <div className="text-info-h">
          {/*  <ApproveBlueModalIcon className="mb-4" /> */}
          <h2 className="py-3">{title}</h2>
          <p
            className="fs-6"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </div>

        <Form noValidate onSubmit={formik.handleSubmit}>
          <div className="d-flex text-center p-3 border-bottom ">
            <p className="col fw-bold m-0">
              {rawMaterial?.Item_Name} ({RawMaterialSize})
            </p>{" "}
            <p className="col fw-bold m-0">
              {finishedItem?.Item_Name} ({FinishedItemSize})
            </p>
          </div>

          <div className="d-flex text-center p-3 border-bottom ">
            <p className="col fw-bold m-0">
              Quantity in stock:{" "}
              {isIronRod && generalSettings?.convertToTonsAndPcs
                ? qtyFormatToString(
                    qtyFormat(
                      rawMaterial.Quantity,
                      rawMaterial.Item_Desc,
                      itemMeasurements
                    )
                  )
                : rawMaterial.Quantity}
            </p>{" "}
            <p className="col fw-bold m-0">
              Quantity in stock:{" "}
              {isIronRod && generalSettings?.convertToTonsAndPcs
                ? qtyFormatToString(
                    qtyFormat(
                      finishedItem.Quantity,
                      rawMaterial.Item_Desc,
                      itemMeasurements
                    )
                  )
                : finishedItem.Quantity}
            </p>
          </div>

          <div className="d-flex text-center p-3 my-1 fs-6">
            <p className="col">{RawMaterialQuantity}</p> <p>{" = "}</p>
            <p className="col">{FinishedItemQuantity}</p>
          </div>
          <div className="row text-center">
            <Form.Group className="col-md-6 mb-3 pb-2">
              <Form.Label className="mb-1 fs-6">Quantity To Convert</Form.Label>
              <NumberCustomInput
                name="quantityToDeduct"
                placeholder="0.00"
                value={formik.values.quantityToDeduct}
                onValueChange={(value, name) => {
                  formik.setFieldValue(name, value);
                }}
                isInvalid={
                  formik.touched.quantityToDeduct &&
                  !!formik.errors.quantityToDeduct
                }
                onBlur={() => formik.setFieldTouched("quantityToDeduct", true)}
              />
              {formik.touched.quantityToDeduct &&
              !!formik.errors.quantityToDeduct ? (
                <span className="custom-invalid-feedback">
                  {formik.errors.quantityToDeduct}
                </span>
              ) : null}
            </Form.Group>

            <Form.Group className="col-md-6 mb-3 pb-2">
              <Form.Label className="mb-1 fs-6">Final Quantity</Form.Label>
              <NumberCustomInput
                name="quantityToAdd"
                placeholder="0.00"
                value={formik.values.quantityToAdd}
                onValueChange={(value, name) => {
                  formik.setFieldValue(name, value);
                }}
                isInvalid={
                  formik.touched.quantityToAdd && !!formik.errors.quantityToAdd
                }
                onBlur={() => formik.setFieldTouched("quantityToAdd", true)}
              />
              {formik.touched.quantityToAdd && !!formik.errors.quantityToAdd ? (
                <span className="custom-invalid-feedback">
                  {formik.errors.quantityToAdd}
                </span>
              ) : null}
            </Form.Group>
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <span />
        <div className="proceed-actions">
          <Button
            onClick={() => proceed(false)}
            variant="white"
            className="border bg-white"
          >
            Cancel
          </Button>
          <Button onClick={() => formik.submitForm()} variant="primary">
            Convert
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default function ConversionDialog(props) {
  return createConfirmation(confirmable(Dialog))(props);
}
