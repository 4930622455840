import { Modal, Form, Button, InputGroup, Row, Col } from "react-bootstrap";
import * as yup from "yup";
import { useFormik } from "formik";
import { useState } from "react";
import { confirmable, createConfirmation } from "react-confirm";
import {
  consumptionTypes,
  customerFullName,
  // vendorFullName,
  qtyFormat,
  tonsToPcs,
  Units,
  unitsResolver,
} from "../../utils/helpers";
import { toast } from "react-toastify";
import { useMutation } from "react-query";
import { useStoreActions, useStoreState } from "easy-peasy";
import Select from "react-select";
import { useEffect } from "react";
import currency from "currency.js";
import { Popover } from "react-tiny-popover";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import { useMemo } from "react";
import Datetime from "react-datetime";
import moment from "moment";
import { lowerCase, isEmpty } from "lodash";
import { useBackendUrl, useIsAdmin } from "../../utils/hooks";
import ConfirmDialog from "../ConfirmDialogue";

export default function DeleteVendorPaymentModal({
  showDeleteVendorPaymentModal = true,
  setShowDeleteVendorPaymentModal,
  refetch,
  selectedLedgerRecord,
}) {
  const itemMeasurements = useStoreState((state) => state.itemMeasurements);
  const backendUrl = useBackendUrl();
  const isAdmin = useIsAdmin();
  const generalSettings = useStoreState((state) => state.generalSettings);

  const initialValues = {
    salesDate: moment(),
    Remark: "",
  };
  const schema = yup.object().shape({});

  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: async (values) => {
      const requiresApproval =
        generalSettings.deleteVendorPaymentRequiresAdminApproval && !isAdmin;

      if (
        await ConfirmDialog({
          title: "Post Transaction",
          description: "Are you sure, you want to post",
        })
      ) {
        deletePaymentMutation.mutate({
          ...selectedLedgerRecord,
          ...values,
          requiresApproval,
        });
      }
    },
  });

  const deletePayment = async (payload) => {
    let response = await fetch(`${backendUrl}/api/vendors/delete-payment`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const deletePaymentMutation = useMutation(
    (payload) => deletePayment(payload),
    {
      onSuccess: ({ message, data }) => {
        if (refetch) refetch();
        toast.success(message);
        setShowDeleteVendorPaymentModal(false);
      },
      onError: () => {
        toast.error(`Unable to perform action`);
      },
    }
  );

  return (
    <Modal
      show={showDeleteVendorPaymentModal}
      onHide={() => {
        setShowDeleteVendorPaymentModal(false);
      }}
      centered={true}
      animation={false}
      enforceFocus={false}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h1 className="h6">Delete Vendor Payment</h1>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate onSubmit={formik.handleSubmit}>
          <p className="mb-2">
            <b>PaymentType:</b> {selectedLedgerRecord?.PaymentType}
          </p>

          <p className="mb-2">
            <b>Amount:</b>{" "}
            {currency(selectedLedgerRecord.Debit, {
              symbol: "",
            }).format()}
          </p>

          <p className="mb-3">{selectedLedgerRecord?.Remark}</p>

          <Form.Group className="mb-3">
            <Form.Label>Remark</Form.Label>
            <Form.Control
              as={"textarea"}
              name="Remark"
              placeholder="Enter Remark"
              value={formik.values.Remark}
              onChange={formik.handleChange}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Date</Form.Label>
            <Datetime
              timeFormat={false}
              closeOnSelect={true}
              closeOnClickOutside={true}
              dateFormat="MMM DD, YYYY"
              name="salesDate"
              inputProps={{
                className: `date-input form-control ${
                  formik.touched.salesDate && !!formik.errors.salesDate
                    ? "is-invalid"
                    : ""
                }`,
                placeholder: "Select date",
                readOnly: true,
              }}
              value={formik.values.salesDate}
              onChange={(date) => {
                formik.setFieldValue("salesDate", date, true);
              }}
              onBlur={() => formik.setFieldTouched("salesDate", true)}
            />
          </Form.Group>

          <Button
            disabled={deletePaymentMutation.isLoading}
            variant="primary"
            className="w-100 p-2"
            type="submit"
          >
            {deletePaymentMutation.isLoading ? "Please wait…" : "Post"}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
