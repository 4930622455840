import fetchIntercept from "fetch-intercept";
import eventBus from "./utils/EventBus";
import { isEmpty } from "lodash";

const handleWhenAnotherUserLoginWithYourAccount = async (response) => {
  try {
    response = await response.json();
    //  console.log(response, "JSON");

    //Another session found - dispatch
    if (response?.alreadyLoggedIn) {
      eventBus.dispatch("ALREADY_LOGGED_IN", response?.alreadyLoggedIn);
    }
  } catch (err) {
    console.log(err);
  }
};

const setUpFetchIntercept = () =>
  fetchIntercept.register({
    request: function(url, config) {
      if (url === "/meta.json") {
        url = `${url}?ver=${Date.now()})`;
      }
      // Modify the url or config here
      // console.log(config);

      // console.log(config, url, "d");
      return [url, config];
    },

    requestError: function(error) {
      // Called when an error occured during another 'request' interceptor call
      return Promise.reject(error);
    },

    response: function(response) {
      handleWhenAnotherUserLoginWithYourAccount(response.clone());

      // Modify the reponse object
      return response;
    },

    responseError: function(error) {
      // Handle an fetch error
      return Promise.reject(error);
    },
  });
export { setUpFetchIntercept };
