import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { format } from "date-fns";
import Datetime from "react-datetime";
import { Popover } from "react-tiny-popover";
import DatePickerCustomInput from "../utils/DatePickerCustomInput";
import { convertMomentDate } from "../../utils/helpers";
import { Dropdown } from "react-bootstrap";
import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";
import CubeOutlineIcon from "mdi-react/CubeOutlineIcon";
import ViewIssueNumberModal from "../Inventory/ViewIssueSerialNumber";
import Select from "react-select";

const ReceiveInventoryTable = (props) => {
  const [showInput, setShowInput] = useState(false);
  const [inputValue, setInputValue] = useState(0);
  const [indexValue, setIndexValue] = useState(0);
  const [inputBatch, setInputBatch] = useState(
    props?.el?.Batch_Name ? props?.el?.Batch_Name : ""
  );
  const [inputStorageLocation, setInputStorageLocation] = useState(
    props?.el?.StorageLocation ? props?.el?.StorageLocation : ""
  );
  const [inputDate, setInputDate] = useState(
    props?.el?.ExpireDate ? new Date(props?.el?.ExpireDate) : new Date()
  );
  const [active, setActive] = useState(null);
  const [datePopoverOpened, setDatePopoverOpened] = useState(false);

  const [openSerialNumbers, setOpenSerialNumbers] = useState(false);
  const [selectedSerialItems, setSelectedSerialItems] = useState({});
  const [serialNumbers, setSerialNumbers] = useState([]);

  // useEffect(() => {
  //   console.log(
  //     // props?.ExpireDate ? new Date(props?.ExpireDate) : new Date(),
  //     // props?.ExpireDate
  //     props
  //   );
  // }, []);

  const update = (e) => {
    // if (e.key === "Enter") {
    if (parseFloat(inputValue) < 1)
      return toast.error(`Quantity can not be less than one`);
    // if (parseFloat(inputValue) > parseFloat(props.el.Quantity))
    //   return toast.error(`Input a lower quantity`);
    setShowInput(false);

    props.updateQuantity(inputValue, active);
    // }
  };

  const batchUpdate = (e) => {
    props.updateBatch(inputBatch, active);
  };

  useEffect(() => {
    setInputValue(props.el.Quantity);
  }, [props.el.Quantity]);

  useEffect(() => {
    setActive((prev) =>
      props?.selectedItems?.find(
        (it) =>
          it?.Bar_Code === props?.el?.Bar_Code &&
          it?.Trans_ID === props.el.Trans_ID
      )
    );
  }, [props.el.Bar_Code, props.el.Trans_ID, props.selectedItems]);

  return (
    <>
      <tr>
        <td>
          {props?.el?.serialNumbers && (
            <Dropdown>
              <Dropdown.Toggle
                variant=""
                className="bg-white border-0"
                bsPrefix="print more"
              >
                <DotsVerticalIcon />
              </Dropdown.Toggle>
              <Dropdown.Menu
                popperConfig={{
                  strategy: "fixed",
                }}
                renderOnMount
                className="dropdown-with-icons"
              >
                <Dropdown.Item
                  as="button"
                  onClick={() => {
                    setSelectedSerialItems(props?.el);
                    setSerialNumbers(JSON.parse(props?.el?.serialNumbers));
                    setOpenSerialNumbers(true);
                    setIndexValue(props?.index);
                  }}
                >
                  <CubeOutlineIcon className="text-light" />
                  Serial Numbers
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}
        </td>
        <td>
          <input
            className="form-check-input"
            type="checkbox"
            onChange={() => props.onChangeSelected(props.el)}
            checked={
              active && props.el.Status !== "Rejected" && props.el.Quantity > 0
            }
            id={props.index}
            disabled={props.el.Status === "Rejected"}
          />

          <label className="form-check-label px-3" htmlFor={props.index}>
            {props.el.Bar_Code}
          </label>
        </td>

        <td onClick={() => setShowInput(false)}>{props.el.Item_Name}</td>
        <td
          onClick={() =>
            !props?.el?.serialNumbers &&
            setShowInput((prev) => (active ? true : false))
          }
        >
          {showInput ? (
            <input
              type="number"
              className="inputTable form-control border-0 px-1"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              // onKeyDown={(e) => update(e, props.el.Bar_Code, props.el.Trans_ID)}
              onBlur={(e) => {
                update(e, props.el.Bar_Code, props.el.Trans_ID);
              }}
              disabled={props.selectedItems.length < 1 || !active}
              max={parseFloat(props.el.Quantity)}
            />
          ) : (
            inputValue
          )}
        </td>
        <td onClick={() => setShowInput(false)}>
          {props?.el?.mainQuantity || "..."}
        </td>
        <td onClick={() => setShowInput((prev) => (active ? true : false))}>
          <input
            type="text"
            className="form-control border-1 px-1"
            value={inputBatch}
            onChange={(e) => setInputBatch(e.target.value)}
            // onKeyDown={(e) =>
            //   batchUpdate(e, props.el.Bar_Code, props.el.Trans_ID)
            // }
            onBlur={(e) => {
              batchUpdate(e, props.el.Bar_Code, props.el.Trans_ID);
            }}
            // disabled={props.selectedItems.length < 1 || !active}
          />
        </td>
        <td>
          <Popover
            reposition={false}
            isOpen={datePopoverOpened}
            onClickOutside={() => setDatePopoverOpened(false)}
            align="end"
            padding={10}
            content={() => (
              <Datetime
                dateFormat="MM DD, YYYY"
                className="date-picker-2"
                timeFormat={false}
                closeOnSelect={true}
                closeOnClickOutside={true}
                name="supplyDate"
                inputProps={{
                  className: `date-input form-control`,
                  placeholder: "Select date",
                  readOnly: true,
                }}
                input={false}
                value={inputDate}
                onChange={(date) => {
                  props.updateDate(date, active);
                  setInputDate(date);
                  setDatePopoverOpened(false);
                }}
              />
            )}
          >
            <DatePickerCustomInput
              onClick={() => setDatePopoverOpened(!datePopoverOpened)}
              value={convertMomentDate(inputDate)}
            />
          </Popover>
        </td>
        <td>
          <div style={{ zIndex: "20" }} key={props?.allStorage}>
            <Select
              classNamePrefix={`form-select`}
              options={props?.allStorage || []}
              value={props?.allStorage?.find(
                (el) => el.value == inputStorageLocation
              )}
              onChange={(selected) => {
                if (selected?.value) {
                  props.updateStorageLocation(selected?.value, active);
                  setInputStorageLocation(selected?.value);
                }
              }}
              menuPosition="fixed"
            />
          </div>{" "}
        </td>
        {/* <td onClick={() => setShowInput(false)} title={props?.el?.sendTo}>
        {props.el.Vendor}
      </td>
      <td>{format(new Date(props?.el?.Date_Log), "yyyy-MM-dd")}</td> */}
        <td>
          {
            <div
              className={`${
                props?.el?.Status === "Rejected"
                  ? "view_status_reject"
                  : props?.el?.Status === "Partly Received"
                  ? "view_status_partly"
                  : props?.el?.Status === "Received All"
                  ? "view_status_all"
                  : props?.el?.Status === "Retured"
                  ? "view_status_returned"
                  : props?.el?.Status === null
                  ? "view_status_pending"
                  : ""
              }`}
            >
              {props?.el?.Status !== null ? props?.el?.Status : "Pending"}
            </div>
          }
        </td>
        <td onClick={() => setShowInput(false)} title={props?.el?.sendTo}>
          {props.el.Vendor}
        </td>
        <td>{format(new Date(props?.el?.Date_Log), "yyyy-MM-dd")}</td>
      </tr>

      {openSerialNumbers && (
        <ViewIssueNumberModal
          show={openSerialNumbers}
          selectedItem={selectedSerialItems}
          setShowBatchesModal={setOpenSerialNumbers}
          serialNumbers={serialNumbers}
          updateQuantity={props.updateQuantity}
          setInputValue={setInputValue}
          updateSerial={props.updateSerial}
          handleInputChange={props.handleInputChange}
          indexValue={indexValue}
        />
      )}
    </>
  );
};

export default ReceiveInventoryTable;
