// import AttachFileIcon from "mdi-react/AttachFileIcon";
import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
  useLayoutEffect,
} from "react";
import "../../../assets/scss/taskDetail.scss";
import eventBus from "../../../utils/EventBus";
import { usePrevious } from "../../../utils/hooks";
/* 
import SendIcon from "mdi-react/SendIcon";
import { socket } from "../../../sockets";
import {
  AppRegExp,
  createMarkup,
  escapeHTML,
  formatDate,
  formatDateOnly,
  GetFileIcon,
  getUniqueListBy,
  linkify,
  showToast,
  styleHelper,
  truncateFileName,
  usernameFromText,
} from "../utils";
import Comment from "./Comments";
import { Form } from "react-bootstrap";
import { SpinnerSmall as Spinner, TrashIconSmall } from "./Icons";
import { connect } from "react-redux";
import uploadClient from "../../../helper/uploadClient";
import { appendUploadedFileId } from "../../../services/taskServices";
import { updateFileName } from "../../../services/userServices";
import { cloneDeep, initial, throttle, toArray, isEmpty } from "lodash-es";
import CloseIcon from "mdi-react/CloseIcon";
import { v4 as uuidv4 } from "uuid";
import FileModal from "../modals/FileModal";
import {
  ChevronDownIcon,
  EmptyThread,
  SearchIcon,
  ThreadArrowBack,
} from "./Icons";
// import Mention from "./Mention";
import DialogModal from "../modals/DialogModal";
import "emoji-mart/css/emoji-mart.css";
import { Picker } from "emoji-mart";

import AtIcon from "mdi-react/AtIcon";
import EmoticonHappyOutline from "mdi-react/EmoticonHappyOutlineIcon";
import EmojiPicker from "./EmojiPicker";
import PaperclipIcon from "mdi-react/PaperclipIcon";
import { useDebounce, useOnClickOutside, usePrevious } from "../custom-hooks";
import Avatar from "./Avatar";
import { setUploadFile } from "../../../store/actions/uploadFileActions";
import { Editor } from "./Editor";
import SearchWindow from "./SearchWindow";
import {
  removeComment,
  setGlobalComment,
} from "../../../store/actions/commentActions";
import { compareAsc } from "date-fns";
import store from "../../../store";
import CommentArea from "./CommentArea";
import { ShowDownComponent } from "./showDownComponent"; */

import { Spinner } from "react-bootstrap";
import { cloneDeep, isEmpty, toArray } from "lodash";
import CloseIcon from "mdi-react/CloseIcon";
import DetailedIcon from "../../Icons";
import CommentArea from "./CommentArea";

const socket = {};
const styleHelper = {};
const showToast = () => {};

const Conversations = React.memo(
  ({
    currentTab,
    selectedTask = {},
    token,
    clearUnreadTaskComment,
    projectUsersList,
  }) => {
    const watcherServerUrl = process.env.REACT_APP_FILE_WATCHER_SERVER;
    let thread = useRef(null);
    let firstUpdate = useRef(true);
    let firstSelectedTaskUpdate = useRef(true);
    let firstCurrentTabUpdate = useRef(true);
    let loadingRef = useRef("");
    let loadingRefBtn = useRef("");
    let loadingRefNew = useRef("");
    let loadingRefBtnNew = useRef("");
    let commentRef = useRef("");
    let scrollTop = useRef(0);
    let commentAreaRef = useRef("");

    let loadOlderRef = useRef({});
    let loadNewerRef = useRef({});

    let scrollPosition = useRef({});
    let editorRef = useRef({});
    const [isLoading, setIsLoading] = useState(true);
    const [showDrop, setShowDrop] = useState(false);
    const [comments, setComments] = useState([]);
    const prevComments = usePrevious(comments);
    // const [commentToReply, setCommentToReply] = useState({});
    const [data, setData] = useState({
      /*newComment: {
      employeeId: null,
      employeeImage: "",
      employeeName: "",
      date: "",
      time: "",
      // comment: "",
      files: [],
      filesToUpload: [],
      alreadySavedfiles: [],
    },
    */
      indexInView: 0,
      imageFiles: [],
      taskFilesDataList: [],
    });
    const [pagination, setPagination] = useState({
      next: { page: 1, limit: 15 },
    });
    const [commentsPagination, setCommentsPagination] = useState({
      newerMessagesRemaining: 0,
      olderMessagesRemaining: 0,
      oldestCommentId: "",
      newestCommentId: "",
    });

    const _commentsPagination = useRef(commentsPagination);
    const _setCommentsPagination = (data) => {
      _commentsPagination.current = data;
      setCommentsPagination(data);
    };

    const [showFileModal, setShowFileModal] = useState(false);
    const [showUsersList, setShowUsersList] = useState(false);
    const [showSearch, setShowSearch] = useState(false);

    const [inputActive, setInputActive] = useState(false);

    const _comments = useRef(comments);
    const _setComments = (data) => {
      _comments.current = data;
      setComments(data);
    };

    const [showEmoji, setShowEmoji] = useState(false);
    const [isLoadingMessages, setIsLoadingMessage] = useState(false);
    const [isLoadingMessagesNewer, setIsLoadingMessageNewer] = useState(false);
    const [disableSubmitBtn, setDisableSubmitBtn] = useState(false);

    const [lastComment, setLastComment] = useState({});
    const _setShowDownIcon = (show) => {
      eventBus.dispatch("setShowDownIcon", { show });
    };

    const [newMessagesTotal, setNewMessagesTotal] = useState(0);
    const [commentIdToScroll, setCommentIdToScroll] = useState("");

    const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);
    const _unreadMessagesCount = useRef(unreadMessagesCount);
    const _setUnreadMessagesCount = (data) => {
      _unreadMessagesCount.current = data;
      setUnreadMessagesCount(data);
    };

    const setCommentToReply = (comment) => {
      eventBus.dispatch("setCommentToReply", {
        comment,
      });
    };

    /*useEffect(() => {
      
      return () => {
        eventBus.remove("add-file-to-comment", () => {});
        eventBus.remove("remove-comment-file", () => {});
      };
    }, [data, commentRef, comments]);*/

    const scrolToModalBottom = () => {
      let modal = document.querySelector(".modal-content");
      if (modal && !styleHelper.isMobile) {
        setTimeout(() => {
          modal.scrollIntoView({
            block: "end",
            inline: "nearest",
          });
        }, 100);
      }
    };

    useEffect(() => {
      if (currentTab === "conversations" && firstCurrentTabUpdate) {
        firstCurrentTabUpdate.current = false;
        scrollToBottom(thread);
        return;
      }
    }, [currentTab]);

    useEffect(() => {
      if (comments.length && firstUpdate.current) {
        scrollToBottom(thread);
        scrolToModalBottom();
        const observer = new IntersectionObserver(
          (entities, observer) => {
            if (entities[0].isIntersecting) {
              entities[0].target.click();
            }
          },
          {
            root: thread.current,
            rootMargin: "0px",
            threshold: 0.5,
          }
        );
        observer.observe(loadingRef.current);
        observer.observe(loadingRefNew.current);
        firstUpdate.current = false;
        return;
      }
    }, [comments]);

    /*useEffect(() => {
      const observer = new IntersectionObserver(
        ([entry]) => {
          // Update our state when observer callback fires
          if (entry.isIntersecting && !isEmpty(comments)) {
            entry.target.click();
          }
        },
        {
          root: thread.current,
          rootMargin: "0px",
          threshold: 1,
        }
      );
      if (loadingRef.current && loadingRefNew.current) {
        observer.observe(loadingRef.current);
        observer.observe(loadingRefNew.current);
      }
      return () => {
        if (loadingRef.current) observer.unobserve(loadingRef.current);
        if (loadingRefNew.current) observer.unobserve(loadingRefNew.current);
      };
    }, [comments, loadingRefNew, loadingRef]);*/

    /*useOnClickOutside(commentAreaRef, () => {
      if (!commentRef?.current?.value) {
        setInputActive(false);
      }
    });*/

    const handleNewComment = (response) => {
      if (response?.success) {
        _setComments([..._comments.current, response.comment]);
        clearUnreadTaskComment(selectedTask);
        if (!response.comment?.parentCommentId) {
          _setUnreadMessagesCount(_unreadMessagesCount.current + 1);
        }
      }
    };

    const handleNewCommentReply = (response) => {
      if (response?.success) {
        console.log(response);
        const comment = response.taskCommentReply;
        addNewCommentReply({
          commentId: comment.parentCommentId,
          taskCommentReply: comment,
          repliesCount: response.repliesCount,
        });
        clearUnreadTaskComment(selectedTask);
      }
    };

    const handleNewCommentFile = (response) => {
      if (response?.success) {
        const { commentId, file } = response;
        const foundCommentIndex = _comments.current.findIndex(
          (comment) => comment._id === commentId
        );
        if (foundCommentIndex !== -1) {
          _comments.current[foundCommentIndex].files = [
            ..._comments.current[foundCommentIndex].files,
            file,
          ];
          _setComments([..._comments.current]);
          clearUnreadTaskComment(selectedTask);
          // trigger re-render for attachments
          eventBus.dispatch("new-comment-file", {});
        }
      }
    };

    const handleNewCommentReplyFile = (response) => {
      if (response?.success) {
        const { commentId, file, replyId } = response;
        const foundCommentIndex = _comments.current.findIndex(
          (comment) => comment._id === commentId
        );
        if (foundCommentIndex !== -1) {
          const replies =
            _comments.current[foundCommentIndex].taskCommentReplies;
          const foundReplyIndex = replies.findIndex(
            (reply) => reply._id === replyId
          );
          if (foundReplyIndex !== -1) {
            const files =
              _comments.current[foundCommentIndex].taskCommentReplies[
                foundReplyIndex
              ].files;
            _comments.current[foundCommentIndex].taskCommentReplies[
              foundReplyIndex
            ].files = [...files, file];
            _setComments([..._comments.current]);
            clearUnreadTaskComment(selectedTask);
            // trigger re-render for attachments
            eventBus.dispatch("new-comment-file", {});
          }
        }
      }
    };

    const getMessagesWhileOffline = () => {
      console.log("getMessagesWhileOffline");
      // Major bug for last comment

      const globalComments = toArray();
      // store.getState().commentReducer.globalComments
      const lastComment = _comments.current
        .reverse()
        .find((commentEl) => commentEl.createdAt && commentEl.updatedAt);
      if (lastComment) {
        socket.emit(
          "fx:get-not-recieved-messages",
          { lastComment, taskId: selectedTask._id },
          async (response) => {
            if (response.success) {
              const {
                comments,
                newerMessagesRemaining,
                unreadCount,
              } = response.data;
              _setCommentsPagination({
                ...commentsPagination.current,
                newerMessagesRemaining,
              });
              if (globalComments) {
                comments.forEach((commentEl) => {
                  if (commentEl.tempId) {
                  } // removeComment(commentEl.tempId);
                });
              }
              _setComments();
              // getUniqueListBy([..._comments.current, ...comments], "_id")
              // _setUnreadMessagesCount(unreadCount);
            }
          }
        );
      }
    };

    useLayoutEffect(() => {
      /*getTaskComments({
        selectedTask,
        next: pagination.next,
        usage: "first",
      }).then((data) => console.log(data));*/
    }, []);

    const getTaskComments = async ({
      selectedTask,
      next,
      setScroll,
      usage,
      isLoadingMessagesNewer = false,
    }) => {
      await new Promise(async (resolve, reject) => {
        try {
          console.log("get task comments");
          if (selectedTask?.selectedCommentId) {
            handleSelectedComment(
              { _id: selectedTask?.selectedCommentId },
              "notification"
            );
            // clear the selected
            return;
          } else {
            if (isLoadingMessagesNewer) {
              setIsLoadingMessageNewer(true);
            } else {
              setIsLoadingMessage(true);
            }
            const { page, limit } = next;
            // setTimeout(() => {
            socket.emit(
              "fx:get-task-comments",
              {
                page,
                taskId: selectedTask._id,
                limit,
              },
              async (response) => {
                if (isLoadingMessagesNewer) {
                  setIsLoadingMessageNewer(false);
                } else {
                  setIsLoadingMessage(false);
                }
                setIsLoading(false);

                if (response.success) {
                  setPagination({
                    ...response.data.pagination,
                  });

                  _setComments([...cloneDeep(response.data.comments)]);

                  if (usage === "first") {
                    scrollToBottom(thread);
                  }
                  resolve();
                } else {
                  showToast({
                    message: {
                      title: "Unable to get Comments",
                    },
                    type: "error",
                  });
                  reject();
                }
              }
            );
            //}, 5000);
          }
        } catch (e) {
          reject();
        } finally {
        }
      });
    };

    const getReplies = ({ comment, skip, limit }) => {
      socket.emit(
        "fx:task-get-comment-replies",
        { parentCommentId: comment._id, skip, limit },
        async (response) => {
          // console.log(response);
          if (response && response.success) {
            addCommentReply({
              commentId: comment._id,
              taskCommentReplies: response.taskCommentReplies,
              repliesCount: response.repliesCount,
            });
          }
        }
      );
    };

    const addNewCommentReply = ({
      commentId,
      taskCommentReply,
      repliesCount,
      update = false,
    }) => {
      console.log("addNewCommentReply");
      let updatedComments = cloneDeep([..._comments.current]);
      const foundIndex = updatedComments.findIndex(
        (comment) => comment._id === commentId
      );
      if (foundIndex != -1) {
        const taskCommentReplies =
          updatedComments[foundIndex].taskCommentReplies;
        if (!update) {
          // console.log(repliesCount);
          updatedComments[foundIndex].repliesCount = repliesCount;
          updatedComments[foundIndex].taskCommentReplies = [
            taskCommentReply,
            ...(Array.isArray(taskCommentReplies) ? taskCommentReplies : []),
          ];
        } else {
          /*const foundCommentReplyIndex = taskCommentReplies.findIndex(
          (reply) => reply._id === taskCommentReply._id
        );
        */
          if (foundIndex != -1) {
            // taskCommentReplies[foundCommentReplyIndex] = taskCommentReply;
          }
        }
        _setComments([...updatedComments]);
      }
    };

    const addCommentReply = ({
      commentId,
      taskCommentReplies,
      repliesCount,
    }) => {
      let updatedComments = cloneDeep(comments);
      const foundIndex = updatedComments.findIndex(
        (comment) => comment._id === commentId
      );
      if (foundIndex != -1) {
        updatedComments[foundIndex].repliesCount = repliesCount;
        updatedComments[foundIndex].taskCommentReplies = [
          ...updatedComments[foundIndex].taskCommentReplies,
          ...(Array.isArray(taskCommentReplies) ? taskCommentReplies : []),
        ];
        //  _setComments(getUniqueListBy([...updatedComments], "_id"));
      }
    };

    const addUsernameToInput = (username) => {
      /*setData({
        ...data,
        newComment: {
          ...data.newComment,
          comment: username,
        },
      });*/

      eventBus.dispatch("setText", { newText: username });
    };

    const scrollToBottom = (thread) => {
      disableLoaders(true);
      if (thread && thread.current) {
        thread.current.scrollTop = thread.current.scrollHeight;
      }

      setTimeout(() => {
        disableLoaders(false);
      }, 1000);
    };

    const setShowReplies = ({ commentId, isShow }) => {
      const foundCommentIndex = comments.findIndex(
        (comment) => comment._id === commentId
      );
      if (foundCommentIndex !== -1) {
        comments[foundCommentIndex].showReplies = isShow;
        _setComments([...comments]);
      }
    };

    const showCommentFiles = ({ files, indexInView }) => {
      setData({
        ...data,
        indexInView,
        imageFiles: [...files],
      });
      setShowFileModal(true);
    };

    const updateCommentFiles = ({ file, commentId, oldFileId }) => {
      const foundCommentIndex = comments.findIndex(
        (comment) => comment._id === commentId
      );
      if (foundCommentIndex !== -1) {
        const files = comments[foundCommentIndex].files;
        const foundFileIndex = files.findIndex(
          (fileEl) => fileEl.id === oldFileId
        );
        if (foundFileIndex !== -1) {
          comments[foundCommentIndex].files[foundFileIndex] = file;
          _setComments([...comments]);

          // trigger re-render for attachments
          eventBus.dispatch("new-comment-file", {});
        }
      }
    };

    const updateCommentReplyFiles = ({
      file,
      commentId,
      commentReplyId,
      oldFileId,
    }) => {
      const foundCommentIndex = comments.findIndex(
        (comment) => comment._id === commentId
      );
      if (foundCommentIndex !== -1) {
        const replies = comments[foundCommentIndex].taskCommentReplies;
        const foundCommentReplyIndex = replies.findIndex(
          (comment) => comment._id === commentReplyId
        );
        if (foundCommentReplyIndex !== -1) {
          const files = replies[foundCommentReplyIndex].files;
          const foundFileIndex = files.findIndex(
            (fileEl) => fileEl.id === oldFileId
          );
          if (foundFileIndex !== -1) {
            comments[foundCommentIndex].taskCommentReplies[
              foundCommentReplyIndex
            ].files[foundFileIndex] = file;
            _setComments([...comments]);
            // trigger re-render for attachments
            eventBus.dispatch("new-comment-file", {});
          }
        }
      }
    };

    const handleDragOver = (e) => {
      e.preventDefault();
      if (!showDrop) setShowDrop(true);
    };

    const handleDragLeave = (e) => {
      e.preventDefault();
      setShowDrop(false);
    };

    const handleDropFile = (e) => {
      e.preventDefault();
      let files = e.dataTransfer.files;
      setShowDrop(false);
      if (files) eventBus.dispatch("pickFiles", files);
    };

    const updateComment = ({ comment, updatedContent }) => {
      console.log(comment, updatedContent, comments);
      const foundCommentIndex = comments.findIndex(
        (commentEl) => commentEl._id === comment._id
      );
      if (foundCommentIndex !== -1) {
        comments[foundCommentIndex] = {
          ...comments[foundCommentIndex],
          ...updatedContent,
        };
        _setComments([...comments]);
      }
    };

    const updateCommentReply = ({ reply, updatedContent }) => {
      const foundCommentIndex = comments.findIndex(
        (commentEl) => commentEl._id === reply.parentCommentId
      );
      if (foundCommentIndex !== -1) {
        const replies = comments[foundCommentIndex].taskCommentReplies;
        const foundReplyIndex = replies.findIndex(
          (replyEl) => replyEl._id === reply._id
        );
        if (foundReplyIndex !== -1) {
          comments[foundCommentIndex].taskCommentReplies[foundReplyIndex] = {
            ...comments[foundCommentIndex].taskCommentReplies[foundReplyIndex],
            ...updatedContent,
          };

          _setComments([...comments]);
        }
      }
    };

    const saveEditedComment = async ({ comment, newText }) => {
      let text = "";
      /*    const { text } = await usernameFromText({
        text: linkify(escapeHTML(newText)),
        members: [
          ...cloneDeep(
            projectUsersList.leaders ? projectUsersList.leaders : []
          ),
          ...cloneDeep(projectUsersList.team ? projectUsersList.team : []),
        ],
      }); */

      socket.emit(
        "fx:task-edit-comment",
        {
          token,
          editedComment: {
            ...comment,
            comment: text,
          },
          taskId: selectedTask._id,
        },
        (response) => {
          if (response?.success) {
            const foundCommentIndex = comments.findIndex(
              (commentEl) => commentEl._id === comment._id
            );
            if (foundCommentIndex !== -1) {
              comments[foundCommentIndex].comment = response.editedText;
              _setComments([...comments]);
            }
            showToast({
              message: {
                title: "Comment Edited",
              },
              type: "success",
            });
          } else {
            showToast({
              message: {
                title: "Unable to edit Comment",
              },
              type: "error",
            });
          }
        }
      );
    };

    const saveEditedCommentReply = async ({ reply, newText }) => {
      let text = "";
      /*const { text } = await usernameFromText({
        text: linkify(escapeHTML(newText)),
        members: [
          ...cloneDeep(
            projectUsersList.leaders ? projectUsersList.leaders : []
          ),
          ...cloneDeep(projectUsersList.team ? projectUsersList.team : []),
        ],
      });*/
      socket.emit(
        "fx:task-edit-reply-comment",
        {
          token,
          editedComment: {
            ...reply,
            comment: text,
          },
          taskId: selectedTask._id,
        },
        (response) => {
          if (response?.success) {
            const foundCommentIndex = comments.findIndex(
              (commentEl) => commentEl._id === reply.parentCommentId
            );
            if (foundCommentIndex !== -1) {
              const replies = comments[foundCommentIndex].taskCommentReplies;
              const foundReplyIndex = replies.findIndex(
                (replyEl) => replyEl._id === reply._id
              );
              if (foundReplyIndex !== -1) {
                comments[foundCommentIndex].taskCommentReplies[
                  foundReplyIndex
                ].comment = response.editedText;

                _setComments([...comments]);
              }
            }
            showToast({
              message: {
                title: "Reply Edited",
              },
              type: "success",
            });
          } else {
            showToast({
              message: {
                title: "Unable to edit Reply",
              },
              type: "error",
            });
          }
        }
      );
    };

    const deleteComment = async ({ commentId }) => {
      if (
        /*  await DialogModal({
          title: "Delete Comment",
          description: `Are you sure you want to delete this comment?`,
          type: "warning",
        })*/
        false
      ) {
        socket.emit(
          "fx:delete-task-comment",
          {
            commentId,
            taskId: selectedTask._id,
            token,
          },
          (response) => {
            if (response?.success) {
              const foundCommentIndex = comments.findIndex(
                (comment) => comment._id === commentId
              );
              let filteredComments = [];
              const filesCloudIdToDelete = response.filesCloudIdToDelete;
              if (filesCloudIdToDelete) {
                filteredComments = markFilesAsDeleted({
                  filteredComments,
                  filesCloudIdToDelete,
                });
              }

              filteredComments = cloneDeep(comments).filter(
                (comment) => comment._id !== commentId
              );

              _setComments(filteredComments);

              // delete in store
              if (comments[foundCommentIndex].id) {
                //    removeComment(comments[foundCommentIndex].id);
              }

              showToast({
                message: {
                  title: "Comment deleted",
                },
                type: "success",
              });
            } else {
              showToast({
                message: {
                  title: "Unable to delete Comment",
                },
                type: "error",
              });
            }
          }
        );
      }
    };

    const deleteCommentReply = async ({
      commentId,
      commentReplyId,
      taskId,
    }) => {
      if (
        /*  await DialogModal({
          title: "Delete Reply",
          description: `Are you sure you want to delete this reply?`,
          type: "warning",
        }) */
        false
      ) {
        socket.emit(
          "fx:delete-task-comment-reply",
          {
            replyId: commentReplyId,
            taskId,
            token,
          },
          (response) => {
            if (response?.success) {
              //---
              let filteredComments = cloneDeep(comments);
              const foundCommentIndex = filteredComments.findIndex(
                (comment) => comment._id === commentId
              );
              if (foundCommentIndex !== -1) {
                const replies =
                  filteredComments[foundCommentIndex].taskCommentReplies;

                filteredComments[
                  foundCommentIndex
                ].taskCommentReplies = replies.filter(
                  (reply) => reply._id !== commentReplyId
                );

                filteredComments[foundCommentIndex].repliesCount =
                  filteredComments[foundCommentIndex].repliesCount - 1;

                let theReply = replies.find(
                  (reply) => reply._id === commentReplyId
                );

                if (theReply) {
                  const filesCloudIdToDelete = response.filesCloudIdToDelete;
                  if (filesCloudIdToDelete) {
                    filteredComments = markFilesAsDeleted({
                      filteredComments,
                      filesCloudIdToDelete,
                    });
                  }
                  _setComments(filteredComments);
                }

                // delete in store
                if (theReply?.id) {
                  // removeComment(theReply.id);
                }

                /*const foundCommentReplyIndex = replies.findIndex(
                (comment) => comment._id === commentReplyId
              );
              if (foundCommentReplyIndex !== -1) {
                const fiteredReplies = replies[foundCommentReplyIndex].filter(
                  (reply) => reply._id !== commentReplyId
                );
                comments[foundCommentIndex].taskCommentReplies = fiteredReplies;
                 _setComments([...comments]);
              }
              */
              }

              //----
              showToast({
                message: {
                  title: "Reply deleted",
                },
                type: "success",
              });
            } else {
              showToast({
                message: {
                  title: "Unable to delete Reply",
                },
                type: "error",
              });
            }
          }
        );
      }
    };

    const markFilesAsDeleted = ({ filteredComments, filesCloudIdToDelete }) => {
      eventBus.dispatch("remove-file-from-attachments", {
        filesToRemove: filesCloudIdToDelete,
      });
      eventBus.dispatch("remove-cover-image", {
        filesToRemove: filesCloudIdToDelete,
      });
      filteredComments = filteredComments.map((comment) => {
        return {
          ...comment,
          files: comment.files.map((file) => {
            return {
              ...file,
              isDelete: filesCloudIdToDelete.find(
                (fileEL) => fileEL.cloud_id === file.cloud_id
              ),
            };
          }),
          taskCommentReplies: comment.taskCommentReplies.map((reply) => {
            return {
              ...reply,
              files: reply.files.map((file) => {
                return {
                  ...file,
                  isDelete: filesCloudIdToDelete.find(
                    (fileEL) => fileEL.cloud_id === file.cloud_id
                  ),
                };
              }),
            };
          }),
        };
      });

      return filteredComments;
    };

    const handleSelectedComment = useCallback(
      (comment, usage = "search") => {
        // scroll into view
        console.log(comment);
        const elFound = document.getElementById(comment._id);
        if (elFound) {
          if (loadingRef.current) loadingRef.current.disabled = true;
          if (loadingRefNew.current) loadingRefNew.current.disabled = true;

          setTimeout(() => {
            elFound.scrollIntoView({
              behavior: "smooth",
              block: "center",
              // inline: "center",
            });
          }, 50);

          elFound.classList.add("message-focus");
          setTimeout(() => {
            elFound.classList.remove("message-focus");
            if (loadingRef.current) loadingRef.current.disabled = false;
            if (loadingRefNew.current) loadingRefNew.current.disabled = false;
          }, 1000);
          setShowSearch(false);
          return;
        }

        setIsLoadingMessage(true);
        socket.emit(
          "fx:get-task-comments-searched",
          {
            taskId: selectedTask._id,
            commentId: comment._id,
            parentCommentId: comment?.parentCommentId,
            createdAt: comment.createdAt,
            usage,
          },
          (response) => {
            // console.log("searched", response);
            if (response?.success) {
              _setComments([...response.data.comments]);
              _setCommentsPagination({
                ...commentsPagination.current,
                newerMessagesRemaining: response.data.newerMessagesRemaining,
                olderMessagesRemaining: response.data.olderMessagesRemaining,
              });
              /*console.log(
            "found",
            [...response.data.comments].find(
              (commentEl) => commentEl._id === comment._id
            )
          );
          */

              // scroll into view
              if (loadingRef.current) loadingRef.current.disabled = true;
              if (loadingRefNew.current) loadingRefNew.current.disabled = true;
              const el = document.getElementById(comment._id);
              setTimeout(() => {
                if (el) {
                  el.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                    // inline: "center",
                  });

                  el.classList.add("message-focus");
                  setTimeout(() => {
                    el.classList.remove("message-focus");
                    if (loadingRef.current) loadingRef.current.disabled = false;
                    if (loadingRefNew.current)
                      loadingRefNew.current.disabled = false;
                  }, 1500);
                }
              }, 50);

              // hide upper load more
              setShowSearch(false);
              setIsLoadingMessage(false);
            } else {
              showToast({
                message: {
                  title: "Unable to get Comment",
                },
                type: "error",
              });
              setIsLoadingMessage(false);
            }
          }
        );
      },
      [commentsPagination, selectedTask._id]
    );

    const disableLoaders = (disabled) => {
      if (loadingRef.current) loadingRef.current.disabled = disabled;
      if (loadingRefNew.current) loadingRefNew.current.disabled = disabled;
    };

    eventBus.useCustomEventListener("disableLoaders", disableLoaders);

    eventBus.useCustomEventListener(
      "handleSelectedComment",
      handleSelectedComment
    );
    eventBus.useCustomEventListener(
      `new-comment-from-store-${selectedTask._id}`,
      ({ comment }) => {
        // removeComment(comment.id);
        if (!comment.parentCommentId) {
          _setComments([..._comments.current, comment]);
        } else {
          console.log("repliesCount", comment.repliesCount);

          addNewCommentReply({
            commentId: comment.parentCommentId,
            taskCommentReply: comment,
            repliesCount: comment.repliesCount,
          });
        }
      }
    );

    eventBus.useCustomEventListener("add-file-to-comment", (file) => {
      setShowFileModal(false);
    });

    eventBus.useCustomEventListener(
      "remove-comment-file",
      ({ fileId, commentId, parentCommentId, type }) => {
        const foundCommentIndex = comments.findIndex(
          (comment) => comment._id === commentId
        );

        if (foundCommentIndex !== -1) {
          let files = comments[foundCommentIndex].files;
          if (type === "comment") {
            comments[foundCommentIndex].files = files.filter(
              (file) => file.id !== fileId
            );
            _setComments([...comments]);
          } else if (type === "reply") {
            // find comment reply
            const foundCommentIndex = comments.findIndex(
              (comment) => comment._id === parentCommentId
            );
            const replies = comments[foundCommentIndex].taskCommentReplies;
            const foundReplyIndex = replies.findIndex(
              (reply) => reply._id == commentId
            );
            if (foundReplyIndex !== -1) {
              let files = replies[foundReplyIndex].files;
              comments[foundCommentIndex].taskCommentReplies[
                foundReplyIndex
              ].files = files.filter((file) => file.id !== fileId);
            }
          }
        }
      }
    );

    eventBus.useCustomEventListener(
      "socket-connected",
      getMessagesWhileOffline
    );

    /*eventBus.useCustomEventListener(
      "remove-file-from-attachments",
      markFilesAsDeleted
    );*/

    const loadMessages = ({
      type,
      commentType = "comment",
      replys,
      parentCommentId,
    }) => {
      // disableLoaders(true);
      const currentComments = cloneDeep(_comments.current);
      let commentId = "";
      let offsetDate = "";

      if (commentType === "comment") {
        if (type === "older") {
          setIsLoadingMessage(true);
          offsetDate = currentComments[0].createdAt;
        } else {
          setIsLoadingMessageNewer(true);
          offsetDate = currentComments[currentComments.length - 1].createdAt;
        }
      } else {
        if (type === "older") {
          offsetDate = replys[0].createdAt;
        } else {
          offsetDate = replys[replys.length - 1].createdAt;
        }
      }

      socket.emit(
        "fx:load-more-comment-messages",
        {
          taskId: selectedTask._id,
          commentId,
          type,
          offsetDate,
          commentType,
          parentCommentId,
        },
        (response) => {
          if (response?.success) {
            // disableLoaders(false);
            const data = response.data;
            const incomingComments = cloneDeep(data.comments);
            const incomingReplies = cloneDeep(data.replies);

            // No records gotten
            /*if (isEmpty(data.comments) && isEmpty(data.replies)) {
              if (commentType === "comment") {
                setIsLoadingMessage(false);
              } else {
                setIsLoadingMessageNewer(false);
              }
              return;
            }*/

            // console.log(data.newerMessagesRemaining);

            if (commentType === "comment") {
              if (type === "older") {
                _setComments();
                /*getUniqueListBy(
                    [...incomingComments, ...currentComments],
                    "_id"
                  )*/
                setIsLoadingMessage(false);
                if (incomingComments) {
                  setNewMessagesTotal(incomingComments.length);
                  setCommentIdToScroll(currentComments[0]._id);
                }

                _setCommentsPagination({
                  ...commentsPagination.current,
                  olderMessagesRemaining: data.olderMessagesRemaining,
                });
              } else {
                // newer
                _setComments();
                /*getUniqueListBy(
                    [...currentComments, ...incomingComments],
                    "_id"
                  )*/
                setIsLoadingMessageNewer(false);

                _setCommentsPagination({
                  ...commentsPagination.current,
                  newerMessagesRemaining: data.newerMessagesRemaining,
                });
              }
            } else {
              // console.log(incomingReplies);
              const foundIndex = currentComments.findIndex(
                (comment) => comment._id === parentCommentId
              );

              // console.log(foundIndex);

              if (type === "older") {
                if (foundIndex != -1) {
                  currentComments[foundIndex].olderReplyMessagesCount =
                    data.remainingReplies;

                  /*  currentComments[
                    foundIndex
                  ].taskCommentReplies = getUniqueListBy(
                    [
                      ...currentComments[foundIndex].taskCommentReplies,
                      ...incomingReplies,
                    ],
                    "_id"
                  ); */
                }
                // _setComments(getUniqueListBy([...currentComments], "_id"));
              } else {
                // newer
                if (foundIndex != -1) {
                  currentComments[foundIndex].newerReplyMessagesCount =
                    data.remainingReplies;
                  /*   currentComments[
                    foundIndex
                  ].taskCommentReplies = getUniqueListBy(
                    [
                      ...incomingReplies,
                      ...currentComments[foundIndex].taskCommentReplies,
                    ],
                    "_id"
                  ); */
                }
                //  _setComments(getUniqueListBy([...currentComments], "_id"));
              }
            }
          }
        }
      );
    };

    const handleScroll = (e) => {
      scrollTop.current = e.target.scrollTop;
      let threshold =
        e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight <=
        150;
      const bottom =
        e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight ||
        threshold;
      if (bottom) {
        _setShowDownIcon(false);
        _setUnreadMessagesCount(0);
      } else {
        // load more
        //if (!showDownIcon) {
        _setShowDownIcon(true);
        //}
      }
    };

    /*const handleKeyDown = (event) => {
      event.preventDefault();
      let charCode = String.fromCharCode(event.which).toLowerCase();
      if ((event.ctrlKey || event.metaKey) && charCode === "s") {
        alert("CTRL+S Pressed");
      } else if ((event.ctrlKey || event.metaKey) && charCode === "c") {
        alert("CTRL+C Pressed");
      } else if ((event.ctrlKey || event.metaKey) && charCode === "v") {
        alert("CTRL+V Pressed");
      }
    };*/

    // Helper function that allows finding first element in the view port
    const findFirstElementInViewPort = (elements) =>
      Array.prototype.find.call(
        elements,
        (element) => element.getBoundingClientRect().y >= 82 // nav height offset
      );

    // Ref to the container with elements

    const scrollTo = useMemo(() => {
      // Find all elements in container which will be checked if are in view or
      if (thread) {
        const nodeElements = thread.current?.querySelectorAll("[data-item]");
        if (nodeElements) {
          return findFirstElementInViewPort(nodeElements);
        }
      }

      return undefined;
    }, [comments]);

    useLayoutEffect(() => {
      if (scrollTo) {
        if (prevComments?.length === comments?.length) return;
        // Scroll to element with should be in view after rendering
        scrollTo.scrollIntoView(true);
        // Scroll by height of nav
        window.scrollBy(0, -82);
      }
    }, [scrollTo, comments]);

    const [massSelectedComments, setMassSelectedComments] = useState([]);

    const handleMassSelectedComments = (checked, commentId) => {
      if (checked) {
        setMassSelectedComments((oldVal) => [...oldVal, commentId]);
      } else {
        setMassSelectedComments((oldVal) => {
          oldVal = oldVal.filter((el) => el !== commentId);
          return [...oldVal];
        });
      }
    };

    const massDelete = async () => {
      if (
        /* await DialogModal({
          title: "Delete Comment",
          description: `Are you sure you want to delete this comment?`,
          type: "warning",
        }) */
        false
      ) {
        socket.emit(
          "fx:delete-task-comment-multiple",
          {
            commentIds: massSelectedComments,
            taskId: selectedTask._id,
            token,
          },
          (response) => {
            if (response?.success) {
              const filteredComments = cloneDeep(comments)
                .map((el) => {
                  let replies = el.taskCommentReplies
                    ? el.taskCommentReplies
                    : [];

                  replies = replies.filter(
                    (reply) => !massSelectedComments.includes(reply._id)
                  );

                  return { ...el, taskCommentReplies: replies };
                })
                .filter((el) => {
                  const found = massSelectedComments.includes(el._id);

                  if (found && el?.id) {
                    //   removeComment(el.id);
                  }

                  return !found;
                });

              _setComments(filteredComments);
              setMassSelectedComments([]);

              showToast({
                message: {
                  title: "Comment deleted",
                },
                type: "success",
              });
            } else {
              showToast({
                message: {
                  title: "Unable to delete comments",
                },
                type: "error",
              });
            }
          }
        );
      }
    };

    /*  useEffect(() => {
      socket.on(`fx:edit-task-comment-${selectedTask._id}`, updateComment);
      socket.on(
        `fx:edit-task-comment-reply-${selectedTask._id}`,
        updateCommentReply
      );
      socket.on(`fx:new-task-comment-${selectedTask._id}`, handleNewComment);
      socket.on(
        `fx:new-task-comment-reply-${selectedTask._id}`,
        handleNewCommentReply
      );
      socket.on(
        `fx:add-comment-file-${selectedTask._id}`,
        handleNewCommentFile
      );
      socket.on(
        `fx:add-comment-reply-file-${selectedTask._id}`,
        handleNewCommentReplyFile
      );

      return () => {
        socket.off(`fx:edit-task-comment-${selectedTask._id}`, updateComment);
        socket.off(
          `fx:edit-task-comment-reply-${selectedTask._id}`,
          updateCommentReply
        );
        socket.off(
          `fx:new-task-comment-reply-${selectedTask._id}`,
          handleNewCommentReply
        );
        socket.off(
          `fx:add-comment-file-${selectedTask._id}`,
          handleNewCommentFile
        );
        socket.off(
          `fx:add-comment-reply-file-${selectedTask._id}`,
          handleNewCommentReplyFile
        );
      };
    }, [comments]); */

    return (
      <div
        className={`conversations  ${
          currentTab === "conversations" ? "d-flex" : "d-none"
        } d-md-flex`}
        onDragOver={(e) => handleDragOver(e)}
      >
        <div className="position-relative">
          <div className="head hide-on-mobile d-flex justify-content-between">
            <span>Conversation </span>{" "}
            <button className="btn" onClick={() => setShowSearch(true)}>
              {/*<SearchIcon /> */}
            </button>
          </div>
          {/*<SearchWindow
          showSearch={showSearch}
          setShowSearch={setShowSearch}
          handleSelectedComment={handleSelectedComment}
          selectedTask={selectedTask}
    />*/}
        </div>

        {!isLoading && !comments.length ? (
          <div className={`empty-conversations mx-auto text-center`}>
            {/*<EmptyThread /> */}
            <h4>Type your first message!</h4>
            <span>It’s time to start a conversation.</span>
          </div>
        ) : (
          <div
            className={`thread p-3 position-relative`}
            ref={thread}
            onScroll={(e) => handleScroll(e)}
          >
            {
              <div className="d-flex w-100">
                <button
                  className="btn btn-sm old-msg-btn rounded-pill invisible p-1 mb-3"
                  ref={loadingRef}
                  onClick={() => {
                    console.log("load older");
                    loadMessages({
                      type: "older",
                    });
                  }}
                >
                  load older messages
                </button>
              </div>
            }
            {isLoadingMessages ? (
              <div className="w-100 d-flex justify-content-center align-items-center p-2">
                <Spinner animation="border" variant="primary" size="sm" />
              </div>
            ) : null}

            {/*<div className="message">
            <>
              {[
                ...getUniqueListBy(comments, "_id"),
                ...toArray(globalComments).filter(
                  (comment) =>
                    !comment.parentCommentId &&
                    !comment.updatedAt &&
                    comment.taskId === selectedTask._id &&
                    !comments.find(
                      (commentEL) =>
                        comment.id && commentEL.tempId === comment.id
                    )
                ),
              ]
                .sort((a, b) =>
                  compareAsc(
                    new Date(a.createdAt).getTime(),
                    new Date(b.createdAt).getTime()
                  )
                )
                .map((comment, index) => (
                  <Comment
                    key={comment?._id ? comment._id : comment.id}
                    comment={comment}
                    setCommentToReply={setCommentToReply}
                    getReplies={getReplies}
                    addUsernameToInput={addUsernameToInput}
                    setShowReplies={setShowReplies}
                    taskId={selectedTask._id}
                    showCommentFiles={showCommentFiles}
                    updateCommentFiles={updateCommentFiles}
                    updateCommentReplyFiles={updateCommentReplyFiles}
                    selectedProject={selectedProject}
                    isShared={isShared}
                    deleteComment={deleteComment}
                    deleteCommentReply={deleteCommentReply}
                    saveEditedComment={saveEditedComment}
                    saveEditedCommentReply={saveEditedCommentReply}
                    projectUsersList={projectUsersList}
                    user={user}
                    token={token}
                    loadMessages={loadMessages}
                    threadRef={thread}
                    handleSelectedComment={handleSelectedComment}
                    setAsCover={setAsCover}
                    massSelectedComments={massSelectedComments}
                    setMassSelectedComments={setMassSelectedComments}
                    handleMassSelectedComments={handleMassSelectedComments}
                    updateComment={updateComment}
                    updateCommentReply={updateCommentReply}
                  />
                ))}
            </>
          </div>*/}

            {isLoadingMessagesNewer && (
              <div className="w-100 d-flex justify-content-center align-items-center p-2">
                <Spinner animation="border" variant="primary" size="sm" />
              </div>
            )}

            <div
              style={
                !commentsPagination.newerMessagesRemaining
                  ? {
                      marginRight: "-120vw",
                      position: "fixed",
                    }
                  : {}
              }
              className="d-flex mb-0 mt-3"
            >
              <button
                className="btn old-msg-btn rounded-pill border invisible"
                ref={loadingRefNew}
                onClick={() => {
                  console.log("load newer");
                  loadMessages({
                    type: "newer",
                  });
                }}
              >
                load newer messages
              </button>
            </div>
          </div>
        )}

        {/*<ShowDownComponent
        unreadMessagesCount={unreadMessagesCount}
        selectedTask={selectedTask}
        getTaskComments={getTaskComments}
        thread={thread}
        scrollToBottom={scrollToBottom}
            /> */}

        {showDrop && (
          <div
            onDrop={(e) => handleDropFile(e)}
            onDragOver={(e) => handleDragOver(e)}
            onDragLeave={(e) => handleDragLeave(e)}
            className="drag-area"
          >
            <div>
              <p>Drop files here</p>
            </div>
          </div>
        )}

        <div
          className={`comment-area ${
            !isLoading && !comments.length ? "position-absolute" : ""
          }`}
        >
          <CommentArea
            projectUsersList={projectUsersList}
            scrollToBottom={scrollToBottom}
            thread={thread}
          />

          {!isEmpty(massSelectedComments) ? (
            <div className="mass-message-select-actions">
              <button
                onClick={() => setMassSelectedComments([])}
                className="btn"
                title="Cancel"
              >
                <CloseIcon /> {massSelectedComments.length} Selected
              </button>

              <button
                className="btn"
                title="Delete"
                onClick={() => massDelete()}
              >
                <DetailedIcon />
              </button>
            </div>
          ) : null}
        </div>

        {/*showFileModal && (
        <FileModal
          indexInView={data.indexInView}
          showFileModal={showFileModal}
          setShowFileModal={(isOpen) => setShowFileModal(isOpen)}
          files={data.imageFiles}
          setAsCover={setAsCover}
          canSeeChat={canSeeChat}
          isShared={isShared}
        />
      )*/}
      </div>
    );
  }
);

export default Conversations;
