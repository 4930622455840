import MagnifyIcon from "mdi-react/MagnifyIcon";
import { useState } from "react";
import { Modal, Table, Form, Button, Dropdown } from "react-bootstrap";
// import "./../assets/scss/customer-select-modal.scss";
import useDebounce, {
  useEffectOnce,
  useSingleAndDoubleClick,
} from "../../utils/hooks";
import { appSettings, services } from "../../config";
import queryString from "query-string";
import { useMutation, useQuery, useQueryClient } from "react-query";

import ReactPaginate from "react-paginate";
import {
  fetchActionsUtil,
  paginationOptions,
  scrollToTop,
} from "../../utils/helpers";

import { format } from "date-fns";

import { useAuth } from "../../hooks/useAuth";

import CachedIcon from "mdi-react/CachedIcon";

const WarehouseSerialItemModal = ({
  show,
  selectedItem,
  setSelectedItem,
  setShowBatchesModal,
}) => {
  const initialFilterParams = {
    BatchCode: selectedItem?.Bar_Code,
    q: "",
  };

  const wareshouseLocal = JSON.parse(
    window.localStorage.getItem("warehouseName")
  );

  const { backendUrl } = useAuth();
  const [queryParams, setQueryParams] = useState({
    page: 1,
    limit: 40,
    ...initialFilterParams,
  });

  const debouncedqueryParams = useDebounce(queryParams, 800);

  const { data = { batches: [] }, refetch } = useQuery(
    ["WAREHOUSE_GET_SERIAL_NUMBERS", debouncedqueryParams],
    () =>
      fetchActionsUtil(
        `${backendUrl}/api/warehouse/get/serial-item?${queryString.stringify(
          debouncedqueryParams
        )}&Warehouse=${wareshouseLocal?.whID}`
      ),
    {
      enabled: true,
      keepPreviousData: true,
    }
  );

  const handleSearchQueryChange = (e) => {
    setQueryParams({
      ...queryParams,
      page: 1,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  return (
    <>
      <Modal
        show={show}
        onHide={() => {
          setSelectedItem(null);
          setShowBatchesModal(false);
        }}
        dialogClassName="requisition-details-modal approve-modal"
        backdropClassName={`global-backdrop`}
        centered={true}
        animation={false}
        enforceFocus={false}
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h1 className="h5">Serial Number Details</h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-4 d-flex flex-column pt-0">
          <div className="search-area d-flex justify-content-between align-items-center gap-3 w-100 px-3 mb-4 h6">
            <h2>
              {selectedItem?.Bar_Code}
              <button
                title="Refresh"
                onClick={() => refetch()}
                className="btn text-primary"
              >
                <CachedIcon />
              </button>
            </h2>
          </div>

          <div className="col-6 d-flex gap-3 align-items-center pe-3 mb-3">
            <div className="global-search-area w-75">
              <MagnifyIcon />
              <input
                className="form-control search-input"
                name="q"
                value={queryParams.q}
                onChange={(e) =>
                  setQueryParams({
                    ...queryParams,
                    page: 1,
                    [e.target.name]: e.target.value,
                  })
                }
                placeholder={`Search Serial number`}
                autoFocus
                autoComplete="off"
                type={"text"}
              />
            </div>
          </div>

          <div className="content px-1 pb-4 pe-2 flex-grow-1">
            <Table borderless responsive striped className="product-table">
              <thead className="sticky border-bottom">
                <tr>
                  <th>Serial Number</th>
                  <th>Batch Code</th>
                  <th>Bar Code</th>
                  <th>Item Name</th>
                  <th>Delivery Date</th>
                </tr>
              </thead>

              <tbody className="blue-hover">
                {data?.batches &&
                  data.batches.map((el, index) => (
                    <tr key={index} className={`p-cursor`}>
                      <td>{el?.serialNum}</td>
                      <td>{el?.BatchCode}</td>
                      <td>{el?.Bar_Code}</td>
                      <td>{el?.Item_Name}</td>
                      <td>
                        {el?.Reg_date
                          ? format(new Date(el.Reg_date), "dd MMM, yyyy")
                          : "..."}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>

          <div className="d-flex justify-content-between px-3 align-items-center pagination">
            <p className="m-0">
              Showing {data?.startIndex + 1} to{" "}
              {data?.endIndex <= data?.count ? data?.endIndex : data?.count} of{" "}
              {data.count} entries
            </p>

            <ReactPaginate
              {...paginationOptions}
              pageCount={Math.ceil(data?.count / queryParams?.limit)}
              marginPagesDisplayed={2}
              pageRangeDisplayed={0}
              onPageChange={({ selected }) => {
                scrollToTop();
                setQueryParams({
                  ...queryParams,
                  page: selected + 1,
                });
              }}
              forcePage={queryParams.page - 1}
            />
          </div>
        </Modal.Body>

        {/* <ModalLoader
              show={
                updateBatchMutation.isLoading || quarantineBatchMutation.isLoading
              }
            /> */}
      </Modal>
    </>
  );
};

export default WarehouseSerialItemModal;
