import { Table, Dropdown } from "react-bootstrap";
import CachedIcon from "mdi-react/CachedIcon";
import { CSVLink } from "react-csv";
import queryString from "query-string";
import { useQuery } from "react-query";
import { useEffect, useState, useRef } from "react";
import { format, parse } from "date-fns";
import { useOutletContext } from "react-router-dom";
// import ReactPaginate from "react-paginate";

import { ExportIcon, ExcelIcon, PDFIcon, FilterTwoIcon } from "../../Icons";
import NoTableItem from "../../utils/NoTableItem";
import { useDownloadExcel } from "../../../hooks/useDownloadExcel";
import { useAuth } from "../../../hooks/useAuth";
import useDebounce, { useScrollTop } from "../../../utils/hooks";
import ModalLoader from "../../utils/ModalLoader";
// import { paginationOptions } from "../../../utils/helpers";
import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";
import SetSalesRepModal from "../../SalesAndInvoicing/SetSalesRepModal";
import { fetchActionsUtil } from "../../../utils/helpers";

export default function Customers() {
  useScrollTop();
  const {
    queryParams,
    setQueryParams,
    showFilter,
    setShowFilter,
  } = useOutletContext();

  const initialFilterParams = {};

  const [excelData, setExcelData] = useState([]);

  const CSVLinkRef = useRef(null);

  const { backendUrl } = useAuth();

  // fetch excel hook
  const [isfetchingExcel, fetchExcelData] = useDownloadExcel(
    excelData,
    CSVLinkRef
  );
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [showSalesRepManager, setShowSalesRepManager] = useState(null);

  const [filterParams] = useState({
    ...initialFilterParams,
    ...queryParams,

    // Get the date in url and parse so date picker can use it
    date: queryParams?.date
      ? parse(queryParams?.date, "yyyy-MM-dd", new Date())
      : "",
  });

  const debouncedFilterParams = useDebounce(filterParams, 500);
  useEffect(() => {
    setQueryParams({
      ...queryParams,
      ...debouncedFilterParams,
    });
  }, [debouncedFilterParams]);

  const {
    data = {
      count: 0,
      customers: [],
    },
    refetch,
    isFetching,
    isSuccess,
  } = useQuery(
    ["FETCH_SALES_CUSTOMERS", queryParams],
    () =>
      fetchActionsUtil(
        `${backendUrl}/api/customers/customers-sales-rep?${queryString.stringify(
          queryParams
        )}`,
        "GET"
      ),
    {
      keepPreviousData: true,
    }
  );

  // console.log(data);

  // const handleSearchQueryChange = (e) => {
  //   setQueryParams({
  //     // ...searchParamsToObject(searchQuery.entries()),
  //     ...queryParams,
  //     [e.target.name]: e.target.value,
  //   });
  // };

  const { limit, page, ...rest } = queryParams;
  const onDownloadExcelData = async () => {
    let exData = await fetchExcelData(
      `${backendUrl}/api/customers/customers-sales-rep?${queryString.stringify(
        rest
      )}`,
      "GET"
    );
    const company = exData?.company?.CompName;

    const date =
      rest.startDate && rest.endDate
        ? `Date Prepared: Between ${format(
            new Date(rest.startDate),
            "E MMM d yyyy k:mm:ss z"
          )} to ${format(new Date(rest.endDate), "E MMM d yyyy k:mm:ss z")}`
        : "";

    exData = exData?.customers?.map(({ Product, Cust_Rep_Customers }, i) => [
      i + 1,
      Cust_Rep_Customers.Cust_ID,
      Cust_Rep_Customers.LastName,
      Cust_Rep_Customers.TransType,
      Product,
    ]);

    exData = [
      [company],
      ["Rep.Sales/Customers Report"],
      [date],
      [""],
      [
        "S/N",
        "Cust ID",
        "Business Name",
        "Transaction Type",
        "Product / Manufacturer",
      ],
      ...exData,
    ];
    console.log(exData);
    setExcelData(exData);
  };

  const manageSalesRep = (el) => {
    setSelectedCustomer(el);
    setShowSalesRepManager(true);
  };

  return (
    <>
      <div className="absolute-title">
        <h1> Sales Reps Analysis</h1>
      </div>
      <main className="cash-book">
        <div className="content">
          <div className="content-main">
            <div className="content-body">
              <header>
                <h1 className="d-flex align-items-center gap-3">
                  {!showFilter && (
                    <button
                      onClick={() => setShowFilter(!showFilter)}
                      className="btn filter"
                    >
                      <FilterTwoIcon />
                    </button>
                  )}
                  Sales/Customers Report
                  <button
                    title="Refresh"
                    onClick={() => refetch()}
                    className="btn text-primary"
                  >
                    <CachedIcon />
                  </button>
                </h1>

                <div className="actions">
                  <CSVLink
                    className="btn print d-none"
                    filename={`Rep.Sales/Customers Report(${format(
                      new Date(),
                      "dd-MMM-yyyy hh:mm:ss a"
                    )}).csv`}
                    data={excelData}
                    ref={CSVLinkRef}
                  />

                  {/* <button /> */}
                  <Dropdown>
                    <Dropdown.Toggle
                      variant=""
                      className="btn print"
                      disabled={isfetchingExcel}
                      bsPrefix=""
                    >
                      Export
                      <ExportIcon color="#008000" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      popperConfig={{
                        strategy: "fixed",
                      }}
                      renderOnMount
                      className="text-center"
                    >
                      <Dropdown.Item
                        as="div"
                        onClick={onDownloadExcelData}
                        className="p-cursor"
                      >
                        Excel <ExcelIcon color="#008000" />
                      </Dropdown.Item>
                      <Dropdown.Item as="div">
                        <a
                          href={`${backendUrl}/api/customers/pdf/customers-sales-rep?${queryString.stringify(
                            rest
                          )}`}
                          target="blank"
                        >
                          PDF
                          <PDFIcon color="#ff0000" />
                        </a>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </header>

              <div className="px-md-4">
                <Table borderless striped responsive className="product-table">
                  <thead className="sticky border-bottom">
                    <tr>
                      <th />
                      <th>Cust ID</th>
                      <th>Business Name</th>
                      <th>Transaction Type</th>
                      <th>Product / Manufacturer</th>
                    </tr>
                  </thead>

                  <tbody className="blue-hover">
                    {data.customers &&
                      data.customers?.map(
                        ({ Cust_Rep_Customers, Product }, index) => (
                          <tr key={index}>
                            <td>
                              <Dropdown>
                                <Dropdown.Toggle
                                  variant=""
                                  className="bg-white border-0"
                                  bsPrefix="print more"
                                >
                                  <DotsVerticalIcon />
                                </Dropdown.Toggle>
                                <Dropdown.Menu
                                  popperConfig={{
                                    strategy: "fixed",
                                  }}
                                  renderOnMount
                                  className="dropdown-with-icons"
                                >
                                  <Dropdown.Item
                                    onClick={() =>
                                      manageSalesRep(Cust_Rep_Customers)
                                    }
                                    as="button"
                                  >
                                    Manage Sales Rep
                                  </Dropdown.Item>
                                </Dropdown.Menu>{" "}
                              </Dropdown>
                            </td>
                            <td>{Cust_Rep_Customers.Cust_ID}</td>
                            <td>{Cust_Rep_Customers.LastName}</td>
                            <td>{Cust_Rep_Customers.TransType}</td>
                            <td>{Product}</td>
                          </tr>
                        )
                      )}
                  </tbody>
                </Table>

                {!isFetching && isSuccess && data?.customers.length < 1 ? (
                  <NoTableItem queryParams={queryParams} />
                ) : null}
              </div>
              {/* {data?.customers.length > 0 ? (
                <div className="d-flex justify-content-between px-3 align-items-center pagination">
                  <div className="pagination_left">
                    <p className="m-0 p-0">Show</p>

                    <select
                      value={queryParams.limit}
                      name="limit"
                      className="form-select "
                      onChange={(e) => handleSearchQueryChange(e)}
                    >
                      <option value="10">10 rows</option>
                      <option value="20">20 rows</option>
                      <option value="30">30 rows</option>
                      <option value="40">40 rows</option>
                      <option value="50">50 rows</option>
                      <option value="100">100 rows</option>
                    </select>
                  </div>

                  <ReactPaginate
                    {...paginationOptions}
                    pageCount={data.count / queryParams.limit}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={0}
                    onPageChange={({ selected }) => {
                      document.body.scrollTop = document.documentElement.scrollTop = 0;
                      setQueryParams({
                        ...queryParams,
                        page: selected + 1,
                      });
                    }}
                    forcePage={queryParams.page - 1}
                  />
                </div>
              ) : (
                <div className="py-2" />
              )} */}
            </div>
          </div>
          {showSalesRepManager && selectedCustomer ? (
            <SetSalesRepModal
              selectedCustomer={selectedCustomer}
              setSelectedCustomer={setSelectedCustomer}
              setShowSaleRepModal={setShowSalesRepManager}
            />
          ) : null}
        </div>
        <ModalLoader show={isfetchingExcel} />
      </main>
    </>
  );
}
