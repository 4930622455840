import { useState } from "react";
import { NavLink } from "react-router-dom";

export function DrumsNav(props) {
  const [items] = useState([
    {
      name: "Update Drum Account",
      to: "update-drum-account",
    },
    {
      name: "Drum Account Sheet",
      to: "drum-account-sheet",
    },
  ]);

  return (
    <div className="dashboard-tabs innerpage-tabs px-4">
      <ul className="nav nav-tabs">
        {items.map((item, index) => (
          <li key={index} className="nav-item">
            <NavLink className={`nav-link ga-nav-tab`} to={item.to}>
              {item.name}
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  );
}
