import { Button, Dropdown, Form, Modal } from "react-bootstrap";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useQuery, useQueryClient } from "react-query";
import { useEffect } from "react";
import { services } from "../../config";
import { useAuth } from "../../hooks/useAuth";
import { useState } from "react";
import useDebounce, {
  useChosenBackendUrl,
  useCurrencies,
  useIsGovernmentInvexERP,
} from "../../utils/hooks";
import queryString from "query-string";
import CachedIcon from "mdi-react/CachedIcon";
import Expenses from "../Overviews/Expenses";
import Revenue from "../Overviews/Revenue";
import { isEmpty } from "lodash";
import { format } from "date-fns";
import Select from "react-select";
import { analyticsOptions } from "../../utils/helpers";

import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default function RevenueVsExpenses() {
  const {
    deploymentCurrencies: currenciesOptions,
    prevailingCurrency,
  } = useCurrencies();

  const initialFilterParams = {
    by: "year",
    currency: prevailingCurrency,
    year: new Date().getFullYear(),
  };
  const isGovernmentInvexERP = useIsGovernmentInvexERP();
  const backendUrl = useChosenBackendUrl();
  const [show, setShow] = useState(false);
  const [queryParams, setQueryParams] = useState({
    ...initialFilterParams,
  });
  const debouncedqueryParams = useDebounce(queryParams, 800);

  const getRevenue = async (debouncedqueryParams) => {
    let response = await fetch(
      `${backendUrl}/api/dashboard/get-revenue?${queryString.stringify(
        debouncedqueryParams
      )}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    return data;
  };

  const { error, data, isSuccess, refetch, isFetching } = useQuery(
    ["GET_REVENUE", debouncedqueryParams, backendUrl],
    () => getRevenue(debouncedqueryParams),
    {
      // enabled: false,
      // cacheTime: 0,
      onSuccess: (data) => {
        // queryClient.removeQueries("LOGIN");
      },
    }
  );

  useEffect(() => {
    console.log(error);
  }, [error]);

  return (
    <div className="chart">
      <header className="section-header p-3 pe-4">
        <h2>
          {isGovernmentInvexERP
            ? "Funds vs Expenses"
            : "Total Revenue vs Expenses"}{" "}
          <button
            title="Refresh"
            onClick={() => refetch()}
            className="btn text-primary"
          >
            <CachedIcon />
          </button>
        </h2>

        <div className="d-flex gap-2">
          <Button onClick={() => setShow(true)} variant="light-blue">
            Overview
          </Button>

          <div className="position-relative z-10">
            <Select
              classNamePrefix={`form-select-analytics`}
              isSearchable={false}
              options={analyticsOptions}
              value={analyticsOptions.find((el) => el.value === queryParams.by)}
              onChange={(selected) => {
                setQueryParams({
                  ...queryParams,
                  by: selected?.value,
                });
              }}
            />
            {queryParams.by === "select-year" && (
              <div className="position-absolute -bottom-100 end-0 pt-2">
                <Form.Control
                  type="number"
                  value={queryParams.year}
                  onChange={(e) =>
                    setQueryParams({
                      ...queryParams,
                      year: e.target.value,
                    })
                  }
                  autoFocus={true}
                />
              </div>
            )}
          </div>

          <Dropdown>
            <Dropdown.Toggle
              variant=""
              className="bg-white border-lighter"
              bsPrefix="print more"
              size="sm"
            >
              <DotsVerticalIcon />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Select
                classNamePrefix="form-select"
                placeholder="Select Currency"
                isSearchable={false}
                options={currenciesOptions}
                value={currenciesOptions?.find(
                  (el) => el.value === queryParams.currency
                )}
                onChange={(selected) => {
                  setQueryParams({
                    ...queryParams,
                    currency: selected?.value,
                  });
                }}
                isDisabled
              />
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </header>

      {/* bar chart */}

      <div className="chart-holder ps-3">
        {
          <Bar
            options={{
              //  responsive: true,
              plugins: {
                legend: {
                  position: "bottom",
                  labels: {
                    fontColor: "#94A3B8",
                    fontSize: 12,
                    boxHeight: 8,
                    boxWidth: 8,
                    pointStyle: "circle",
                    usePointStyle: true,
                  },
                },
                title: {
                  display: false,
                },
                tooltip: {
                  backgroundColor: "#00000080",
                  boxHeight: 8,
                  boxWidth: 8,
                  pointStyle: "circle",
                  usePointStyle: true,
                  boxPadding: 8,
                },
              },

              scales: {
                y: {
                  grid: {
                    color: "#F4F7FA",
                  },
                  ticks: {
                    color: "#94A3B8",
                    stepSize: 100000000,
                    fontSize: 12,
                  },
                },
                x: {
                  grid: {
                    color: "#fff",
                  },
                  ticks: {
                    color: "#94A3B8",
                    fontSize: 12,
                  },
                },
              },
            }}
            data={{
              labels: [...(isSuccess ? data.map((el) => el.date) : [])],
              datasets: [
                {
                  label: "Expenses",
                  data: [...(isSuccess ? data.map((el) => el.expenses) : [])],
                  backgroundColor: "#C0C5CF",
                  borderRadius: 100,
                  barThickness: 16,
                },
                {
                  label: "Revenue",
                  data: [...(isSuccess ? data.map((el) => el.revenue) : [])],
                  backgroundColor: "#2463AE",
                  borderRadius: 100,
                  barThickness: 16,
                },
              ],
            }}
          />
        }
        <div className="blur" />
      </div>
      <OverviewModal
        show={show}
        setShow={setShow}
        dateRange={
          !isEmpty(data)
            ? {
                startDate: data[0].startDate,
                endDate: data[data.length - 1].endDate,
              }
            : {}
        }
      />
    </div>
  );
}

export function OverviewModal({ show, setShow, dateRange = {} }) {
  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      dialogClassName="modal-90w"
      aria-labelledby="example-custom-modal-styling-title"
      centered={true}
      animation={false}
      enforceFocus={false}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title className="d-block">
          <h2 className="h5 text-nowrap w-100">
            Total Revenue vs Expenses{" "}
            {dateRange?.startDate && dateRange?.endDate ? (
              <span>
                {dateRange.startDate
                  ? format(new Date(dateRange.startDate), "dd MMM, yyy")
                  : ""}
                {" - "}
                {dateRange.endDate
                  ? format(new Date(dateRange.endDate), "dd MMM, yyy")
                  : ""}
              </span>
            ) : null}
          </h2>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row justify-content-between">
          <div className="col-md-6  pe-5">
            <Revenue dateRange={dateRange} />
          </div>
          <div className="col-md-6  ps-5">
            <Expenses dateRange={dateRange} />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
