// import { DatePicker } from "antd";
import { CalendarIcon } from "../Icons";
import { useRef, useState } from "react";
import moment from "moment";
import { useOnClickOutside } from "../../utils/hooks";
import "./../../assets/scss/scoped/datepicker.scoped.scss";

import dateFnsGenerateConfig from "rc-picker/lib/generate/dateFns";
import generatePicker from "antd/es/date-picker/generatePicker";
import { format, parse } from "date-fns";
import { result } from "lodash";

const DatePicker = generatePicker(dateFnsGenerateConfig);

const { RangePicker } = DatePicker;

function Panel(props) {
  console.log(props);
  return (
    <button className="btn date-range">
      <CalendarIcon />
      Jan 17, 2022 - Jan 23, 2022
    </button>
  );
}

function RangeFooter(props) {
  return (
    <div className="range-footer">
      <div className="d-flex align-items-center date">
        {props.date?.length > 1 && (
          <>
            <button className="btn default">
              {format(props.date[0], "MMM dd, yyyy")}
            </button>
            <span>-</span>
            <button className="btn default">
              {format(props.date[1], "MMM dd, yyyy")}
            </button>
          </>
        )}
      </div>
      <div className="d-flex align-items-center actions">
        <button className="btn default" onClick={() => props.setOpen(false)}>
          Cancel
        </button>
        <button className="btn btn-primary" onClick={() => props.apply()}>
          Apply
        </button>
      </div>
    </div>
  );
}

export default function DateRangePicker(props) {
  const ref = useRef();
  const [open, setOpen] = useState(false);
  const [date, setDate] = useState(props?.defaultValue);

  const apply = () => {
    if (props.apply) props.apply(date);
    setOpen(false);
  };

  const handleChange = (data) => {
    setDate(data);
  };

  /*useOnClickOutside(ref, () => {
    setOpen(false);
  });*/

  const onPanelChange = (data) => {
    console.log(data);
  };

  return (
    <RangePicker
      open={open}
      value={date}
      onOpenChange={(open) => open && setOpen(open)}
      className="ga-range-picker"
      dropdownClassName="ga-range-picker-dropdown"
      renderExtraFooter={(data) => (
        <RangeFooter {...data} setOpen={setOpen} apply={apply} date={date} />
      )}
      separator="-"
      format="DD MMM, YYYY"
      onChange={handleChange}
      onOk={apply}
      inputReadOnly={true}
      onPanelChange={onPanelChange}
      placement={"bottomRight"}
      transitionName=""
      {...props}
    />
  );
}
