import { Modal, Form, Button } from "react-bootstrap";
import { useState, useEffect } from "react";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import Select from "react-select";
import { useFormik } from "formik";
import * as yup from "yup";
import CreatableSelect from "react-select/creatable";
import { CustomInput } from "../utils/CustomInput";
import Datetime from "react-datetime";
import moment from "moment";

import { useAuth } from "../../hooks/useAuth";
import { fetchActionsUtil } from "../../utils/helpers";
import ConfirmDialog from "../ConfirmDialogue";
import ModalLoader from "../utils/ModalLoader";
import CloseIcon from "mdi-react/CloseIcon";

const ReturnStorageItemModal = (props) => {
  const { backendUrl, token } = useAuth();

  const wareshouseLocal = JSON.parse(
    window.localStorage.getItem("warehouseName")
  );

  const warehouseActive = props?.warehouseActive;

  //console.log(props?.activeItem);
  const returnStorageItemMutation = useMutation(
    (payload) =>
      fetchActionsUtil(
        `${backendUrl}/api/warehouse/storage/return-item`,
        "POST",
        token,
        payload
      ),
    {
      onSuccess: ({ message, data }) => {
        props.refetch();
        toast.success(message);
        props.onHide();
      },
      onError: () => {
        toast.error(`Unable to perform action`);
      },
    }
  );

  const formik = useFormik({
    initialValues: {
      itemName: props?.activeItem?.Item_Name,
      issueDate: moment(),
      item: props?.activeItem,
      QuantityFromReciever: "",
      Comment: "",
      remainingQty:
        parseFloat(props?.activeItem?.Quantity) -
        parseFloat(props?.activeItem?.QuantityFromReciever),
    },
    validationSchema: yup.object().shape({
      itemName: yup.string().required("itemName is required"),
      QuantityFromReciever: yup.string().required("Quality is required"),
    }),
    onSubmit: async (values) => {
      const {
        itemName,
        issueDate,
        item,
        QuantityFromReciever,
        Comment,
        remainingQty,
      } = values;

      if (QuantityFromReciever > remainingQty || QuantityFromReciever <= 0) {
        return formik.setErrors({
          QuantityFromReciever: "Check your Quantity",
        });
      }

      if (
        await ConfirmDialog({
          title: "Return Item",
          description:
            "Are you sure you want to return this Item to your inventory",
        })
      ) {
        if (
          warehouseActive?.Warehouse_Type === "Storage" &&
          wareshouseLocal?.cater === "Comsumables"
        ) {
          const conStorage = {
            allTotal:
              parseFloat(props?.activeItem?.UnitCost) *
              parseFloat(QuantityFromReciever),
            cons: props?.activeItem?.contracts,
          };
          returnStorageItemMutation.mutate({
            itemName,
            issueDate,
            ...item,
            QuantityFromReciever,
            Comment,
            conStorage,
          });
        } else {
          returnStorageItemMutation.mutate({
            itemName,
            issueDate,
            ...item,
            QuantityFromReciever,
            Comment,
          });
        }
      }
    },
  });

  return (
    <Modal
      onHide={props.onHide}
      show={props.show}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div>
          <div>
            <div className="d-flex justify-content-between">
              <h6>Return Item</h6>
              <div role="button" className="text-lg" onClick={props.onHide}>
                <CloseIcon />
              </div>
            </div>
            {/* <p className="text-muted">
              
            </p> */}
          </div>
          <div className="mt-3">
            {/*  */}

            <div className="mb-3">
              <label className="col-form-label fw-bold">Iten Name</label>
              <div className="form-group">
                <CustomInput
                  name="itemName"
                  placeholder="Enter Item Name"
                  typeOfInput="text"
                  //   label="Item Name"
                  disabled={true}
                  value={formik.values.itemName}
                  onChange={formik.handleChange}
                />
                <span className="form-control-feedback text-danger">
                  {formik.errors.itemName}
                </span>
              </div>
            </div>
            {/*  */}
            <div className="mb-3">
              <label className="col-form-label fw-bold">Quantity</label>
              <div className="form-group">
                <CustomInput
                  name="QuantityFromReciever"
                  placeholder="Enter Quantity"
                  typeOfInput="number"
                  //   label="Quantity"
                  value={formik.values.QuantityFromReciever}
                  onChange={formik.handleChange}
                />
                <span className="form-control-feedback text-danger">
                  {formik.errors.QuantityFromReciever}
                </span>
              </div>
            </div>
            {/*  */}
            <Form.Group className="">
              <Form.Label className="mb-1">Comment(Optional)</Form.Label>
              <Form.Control
                as="textarea"
                placeholder="Enter Comment"
                name="Comment"
                rows={5}
                value={formik.values.Comment}
                onChange={formik.handleChange}
                isInvalid={formik.touched.Comment && !!formik.errors.Comment}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.Comment}
              </Form.Control.Feedback>
            </Form.Group>
            {/*  */}
            <div className="my-3">
              <label htmlFor="date" className="form-label">
                Return Date
              </label>
              <Datetime
                dateFormat="MMM DD, YYYY"
                timeFormat={false}
                closeOnSelect={true}
                closeOnClickOutside={true}
                name="issueDate"
                inputProps={{
                  className: `date-input form-control ${
                    formik.touched.issueDate && !!formik.errors.issueDate
                      ? "is-invalid"
                      : ""
                  }`,

                  placeholder: "Select date",
                  readOnly: true,
                }}
                value={formik.values.issueDate}
                onChange={(date) => {
                  formik.setFieldValue("issueDate", date, true);
                }}
                onBlur={() => formik.setFieldTouched("issueDate", true)}
              />
              {formik.touched.issueDate && !!formik.errors.issueDate ? (
                <span className="custom-invalid-feedback">
                  {formik.errors.issueDate}
                </span>
              ) : null}
            </div>

            {/*  */}
            <div className="my-3">
              <CustomInput
                name="remainingQty"
                placeholder="Enter Quantity"
                typeOfInput="number"
                label="Remaining Quantity"
                disabled={true}
                value={formik.values.remainingQty}
                onChange={formik.handleChange}
              />
            </div>

            {/*  */}
          </div>
        </div>
      </Modal.Body>
      <div className="d-inline-flex justify-content-end m-4">
        <button className="btn bg print mx-4" onClick={props.onHide}>
          Cancel
        </button>
        <button
          type="submit"
          className="btn btn-primary"
          onClick={formik.handleSubmit}
        >
          Send
        </button>
      </div>
      <ModalLoader show={returnStorageItemMutation.isLoading} />
    </Modal>
  );
};

export default ReturnStorageItemModal;
