import { useState } from "react";

import MagnifyIcon from "mdi-react/MagnifyIcon";
import { Modal, Table, Form, Button, InputGroup } from "react-bootstrap";
import { services } from "../../config";
import useDebounce, { useEffectOnce, useUpdateEffect } from "../../utils/hooks";
import { queryActions } from "../../utils/reactQueryActions";
import "./../../assets/scss/item-select-modal.scss";
import { useQuery } from "react-query";
import currency from "currency.js";
import CurrencyCustomInput from "../utils/CurrencyCustomInput";
import NumberCustomInput from "../utils/NumberCustomInput";

import { useFormik, getIn } from "formik";
import * as yup from "yup";
import Select from "react-select";
import { NoSelectedItemIcon } from "../Icons";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { formatForQty } from "../../utils/helpers";

export default function EditItemModal({
  selectedItem,
  setEditedItemIndex,
  handleEditItem,
}) {
  const durationOptions = [
    {
      value: "Day",
      label: "Day",
    },
    {
      value: "Week",
      label: "Week",
    },
    {
      value: "Month",
      label: "Month",
    },
  ];

  const formik = useFormik({
    initialValues: {
      Quantity: 1,
      UnitPrice: "",
      PriceSold: "",
      durationNumber: 1,
      durationValue: "Month",
      Warranty: false,
      Discount: "",
    },
    validationSchema: yup.object().shape({
      Quantity: yup.string().required(),
      UnitPrice: yup.number().required(),
      PriceSold: yup.number().required(),
    }),
    onSubmit: (values) => {
      console.log(values);

      if (Number(values.Quantity) > Number(selectedItem?.quantityInStock)) {
        return formik.setFieldError(
          "Quantity",
          `${selectedItem?.quantityInStock} of this item in stock`
        );
      }

      /* values.Warrant_Duration = `${values.durationNumber} ${
        values.durationValue
      }`; */

      values.Warrant_Duration = values?.Warrant_Duration || "";

      /*  selectedItem.UnitCost = currency(selectedItem.UnitCost, {
        symbol: "",
        separator: "",
      }).format(); */

      values.PriceSold = currency(values.PriceSold, {
        symbol: "",
        separator: "",
      }).format();

      values.defaultSubTotal = currency(values.UnitPrice, {
        symbol: "",
        separator: "",
      })
        .multiply(values.Quantity)
        .format();

      values.SubTotal = currency(values.PriceSold, {
        symbol: "",
        separator: "",
      })
        .multiply(values.Quantity)
        .format();

      values.Discount = currency(values.Discount, {
        symbol: "",
        separator: "",
      }).format();

      values.Profit = currency(values.PriceSold, {
        symbol: "",
        separator: "",
      })
        .subtract(selectedItem.UnitCost)
        .multiply(values.Quantity)
        .format();

      console.log(values);

      handleEditItem({ ...selectedItem, ...values });
    },
  });

  useEffectOnce(() => {
    const UnitPrice = currency(selectedItem.UnitPrice, {
      symbol: "",
      separator: "",
    }).format();
    const PriceSold = currency(selectedItem.PriceSold, {
      symbol: "",
      separator: "",
    }).format();
    const Quantity = currency(selectedItem.Quantity, {
      symbol: "",
      separator: "",
      precision: 2,
      format: formatForQty,
    }).format();
    const Discount = currency(selectedItem.Discount, {
      symbol: "",
      separator: "",
    }).format();

    formik.setFieldValue("Quantity", Quantity);
    formik.setFieldValue("UnitPrice", UnitPrice);
    formik.setFieldValue("PriceSold", PriceSold);
    formik.setFieldValue("Discount", Number(Discount) ? Discount : "");

    if (selectedItem.Warranty) {
      const duration = selectedItem.Warrant_Duration.split(" ");

      formik.setFieldValue("Warranty", true);
      formik.setFieldValue("durationNumber", duration[0]);
      formik.setFieldValue("durationValue", duration[1]);
    }

    setTimeout(() => {
      const el = document.querySelector('input[name="Quantity"]');
      if (el) {
        el.focus();
        el.select();
        el.scrollIntoView({ behavior: "smooth" });
      }
    }, 50);
  });

  const handleDiscountChange = (discount) => {
    const finalSellingPrice = currency(formik.values.UnitPrice, {
      symbol: "",
      separator: "",
    })
      .subtract(discount)
      .format();
    formik.setFieldValue("PriceSold", finalSellingPrice);
    formik.setFieldValue("Discount", discount);
  };

  return (
    <Modal
      show={true}
      onHide={() => setEditedItemIndex(null)}
      dialogClassName="item-select-modal edit"
      backdropClassName={`global-backdrop`}
      centered={true}
      animation={false}
      enforceFocus={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h1>Edit Item Details</h1>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="body-content">
          <div className="postion-relative">
            {/*   <h2>Edit Item Details</h2> */}

            <Form noValidate onSubmit={formik.handleSubmit}>
              <Form.Group className="mb-2">
                <Form.Label className="slim">Item Name</Form.Label>
                <p className="selected">{selectedItem?.Item_Name || "..."} </p>
              </Form.Group>

              <Form.Group className="mb-3 d-flex flex-wrap justify-content-between gap-3 ">
                <div>
                  <Form.Label className="slim">Item Code</Form.Label>
                  <p className="selected"> {selectedItem?.Bar_Code || "..."}</p>
                </div>

                <div>
                  <Form.Label className="slim">Quantity in Stock</Form.Label>
                  <p className="selected">
                    {selectedItem?.quantityInStock || "..."}
                  </p>
                </div>

                <div>
                  <Form.Label className="slim">Cost Price</Form.Label>
                  <p className="selected">
                    {currency(selectedItem?.UnitCost, {
                      symbol: "",
                    }).format() || "..."}
                  </p>
                </div>
              </Form.Group>
              <hr />

              <h2 className="pt-0">Pricing Details</h2>

              <Form.Group className="mb-3 pb-1">
                <Form.Label>Unit Price</Form.Label>
                <CurrencyCustomInput
                  name="UnitPrice"
                  value={formik.values.UnitPrice}
                  isInvalid={
                    formik.touched.UnitPrice && !!formik.errors.UnitPrice
                  }
                  onValueChange={(value, name) =>
                    formik.setFieldValue(name, value)
                  }
                  placeholder="0.00"
                  decimalsLimit={2}
                />
                {formik.touched.UnitPrice && !!formik.errors.UnitPrice ? (
                  <span className="custom-invalid-feedback">
                    {formik.errors.UnitPrice}
                  </span>
                ) : null}
              </Form.Group>

              <Form.Group className="mb-3 pb-1">
                <Form.Label>
                  Discount <span className="slim">(optional)</span>
                </Form.Label>
                <CurrencyCustomInput
                  name="Discount"
                  value={formik.values.Discount}
                  onValueChange={(value, name) => handleDiscountChange(value)}
                  placeholder="0.00"
                />
              </Form.Group>

              <Form.Group className="mb-3 pb-1">
                <Form.Label>Final Selling price</Form.Label>
                <CurrencyCustomInput
                  name="PriceSold"
                  value={formik.values.PriceSold}
                  isInvalid={
                    formik.touched.PriceSold && !!formik.errors.PriceSold
                  }
                  onValueChange={(value, name) =>
                    formik.setFieldValue(name, value)
                  }
                  placeholder="0.00"
                  decimalsLimit={2}
                />
                {formik.touched.PriceSold && !!formik.errors.PriceSold ? (
                  <span className="custom-invalid-feedback">
                    {formik.errors.PriceSold}
                  </span>
                ) : null}
              </Form.Group>

              <Form.Group className="mb-3 pb-2">
                <Form.Label>Quantity</Form.Label>
                <NumberCustomInput
                  placeholder="0"
                  name="Quantity"
                  value={formik.values.Quantity}
                  onValueChange={(value, name) => {
                    formik.setFieldValue(name, value, true);
                  }}
                  isInvalid={
                    formik.touched.Quantity && !!formik.errors.Quantity
                  }
                />
              </Form.Group>

              <Form.Check
                type="switch"
                id="custom-switch"
                label="Enable warranty"
                className="mb-3"
                name="Warranty"
                checked={formik.values.Warranty}
                onChange={formik.handleChange}
              />

              <Form.Group className="mb-3 pb-2">
                <Form.Label>Set Duration</Form.Label>

                <div className="duration">
                  <NumberCustomInput
                    placeholder="0"
                    name="durationNumber"
                    value={formik.values.durationNumber}
                    onValueChange={(value, name) => {
                      formik.setFieldValue(name, value, true);
                    }}
                    // isInvalid={formik.touched.Quantity && !!formik.errors.Quantity}
                  />

                  <Select
                    classNamePrefix={"form-select"}
                    placeholder={"Month"}
                    value={durationOptions.find(
                      (el) => el.value === formik.values.durationValue
                    )}
                    options={durationOptions}
                    onChange={({ value }) =>
                      formik.setFieldValue("durationValue", value)
                    }
                  />
                </div>
              </Form.Group>

              <Button
                disabled={!selectedItem}
                type="submit"
                className="w-100 submit-btn"
              >
                Save Changes
              </Button>
            </Form>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
