import { Modal, Button } from "react-bootstrap";
import "./../assets/scss/new-entity-modal.scss";
import { useQuery } from "react-query";
import { useState, useEffect, useRef } from "react";
import { format } from "date-fns";

import PreviewFormOnlyComponent from "./Requisition/PreviewFormOnlyComponent";

export default function ViewPurchaseOrderModal({
  form,
  showImage,
  setShowImage,
  title,
  canFill,
  showTableCheckbox,
  showSendButton,
  saveForm,
  printForm,
}) {
  const [fetchingIframe, setFetchingIframe] = useState(false);

  return (
    <div>
      <Modal
        show={showImage}
        onHide={() => setShowImage(false)}
        centered={true}
        animation={false}
        enforceFocus={false}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h5>{title ? title : "..."}</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="h-100 d-flex align-items-center justify-content-center">
            {!fetchingIframe && (
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            )}
          </div>

          <div>
            <PreviewFormOnlyComponent
              selectedFilledForm={form}
              template={form?.filledFormData}
              setFetchingIframe={setFetchingIframe}
              size="30rem"
              canFill={canFill ? canFill : false}
              showTableCheckbox={showTableCheckbox ? showTableCheckbox : false}
              showSendButton={showSendButton}
              saveForm={saveForm}
              printForm={printForm}
            />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
