import { Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import CurrencyCustomInput from "../utils/CurrencyCustomInput";
import { useFormik } from "formik";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { useAuth } from "../../hooks/useAuth";
import { useStoreState } from "easy-peasy";
import { amountTypes } from "../../utils/helpers";
import Select from "react-select";
import { useMemo } from "react";
import currency from "currency.js";
import ConfirmDialog from "../ConfirmDialogue";

const adjustmentPositionOptions = [
  { label: "Increment (+)", value: "Increment" },
  { label: "Decrement (-)", value: "Decrement" },
];

function ItemPriceChangeModal({
  showItemPriceChangeModal,
  setShowItemPriceChangeModal,
  refetch,
}) {
  const generalSettings = useStoreState((state) => state?.generalSettings);
  const { backendUrl } = useAuth();

  const addConversion = async (payload) => {
    let response = await fetch(`${backendUrl}/api/items/price-change`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify({
        ...payload,
      }),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };

  const formik = useFormik({
    initialValues: {
      conversionAmount: generalSettings.dollarInBaseCurrency,
      adjustmentType: "",
      adjustmentPosition: "",
      percentage: 0,
      amount: 0,
      egAmount: 1500.0,
    },
    onSubmit: async (values) => {
      if (
        !(await ConfirmDialog({
          title: "Change Prices",
          description: `Are you sure?`,
        }))
      ) {
        return;
      }

      addConversionMutation.mutate(values);
    },
  });

  const addConversionMutation = useMutation(
    (payload) => addConversion(payload),
    {
      onError: () => {
        toast.error(`Unable to perform action`);
      },
      onSuccess: () => {
        toast.success(`Success`);
        refetch();
        setShowItemPriceChangeModal(false);
      },
    }
  );

  const finalSellingPrice = useMemo(() => {
    const percentage = currency(formik.values.percentage, {
      precision: 4,
    }).divide(100).value;

    const percentageValue = currency(formik.values.egAmount).multiply(
      percentage
    );

    if (formik.values.adjustmentType === "Percentage") {
      if (formik.values.adjustmentPosition === "Increment") {
        return currency(formik.values.egAmount).add(percentageValue).value;
      } else {
        return currency(formik.values.egAmount).subtract(percentageValue).value;
      }
    } else {
      if (formik.values.adjustmentPosition === "Increment") {
        return currency(formik.values.egAmount).add(formik.values.amount).value;
      } else {
        return currency(formik.values.egAmount).subtract(formik.values.amount)
          .value;
      }
    }
  }, [
    formik.values.adjustmentType,
    formik.values.egAmount,
    formik.values.amount,
    formik.values.percentage,
    formik.values.adjustmentPosition,
  ]);

  return (
    <>
      <Modal
        show={showItemPriceChangeModal}
        onHide={setShowItemPriceChangeModal}
        animation={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="h5">
            Unit Price Adjustment for All Items{" "}
          </Modal.Title>
        </Modal.Header>
        <Form noValidate onSubmit={formik.handleSubmit} autoComplete="off">
          <Modal.Body>
            <Form.Group className="mb-3">
              <Form.Label>Adjustment Type</Form.Label>
              <Select
                classNamePrefix={"form-select"}
                options={amountTypes}
                value={amountTypes.find(
                  (el) => el.value === formik.values.adjustmentType
                )}
                onChange={(selected) =>
                  formik.setFieldValue("adjustmentType", selected.value)
                }
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Direction</Form.Label>
              <Select
                classNamePrefix={"form-select"}
                options={adjustmentPositionOptions}
                value={adjustmentPositionOptions.find(
                  (el) => el.value === formik.values.adjustmentPosition
                )}
                onChange={(selected) =>
                  formik.setFieldValue("adjustmentPosition", selected.value)
                }
              />{" "}
            </Form.Group>

            {formik.values.adjustmentType === "Percentage" ? (
              <Form.Group className="col-md-3 mb-3 pb-2">
                <Form.Label className="mb-1">Percentage (%)</Form.Label>
                <Form.Control
                  type="number"
                  name="percentage"
                  value={formik.values.percentage}
                  onChange={formik.handleChange}
                />
              </Form.Group>
            ) : (
              <Form.Group className="col-md-5 mb-3 pb-2">
                <Form.Label className="mb-1">Amount</Form.Label>
                <CurrencyCustomInput
                  name="amount"
                  placeholder="0.00"
                  value={formik.values.amount}
                  onValueChange={(value, name) => {
                    formik.setFieldValue(name, value);
                  }}
                />
              </Form.Group>
            )}

            <hr />

            <p className="fw-bold h6">Example:</p>
            <Form.Group className="col-md-5 mb-3 pb-2">
              <Form.Label className="mb-1">eg Unit Price</Form.Label>
              <CurrencyCustomInput
                name="egAmount"
                placeholder="0.00"
                value={formik.values.egAmount}
                onValueChange={(value, name) => {
                  formik.setFieldValue(name, value);
                }}
              />
            </Form.Group>

            <Form.Group className="col-md-5 mb-3 pb-2">
              <Form.Label className="mb-1">eg Final Selling Price</Form.Label>
              <CurrencyCustomInput
                placeholder="0.00"
                value={finalSellingPrice}
                onValueChange={(value, name) => {}}
                disabled
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              type="button"
              nClick={() => setShowItemPriceChangeModal(false)}
            >
              Close
            </Button>
            <Button
              type="submit"
              variant="primary"
              disabled={addConversionMutation.isLoading}
            >
              {addConversionMutation?.isLoading
                ? "Please wait..."
                : " Save Changes"}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}

export default ItemPriceChangeModal;
