import { useMemo, useState } from "react";
import { NavLink } from "react-router-dom";
import Cookies from "universal-cookie";
import { useResolveRootRoles } from "../../utils/hooks";

export function BusinessDevelopmentCRMNav(props) {
  const { getRootRoles } = useResolveRootRoles();
  const cookies = new Cookies();
  const items = useMemo(() => {
    const navItems = [
      {
        name: "Customer List",
        to: "customer-list",
      },
      {
        name: "Job List",
        to: "job-list",
      },
      {
        name: "Documents & Certifications",
        to: "documents-certifications",
      },
      {
        name: "CRM",
        to: `${
          process.env.REACT_APP_FEXSPACE_CLOUD_DOMAIN
        }/api/login?t=${cookies.get("fextoken")}&redirect=${
          process.env.REACT_APP_FEXSPACE_COLLABORATION_DOMAIN
        }/crm&id=${cookies.get("comp_id")}`,
        type: "link",
      },
      {
        name: "Prospective Customers",
        to: "prospective-customers",
      },
      {
        name: "Email Campaign",
        to: "/send-customers-email",
      },
    ];

    return getRootRoles({
      parent: "Business Development/CRM",
      navItems,
    });
  });

  return (
    <div className="dashboard-tabs innerpage-tabs px-4">
      <ul className="nav nav-tabs">
        {items.map((item, index) => (
          <li key={index} className="nav-item">
            {item?.type !== undefined ? (
              <a className={`nav-link ga-nav-tab`} href={item.to}>
                {item.name}
              </a>
            ) : (
              <NavLink className={`nav-link ga-nav-tab`} to={item.to}>
                {item.name}
              </NavLink>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
}
