import currency from "currency.js";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import { useQuery } from "react-query";
// import { services } from "../../config";
import { useAuth } from "../../hooks/useAuth";
import useDebounce from "../../utils/hooks";
import { queryActions } from "../../utils/reactQueryActions";
import "./../../assets/scss/scoped/items-table.scoped.scss";
import queryString from "query-string";
import ReactPaginate from "react-paginate";
import { paginationOptions, fetchActionsUtil } from "../../utils/helpers";
import AddItemToWarehouse from "./AddItemToWarehouse";

export default function AllWarehouseItemsModal({
  handleSelectedItem,
  hideItemsNotInStock = false,
  plant = false,
}) {
  const { backendUrl } = useAuth();
  const [queryParams, setQueryParams] = useState({
    page: 1,
    limit: 50,
    q: "",
  });
  const [item, setItems] = useState({});

  const [openAddItem, setOpenAddItem] = useState(false);

  const debouncedqueryParams = useDebounce(queryParams, 500);

  //   const wareshouseLocal = JSON.parse(
  //     window.localStorage.getItem("warehouseName")
  //   );/all/in-progress

  useEffect(() => {
    setItems({});
  }, []);
  console.log(plant, hideItemsNotInStock);
  const { data } = useQuery(
    ["GET_ITEMS_PLANT_WAREHOUSE_", { debouncedqueryParams }],
    () =>
      plant
        ? fetchActionsUtil(
            `${backendUrl}/api/production/all/in-progress?&${queryString.stringify(
              queryParams
            )}`,
            "GET"
          )
        : fetchActionsUtil(
            `${backendUrl}/api/warehouse/items?&${queryString.stringify(
              queryParams
            )}`,
            "GET"
          ),
    {
      enabled: true,
      keepPreviousData: true,
    }
  );

  return (
    <div className="items-table-area rounded">
      <div className="d-flex justify-content-between">
        <div className="search-area m-3">
          <MagnifyIcon />
          <input
            className="form-control search-input"
            name="q"
            value={queryParams.q}
            onChange={(e) =>
              setQueryParams({
                ...queryParams,
                page: 1,
                [e.target.name]: e.target.value,
              })
            }
            placeholder="Search item..."
            autoFocus
            autoComplete="off"
            type={"text"}
          />
        </div>

        {/* <div className="m-3">
          <button
            onClick={() => setOpenAddItem(true)}
            className="btn btn-primary"
          >
            Create New
          </button>
        </div> */}
      </div>

      <div className="content">
        <Table borderless hover striped className="product-table">
          <thead className="sticky text-nowrap">
            <tr>
              <th scope="col">Item Code</th>
              <th scope="col">Item Name</th>
              <th scope="col">Unit Cost</th>
              <th scope="col">Unit Price</th>
            </tr>
          </thead>
          <tbody>
            {data?.items &&
              data?.items

                ?.filter((el) =>
                  hideItemsNotInStock ? Number(el.Quantity) > 0 : el
                )
                ?.map((el, index) => (
                  <tr
                    className="p-cursor"
                    key={index}
                    // onClick={() => handleSelectedItem && handleSelectedItem(el)}
                    onClick={() => setItems({ ...el, Quantity: 1 })}
                  >
                    <td>{el.Bar_Code}</td>
                    <td title={el?.Quantity}>{el.Item_Name}</td>
                    <td>
                      {currency(el.UnitCost, {
                        precision: 2,
                        symbol: "",
                      }).format()}
                    </td>
                    <td>
                      {currency(el.UnitPrice, {
                        precision: 2,
                        symbol: "",
                      }).format()}
                    </td>
                  </tr>
                ))}
          </tbody>
        </Table>
      </div>

      <div className="d-flex justify-content-between px-3 my-3 align-items-center pagination">
        <p className="m-0">
          Showing {data?.startIndex + 1} to{" "}
          {data?.endIndex <= data?.count ? data?.endIndex : data?.count} of{" "}
          {data?.count} entries
        </p>

        <ReactPaginate
          {...paginationOptions}
          pageCount={Math.ceil(data?.count / queryParams.limit)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={0}
          onPageChange={({ selected }) => {
            document.body.scrollTop = document.documentElement.scrollTop = 0;
            setQueryParams({
              ...queryParams,
              page: selected + 1,
            });
          }}
          forcePage={queryParams.page - 1}
        />
      </div>

      {Object.entries(item).length > 0 && (
        <div className="mt-2 mb-2 container">
          <div className="row">
            <div className="col-4 fw-bold">
              <p className="my-2">Bar Code: </p>
              <p className="my-2">Item Name: </p>
              <p className="my-2">Quantity: </p>
            </div>
            <div className="col-8 fw-bold">
              <p className="my-2">{item.Bar_Code}</p>
              <p className="my-2">{item.Item_Name} </p>
              <p className="my-2">
                <input
                  type="number"
                  value={item?.Quantity}
                  onChange={(e) =>
                    setItems({ ...item, Quantity: e.target.value })
                  }
                  className="border-1"
                />
              </p>
            </div>
          </div>

          <div className="d-flex justify-content-end">
            <button
              onClick={() => handleSelectedItem(item)}
              className="btn btn-primary btn-small my-3"
            >
              Add To List
            </button>
          </div>
          <AddItemToWarehouse
            show={openAddItem}
            // setShowCreateNewInventoryModal={setShowCreateNewInventoryModal}
          />
        </div>
      )}
    </div>
  );
}
