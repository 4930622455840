// import default style
import "./../../assets/scss/rsuite.scss";
import DateRangePicker from "rsuite/DateRangePicker";
import { CalendarIcon } from "../Icons";
import { useState } from "react";

export default function RsDateRangePicker(props) {
  const [date, setDate] = useState(props?.defaultValue);
  return (
    <DateRangePicker
      ranges={[]}
      format="dd MMM, yyyy"
      value={date}
      onChange={(value) => setDate(value)}
      caretAs={() => (
        <svg
          width="1.25em"
          height="1.25em"
          viewBox="0 0 18 18"
          fill="currentColor"
          aria-hidden="true"
          focusable="false"
          class="rs-picker-toggle-caret rs-icon"
          aria-label="calendar"
          data-category="legacy"
        >
          <path
            d="M13.167 2.5H16.5003C16.7213 2.5 16.9333 2.5878 17.0896 2.74408C17.2459 2.90036 17.3337 3.11232 17.3337 3.33334V16.6667C17.3337 16.8877 17.2459 17.0996 17.0896 17.2559C16.9333 17.4122 16.7213 17.5 16.5003 17.5H1.50033C1.27931 17.5 1.06735 17.4122 0.91107 17.2559C0.75479 17.0996 0.666992 16.8877 0.666992 16.6667V3.33334C0.666992 3.11232 0.75479 2.90036 0.91107 2.74408C1.06735 2.5878 1.27931 2.5 1.50033 2.5H4.83366V0.833336H6.50033V2.5H11.5003V0.833336H13.167V2.5ZM11.5003 4.16667H6.50033V5.83334H4.83366V4.16667H2.33366V7.5H15.667V4.16667H13.167V5.83334H11.5003V4.16667ZM15.667 9.16667H2.33366V15.8333H15.667V9.16667Z"
            fill="#000"
          />
        </svg>
      )}
      disabled={props.disabled}
      character=" - "
      contentEditable={false}
      {...props}
    />
  );
}
